import React from 'react';

// TODO: https://hello.jira.atlassian.cloud/browse/A4C-3424 Replace with @atlaskit/icon/core/automation
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/no-custom-icons */
import Icon from '@atlaskit/icon';

type AutomationGlyphProps = {
	testId?: string;
	color?: string;
};

export const AutomationGlyph = ({ testId, color = 'currentColor' }: AutomationGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={testId}
	>
		<path d="M14 5L10 19" stroke={color} strokeWidth="2" strokeLinecap="round" />
		<path
			d="M14 5L7 12H17L10 19"
			stroke={color}
			fill="transparent"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const AutomationIcon = ({ testId, color }: AutomationGlyphProps) => {
	const glyph = () => <AutomationGlyph testId={testId} color={color} />;
	return <Icon glyph={glyph} label="Automation" />;
};
