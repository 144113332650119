import React from 'react';

import ThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import type { CustomGlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const ThumbIconSvg = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="18"
		height="18"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="like">
			<path
				id="Vector"
				d="M13.5 3.99707C14.8255 3.99707 15.91 5.02861 15.9947 6.33269L16 6.49707V8.99907L18 9.00001C19.0544 9.00001 19.9182 9.81588 19.9945 10.8507L20 11V18C20 19.0544 19.1841 19.9182 18.1493 19.9945L18 20H12C11.8421 20 11.6869 19.9626 11.5471 19.8916L11.4453 19.8321L9.84806 18.766C9.56747 19.4422 8.92661 19.9311 8.16497 19.9933L8 20H6C4.94564 20 4.08183 19.1841 4.00549 18.1493L4 18V11C4 9.94564 4.81588 9.08184 5.85074 9.00549L6 9.00001H8C8.69234 9.00001 9.30251 9.35179 9.66152 9.88637C10.3961 9.63067 10.935 8.95661 10.9945 8.14927L11 8.00001V6.49707C11 5.11636 12.1193 3.99707 13.5 3.99707ZM7.99989 11H6V18H8L7.99989 11ZM13.5 5.99707C13.2545 5.99707 13.0504 6.17395 13.0081 6.40719L13 6.49707V8.00001C13 9.86357 11.7256 11.4295 10.0008 11.8738L10 16.4641L12.302 17.9991L18 18V11H15C14.4872 11 14.0645 10.614 14.0067 10.1166L14 10V6.49707C14 6.22093 13.7761 5.99707 13.5 5.99707Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export const ThumbsIcon = ({ isSelected }: { isSelected: boolean }) => (
	<ThumbsUpIcon
		color={isSelected ? token('color.icon.selected') : token('color.icon.subtle')}
		spacing="spacious"
		label=""
		LEGACY_fallbackIcon={ThumbIconSvg}
		LEGACY_size="medium"
		LEGACY_primaryColor={isSelected ? token('color.icon.selected') : token('color.icon.subtle')}
	/>
);
