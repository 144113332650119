import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { AIAgentResponseState, type AIAgentStreamingState } from '../models/AIAgentStreamingState';

import { type AIAgentRequestConfig } from './createAIAgentRequestConfig';
import { readStream } from './readStream';

export function makeAIAgentStreamRequest(
	requestConfig: AIAgentRequestConfig,
): BehaviorSubject<AIAgentStreamingState> {
	const behaviorSubject = new BehaviorSubject<AIAgentStreamingState>({
		content: '',
		responseState: AIAgentResponseState.Initialization,
	});

	fetch(requestConfig.url, {
		credentials: 'include',
		...requestConfig.requestInitParams,
	})
		.then((response) => readStream(response))
		.then(async (stream) => {
			let bufferContent = '';
			let answerChunkIndex = 0;
			for await (const chunk of stream) {
				const state = behaviorSubject.getValue();
				switch (chunk.type) {
					case 'ANSWER_PART':
						bufferContent += chunk.message.content;
						behaviorSubject.next({
							...state,
							responseState: AIAgentResponseState.AnswerPart,
							content: bufferContent,
							answerChunkIndex,
						});
						answerChunkIndex += 1;
						break;
					case 'FINAL_RESPONSE':
						bufferContent = chunk.message.message.content;
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							responseState: AIAgentResponseState.FinalResponse,
							content: bufferContent,
							metadata: {
								tokenData: chunk.message.usage_data,
							},
						});
						break;
					case 'FOLLOW_UP_QUERIES':
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							followUpContent: chunk.message.follow_ups ?? [],
							responseState: AIAgentResponseState.FollowUp,
						});
						break;
					case 'ERROR': {
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							responseState: AIAgentResponseState.Error,
							error: { ...chunk },
						});
						break;
					}
				}
			}
		})
		.finally(() => {
			behaviorSubject.complete();
		});

	return behaviorSubject;
}
