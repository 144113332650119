import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';
import type Token from 'markdown-it/lib/token';

import { createToken } from '../utils/create-token';

const bulletListOpen = 'bullet_list_open';
const bulletListClose = 'bullet_list_close';
const listItemOpen = 'list_item_open';
const listItemClose = 'list_item_close';
const taskListOpen = 'task_list_open';
const taskListClose = 'task_list_close';
const taskItemOpen = 'task_item_open';
const taskItemClose = 'task_item_close';
const paragraphOpen = 'paragraph_open';
const paragraphClose = 'paragraph_close';

function checkTypeByIndex(acc: Token[], type: string, indexOffset: number = -1): boolean {
	return acc[acc.length + indexOffset]?.type === type;
}

function getTokenByIndex(acc: Token[], indexOffset: number = -1): Token {
	return acc[acc.length + indexOffset];
}

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/max-params
function handleBulletListOpen(state: StateCore, token: Token, acc: Token[], index: number) {
	const isPreviousTokenInline = checkTypeByIndex(acc, 'inline');
	const isStartOfTaskList =
		state.tokens[index + 3].type === 'inline' && startsWithTodoMarkdown(state.tokens[index + 3]);

	// If the previous token is inline and our current token is a bullet_list_open that
	// means we have found the start of a nested list, due to current ADF limitations task items
	// cannot contain new task lists so first we must close the task item and then convert the
	// bullet_list_open to task_list_open
	if (isPreviousTokenInline) {
		acc.push(createToken(state, taskItemClose, getTokenByIndex(acc).level - 1));
		acc.push(createToken(state, taskListOpen, getTokenByIndex(acc).level));
	} else if (isStartOfTaskList) {
		acc.push(createToken(state, taskListOpen));
	} else {
		acc.push(token);
	}
}

function handleBulletListClose(state: StateCore, token: Token, acc: Token[]) {
	const isPreviousTokenTaskItemClose = checkTypeByIndex(acc, taskItemClose);
	const isPreviousTokenTaskListClose = checkTypeByIndex(acc, taskListClose);

	if (isPreviousTokenTaskItemClose || isPreviousTokenTaskListClose) {
		acc.push(createToken(state, taskListClose, getTokenByIndex(acc).level - 1));
	} else {
		acc.push(token);
	}
}

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/max-params
function handleListItemOpen(state: StateCore, token: Token, acc: Token[], index: number) {
	const newTaskItemOpen = createToken(state, taskItemOpen);
	const isTaskItem =
		state.tokens[index + 2].type === 'inline' && startsWithTodoMarkdown(state.tokens[index + 2]);
	const isPreviousTokenTaskItemClose = checkTypeByIndex(acc, taskItemClose);
	const isPreviousTokenListItemClose = checkTypeByIndex(acc, listItemClose);
	const isPreviousTokenTaskListClose = checkTypeByIndex(acc, taskListClose);

	if (isTaskItem) {
		// If the previous token is a list item close then we have run into some incorrect task list / list markdown
		// We need to complete the previous bullet list and then open a new task list
		if (isPreviousTokenListItemClose) {
			acc.push(createToken(state, bulletListClose));
			acc.push(createToken(state, taskListOpen));
		}

		newTaskItemOpen.meta = isDone(state.tokens[index + 2]);
		newTaskItemOpen.level = getTokenByIndex(acc).level + (isPreviousTokenTaskListClose ? 0 : 1);
		acc.push(newTaskItemOpen);
	} else {
		// If the previous token is a task item close but the current token is not a task item we need to
		// close the current task item list and then open a new bullet list
		if (isPreviousTokenTaskItemClose) {
			acc.push(createToken(state, taskListClose));
			acc.push(createToken(state, bulletListOpen));
		}
		acc.push(token);
	}
}

function handleListItemClose(state: StateCore, token: Token, acc: Token[]) {
	const isWithinTaskList =
		checkTypeByIndex(acc, 'inline') && checkTypeByIndex(acc, taskItemOpen, -2);

	if (isWithinTaskList) {
		acc.push(createToken(state, taskItemClose, getTokenByIndex(acc, -2).level));
	} else if (!checkTypeByIndex(acc, taskListClose)) {
		acc.push(token);
	}
}

function handleInline(token: Token, acc: Token[]) {
	if (token.children?.[0]) {
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		token.children![0].content = removeTodoMarkdown(token.children![0].content);
	}
	token.content = removeTodoMarkdown(token.content);
	token.level = getTokenByIndex(acc).level + 1;

	acc.push(token);
}

function handleParagraphs(token: Token, acc: Token[]) {
	const isWithinTaskItem =
		checkTypeByIndex(acc, taskItemOpen) || checkTypeByIndex(acc, taskItemOpen, -2);
	if (!isWithinTaskItem) {
		acc.push(token);
	}
}

function isInlineAfterTaskItemOpen(token: Token, acc: Token[]) {
	return token.type === 'inline' && acc[acc.length - 1].type === taskItemOpen;
}

function startsWithTodoMarkdown(token: Token): boolean {
	// leading whitespace in a list item is already trimmed off by markdown-it
	return (
		token.content.indexOf('[ ] ') === 0 ||
		token.content.indexOf('[x] ') === 0 ||
		token.content.indexOf('[X] ') === 0
	);
}

function removeTodoMarkdown(content: string): string {
	// Ignored via go/ees005
	// eslint-disable-next-line require-unicode-regexp
	const regex = /(\[x\]\s)|(\[\s\]\s)/;
	return content.replace(regex, '');
}

function isDone(token: Token): 'TODO' | 'DONE' {
	if (token.content.indexOf('[ ] ') === 0) {
		return 'TODO';
	} else {
		return 'DONE';
	}
}

export default function (md: MarkdownIt) {
	md.core.ruler.after('inline', 'github-task-lists', function (state: StateCore) {
		state.tokens = state.tokens.reduce((acc: Token[], token, index) => {
			const { type } = token;
			if (type === bulletListOpen) {
				handleBulletListOpen(state, token, acc, index);
			} else if (type === bulletListClose) {
				handleBulletListClose(state, token, acc);
			} else if (type === listItemOpen) {
				handleListItemOpen(state, token, acc, index);
			} else if (isInlineAfterTaskItemOpen(token, acc)) {
				handleInline(token, acc);
			} else if (type === listItemClose) {
				handleListItemClose(state, token, acc);
			} else if (type === paragraphOpen || type === paragraphClose) {
				handleParagraphs(token, acc);
			} else {
				acc.push(token);
			}
			return acc;
		}, []);
	});
}
