import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import AutomationIcon from '@atlaskit/icon/core/automation';

import { AutomationIcon as LegacyAutomationIcon } from '@confluence/icons/entry-points/AutomationIcon';

const i18n = defineMessages({
	automationGroupedPageButtonAriaLabel: {
		id: 'automation-menu.automation-grouped-page-button.tooltip.aria-label',
		description: 'Aria label of the automation menu.',
		defaultMessage: 'Automation menu',
	},
	automationGroupedPageButtonsIconLabel: {
		id: 'automation-menu.automation-grouped-page-button.icon.label',
		description: 'Label for the icon of the automation menu.',
		defaultMessage: 'Automation menu icon',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025', '2px'),
		paddingRight: token('space.025', '2px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span<{ isSelected?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: `${token('space.400', '32px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isSelected }) =>
		isSelected ? token('color.background.accent.blue.subtlest', '#DEEBFF') : '',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isSelected }) => (isSelected ? token('color.icon.information', '#0065FF') : ''),
	borderRadius: token('space.050', '4px'),
});

interface AutomationMenuButtonProps {
	isSelected: boolean;
	onClickWithAnalytics: () => void;
	shouldShowPulse?: boolean;
	showSpotlight?: () => void;
}

export const AutomationMenuButton = forwardRef<HTMLElement, AutomationMenuButtonProps>(
	(
		{ isSelected, onClickWithAnalytics, shouldShowPulse, showSpotlight }: AutomationMenuButtonProps,
		ref,
	) => {
		const { formatMessage } = useIntl();

		return (
			<StyledButton
				ref={ref}
				appearance="subtle"
				isSelected={isSelected}
				onClick={shouldShowPulse ? showSpotlight : onClickWithAnalytics}
				testId="automation-menu.ui.button.menu-button"
				aria-label={formatMessage(i18n.automationGroupedPageButtonAriaLabel)}
				aria-expanded={isSelected}
				iconBefore={
					<IconWrapper isSelected={isSelected}>
						<AutomationIcon
							label=""
							color="currentColor"
							LEGACY_fallbackIcon={LegacyAutomationIcon}
							spacing="spacious"
						/>
					</IconWrapper>
				}
			/>
		);
	},
);
