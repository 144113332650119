import {
	ConfluenceExtension,
	type ForgeUIExtensionType,
	type ConfluenceExtensionType,
} from '@atlassian/forge-ui-types';
import { createMakeProductURLPath } from '../createMakeProductURLPath';
import { type TargetURLUtils, getExtensionByModuleKey } from './common';

type FullForgeUIExtensionType = ForgeUIExtensionType & {
	properties: ForgeUIExtensionType['properties'] & {
		route?: string;
	};
};

const makeConfluenceURLPath = createMakeProductURLPath('/wiki');

const isCustomContentType = (contentType: string) => contentType.startsWith('forge:');

export const confluenceExtensionURLHelpers: Partial<
	Record<
		ConfluenceExtensionType,
		(extension: FullForgeUIExtensionType, params?: any) => string | null
	>
> = {
	[ConfluenceExtension.GLOBAL_PAGE]: ({ properties, environmentId, appId }) => {
		const { route } = properties;
		if (appId && environmentId && route) {
			return makeConfluenceURLPath(`/apps/${appId}/${environmentId}/${route}`);
		}
		return null;
	},
	[ConfluenceExtension.GLOBAL_SETTINGS]: ({ id }) => makeConfluenceURLPath('/admin/forge', { id }),
	[ConfluenceExtension.SPACE_PAGE]: (
		{ properties, environmentId, appId },
		{ spaceKey }: { spaceKey: string },
	) => {
		const { route } = properties;
		if (spaceKey && route && environmentId && appId) {
			return makeConfluenceURLPath(`/spaces/${spaceKey}/apps/${appId}/${environmentId}/${route}`);
		}
		return null;
	},
	[ConfluenceExtension.SPACE_SETTINGS]: (
		{ properties, appId, environmentId },
		{ spaceKey }: { spaceKey: string },
	) => {
		const { route } = properties;
		if (spaceKey && route && environmentId && appId) {
			return makeConfluenceURLPath(
				`/spaces/${spaceKey}/settings/apps/${appId}/${environmentId}/${route}`,
			);
		}
		return null;
	},
};

export const confluenceNavigationTargetURLHelpers = {
	contentView: async ({ contentId, version }: { contentId: string; version?: number }) => {
		const resp = await fetch(`/wiki/rest/api/content/${contentId}`);
		const {
			id,
			type,
			_links: { webui },
		} = await resp.json();

		// if version is provided, we should use it, however, it only works for none-custom content type
		if (version && version >= 0) {
			if (isCustomContentType(type)) {
				return null;
			}
			return makeConfluenceURLPath('/pages/viewpage.action', {
				pageId: id,
				pageVersion: `${version}`,
			});
		} else if (webui) {
			return makeConfluenceURLPath(webui);
		}
		return null;
	},
	contentEdit: async ({ contentId }: { contentId: string }) => {
		const resp = await fetch(`/wiki/rest/api/content/${contentId}`);
		const {
			_links: { edituiv2 },
		} = await resp.json();

		// content with custom content type doesn't have edit link
		if (edituiv2) {
			return makeConfluenceURLPath(edituiv2);
		}
		return null;
	},
	spaceView: async ({ spaceKey }: { spaceKey: string }) =>
		makeConfluenceURLPath(`/spaces/${spaceKey}`),
	contentList: async (
		params:
			| {
					contentType: 'page' | 'blogpost';
					spaceKey: string;
			  }
			| {
					contentType: 'customContent';
					moduleKey: string;
			  },
		{ getAppForgeUIExtensions }: TargetURLUtils,
	) => {
		if (params.contentType === 'customContent' && params.moduleKey) {
			const { moduleKey } = params;
			const extension = await getExtensionByModuleKey(getAppForgeUIExtensions, moduleKey);
			if (!extension) {
				return null;
			}
			const { appId, environmentId } = extension;
			if (appId && environmentId) {
				return makeConfluenceURLPath('/search', {
					type: `forge:${appId}:${environmentId}:${moduleKey}`,
				});
			}
		} else if (params.contentType === 'page' && params.spaceKey) {
			return makeConfluenceURLPath(`/spaces/${params.spaceKey}/pages`);
		} else if (params.contentType === 'blogpost' && params.spaceKey) {
			return makeConfluenceURLPath(`/spaces/${params.spaceKey}/blogs`);
		}

		return null;
	},
} as const;
