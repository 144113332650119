import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import noop from 'lodash/noop';
import { styled } from '@compiled/react';

import AkWarningIcon from '@atlaskit/icon/core/migration/warning';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { N0, N500, N700, N90, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@confluence/typography-placeholder';

type BannerContainerProps = {
	height: number;
	isWarning: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContainer = styled.div<BannerContainerProps>({
	width: '100%',
	padding: token('space.0', '0px'),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => `${props.height}px`,
	textAlign: 'center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isWarning
			? token('color.background.warning.bold', Y300)
			: token('color.background.accent.gray.bolder', N500),
});

const MAX_BANNER_HEIGHT = gridSize * 3.5; // 28px when gridSize = 8px
const BANNER_FONT_SIZE = MAX_BANNER_HEIGHT / 2; // 14px when gridSize = 8px

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Banner = styled.div<{ isWarning: boolean }>({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${MAX_BANNER_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isWarning
			? token('color.background.warning.bold', Y300)
			: token('color.background.accent.gray.bolder', N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isWarning ? token('color.text.warning.inverse', N700) : token('color.text.inverse', N0),
	// Disabling the @atlaskit/design-system/use-tokens-typography rule because the fontSize needs to factor in the gridSize.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	fontSize: `${BANNER_FONT_SIZE}px`,
	fontWeight: token('font.weight.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContent = styled.div({
	margin: `${token('space.0', '0px')} ${token('space.600', '48px')}`,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerText = styled.span({
	padding: token('space.050', '4px'),
});

const BANNER_DISMISS_BUTTON_SIZE = gridSize * 3; // 24px when gridSize = 8px
const BANNER_DISMISS_BUTTON_RIGHT_OFFSET = gridSize * 1.25; // 10px when gridSize = 8px

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerDismissButton = styled.div<{ isWarning: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BANNER_DISMISS_BUTTON_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${BANNER_DISMISS_BUTTON_SIZE}px`,
	cursor: 'pointer',
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	right: `${BANNER_DISMISS_BUTTON_RIGHT_OFFSET}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isWarning
			? token('color.background.warning.bold', Y300)
			: token('color.background.accent.gray.bolder', N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isWarning ? token('color.text.warning.inverse', N700) : token('color.text.inverse', N0),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) =>
			props.isWarning ? token('color.text.warning.inverse', N90) : token('color.text.inverse', N90),
	},
});

const BannerMessages = defineMessages({
	closeLabel: {
		id: 'banners.dismissible-banner.close-label',
		defaultMessage: 'Close',
		description: 'Accessibility label for close button',
	},
});

export type DismissibleBannerProps = {
	height: number;
	children: ReactNode;
	onView?: () => void;
	onDismiss?: () => void;
	isWarning?: boolean;
};

export const DismissibleBanner: FC<DismissibleBannerProps> = ({
	height,
	children,
	onView = noop,
	onDismiss = noop,
	isWarning = false,
}) => {
	const intl = useIntl();
	useEffect(() => {
		onView();
	}, [onView]);

	// This constant is copied from the <AKBanner> source code as our custom <Banner> is replacing an
	// <AkBanner>.  This code has 2 purposes: to add the accessibility attributes of the <AkBanner>
	// so screen readers work the same with the custom banner, and to keep the unit tests functioning
	// as the tests are looking for the aria-label.
	const accessibilityProps = useMemo(() => {
		const baseProps = {
			role: 'alert',
		};

		if (!isWarning) {
			return {
				...baseProps,
				'aria-label': 'announcement',
				tabIndex: 0,
				role: 'region',
			};
		}

		return baseProps;
	}, [isWarning]);

	return (
		<BannerContainer data-testid="banner-container" height={height} isWarning={isWarning}>
			<BannerContent>
				<Banner isWarning={isWarning} {...accessibilityProps}>
					{isWarning && (
						<AkWarningIcon
							spacing="spacious"
							label="Warning icon"
							LEGACY_secondaryColor={token('color.background.warning.bold', Y300)}
						/>
					)}
					<BannerText>{children}</BannerText>
				</Banner>
			</BannerContent>
			<BannerDismissButton
				data-testid="banner-dismiss-button"
				onClick={onDismiss}
				isWarning={isWarning}
				role="button"
				tabIndex={0}
			>
				<EditorCloseIcon spacing="spacious" label={intl.formatMessage(BannerMessages.closeLabel)} />
			</BannerDismissButton>
		</BannerContainer>
	);
};
