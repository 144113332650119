import { defineMessages } from 'react-intl-next';

import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';

import { useCommentsPanel, type CurrentView } from './hooks/useCommentsPanel';
import { CommentsPanelHeaderBadge } from './CommentsPanelHeaderBadge';
import { CommentsPanelWrapper } from './CommentsPanelWrapper';
import { useUnreadComments } from './hooks/useUnreadComments';

export const i18n = defineMessages({
	commentsPanelHeaderLabel: {
		id: 'comments-panel.panel.header.label',
		defaultMessage: 'Comments',
		description: 'Label for the comments panel header',
	},
});

export const useShowCommentsPanel = () => {
	const { handleMarkCommentsAsRead } = useUnreadComments();
	const [{ commentIdsByAnnotationToMarkAsRead }] = useCommentsPanel();
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar, hideObjectSidebar }] =
		useObjectSidebar();

	const [, { setCurrentView }] = useCommentsPanel();

	const onPanelClose = () => {
		setCurrentView(undefined);
		if (Object.keys(commentIdsByAnnotationToMarkAsRead)?.length > 0) {
			handleMarkCommentsAsRead();
		}
	};

	const canClosePanel = () => {
		// TODO: Check if the content has changed before we allow the closing of the panel
		return true;
	};

	const showCommentsPanel = ({ openWithView }: { openWithView?: CurrentView } = {}) => {
		showObjectSidebar(
			{
				id: PanelName.CommentsPanel,
				headerComponentElements: {
					headerLabel: i18n.commentsPanelHeaderLabel,
					HeaderAfterElement: CommentsPanelHeaderBadge,
				},
				BodyComponent: () => CommentsPanelWrapper({ openWithView }),
				closeOptions: {
					canClosePanel,
					onPanelClose,
				},
			},
			'push',
		);
	};

	// NOTE: Exposing the hide function for now while we have the CommentButton
	return {
		isCommentsPanelShown: isObjectSidebarShown && panel?.id === PanelName.CommentsPanel,
		showCommentsPanel,
		hideCommentsPanel: hideObjectSidebar,
	};
};
