import type { FC } from 'react';
import React, { memo, useContext, useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import {
	ONBOARDING_EDITOR_RECENT_DRAFT_SPOTLIGHT_EXPERIENCE,
	APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { OnboardingCTANudge } from '@confluence/onboarding-nudge-tooltip';
import { SSRMouseEventWrapper, SSR_NAV_PAGES_BUTTON_METRIC } from '@confluence/browser-metrics';
import { RecentPagesLazy as RecentPages, preloadRecentPages } from '@confluence/recent-pages';
import { useRouteDataRef } from '@confluence/route-manager';
import { useSessionData } from '@confluence/session-data';
import { EDITOR_ONBOARDING_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { TopNavItemNudgeWrappable } from '@confluence/onboarding-hover-nudge/entry-points/BorderNudge';
import { HOVER_TARGET } from '@confluence/onboarding-hover-nudge/entry-points/constants/HoverTarget';
import { useOnboardingNewHomeTakeoverEnabled } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingNewHomeTakeover';
import {
	useSetOnboardingState,
	useGetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { GO_TO_RECENT_SHORTCUT } from '@confluence/shortcuts';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import type { PrimaryItemProps } from './PrimaryItem';
import { PrimaryDropdownItem } from './PrimaryDropdownItem';
import type { ClosePopup } from './PrimaryDropdownItem';

const i18n = defineMessages({
	pagesButton: {
		id: 'app-navigation.recent.link',
		defaultMessage: 'Recent',
		description: 'Label for the recent dropdown',
	},
	yourWorkButton: {
		id: 'app-navigation.your-work.link',
		defaultMessage: 'Your work',
		description: 'Label for the Your work dropdown',
	},
	nudgeTooltip: {
		id: 'app-navigation.editor-onboarding.nudge.tooltip',
		defaultMessage: 'Need to get back to a draft? Find it in the <b>Recent</b> tab.',
	},
	nudgeTooltipLivePages: {
		id: 'app-navigation.editor-onboarding.nudge.tooltip.live-pages',
		defaultMessage: 'Need to get back to a page? Find it in the <b>Recent</b> tab.',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NudgeFixer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireRecentsClickedAnalytics,
);

const PrimaryDropdownContent: FC<ClosePopup> = ({ closePopup }) => {
	return <RecentPages closePopup={closePopup} />;
};

export const PagesItem: FC<PrimaryItemProps> = memo(({ testId }) => {
	const { cloudId } = useSessionData();
	const routeDataRef = useRouteDataRef();
	const [isNudgeHidden, setIsNudgeHidden] = useState(true);
	const [forceOpen, setForceOpen] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { isEnabled: isOnboardingNewHomeTakeoverEnabled } = useOnboardingNewHomeTakeoverEnabled();

	const { data, loading, error } = useGetOnboardingState(
		Object.values(EDITOR_ONBOARDING_STATE_KEYS),
		!fg('cc_onboarding_experience'),
	);
	const { setOnboardingState } = useSetOnboardingState();

	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();
	const isWhiteboardViewedEnabled = isWhiteboardFeatureEnabled('whiteboardsEnabled');
	const isWhiteboardUpdatedEnabled = isWhiteboardFeatureEnabled('whiteboardsEnabled');
	const isDatabaseEnabled = isContentTypeEnabledInCurrentEnv('database');
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	// Start Experience Tracker
	useEffect(() => {
		experienceTracker.start({
			name: ONBOARDING_EDITOR_RECENT_DRAFT_SPOTLIGHT_EXPERIENCE,
		});
	}, [experienceTracker]);

	useEffect(() => {
		if (data && !loading && !error) {
			const editorOnboardingEligiblity =
				data?.onboardingState?.find(
					(prop) => prop.key === EDITOR_ONBOARDING_STATE_KEYS.EDITOR_ONBOARDING_ELIGIBLE_NEW,
				)?.value === 'true';

			const recentSpotlightOpen =
				data?.onboardingState?.find(
					(prop) => prop.key === EDITOR_ONBOARDING_STATE_KEYS.RECENT_SPOTLIGHT_OPEN,
				)?.value === 'true';

			// If the spotlight hasn't been seen before
			if (editorOnboardingEligiblity && recentSpotlightOpen) {
				setIsNudgeHidden(false);
				// Change onboarding state to only show the banner once
				void setOnboardingState({
					key: EDITOR_ONBOARDING_STATE_KEYS.RECENT_SPOTLIGHT_SEEN,
					value: 'true',
				});

				void setOnboardingState({
					key: EDITOR_ONBOARDING_STATE_KEYS.RECENT_SPOTLIGHT_OPEN,
					value: 'false',
				});

				// End Experience Tracker
				experienceTracker.succeed({
					name: ONBOARDING_EDITOR_RECENT_DRAFT_SPOTLIGHT_EXPERIENCE,
				});
			}
		} else if (error && !loading) {
			// Fail Experience Tracker
			experienceTracker.fail({
				name: ONBOARDING_EDITOR_RECENT_DRAFT_SPOTLIGHT_EXPERIENCE,
				error,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, loading, error]);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const onOpen = useCallback(() => {
		setIsNudgeHidden(true);
		setForceOpen(true);
		void onClick(true);
	}, [onClick]);

	const onHover = useCallback(() => {
		void RecentPages.preload();
		void preloadRecentPages({
			cloudId,
			isWhiteboardViewedEnabled,
			isWhiteboardUpdatedEnabled,
			isDatabaseEnabled,
		});
	}, [cloudId, isWhiteboardViewedEnabled, isWhiteboardUpdatedEnabled, isDatabaseEnabled]);

	const tooltipMessage = fg('confluence_live_pages_ab_test_opted_in')
		? i18n.nudgeTooltipLivePages
		: i18n.nudgeTooltip;
	const labelMessage = i18n.pagesButton;
	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_PAGES_BUTTON_METRIC}>
			<OnboardingCTANudge
				hidden={isNudgeHidden}
				position="bottom-start"
				wrapper={NudgeFixer}
				content={
					<FormattedMessage
						{...tooltipMessage}
						values={{
							b: (...chunks) => <b>{chunks}</b>,
						}}
					/>
				}
				source="onboardingNudge"
				actionSubjectId="recentItem"
				width={280}
				renderChildrenWhileLoading
				dataVC="app-navigation-primary-pages-dropdown"
			>
				<TopNavItemNudgeWrappable
					target={HOVER_TARGET.RECENT_PAGES}
					shouldWrap={isOnboardingNewHomeTakeoverEnabled}
				>
					<PrimaryDropdownItem
						testId={testId}
						i18n={labelMessage}
						ContentComponent={PrimaryDropdownContent}
						collapsedTestId="app-navigation-collapsed-pages"
						onOpen={onOpen}
						onClose={() => onClick(false)}
						onHover={onHover}
						keyboardShortcut={
							isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')
								? undefined
								: GO_TO_RECENT_SHORTCUT
						}
						experienceName={APP_NAV_PAGES_DROPDOWN_EXPERIENCE}
						forceOpen={forceOpen}
					/>
				</TopNavItemNudgeWrappable>
			</OnboardingCTANudge>
		</SSRMouseEventWrapper>
	);
});
