import type { ApolloError } from 'apollo-client';
import { useEffect, useCallback } from 'react';
import { addMonths, isBefore } from 'date-fns';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';

import { useSitePersonalizationQuery } from '../useSitePersonalization/useSitePersonalizationQuery';
import { QUICKSTART_STATE_KEYS } from '../../constants/onboarding-state/onboarding-quickstart-constants';
import {
	useGetOnboardingState,
	useSetOnboardingState,
	deserializeState,
} from '../onboardingState/onboardingState';

import {
	ACTIVATION_DATE_KEY,
	CONFLUENCE_MIGRATION_STATUS_KEY,
	CONFLUENCE_FIRST_MIGRATION_SIGNAL_DATE_KEY,
	ENTITLEMENT_TYPE_KEY,
	MONTHS_UNTIL_MIGRATIONS_EXPIRY,
	MIGRATIONS_ENTITLEMENT_TYPE,
	ADDED_MIGRATED_USER_TAG,
} from './constants';

interface MigrationState {
	isMigratedUser: boolean;
	isMigratedUserReady: boolean;
	error: ApolloError | undefined;
	skipQuery: boolean;
}

interface Options {
	skip?: boolean;
}

export const useMigratedUser = ({ skip = false }: Options = {}): MigrationState => {
	const { isLicensed, isLoggedIn, cloudId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setOnboardingState } = useSetOnboardingState();

	const fireMigrationsAnalytics = useCallback(
		(action: string, migrationAttributes: object): void => {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: 'useMigratedUser',
					actionSubject: 'isMigratedUser',
					action,
					attributes: migrationAttributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	let isMigratedUser: boolean = false;

	// Fetch onboarding state so we can see if the user is already migrated or untagged
	const {
		data,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		Object.values([QUICKSTART_STATE_KEYS.IS_MIGRATED_USER_V2]),
		// skip query if Anonymous User
		!isLoggedIn || !isLicensed || skip,
	);
	const { isMigratedUserV2 } = deserializeState(data);
	const isUntaggedUser: boolean = !onboardingStateLoading && isMigratedUserV2 === undefined;

	// Skip useSitePersonalizationQuery if the user is already migrated or is an untagged user
	const eligibleToBeTaggedAsMigratedUser: boolean = isMigratedUserV2 || isUntaggedUser;

	const skipQuery = skip || onboardingStateLoading || !eligibleToBeTaggedAsMigratedUser;

	const {
		loading: sitePersonalizationQueryLoading,
		error: sitePersonalizationQueryError,
		attributes,
	} = useSitePersonalizationQuery({
		cloudId,
		skip: skipQuery,
	});

	const sitePersonalizationDataAttributes = attributes?.reduce<Record<string, string>>(
		(obj, item) => ({ ...obj, [item.name]: item.value }),
		{},
	);

	const entitlementType: string | undefined =
		sitePersonalizationDataAttributes?.[ENTITLEMENT_TYPE_KEY];
	const activationDate: string | undefined =
		sitePersonalizationDataAttributes?.[ACTIVATION_DATE_KEY];
	const confluenceMigrationStatus: string | undefined =
		sitePersonalizationDataAttributes?.[CONFLUENCE_MIGRATION_STATUS_KEY];

	const shouldSkip =
		skipQuery ||
		onboardingStateLoading ||
		onboardingStateError ||
		sitePersonalizationQueryLoading ||
		sitePersonalizationQueryError;

	const confluenceFirstMigrationSignalDate: string | undefined =
		sitePersonalizationDataAttributes?.[CONFLUENCE_FIRST_MIGRATION_SIGNAL_DATE_KEY];

	/**
	 * Setting the variables based off of traits:
	 * - Use the signal date if V2 traits are backfilled
	 * - Otherwise fall back to entitlement type and activation date
	 */
	const isMigratedTraitActive: boolean = confluenceMigrationStatus === 'true';

	const migrationsStartDate: string | undefined = isMigratedTraitActive
		? confluenceFirstMigrationSignalDate
		: activationDate;

	const extendedTrialEligible = entitlementType === MIGRATIONS_ENTITLEMENT_TYPE;

	const isMigratedSite: boolean = isMigratedTraitActive || extendedTrialEligible;

	useEffect(() => {
		if (shouldSkip) {
			return;
		}

		if (!isMigratedSite) {
			// if the trait hasn't been populated yet, skip setting the key until we have V2 traits
			// the exception is when the user has been previously tagged as migrated and no longer qualifies
			if (confluenceMigrationStatus !== undefined || isMigratedUserV2) {
				if (isMigratedUserV2 !== false) {
					void setOnboardingState({
						key: QUICKSTART_STATE_KEYS.IS_MIGRATED_USER_V2,
						value: 'false',
					});
				}
			}
		} else if (migrationsStartDate) {
			const migrationPeriodEligible = getMigrationPeriodEligible(migrationsStartDate);

			// The user is not within the migrations expiry period, set state to False and return False in the hook
			if (!migrationPeriodEligible) {
				if (isMigratedUserV2 !== false) {
					void setOnboardingState({
						key: QUICKSTART_STATE_KEYS.IS_MIGRATED_USER_V2,
						value: 'false',
					});
				}
			} else if (isMigratedUserV2 !== true) {
				/**
				 * The user has met all eligibility requirements to be considered a migrated user
				 *
				 * Fire analytics for untagged users
				 * Set isMigratedUser to True in onboarding state, and return True in the hook
				 */
				void setOnboardingState({
					key: QUICKSTART_STATE_KEYS.IS_MIGRATED_USER_V2,
					value: 'true',
				});

				fireMigrationsAnalytics(ADDED_MIGRATED_USER_TAG, {
					isMigratedUser,
				});
			}
		}
	}, [
		shouldSkip,
		isMigratedSite,
		migrationsStartDate,
		isMigratedUserV2,
		confluenceMigrationStatus,
		setOnboardingState,
		isMigratedUser,
		fireMigrationsAnalytics,
	]);

	if (shouldSkip) {
		return {
			isMigratedUser: false,
			isMigratedUserReady: true,
			error: sitePersonalizationQueryError,
			skipQuery,
		};
	}

	/**
	 * Setting the migrated user logic starts here:
	 *
	 * The first disqualifying V2 logic - confluence migration status (trait) is false AND the entitlement type is not extended trial
	 * If already tagged as migrated in onboarding state, fire endMigratedSiteEligible and set to False (previously confluence_migration_status = true, or entitlement_type = extended_trial)
	 * If no prior tag in onboarding state, fire migratedSiteIneligible and set to False
	 *
	 * Edge case: if the V2 traits aren't backfilled yet, don't set state so we can run again next time and temporarily return False
	 * Edge case: if the V2 traits aren't backfilled but the user was previously tagged as migrated with extended trial, set state to False
	 */
	if (!isMigratedSite) {
		return {
			isMigratedUser: false,
			isMigratedUserReady: !sitePersonalizationQueryLoading && !sitePersonalizationQueryError,
			error: sitePersonalizationQueryError,
			skipQuery,
		};
	}

	/**
	 * Check the second disqualifying condition: whether users fall outside the migrations expiry period
	 * Note: migrationsStartDate can either be activation date OR signal date depending on confluence_migration_status
	 */
	if (migrationsStartDate) {
		const migrationPeriodEligible = getMigrationPeriodEligible(migrationsStartDate);

		if (!migrationPeriodEligible) {
			return {
				isMigratedUser: false,
				isMigratedUserReady: !sitePersonalizationQueryLoading && !sitePersonalizationQueryError,
				error: sitePersonalizationQueryError,
				skipQuery,
			};
		}

		isMigratedUser = true;
	}

	return {
		isMigratedUser,
		isMigratedUserReady: !onboardingStateLoading && !sitePersonalizationQueryLoading,
		error: onboardingStateError || sitePersonalizationQueryError,
		skipQuery,
	};
};

const getMigrationPeriodEligible = (migrationsStartDate: string) => {
	const migrationsExpiryDate = addMonths(
		new Date(migrationsStartDate),
		MONTHS_UNTIL_MIGRATIONS_EXPIRY,
	);

	return isBefore(new Date(), migrationsExpiryDate);
};
