import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

import { expVal } from '@confluence/feature-experiments';

import type { UnreadCommentQuery_comments_nodes as UnreadCommentNode } from '../__types__/UnreadCommentQuery';
import type {
	MarkerRefToCommentIdMap,
	UnreadInlineComment,
	UnreadInlineCommentsState,
} from '../types/unreadCommentsTypes';

const initialState: UnreadInlineCommentsState = {
	unreadCommentsListState: [], // list of unread parent comment and reply IDs and their marker refs
	readCommentsListState: new Set(), // list of read parent comment and reply IDs
	shouldUpdateUnreadComments: false,
	shouldUpdateUnreadCommentsOnFirstRender: true,
	markerRefToCommentIdMap: {}, // maps each marker ref to a list of comments, with the first comment as the parent comment
	allInlineComments: [], // list of all parent comment and reply information, both read and unread
	isUnreadQueryLoading: false,
};

const actions = {
	updateUnreadCommentsListState:
		(
			transform: (
				prevUnreadCommentsListState: UnreadInlineComment[],
				markerRefToCommentIdMap: MarkerRefToCommentIdMap,
			) => UnreadInlineComment[],
		) =>
		({ setState, getState }: StoreActionApi<UnreadInlineCommentsState>) => {
			const { unreadCommentsListState, markerRefToCommentIdMap } = getState();
			setState({
				unreadCommentsListState: transform(unreadCommentsListState, markerRefToCommentIdMap),
			});
		},
	updateReadCommentsListState:
		(transform: (prevReadCommentsListState: Set<string>) => Set<string>) =>
		({ setState, getState }: StoreActionApi<UnreadInlineCommentsState>) => {
			const { readCommentsListState } = getState();
			setState({
				readCommentsListState: transform(readCommentsListState),
			});
		},
	updateUnreadComments:
		(shouldUpdateUnreadComments: boolean) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ shouldUpdateUnreadComments });
		},
	updateUnreadCommentsOnFirstRender:
		(shouldUpdateUnreadCommentsOnFirstRender: boolean) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ shouldUpdateUnreadCommentsOnFirstRender });
		},
	updateMarkerRefToCommentIdMap:
		(transform: (markerRefToCommentIdMap: MarkerRefToCommentIdMap) => MarkerRefToCommentIdMap) =>
		({ setState, getState }: StoreActionApi<UnreadInlineCommentsState>) => {
			const { markerRefToCommentIdMap } = getState();
			setState({
				markerRefToCommentIdMap: transform(markerRefToCommentIdMap),
			});
		},
	updateAllInlineComments:
		(allInlineComments: UnreadCommentNode[]) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ allInlineComments });
		},
	setIsUnreadQueryLoading:
		(loading: boolean) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ isUnreadQueryLoading: loading });
		},
};

const UnreadInlineCommentsStore = createStore({
	initialState,
	actions,
	name: 'UnreadInlineCommentsStore',
});

export const useUnreadInlineComments = createHook(UnreadInlineCommentsStore);

export const useUnreadCommentsIsEnabled = () => {
	return (
		expVal<'control' | 'dot' | 'count'>('unread_comments_navigation', 'cohort', 'control') !==
		'control'
	);
};
