import { useEffect } from 'react';

import type { Column } from '../providers/BulkConfigureContextProvider';
import { useMappingsActions } from '../providers/BulkConfigureContextProvider';
import { useIssueCreateSidePanelContext } from '../providers/IssueCreateSidePanelContextProvider';

export const useSetOptionsColumns = () => {
	const { setOptionsColumns } = useMappingsActions();
	const [{ selectedTable }] = useIssueCreateSidePanelContext();

	useEffect(() => {
		if (selectedTable) {
			const columns: Column[] = [];

			let columnIndex = 0;
			// Always use the first row as the header row, even if it is not a HTML header row
			const firstRow = selectedTable?.querySelector('tbody tr:first-child');
			if (firstRow) {
				const firstRowCells = firstRow.querySelectorAll('td, th');
				firstRowCells.forEach((cell) => {
					// Skip the numbered column
					if (!cell.classList.contains('ak-renderer-table-number-column')) {
						columns.push({ index: columnIndex, header: cell.textContent ?? '' });
					}
					columnIndex += 1;
				});
			}
			setOptionsColumns(columns);
		}
	}, [selectedTable, setOptionsColumns]);
};
