import { useEffect } from 'react';

import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSetBulkCreateFlagsContainer } from './providers/AiBulkNavigationContextProvider';

type BulkCreateFlagsContainerProps = {
	flags: FlagsStateContainer;
};

const BulkCreateFlagsContainerComponent = ({ flags }: BulkCreateFlagsContainerProps) => {
	const setFlags = useSetBulkCreateFlagsContainer();

	useEffect(() => {
		setFlags(flags);
	}, [flags, setFlags]);

	return null;
};

export const BulkCreateFlagsContainer = withFlags(BulkCreateFlagsContainerComponent);
