import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ContentOwnershipContentInfoQuery } from './queries/ContentOwnershipContentInfoQuery.graphql';
import type { ContentOwnershipContentInfoQuery as ContentOwnershipContentInfoQueryType } from './queries/__types__/ContentOwnershipContentInfoQuery';

export const useContentInfo = ({ contentId }) => {
	const [contentTitle, setContentTitle] = useState<string>('');
	const [isArchived, setIsArchived] = useState<boolean>(false);

	const { data } = useQuery<ContentOwnershipContentInfoQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentOwnershipContentInfoQuery,
		{
			variables: { contentId },
		},
	);

	useEffect(() => {
		const title = data?.singleContent?.title ?? '';
		const archived = data?.singleContent?.status === 'archived';
		setContentTitle(title);
		setIsArchived(archived);
	}, [data]);

	return { contentTitle, isArchived };
};
