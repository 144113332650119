import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { ContentOwnershipContributorsQuery } from './queries/ContentOwnershipContributorsQuery.graphql';
import type { ContentOwnershipContributorsQuery as ContentOwnershipContributorsQueryType } from './queries/__types__/ContentOwnershipContributorsQuery';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT } from './contentOwnershipConstants';
import { parseContributors } from './content-ownership-helpers';
import type { UserType } from './types';

export const useContentOwnershipContributors = ({
	contentId,
	spaceKey,
}: {
	contentId: string;
	spaceKey: string;
}) => {
	const [arrangedContributors, setArrangedContributors] = useState<UserType[]>([]);

	const { data, error } = useQuery<ContentOwnershipContributorsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentOwnershipContributorsQuery,
		{
			variables: {
				contentId,
				spaceKey,
				limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
			},
		},
	);

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
		} else {
			// TODO: add analytics or experience tracking for errors
		}
	}

	useEffect(() => {
		if (data) {
			const contributors = parseContributors(data);
			setArrangedContributors(contributors);
		}
	}, [data]);

	return arrangedContributors;
};
