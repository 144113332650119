import React, { forwardRef, type ReactNode, useCallback, useRef, useState } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import type { IconProps } from '@atlaskit/icon';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { MenuItemBase } from '../menu-item';
import { useScrollMenuItemIntoView } from '../use-scroll-menu-item-into-view';

import {
	useIsChildSelected,
	useIsExpanded,
	useOnExpansionToggle,
	useSetIsExpanded,
} from './expandable-menu-item-context';

type ExpandableMenuItemIconProps = {
	isExpanded: boolean;
	isHovering: boolean;
	isSelected?: boolean;
	/**
	 * The element to display when the user is not hovering over the icon. If not provided, the chevron icon will be used
	 */
	defaultStateElem?: JSX.Element;
	iconProps?: IconProps;
};

const ExpandableMenuItemIcon = ({
	iconProps,
	isExpanded,
	isHovering,
	isSelected,
	defaultStateElem,
}: ExpandableMenuItemIconProps): JSX.Element => {
	const isChildSelected = useIsChildSelected();
	const ChevronIcon = isExpanded ? ChevronDownIcon : ChevronRightIcon;

	const chevronElem = (
		<ChevronIcon
			{...iconProps}
			label=""
			color={isSelected || isChildSelected ? token('color.icon.selected') : undefined}
		/>
	);

	return isHovering ? chevronElem : defaultStateElem ?? chevronElem;
};

export type ExpandableMenuItemTriggerProps = {
	isSelected?: boolean;
	/**
	 * If provided, the chevron icon (expand/collapse symbol) will be rendered within a separate icon button element.
	 * Clicking on this icon button will not trigger the `onClick` event. It will only expand or collapse the expandable.
	 *
	 * If a `href` is not provided, the chevron icon is rendered as part of the element.
	 */
	href?: string;
	/**
	 * A slot for additional actions (e.g. IconButtons) to be displayed after the content of the menu item.
	 * It will always be displayed.
	 */
	actions?: ReactNode;
	/**
	 * A slot for additional actions (e.g. IconButtons) to be displayed after the content of the menu item.
	 *
	 * They will only be displayed when the user is hovering over the menu item, or when the expandable menu item is expanded.
	 */
	actionsOnHover?: ReactNode;
	/**
	 * The element to display before the content of the menu item.
	 *
	 * By default, a chevron icon will be displayed in this slot. If a custom `elemBefore` is provided, the custom element will
	 * replaced by the chevron icon while the user is hovering over the menu item.
	 */
	elemBefore?: JSX.Element;
	/**
	 * A slot for elements (e.g. a Lozenge) to be displayed after the content of the menu item.
	 *
	 * If both `elemAfter` and `actionsOnHover` are provided, `elemAfter` will not be displayed when the item is expanded.
	 * This is because the `actionsOnHover` will be displayed instead.
	 */
	elemAfter?: ReactNode;
	/**
	 * Called when the user has clicked on the trigger content.
	 *
	 * __It is not called when the user clicks on the expand/collapse chevron icon button.__
	 * This is to differentiate a click that will only "expand" the menu item _without selecting it_, from a click to
	 * expand _and_ "select" or navigate to the menu item.
	 *
	 * If you need a callback for when the user expands or collapses the expandable, use `onExpansionToggle` on the
	 * `ExpandableMenuItem` component instead.
	 */
	onClick?: (
		event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
		analyticsEvent: UIAnalyticsEvent,
		analyticsAttributes: { isExpanded: boolean },
	) => void;
	children: ReactNode;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;

	/**
	 * Disable tooltip for menu item content. This should only be done when there is some other way
	 * to display the full menu content and description of a menu item close by (eg with another popup)
	 */
	isContentTooltipDisabled?: boolean;

	/**
	 * Exposes the visually complete menu item, including:
	 *
	 * - the main interactive element (exposed through `ref`)
	 * - any `elemBefore`, `elemAfter`, `actions`, or `actionsOnHover`
	 *
	 * This specifically excludes the wrapping list item,
	 * which is also exposed through the `listItemRef` prop.
	 */
	visualContentRef?: React.Ref<HTMLDivElement>;
};

/**
 * __ExpandableMenuItemTrigger__
 *
 * The trigger component for an `ExpandableMenuItem`. Interacting with it will expand or collapse the expandable.
 */
export const ExpandableMenuItemTrigger = forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	ExpandableMenuItemTriggerProps
>(
	(
		{
			actions,
			isSelected,
			href,
			elemBefore,
			elemAfter,
			actionsOnHover,
			onClick,
			children,
			testId,
			interactionName,
			isContentTooltipDisabled,
			visualContentRef,
		},
		forwardedRef,
	) => {
		const onExpansionToggle = useOnExpansionToggle();
		const isExpanded = useIsExpanded();
		const setIsExpanded = useSetIsExpanded();
		const itemRef = useRef<HTMLDivElement>(null);

		const [isHovering, setIsHovering] = useState(false);
		const handleMouseEnter = useCallback(() => setIsHovering(true), []);
		const handleMouseLeave = useCallback(() => setIsHovering(false), []);

		const handleIconClick = useCallback(() => {
			onExpansionToggle?.(!isExpanded);
			setIsExpanded(!isExpanded);
		}, [isExpanded, onExpansionToggle, setIsExpanded]);

		const handleMenuContentClick = useCallback(
			(
				event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				const newValue = !isExpanded;
				onClick?.(event, analyticsEvent, { isExpanded: newValue });
				onExpansionToggle?.(newValue);
				setIsExpanded(newValue);
			},
			[onClick, onExpansionToggle, isExpanded, setIsExpanded],
		);

		const isSelectable = typeof href !== 'undefined';

		useScrollMenuItemIntoView({
			elementRef: itemRef,
			isSelected: Boolean(isSelectable && isSelected),
		});

		const expandableElemBefore = isSelectable ? (
			<IconButton
				icon={(iconProps) => (
					<ExpandableMenuItemIcon
						iconProps={iconProps}
						isHovering={isHovering}
						isExpanded={isExpanded}
						isSelected={isSelected}
						defaultStateElem={elemBefore}
					/>
				)}
				aria-expanded={isExpanded}
				label={isExpanded ? 'Collapse' : 'Expand'}
				appearance="subtle"
				spacing="compact"
				onClick={handleIconClick}
				interactionName={interactionName}
			/>
		) : (
			<ExpandableMenuItemIcon
				isHovering={isHovering}
				isExpanded={isExpanded}
				isSelected={isSelected}
				defaultStateElem={elemBefore}
			/>
		);

		return (
			// For expandable menu items, we shouldn't wrap in a `li` here. The `li` is instead at a higher level (`ExpandableMenuItem`), grouping the expandable menu item trigger and its content
			// Wrapping in a `Box` so we can add the mouse event handlers, as `Flex` does not support those props
			<Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={itemRef}>
				<MenuItemBase
					actions={actions}
					actionsOnHover={actionsOnHover}
					elemBefore={expandableElemBefore}
					ariaExpanded={isExpanded}
					elemAfter={elemAfter}
					href={href}
					isSelected={isSelected}
					onClick={handleMenuContentClick}
					ref={forwardedRef}
					visualContentRef={visualContentRef}
					testId={testId}
					interactionName={interactionName}
					isContentTooltipDisabled={isContentTooltipDisabled}
				>
					{children}
				</MenuItemBase>
			</Box>
		);
	},
);
