import React from 'react';

import { StarSelectedIcon as LegacyStarredIcon } from '@confluence/icons/entry-points/StarSelectedIcon';

import StarredIcon from '@atlaskit/icon/core/star-starred';
import UnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import LegacyUnstarredIcon from '@atlaskit/icon/core/migration/star-unstarred--star';
import { token, useThemeObserver } from '@atlaskit/tokens';
import { Y300 } from '@atlaskit/theme/colors';

type IconSpacing = 'none' | 'spacious';

type StarIconProps = {
	isStarred: boolean;
	size?: 'small' | 'medium';
	legacy?: boolean;
	spacing?: IconSpacing;
};

export const StarIcon = ({
	isStarred,
	size = 'medium',
	legacy = false,
	spacing = 'spacious',
}: StarIconProps) => {
	const theme = useThemeObserver();
	const starredColor =
		theme.colorMode === 'dark'
			? token('color.icon.accent.yellow', Y300)
			: token('color.icon.accent.orange', '#E56910');

	if (legacy) {
		return isStarred ? (
			<LegacyStarredIcon label="" size={size} spacing={spacing} />
		) : (
			<LegacyUnstarredIcon label="" LEGACY_size={size} spacing={spacing} />
		);
	}

	return isStarred ? (
		<StarredIcon label="" color={starredColor} LEGACY_size={size} spacing={spacing} />
	) : (
		<UnstarredIcon label="" color="currentColor" LEGACY_size={size} spacing={spacing} />
	);
};
