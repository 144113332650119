import React, { useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Flex, xcss, Text, Box } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

import { Link } from '@confluence/route-manager';
import { EDIT_PAGE, EDIT_BLOG } from '@confluence/named-routes';
import type { Route } from '@confluence/route';
import { usePageState } from '@confluence/page-context';

const timeoutErrorStyles = xcss({
	margin: 'auto',
	width: '50%',
	height: '90vh',
});

const timeoutErrorBorderContainerStyles = xcss({
	border: '1px solid',
	borderColor: 'color.border',
	padding: 'space.500',
	borderRadius: 'border.radius.200',
});

const i18n = defineMessages({
	heading: {
		id: 'content-prerequisites.timeout-error-screen.heading',
		defaultMessage: 'Unable to load page',
		description: 'Heading for unable to load page on timeout error screen',
	},
	resolutionOptions: {
		id: 'content-prerequisites.timeout-error-screen.resolution-options',
		defaultMessage: 'To resolve this issue, you may want to:',
		description: 'Text for resolution options on timeout error screen',
	},
	reloadPage: {
		id: 'content-prerequisites.timeout-error-screen.reload-page',
		defaultMessage: 'Reload this page',
		description: 'List item text for error message to reload the page',
	},
	convertToCloudEditor: {
		id: 'content-prerequisites.timeout-error-screen.convert-to-cloud-editor',
		defaultMessage:
			'<EditLink>Return to edit mode</EditLink> and convert to the <SACLink>cloud editor</SACLink> now',
		description:
			'List item text for error message and link to edit page and link to convert to the cloud editor',
	},
	removeMacroOrBreakPageContent: {
		id: 'content-prerequisites.timeout-error-screen.remove-excerpt-macro-or-break-page-content',
		defaultMessage:
			'Remove one or more excerpt macros, or break this page content into several smaller pages',
		description:
			'List item text for error message to remove the Inline Excerpt macro or break up page content',
	},
	contactAdmin: {
		id: 'content-prerequisites.timeout-error-screen.contact-admin',
		defaultMessage:
			'If this keeps happening, ask your admin to contact <SupportLink>our Support team</SupportLink> and give them the URL of this page.',
		description: 'Text for error message to contact admin to contact support team',
	},
});

const TimeoutErrorScreenQuery = gql`
	query TimeoutErrorScreenQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			properties(key: "editor") {
				nodes {
					id
					key
					value
				}
			}
		}
	}
`;

export const TimeoutErrorScreen = ({ contentId, spaceKey }) => {
	const [{ contentType }] = usePageState();
	const isBlogpost = contentType === 'blogpost';
	const routeType: Route = isBlogpost ? EDIT_BLOG : EDIT_PAGE;
	const { data } = useQuery(TimeoutErrorScreenQuery, {
		variables: {
			contentId: contentId!,
		},
	});
	const linkParams = useMemo<{
		contentId: string;
		spaceKey: string | undefined;
		contentType: string;
	}>(
		() => ({
			contentId,
			spaceKey,
			contentType: isBlogpost ? 'blog' : 'pages',
		}),
		[contentId, spaceKey, isBlogpost],
	);
	const isTinyMCE = data?.singleContent?.properties?.nodes?.[0]?.value === '"v1"';
	if (!isTinyMCE) return null;
	const editHref = routeType.toUrl(linkParams);

	return (
		<Flex xcss={timeoutErrorStyles} direction="column" justifyContent="center">
			<Box xcss={timeoutErrorBorderContainerStyles}>
				<Heading size="large" as="h2">
					<FormattedMessage {...i18n.heading} />
				</Heading>
				<Text as="p">
					<FormattedMessage {...i18n.resolutionOptions} />
				</Text>
				<ul>
					<li>
						<FormattedMessage {...i18n.reloadPage} />
					</li>
					<li>
						<FormattedMessage
							{...i18n.convertToCloudEditor}
							values={{
								SACLink: (chunks: React.ReactNode[]) => (
									<Link
										target="_blank"
										href="https://support.atlassian.com/confluence-cloud/docs/convert-pages-to-the-new-editor"
									>
										{chunks}
									</Link>
								),
								EditLink: (chunks: React.ReactNode[]) => (
									<Link target="_blank" href={editHref}>
										{chunks}
									</Link>
								),
							}}
						/>
					</li>
					<li>
						<FormattedMessage {...i18n.removeMacroOrBreakPageContent} />
					</li>
				</ul>
				<Text as="p">
					<FormattedMessage
						{...i18n.contactAdmin}
						values={{
							SupportLink: (chunks: React.ReactNode[]) => (
								<Link target="_blank" href="https://support.atlassian.com/contact/#/">
									{chunks}
								</Link>
							),
						}}
					/>
				</Text>
			</Box>
		</Flex>
	);
};
