import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, Text, Inline, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import ArrowUpIcon from '@atlaskit/icon/utility/arrow-up';
import { token } from '@atlaskit/tokens';
import { N50 } from '@atlaskit/theme/colors';

import { useSideNavigation } from '@confluence/page-layout/entry-points/SideNavigationContext';

export const ScrollToTopButton = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { handleScrollToTop } = useSideNavigation();

	const onClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'QuickAccess',
				source: 'spaceNavigation',
				data: {
					navigationLayer: 'global',
					navigationContainer: 'sidebar',
					navVersion: '4',
				},
			},
		}).fire();
		handleScrollToTop();
	};

	return (
		<Box xcss={stickyWrapper} id="scroll-to-top-button">
			<Pressable xcss={pressableStyles} onClick={onClick}>
				<Inline space="space.050" alignInline="center" alignBlock="center">
					<ArrowUpIcon label="" color={token('color.icon.subtle', N50)} />
					<Text align="center" size="small" color="color.text.subtle">
						<FormattedMessage {...i18n.scrollToTopButtonLabel} />
					</Text>
				</Inline>
			</Pressable>
		</Box>
	);
};

const stickyWrapper = xcss({
	zIndex: 'navigation', // Needs to be at least z-index 2 to appear on top of Content and Shortcuts
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
});

const pressableStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: '32px',
	paddingInline: 'space.100',
	alignItems: 'center',
	width: '140px',
	height: '32px',
	flexShrink: '0',
	position: 'absolute',
	top: '60px',
	boxShadow: 'elevation.shadow.overlay',

	':hover': {
		backgroundColor: 'elevation.surface.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.pressed',
	},
	':focus': {
		outline: 'color.border.focused',
	},
});

const i18n = defineMessages({
	scrollToTopButtonLabel: {
		id: 'side-navigation.global.scroll-to-top.button.label',
		defaultMessage: 'Back to top',
		description:
			'Label text for a button indicating it scrolls to the top of the navigation to view menu items.',
	},
});
