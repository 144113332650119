import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import { Anchor } from '@atlaskit/primitives';

import { useCopyToClipboard } from '@confluence/copy-content-link';

import { useBulkCreateFlagsContainer } from '../providers/AiBulkNavigationContextProvider';
import { messages } from '../messages';
import type { CreateIssueResponse } from '../__types__/apiUtils';

import { useGetJiraIssuesLink } from './useGetJiraIssuesLink';

type SingleIssueCreatedFlagPayload = {
	issueKey: string;
	summary: string;
};

export const SINGLE_ISSUE_CREATED_FLAG_ID = 'bulk-issue-create--single-issue-created-flag';
export const MULTIPLE_ISSUES_CREATED_FLAG_ID = 'bulk-issue-create--multiple-issues-created-flag';
export const SINGLE_ISSUE_INSERT_LINK_FAILURE_FLAG_ID =
	'bulk-issue-create--single-issue-insert-link-failure-flag';
export const MULTIPLE_ISSUES_INSERT_LINK_FAILURE_FLAG_ID =
	'bulk-issue-create--multiple-issues-insert-link-failure-flag';

export const useBulkCreateFlags = () => {
	const { formatMessage } = useIntl();
	const flags = useBulkCreateFlagsContainer();
	const getJiraIssuesLink = useGetJiraIssuesLink();
	const { copyToClipboard } = useCopyToClipboard();

	const triggerSingleIssueCreatedFlag = useCallback(
		(payload: SingleIssueCreatedFlagPayload) => {
			const link = getJiraIssuesLink([{ key: payload.issueKey }]);
			void flags?.showFlag({
				id: SINGLE_ISSUE_CREATED_FLAG_ID,
				type: 'success-circle',
				title: formatMessage(messages.sidePanelSingleIssueCreateSuccessFlagTitle, {
					issueKey: payload.issueKey,
					issueSummary: payload.summary,
					link: (chunks: ReactNode[]) => (
						<Anchor href={link} target="_blank">
							{chunks}
						</Anchor>
					),
				}),
				close: 'auto',
			});
		},
		[flags, formatMessage, getJiraIssuesLink],
	);

	const triggerMultipleIssuesCreatedFlag = useCallback(
		(payload: CreateIssueResponse[], reloadOnClick: () => void, showReloadAction: boolean) => {
			const href = getJiraIssuesLink(payload);
			void flags?.showFlag({
				id: MULTIPLE_ISSUES_CREATED_FLAG_ID,
				type: 'success-circle',
				title: formatMessage(messages.sidePanelMultipleIssueCreateSuccessFlagTitle, {
					issueCount: payload.length,
				}),
				actions: [
					...(showReloadAction
						? [
								{
									content: formatMessage(messages.sidePanelMultipleIssueCreateFlagReloadToSeeLinks),
									onClick: reloadOnClick,
								},
							]
						: []),
					{
						content: formatMessage(messages.sidePanelMultipleIssueCreateFlagViewIssue, {
							issueCount: payload.length,
						}),
						href,
						target: '_blank',
					},
				],
			});
		},
		[flags, formatMessage, getJiraIssuesLink],
	);

	const triggerSingleIssueInsertLinkFailureFlag = useCallback(
		(payload: SingleIssueCreatedFlagPayload) => {
			const link = getJiraIssuesLink([{ key: payload.issueKey }]);
			void flags?.showFlag({
				id: SINGLE_ISSUE_INSERT_LINK_FAILURE_FLAG_ID,
				type: 'error',
				title: formatMessage(messages.sidePanelSingleIssueInsertLinkFailureFlagTitle, {
					issueKey: payload.issueKey,
					issueSummary: payload.summary,
					link: (chunks: ReactNode[]) => (
						<Anchor href={link} target="_blank">
							{chunks}
						</Anchor>
					),
				}),
				close: 'auto',
			});
		},
		[flags, formatMessage, getJiraIssuesLink],
	);

	const triggerMultipleIssuesInsertLinkFailureFlag = useCallback(
		(payload: CreateIssueResponse[]) => {
			const href = getJiraIssuesLink(payload);
			void flags?.showFlag({
				id: MULTIPLE_ISSUES_INSERT_LINK_FAILURE_FLAG_ID,
				type: 'error',
				title: formatMessage(messages.sidePanelMultipleIssuesInsertLinkFailureFlagTitle, {
					issueCount: payload.length,
				}),
				close: 'auto',
				actions: [
					{
						content: formatMessage(messages.sidePanelMultipleIssueCreateFlagViewIssue, {
							issueCount: payload.length,
						}),
						href,
						target: '_blank',
					},
					{
						content: formatMessage(messages.sidePanelMultipleIssueCreateFlagCopyLink),
						onClick: () => void copyToClipboard(href),
					},
				],
			});
		},
		[flags, formatMessage, getJiraIssuesLink, copyToClipboard],
	);

	return {
		triggerSingleIssueCreatedFlag,
		triggerMultipleIssuesCreatedFlag,
		triggerSingleIssueInsertLinkFailureFlag,
		triggerMultipleIssuesInsertLinkFailureFlag,
	};
};
