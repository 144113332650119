import { useQuery } from '@apollo/react-hooks';
import { useContext, useEffect, useMemo } from 'react';
import { addMonths, isFuture } from 'date-fns';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';
import { useSitePersonalizationQuery } from '@confluence/onboarding-helpers/entry-points/hooks/useSitePersonalizationQuery';
import { markErrorAsHandled } from '@confluence/graphql';

import { AABMigratorPromptQuery } from './queries/AABMigratorPromptQuery.graphql';
import type { AABMigratorPromptQuery as AABMigratorPromptQueryData } from './queries/__types__/AABMigratorPromptQuery';

export const useMigratorPromptEligible = ({ skip = false } = {}): {
	isMigratorPromptEligible: boolean;
	loading: boolean;
} => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// Feature and role checks
	const { isSiteAdmin } = useContext(SPAViewContext);
	const shouldSkip = skip || !isSiteAdmin;

	// Session data
	const { cloudId } = useSessionData();

	// Query for AABTableData
	const {
		data: AABTableData,
		loading: AABTableLoading,
		error: AABTableError,
	} = useQuery<AABMigratorPromptQueryData>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AABMigratorPromptQuery,
		{
			skip: shouldSkip,
		},
	);

	// Query for site personalization data
	const {
		attributes,
		loading: sitePersonalizationLoading,
		error: sitePersonalizationError,
	} = useSitePersonalizationQuery({
		cloudId,
		skip: shouldSkip,
	});

	// Processing site personalization data
	const sitePersonalizationDataAttributes = attributes?.reduce(
		(obj, item) => ({ ...obj, [item.name]: item.value }),
		{},
	);

	// Migration date calculations
	const migrationCompletionDate: string | undefined =
		sitePersonalizationDataAttributes?.['confluence_site_migration_completion_date'];

	const dateSixMonthsAfterMigration = migrationCompletionDate
		? addMonths(new Date(migrationCompletionDate), 6)
		: undefined;

	const isWithinSixMonthsAfterMigration = dateSixMonthsAfterMigration
		? isFuture(dateSixMonthsAfterMigration)
		: false;

	// Final loading and error states
	const loading = AABTableLoading || sitePersonalizationLoading;

	const error = useMemo(
		() => AABTableError || sitePersonalizationError,
		[AABTableError, sitePersonalizationError],
	);

	useEffect(() => {
		// Error handling
		if (error) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.ADMIN_EXPERIENCE,
			});
			markErrorAsHandled(error);
		}
	}, [createAnalyticsEvent, error]);

	// Check for existing admin announcement banners
	const hasAdminAnnouncementBanners =
		!!AABTableData?.adminAnnouncementBannerSettingsByCriteria?.nodes?.length;

	// Eligibility check
	const isMigratorPromptEligible =
		!shouldSkip &&
		!loading &&
		!error &&
		isWithinSixMonthsAfterMigration &&
		!hasAdminAnnouncementBanners;

	return {
		isMigratorPromptEligible,
		loading,
	};
};
