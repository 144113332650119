import React from 'react';

import { BulkCreateContainer } from './BulkCreateContextProvider';
import { AiBulkNavigationContextContainer } from './AiBulkNavigationContextProvider';
import { BulkConfigureContextContainer } from './BulkConfigureContextProvider';

export const BulkIssueCreateProvidersWrapper: React.FC = ({ children }) => {
	return (
		<BulkCreateContainer isGlobal>
			<AiBulkNavigationContextContainer>
				<BulkConfigureContextContainer>{children}</BulkConfigureContextContainer>
			</AiBulkNavigationContextContainer>
		</BulkCreateContainer>
	);
};
