import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';

import BookIcon from '@atlaskit/icon/core/migration/book-with-bookmark--book';
import { Anchor, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { CONTENT_HISTORY } from '@confluence/named-routes';
import { FloatingBannerStateContainer } from '@confluence/banners';

import { useViewPageDistinctionBannerVisibilityState } from './useViewPageDistinctionBannerVisibilityState';

const i18n = defineMessages({
	bannerMessage: {
		id: 'live-pages-features.view-page-distinction-banner.message',
		defaultMessage: 'Published — <pageHistoryLink>v. {versionNumber}</pageHistoryLink>',
		description:
			'Banner message that tells a user they are viewing a specific version of a published page. <pageHistoryLink> will be an anchor element that the user can click to navigate to the page history page. The {versionNumber} will be substituted with the current version number of the published page, e.g., "v. 10".',
	},
	tooltipMessage: {
		id: 'live-pages-features.view-page-distinction-banner.version.tooltip.message',
		defaultMessage: 'Go to version history',
		description:
			'Message that appears in the tooltip when hovering over the version number in the banner.',
	},
});

export const BANNER_HEIGHT = 33;

const bannerStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	borderBottom: `1px solid ${token('color.background.neutral')}`,
	backgroundColor: 'color.background.input.hovered',
	color: 'color.text.subtlest',
	height: `${BANNER_HEIGHT}px`,
});

const anchorStyles = xcss({
	fontWeight: 'font.weight.bold',
	color: 'color.text.subtle',
	textDecoration: 'none',
	':active': {
		textDecoration: 'none',
		color: 'color.text.subtle',
	},
	':focus': {
		textDecoration: 'none',
		color: 'color.text.subtle',
	},
	':hover': {
		textDecoration: 'none',
		color: 'color.text.subtle',
	},
	':visited': {
		textDecoration: 'none',
		color: 'color.text.subtle',
	},
});

type ViewPageDistinctionBannerComponentProps = {
	contentId: string;
	spaceKey: string;
	versionNumber: number | null | undefined;
	bannerState: FloatingBannerStateContainer;
	waitForFolderBanner: boolean;
};
const ViewPageDistinctionBannerComponent = ({
	contentId,
	spaceKey,
	versionNumber,
	bannerState,
	waitForFolderBanner,
}: ViewPageDistinctionBannerComponentProps) => {
	const intl = useIntl();
	const { isBannerVisible } = useViewPageDistinctionBannerVisibilityState({
		contentId,
		spaceKey,
		versionNumber,
		bannerState,
		waitForFolderBanner,
	});

	if (!isBannerVisible) {
		return null;
	}

	return (
		<Flex xcss={bannerStyles} alignItems="center" justifyContent="center" gap="space.050">
			<BookIcon spacing="spacious" label="" color={token('color.icon.subtle')} />
			<FormattedMessage
				{...i18n.bannerMessage}
				values={{
					pageHistoryLink: (chunks: any) => (
						<Tooltip content={intl.formatMessage(i18n.tooltipMessage)}>
							{(tooltipProps) => (
								<Anchor
									{...tooltipProps}
									xcss={anchorStyles}
									href={CONTENT_HISTORY.toUrl({ spaceKey, contentId })}
								>
									{chunks}
								</Anchor>
							)}
						</Tooltip>
					),
					versionNumber,
				}}
			/>
		</Flex>
	);
};

export const ViewPageDistinctionBanner = ({
	contentId,
	spaceKey,
	versionNumber,
	waitForFolderBanner,
}: Omit<ViewPageDistinctionBannerComponentProps, 'bannerState'>) => {
	return (
		<Subscribe to={[FloatingBannerStateContainer]}>
			{(bannerState: FloatingBannerStateContainer) => (
				<ViewPageDistinctionBannerComponent
					contentId={contentId}
					spaceKey={spaceKey}
					versionNumber={versionNumber}
					waitForFolderBanner={waitForFolderBanner}
					bannerState={bannerState}
				/>
			)}
		</Subscribe>
	);
};
