import type { FC, MouseEvent, KeyboardEvent, ReactElement } from 'react';
import React, { useCallback, Fragment, useEffect } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ShortcutEvent } from '@confluence/shortcuts';
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';

import { PageStarMutation, PageUnstarMutation } from '../ActionButtonMutations.graphql';
import type {
	ActionButtonPageStar as PageStarData,
	ActionButtonPageStarVariables as PageStarVars,
} from '../__types__/PageStarMutation';
import type {
	ActionButtonPageUnstar as PageUnstarData,
	ActionButtonPageUnstarVariables as PageUnstarVars,
} from '../__types__/PageUnstarMutation';
import { ActionErrorFlag } from '../SharedComponents';
import type { AnalyticsOptions } from '../SpaceStar/SpaceStar';

type IconSpacing = 'none' | 'spacious';

export type PageStarProps = {
	contentId: string;
	isStarred: boolean;
	onStar?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	onUnstar?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	analytics?: AnalyticsOptions;
	legacy?: boolean;
	spacing?: IconSpacing;
};

type ButtonChildrenProps = {
	toggle(e: MouseEvent | KeyboardEvent | ShortcutEvent): void;
};

export type ButtonChildren = {
	children(props: ButtonChildrenProps): ReactElement;
};

const i18n = defineMessages({
	starErrorTitle: {
		id: 'action-buttons.content.star.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble starring this",
		description:
			"Title of error flag shown when a user attempts to click the 'Star' button but the starring action fails",
	},
	unstarErrorTitle: {
		id: 'action-buttons.content.unstar.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble unstarring this",
		description:
			"Title of error flag shown when a user attempts to click the 'Unstar' button but the unstarring action fails",
	},
	errorDescription: {
		id: 'action-buttons.error.description',
		defaultMessage: 'Refresh the page and try again.',
		description:
			'Body of error flag shown when user attempts an action (e.g. star) but it fails. Shown below the error title.',
	},
});

const useStarAnalytics = (contentId, analytics?: AnalyticsOptions) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const stringAttrs = JSON.stringify(analytics?.attributes);
	return useCallback(
		(action: 'created' | 'deleted') => {
			if (!analytics) {
				return;
			}
			const { source, containerId, attributes } = analytics;
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action,
					actionSubject: 'favouritePage',
					source,
					attributes,
					objectId: contentId,
					...(containerId && { containerId }),
				},
			}).fire();
		},
		// Using stringAttrs as dependency instead of analytics.attributes
		// https://github.com/facebook/react/issues/14476
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[createAnalyticsEvent, analytics?.source, stringAttrs],
	);
};

const PageStarComponent: FC<PageStarProps & ButtonChildren & WrappedComponentProps> = ({
	contentId,
	isStarred,
	onStar,
	onUnstar,
	analytics,
	intl,
	children,
}) => {
	const sendAnalyticsEvent = useStarAnalytics(contentId, analytics);

	useSubmitSSRScriptErrors('page-star-button');
	useSubmitSSRScriptErrors('page-unstar-button');

	const [star, { error: starError }] = useMutation<PageStarData, PageStarVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageStarMutation,
		{
			variables: { id: contentId },
			onCompleted: () => {
				sendAnalyticsEvent('created');
			},
			optimisticResponse: {
				favouritePage: {
					__typename: 'FavouritePagePayload',
					content: {
						__typename: 'Content',
						id: contentId,
						metadata: {
							__typename: 'ContentMetadata',
							currentuser: {
								__typename: 'ContentMetadata_CurrentUserMetadataProvider_currentuser',
								favourited: {
									// @ts-ignore TS doesn't recognize __typename which is needed for mutation
									__typename: 'FavouritedSummary',
									isFavourite: true,
									favouritedDate: new Date().toISOString(),
								},
							},
						},
					},
				},
			},
		},
	);

	const [unstar, { error: unstarError }] = useMutation<PageUnstarData, PageUnstarVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageUnstarMutation,
		{
			variables: { id: contentId },
			onCompleted: () => {
				sendAnalyticsEvent('deleted');
			},
			optimisticResponse: {
				unfavouritePage: {
					__typename: 'UnfavouritePagePayload',
					content: {
						__typename: 'Content',
						id: contentId,
						metadata: {
							__typename: 'ContentMetadata',
							currentuser: {
								// @ts-ignore TS doesn't recognize __typename which is needed for mutation
								__typename: 'ContentMetadata_CurrentUserMetadataProvider_currentuser',
								favourited: null,
							},
						},
					},
				},
			},
		},
	);

	const toggleStar = useCallback(
		(e: MouseEvent | KeyboardEvent | ShortcutEvent) => {
			e.preventDefault();
			if (isStarred) {
				onUnstar && onUnstar(e);
				unstar().catch((_) => {});
			} else {
				onStar && onStar(e);
				star().catch((_) => {});
			}
		},
		[isStarred, onStar, star, onUnstar, unstar],
	);

	useEffect(() => {
		if (window?.__SSR_EVENTS_CAPTURE__ && window.__SSR_EVENTS_CAPTURE__['favoriteButton']) {
			toggleStar(window.__SSR_EVENTS_CAPTURE__['favoriteButton']);
			delete window.__SSR_EVENTS_CAPTURE__['favoriteButton'];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			{children({
				toggle: toggleStar,
			})}
			{starError && (
				<ActionErrorFlag
					title={intl.formatMessage(i18n.starErrorTitle)}
					description={intl.formatMessage(i18n.errorDescription)}
					error={starError}
				/>
			)}
			{unstarError && (
				<ActionErrorFlag
					title={intl.formatMessage(i18n.unstarErrorTitle)}
					description={intl.formatMessage(i18n.errorDescription)}
					error={unstarError}
				/>
			)}
		</Fragment>
	);
};

export const PageStar = injectIntl(PageStarComponent);
