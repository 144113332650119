import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Box, Inline, xcss } from '@atlaskit/primitives';
import InformationIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import { LinkButton } from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';

import { messages } from './messages';

const aiSubFooterWrapperStyle = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: 'border.width',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const textColorStyle = xcss({
	paddingTop: 'space.025',
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});

export const AiSubFooter = () => (
	<Box xcss={aiSubFooterWrapperStyle}>
		<Inline spread="space-between" alignBlock="center">
			<LinkButton
				appearance="subtle"
				spacing="compact"
				iconBefore={(iconProps) => (
					<InformationIcon {...iconProps} color={token('color.text.subtlest')} spacing="none" />
				)}
				href="https://www.atlassian.com/trust/atlassian-intelligence"
				rel="noopener"
				target="_blank"
				testId="issue-create-side-panel.ai-footer.disclaimer-text"
			>
				<Box xcss={textColorStyle}>
					<FormattedMessage {...messages.sidePanelFooterDisclaimerText} />
				</Box>
			</LinkButton>
			<LinkButton
				appearance="subtle"
				spacing="compact"
				iconAfter={(iconProps) => <AtlassianIntelligenceIcon {...iconProps} LEGACY_size="small" />}
				href="https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products/"
				rel="noopener"
				target="_blank"
				testId="issue-create-side-panel.ai-footer.powered-by-ai-text"
			>
				<Box xcss={textColorStyle}>
					<FormattedMessage {...messages.sidePanelFooterAIText} />
				</Box>
			</LinkButton>
		</Inline>
	</Box>
);
