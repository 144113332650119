/** @jsx jsx */
/** @jsxFrag */
import React, { type FC } from 'react';
import { css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { ContainerSkeleton } from './ContainerSkeleton';
import { SPACE_NAVIGATION_METRIC } from './perf.config';
import type { SpaceNavigationProps } from './SpaceNavigation';

const separatorStyles = css({
	margin: token('space.100', '8px'),
});

export const SpaceNavigationLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceNavigation" */ './SpaceNavigation'))
			.SpaceNavigation,
	loading: () => <ContainerSkeleton loader />,
});

export const SpaceNavigation: FC<SpaceNavigationProps> = (props) => {
	const isNav4Enabled = useIsNav4Enabled();

	return (
		<>
			{isNav4Enabled && <hr css={separatorStyles} id="space-header-top-divider" />}
			<PageSegmentLoadStart metric={SPACE_NAVIGATION_METRIC} />
			<SpaceNavigationLoader {...props} />
		</>
	);
};

export const SpaceNavigationLoadingSkeleton: FC = () => <ContainerSkeleton loader />;
