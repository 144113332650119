import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';

import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ChangeOwnerSpotlightWrapper } from './ChangeOwnerSpotlightWrapper';
import { i18n } from './i18n';
import type { UserShape } from './types';
import { PageOwnershipBylinePopupContent } from './PageOwnershipBylinePopupContent';
import { useIsAllowedToChangeOwner } from './useIsAllowedToChangeOwner';

type PageOwnershipBylinePopupProps = {
	ownedByAccountId: string | null;
	creator: UserShape;
	createdDate: Date | null;
	contentId: string | null;
	lastOwner: UserShape | null;
};
export const PageOwnershipBylinePopupHandler = ({
	ownedByAccountId,
	creator,
	createdDate,
	contentId,
	lastOwner,
}: PageOwnershipBylinePopupProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const canChangeOwner = useIsAllowedToChangeOwner(ownedByAccountId);
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const openPopup = useCallback(() => {
		setIsOpen(true);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'byline.menu.icon',
				source: 'viewPageScreen',
				attributes: {
					contentId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentId]);

	const closePopup = useCallback(() => setIsOpen(false), []);

	const handleClickPopupTrigger = useCallback(
		() => (!isOpen ? openPopup() : closePopup()),
		[isOpen, openPopup, closePopup],
	);

	useEffect(() => {
		// If the content ID changes, it means the user switched pages.
		// For some reason, the popup doesn't close automatically, so
		// we need to manually close it.
		closePopup();
	}, [closePopup, contentId]);

	const tooltipContent = canChangeOwner
		? intl.formatMessage(i18n.bylineButtonTooltip)
		: intl.formatMessage(i18n.bylineButtonTooltipReadOnly);

	return (
		<Popup
			placement="bottom"
			isOpen={isOpen}
			onClose={closePopup}
			content={() => (
				<PageOwnershipBylinePopupContent
					ownedByAccountId={ownedByAccountId}
					onClosePopup={closePopup}
					creator={creator}
					createdDate={createdDate}
					contentId={contentId}
					lastOwner={lastOwner}
				/>
			)}
			trigger={(triggerProps) => (
				<ChangeOwnerSpotlightWrapper ownedByAccountId={ownedByAccountId}>
					<Tooltip hideTooltipOnClick content={tooltipContent}>
						<Button
							testId="page-owner-byline-popup-trigger"
							spacing="compact"
							appearance="subtle"
							isSelected={isOpen}
							iconBefore={
								<MoreIcon
									LEGACY_size="small"
									label={intl.formatMessage(i18n.popupTriggerLabel)}
									color={token('color.text.subtle', N500)}
								/>
							}
							{...triggerProps}
							onClick={handleClickPopupTrigger}
						/>
					</Tooltip>
				</ChangeOwnerSpotlightWrapper>
			)}
			shouldRenderToParent
		/>
	);
};
