// Note: When modifying shortcut settings, make sure to test across all input fields on the page.
// Some input fields may not have shortcuts fully disabled, which could cause conflicts with shortcuts.
// Ensure that the new shortcuts do not interfere with normal input field operations.

// Shortcuts for editor2
export const EDITOR2_SHORTCUTS = {
	PUBLISH: 'mod+enter',
	PUBLISH_WITH_COMMENT: 'mod+shift+enter',
	EDIT: 'e',
	PREVIEW: 'mod+shift+e',
};
export const BROWSER_SAVE_SHORTCUT = 'mod+s';
export const COMMENT_EDITOR_SHORTCUT = 'command+enter';
export const INLINE_COMMENTS_SHORTCUT = 'mod+enter';
export const BLOG_SHORTCUT = 'b';
export const GLOBAL_CREATE_SHORTCUT = 'c';
export const STAR_BUTTON_SHORTCUT = 'f';
export const CREATE_COMMENT_SHORTCUT = 'm';
export const LINK_TO_THIS_PAGE_SHORTCUT = 'k';
export const EDIT_SHORTCUT = 'e';
export const PRESENTATION_MODE_SHORTCUT = 'r';
export const WATCH_CONTENT_SHORTCUT = 'w';
export const SHARE_PAGE_SHORTCUT = 's';
export const LABELS_SHORTCUT = 'l';
export const VIEW_ATTACHMENTS_SHORTCUT = 't';
export const NEXT_COMMENT_SHORTCUT = 'n';
export const PREV_COMMENT_SHORTCUT = 'p';
export const SUBMIT_FEEDBACK_SHORTCUT = 'g f';
export const NAVIGATION_SHORTCUT = 'g g';
export const GO_HOME_SHORTCUT = 'g d';
export const NOTIFICATIONS_SHORTCUT = 'g n';
export const GO_TO_RECENT_SHORTCUT = 'g r';
export const GO_TO_STARRED_SHORTCUT = 'g s';
export const SPACE_OVERVIEW_SHORTCUT_NAV_3 = 'g s';
export const SPACE_OVERVIEW_SHORTCUT = 'g o';
export const AI_MATE_SHORTCUT = 'g c';
export const NAVIGATION_SLASH_SHORTCUT = '/';
export const SIDE_NAVIGATION_SHORTCUT = '[';
export const UNDO_SHORTCUT = 'mod+z';
export const PREV_TEMPLATE_SHORTCUT = 'left';
export const NEXT_TEMPLATE_SHORTCUT = 'right';
export const HELP_SHORTCUT = '?';
export const AUTOMATION_SHORTCUT = 'a';
export const TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER = ']';
export const CREATE_INLINE_COMMENT_SHORTCUT_WIN = 'mod+alt+c';
export const CREATE_INLINE_COMMENT_SHORTCUT_MAC = 'mod+option+c';
// All the shortcuts below are editing specific
export const TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR = 'ctrl+]';
export const GLOBAL_CREATE_SHORTCUT_IN_EDIT = 'ctrl+option+c';
export const HELP_SHORTCUT_IN_EDIT = 'ctrl+shift+/';
export const SIDE_NAVIGATION_SHORTCUT_IN_EDIT = 'ctrl+[';
export const NEXT_COMMENT_SHORTCUT_IN_EDIT = 'ctrl+.';
export const PREV_COMMENT_SHORTCUT_IN_EDIT = 'ctrl+,';
export const STAR_BUTTON_SHORTCUT_IN_EDIT = 'ctrl+option+f';
export const LIVE_PAGE_MODE_SWITCH_TO_EDIT = 'e';
export const LIVE_PAGE_MODE_SWITCH_TO_VIEW = 'mod+shift+e';
