import { createStore, createHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

export type UpdateDocumentState = {
	updateDocumentFn: (doc: JSONDocNode) => void;
};

export const initialState: UpdateDocumentState = {
	updateDocumentFn: () => {},
};

export const actions = {
	setUpdateDocumentFn:
		(newUpdateDocumentFn: (doc: JSONDocNode) => void) =>
		({ setState }: StoreActionApi<UpdateDocumentState>) => {
			setState({
				updateDocumentFn: newUpdateDocumentFn,
			});
		},
};

export const UpdateDocumentStore = createStore({
	initialState,
	actions,
	name: 'UpdateDocumentStore',
});

export const useUpdateDocument = createHook(UpdateDocumentStore);
