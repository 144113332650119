import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N40 } from '@atlaskit/theme/colors';

import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import type { ReactionLocation } from '@confluence/reactions';
import {
	DelayedReactions,
	Reactions,
	useReactionsQueryParams,
	ReactionContainerType,
} from '@confluence/reactions';
import { ReactionsPlaceholder } from '@confluence/reactions/entry-points/ReactionsPlaceholder';
import { ReactionsContext } from '@confluence/comment-context';
import { useSessionData } from '@confluence/session-data';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewPageReactionsContainer = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	padding: `${token('space.250', '20px')} 0`,
	clear: 'both',
	borderBottom: `1px solid ${token('color.border', N40)}`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.labelsSection': {
		maxWidth: '50%',
		minWidth: '20%',
		float: 'right',
		display: 'inline-block',
	},
	overflow: 'visible',
	maxWidth: 'unset',
	margin: 'unset',
});

export const ViewPageReactions = ({
	hasReactions,
	contentType,
	contentId,
	spaceId,
	contentSubType,
}) => {
	const [state] = useContentState();

	const { focusedPageReactionsId } = useReactionsQueryParams();
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const { edition } = useSessionData();
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const displayReactions = () => {
		if (contentType && spaceId) {
			// We want to load in the background ONLY if we have a query param, otherwise we can preload the query
			const ReactionsLoader = Boolean(focusedPageReactionsId) ? DelayedReactions : Reactions;

			return (
				<ReactionsLoader
					contentId={contentId}
					location={contentType as ReactionLocation}
					containerId={spaceId}
					containerType={ReactionContainerType.SPACE}
					contentSubType={contentSubType}
					customAnalyticsAttributes={{
						edition,
						reactionsPlacement: 'bottom',
					}}
				/>
			);
		} else {
			return <ReactionsPlaceholder />;
		}
	};
	if (renderServerPlaceholder) {
		return (
			<ViewPageReactionsContainer
				id="view-page-reactions-container"
				data-test-id="view-page-reactions-container"
			>
				{isReactionsEnabled && hasReactions && <ReactionsPlaceholder useAccuratePills />}
			</ViewPageReactionsContainer>
		);
	} else if (state?.isContentReady) {
		return (
			<ViewPageReactionsContainer
				id="view-page-reactions-container"
				data-test-id="view-page-reactions-container"
			>
				{hasReactions && isReactionsEnabled && displayReactions()}
			</ViewPageReactionsContainer>
		);
	}

	return null;
};
