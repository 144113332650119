import React, { useEffect, useMemo } from 'react';

import { inlineCommentGifContent } from '@confluence/inline-comments-common';
import { useCommentsData, useActiveInlineCommentsQuery } from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';

import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';
import { EmptyComments } from '../components/EmptyComments';
import { CommentsPanelList } from '../components/CommentsPanelList';
import { getOpenCommentThreads, getAnnotationsToLoad } from '../helper/commentsPanelHelper';
import { useCommentsPanel, CurrentView } from '../hooks/useCommentsPanel';

type OpenViewProps = {
	showDeleteOption: boolean;
};

export const OpenView = ({ showDeleteOption }: OpenViewProps) => {
	const [
		{ orderedActiveAnnotationIdList, inlineCommentsDataMap, removedCommentIdsMap },
		{ clearRemovedCommentsIdMap },
	] = useCommentsData();
	const [{ initialDataLoadedForViewMap }, { setInitialDataLoadedForView }] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[CurrentView.OPEN];

	const [contentId] = usePageContentId();

	const annotationsToLoad = getAnnotationsToLoad(orderedActiveAnnotationIdList);
	const { loading, error, refetch } = useActiveInlineCommentsQuery({
		pageId: contentId || '',
		markerRefList: annotationsToLoad,
		// skip entirely if no unresolved comments on the page or if there are no comments to load anymore
		skip: annotationsToLoad.length === 0,
		orderedActiveAnnotationIdList,
	});

	useEffect(() => {
		// When this view unmounts, we want to clear out the "resolved"/"deleted" comments
		return () => {
			clearRemovedCommentsIdMap();
		};
	}, [clearRemovedCommentsIdMap]);

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: CurrentView.OPEN });
		}
	}, [loading, setInitialDataLoadedForView]);

	const commentThreads = useMemo(() => {
		if ((!isInitialCommentDataLoaded && loading) || orderedActiveAnnotationIdList.length === 0) {
			return [];
		}

		return getOpenCommentThreads({
			inlineCommentsDataMap,
			orderedActiveAnnotationIdList,
			removedCommentIdsMap,
		});
	}, [
		loading,
		isInitialCommentDataLoaded,
		inlineCommentsDataMap,
		orderedActiveAnnotationIdList,
		removedCommentIdsMap,
	]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <Loading />;
		}

		if (error) {
			return (
				<ErrorPanel
					error={error}
					onRetryClick={async () => {
						await refetch();
					}}
				/>
			);
		}
	}

	if (commentThreads.length === 0 && !loading) {
		return <EmptyComments>{inlineCommentGifContent()}</EmptyComments>;
	}

	return (
		<CommentsPanelList
			commentThreads={commentThreads}
			supportedTopLevelActions={
				showDeleteOption ? ['edit', 'resolve', 'delete'] : ['edit', 'resolve']
			}
		/>
	);
};
