import React, { Fragment, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useMutation } from 'react-apollo';
import { defineMessages, injectIntl } from 'react-intl-next';
import PropTypes from 'prop-types';

import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { fg } from '@confluence/feature-gating';
import { FlagsStateContainer } from '@confluence/flags';
import { CenteredSpinner } from '@confluence/confluence-shared-components';
import { LoadableLazy } from '@confluence/loadable';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import {
	CONTENT_TYPES_HEADER_WATCH_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
	WATCH_DIALOG_WATCH_EXPERIENCE,
} from '@confluence/experience-tracker';
import { RoutesContext } from '@confluence/route-manager';
import { ThirdPartyNudge } from '@confluence/third-party-nudge';

import { WatchDialogQuery } from './WatchDialogQuery.graphql';
import { WatchButton } from './WatchButton';
import { WATCH_BUTTON_METRIC } from './perf.config';
import { WATCH_DIALOG_LOCATION } from './WatchDialogLocation';
import { WatchDialogWatchContentMutation } from './WatchDialogWatchContentMutation.graphql';

export const LazyWatchDialogContent = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-WatchDialogContent" */ './WatchDialogContent'))
			.WatchDialogContent,
	// eslint-disable-next-line react/prop-types
	loading: ({ shouldSSRWatchButton }) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ width: '325px', height: '180px' }}>
			{!shouldSSRWatchButton && <CenteredSpinner size="medium" />}
		</div>
	),
});

const LazyWatchDialogShortcut = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-WatchDialogShortcut" */ './WatchDialogShortcut'))
			.WatchDialogShortcut,
});

const i18n = defineMessages({
	watchContentDialogLabel: {
		id: 'watch-dialog.popup.label',
		defaultMessage: 'Watch content dialog',
		description:
			'Label of the popup that contains content watching options used by assistive technologies',
	},
});

export const WatchDialogComponent = injectIntl(
	({
		contentId,
		spaceId,
		params,
		defaultTab,
		customPlacement,
		flagsContainer,
		/**
		 * This disables the usage of the watch button and dialog.
		 *
		 * Note: If a `customTrigger` is provided, this will be passed through
		 * as `isDisabled`. However, as a fallback, even if the button is not
		 * disabled properly, the logic here will continue to be disabled.
		 */
		isDisabled,
		customTrigger,
		useShortcut,
		shouldSSRWatchButton,
		skipTrialDetailsQuery,
		contentType,
		componentLocation,
		intl,
	}) => {
		const [hasRecentlyUnwatchedContent, setHasRecentlyUnwatchedContent] = useState(false);
		const [dialogOpen, setDialogOpen] = useState(false);
		const [shouldOpenWatchDialogFromQueryParams, setShouldOpenWatchDialogFromQueryParams] =
			useState(false);
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const experienceTracker = useContext(ExperienceTrackerContext);
		const { getQueryParams, setQueryParams } = useContext(RoutesContext);
		const nudgeRef = useRef(null);

		useEffect(() => {
			if (fg('cc_notifications_batched_stop_watching_link')) {
				if (isDisabled) {
					return;
				}

				const { openWatchModal } = getQueryParams();

				setShouldOpenWatchDialogFromQueryParams(
					openWatchModal === 'true' &&
						(componentLocation === WATCH_DIALOG_LOCATION.SPACE_OVERVIEW ||
							componentLocation === WATCH_DIALOG_LOCATION.PAGE),
				);

				if (shouldOpenWatchDialogFromQueryParams) {
					setDialogOpen(true);
				}
			}
		}, [isDisabled, getQueryParams, componentLocation, shouldOpenWatchDialogFromQueryParams]);

		const removeOpenModalQueryParams = () => {
			const params = getQueryParams();
			if (params.openWatchModal) {
				setQueryParams(
					{
						...params,
						openWatchModal: undefined,
					},
					true,
				);
				setShouldOpenWatchDialogFromQueryParams(false);
			}
		};

		const toggleDialog = useCallback(() => {
			// Do not enable open the dialog if watch is disabled
			if (isDisabled) {
				return;
			}

			setDialogOpen((prevState) => !prevState);
		}, [isDisabled]);

		const isWatching = useCallback(() => {
			// If watch is disabled, then the user will default to not watching
			return isDisabled
				? false
				: params.isWatchingPage ||
						params.isWatchingWhiteboard ||
						(params.isDatabase && params.isWatchingDatabase) ||
						(params.isBlogPost && params.isWatchingCurrentBlog) ||
						(params.isBlogPost && params.isWatchingBlogs) ||
						params.isWatchingSpace;
		}, [params, isDisabled]);

		const updateQueryHandler = (cache, contentType, newDataValue) => {
			const contentData = cache.readQuery({
				query: WatchDialogQuery,
				variables: { contentId },
			});

			if (contentType === 'content') {
				contentData.singleContent.currentUserIsWatching = newDataValue;
			} else if (contentType === 'space') {
				contentData.singleContent.space.currentUser.isWatched = newDataValue;
			} else {
				contentData.singleContent.space.currentUser.isWatchingBlogs = newDataValue;
			}

			cache.writeQuery({
				query: WatchDialogQuery,
				data: contentData,
			});
		};

		const fireWatchAnalyticsEvent = useCallback(
			(action, actionSubject) => {
				const analyticsData = {
					action,
					actionSubject,
					actionSubjectId: contentId,
					objectId: contentId,
					objectType: contentType,
					source: 'WatchButton',
				};

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: analyticsData,
				}).fire();
			},
			[createAnalyticsEvent, contentId, contentType],
		);

		const [watchContent] = useMutation(WatchDialogWatchContentMutation, {
			onCompleted: () => {
				fireWatchAnalyticsEvent('watch', contentType);
				toggleDialog();
				experienceTracker.succeed({
					name: WATCH_DIALOG_WATCH_EXPERIENCE,
				});
			},
			onError: (error) => {
				experienceTracker.fail({
					name: WATCH_DIALOG_WATCH_EXPERIENCE,
					error: error.message,
				});
			},
			update: (cache, { data: { watchContent } }) => {
				updateQueryHandler(cache, 'content', watchContent.content.currentUserIsWatching);
			},
		});

		const handleOneClickWatch = useCallback(async () => {
			const experienceState = experienceTracker.getExperienceState(WATCH_DIALOG_WATCH_EXPERIENCE);
			if (experienceState === null || experienceState.hasStopped) {
				experienceTracker.start({
					name: WATCH_DIALOG_WATCH_EXPERIENCE,
				});
			}

			await watchContent({ variables: { id: contentId } });
		}, [watchContent, contentId, experienceTracker]);

		const handleWatchButtonClick = useCallback(async () => {
			if (dialogOpen || isWatching()) {
				toggleDialog();
			} else {
				await handleOneClickWatch();
			}
		}, [toggleDialog, isWatching, dialogOpen, handleOneClickWatch]);

		const openDialog = () => {
			setDialogOpen(true);
		};

		const closeDialog = () => {
			setDialogOpen(false);
		};

		const onContentUnwatch = () => {
			setHasRecentlyUnwatchedContent(true);
		};

		const renderPopupTrigger = (triggerProps) => {
			const TriggerButton = customTrigger || WatchButton;
			return (
				<>
					<TriggerButton
						isDisabled={isDisabled}
						isWatching={isWatching()}
						isSelected={dialogOpen}
						onMouseOver={LazyWatchDialogContent.preload}
						onClick={handleWatchButtonClick}
						triggerProps={triggerProps}
						shouldSSRWatchButton={shouldSSRWatchButton}
						defaultTab={defaultTab}
						contentId={contentId}
						updateQueryHandler={updateQueryHandler}
						params={params}
						hasRecentlyUnwatchedContent={hasRecentlyUnwatchedContent}
					/>
					<ExperienceSuccess
						name={CONTENT_TYPES_HEADER_WATCH_EXPERIENCE}
						attributes={{
							isWatching: isWatching(),
						}}
					/>
					<PageSegmentLoadEnd key={contentId} metric={WATCH_BUTTON_METRIC} />
				</>
			);
		};

		const renderPopupContent = () => {
			return (
				<>
					<div
						style={{
							padding: `${token('space.300', '24px')} ${token('space.200', '16px')}`,
						}}
						ref={nudgeRef}
					>
						<LazyWatchDialogContent
							contentId={contentId}
							spaceId={spaceId}
							defaultTab={defaultTab}
							params={params}
							closeWatchDialog={closeDialog}
							updateQueryHandler={updateQueryHandler}
							skipTrialDetailsQuery={skipTrialDetailsQuery}
							contentType={contentType}
							shouldOpenWatchDialogFromQueryParams={shouldOpenWatchDialogFromQueryParams}
							removeOpenModalQueryParams={removeOpenModalQueryParams}
							onContentUnwatch={onContentUnwatch}
						/>
					</div>
					{dialogOpen && (
						<ThirdPartyNudge
							trigger="watch-page"
							showImage={false}
							width={357}
							reference={nudgeRef}
						/>
					)}
				</>
			);
		};

		return (
			<Fragment>
				{useShortcut && !isDisabled && (
					<LazyWatchDialogShortcut
						contentId={contentId}
						params={params}
						flagsContainer={flagsContainer}
						openDialog={openDialog}
						updateQueryHandler={updateQueryHandler}
					/>
				)}
				<Popup
					isOpen={dialogOpen}
					onClose={closeDialog}
					content={() => renderPopupContent()}
					trigger={renderPopupTrigger}
					placement={customPlacement}
					role="dialog"
					label={intl.formatMessage(i18n.watchContentDialogLabel)}
					testId="watch-content-dialog"
					shouldRenderToParent
				/>
			</Fragment>
		);
	},
);

WatchDialogComponent.propTypes = {
	contentId: PropTypes.string.isRequired,
	spaceId: PropTypes.string,
	params: PropTypes.object.isRequired,
	defaultTab: PropTypes.number.isRequired,
	customPlacement: PropTypes.string,
	flagsContainer: PropTypes.instanceOf(FlagsStateContainer),
	isDisabled: PropTypes.bool,
	customTrigger: PropTypes.elementType,
	useShortcut: PropTypes.bool,
	shouldSSRWatchButton: PropTypes.bool,
	skipTrialDetailsQuery: PropTypes.bool,
	contentType: PropTypes.string,
	componentLocation: PropTypes.number,
};
