/* eslint-disable @atlassian/tangerine/import/no-parent-imports */
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, type IntlShape, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { Anchor, Box, Inline, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';

import { CONSENT_DISPLAYED_TEXT_ITEMS } from '../../../services/consent-hub-service/constants';
import type { ConsentDisplayedTextMap } from '../../../services/consent-hub-service/types';
import type { PreferenceCategory } from '../../../types';
import { i18n } from '../messages';

const boxStylesBlue = xcss({
	padding: 'space.200',
	backgroundColor: 'color.background.information',
	borderStyle: 'solid',
	borderWidth: 'border.width',
	borderColor: 'color.border',
	overflowY: 'hidden',
});

const inlineWrapperStyles = xcss({
	// Small viewport defaults
	gap: 'space.200',
	alignItems: 'flex-start',
	flexDirection: 'column',

	// Larger viewports
	[media.above.sm]: {
		gap: 'space.300',
		flexDirection: 'row',
		alignItems: 'center',
	},
});

const noticeContainerStyles = xcss({
	flexDirection: 'column',
});

const buttonContainerStyles = xcss({
	// Prevents the button text from truncating when the screen width decreases
	minWidth: 'fit-content',

	// Applies to below sm size
	flexDirection: 'row-reverse',
	flexWrap: 'wrap-reverse',
	justifyContent: 'start',

	[media.above.sm]: {
		marginLeft: 'auto',
		flexWrap: 'nowrap',
		flexDirection: 'row',
	},
});

const noop = () => {};

interface PresentationalConsentBannerProps {
	onClickOnlyNecessary?: (displayedTextMap: ConsentDisplayedTextMap) => void;
	onClickAcceptAll?: (displayedTextMap: ConsentDisplayedTextMap) => void;
	onClickPreferences?: () => void;
	onClickTrackingNoticeLink?: () => void;
	minWidth?: number;
}

const getDisplayedTextMap = (intl: IntlShape): ConsentDisplayedTextMap => {
	// We'll use this mapping to send the `displayedText` for each checkbox when saving to ConsentHub
	const displayedTextMap = CONSENT_DISPLAYED_TEXT_ITEMS.reduce((translatedTextMap, category) => {
		const linkToCookiesTrackingNotice = intl.formatMessage(i18n.cookiesTrackingNotice);
		const bannerDescription = intl.formatMessage(i18n.useCookiesDescription, {
			linkToCookiesTrackingNotice,
		});
		// Set this for all items (including items with checkboxes in the UI), we'll override as needed below
		// for things like strictlyNecessary/acceptAll
		translatedTextMap[category] = bannerDescription;
		return translatedTextMap;
	}, {} as ConsentDisplayedTextMap);

	return displayedTextMap;
};

const updateDisplayedTextMap = (
	intl: IntlShape,
	displayTextMap: ConsentDisplayedTextMap,
	isOnlyNecessary?: boolean,
) => {
	const updatedDisplayedTextMap: ConsentDisplayedTextMap = {
		...displayTextMap,
	};

	// Use description as message to translate
	const onlyNecessaryContent = intl.formatMessage(i18n.onlyNecessaryBtn);
	const acceptAllContent = intl.formatMessage(i18n.acceptAllBtn);

	// For additional auditing content, append the description related to the button when pressed
	Object.keys(updatedDisplayedTextMap).forEach((key) => {
		const preferenceCategory = key as PreferenceCategory;
		const additionalContent = isOnlyNecessary ? onlyNecessaryContent : acceptAllContent;
		const textContent = updatedDisplayedTextMap[preferenceCategory];
		updatedDisplayedTextMap[preferenceCategory] = `${additionalContent} — ${textContent}`;
	});

	return updatedDisplayedTextMap;
};
/**
 * Component containing the UI aspects of the consent banner.
 *
 * Conditional rendering, analytics, feature flag evaluation, and most other
 * logic are located in the parent component ConsentBanner
 */
export const PresentationalConsentBanner = ({
	onClickOnlyNecessary = noop,
	onClickAcceptAll = noop,
	onClickPreferences = noop,
	onClickTrackingNoticeLink = noop,
	minWidth = 400,
}: PresentationalConsentBannerProps) => {
	const intl = useIntl();

	const displayedTextMap = useMemo(() => {
		return getDisplayedTextMap(intl);
	}, [intl]);

	const handleOnlyNecessaryClick = useCallback(() => {
		const displayedTextOnlyNecessaryMap = updateDisplayedTextMap(intl, displayedTextMap, true);
		onClickOnlyNecessary(displayedTextOnlyNecessaryMap);
	}, [onClickOnlyNecessary, intl, displayedTextMap]);

	const handleAcceptAllClick = useCallback(() => {
		const displayedTextAcceptAllMap = updateDisplayedTextMap(intl, displayedTextMap);
		onClickAcceptAll(displayedTextAcceptAllMap);
	}, [onClickAcceptAll, intl, displayedTextMap]);

	return (
		<Box
			role="dialog"
			aria-labelledby="cookiesTrackingNoticeLink"
			xcss={boxStylesBlue}
			style={{ minWidth: `${minWidth}px` }}
		>
			<Inline xcss={inlineWrapperStyles}>
				<Inline xcss={noticeContainerStyles}>
					<FormattedMessage
						{...i18n.useCookiesDescription}
						tagName="span"
						values={{
							linkToCookiesTrackingNotice: (
								<Anchor
									id="cookiesTrackingNoticeLink"
									href="https://www.atlassian.com/legal/cookies"
									target="_blank"
									onClick={onClickTrackingNoticeLink}
								>
									<FormattedMessage {...i18n.cookiesTrackingNotice} />
								</Anchor>
							),
						}}
					/>
				</Inline>
				<Inline space="space.100" xcss={buttonContainerStyles}>
					<Button appearance="subtle" onClick={onClickPreferences}>
						<FormattedMessage {...i18n.preferencesBtn} />
					</Button>
					<Button onClick={handleOnlyNecessaryClick}>
						<FormattedMessage {...i18n.onlyNecessaryBtn} />
					</Button>
					<Button
						iconBefore={
							<CheckIcon
								color="currentColor"
								spacing="none"
								testId="experiment-one-button-icon"
								label=""
							/>
						}
						onClick={handleAcceptAllClick}
					>
						<FormattedMessage {...i18n.acceptAllBtn} />
					</Button>
				</Inline>
			</Inline>
		</Box>
	);
};
