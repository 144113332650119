import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Box, Inline, xcss } from '@atlaskit/primitives';

import type { BulkConfigureFooterProps } from './__types__/BulkConfigureFooter';
import { messages } from './messages';

const footerBaseStyles = xcss({
	backgroundColor: 'elevation.surface',
	position: 'relative',
	flexShrink: 0,
	bottom: 'space.0',
	width: '100%',
	'::before': {
		content: '""',
		position: 'absolute',
		top: 'space.0',
		left: 'space.150',
		right: 'space.150',
		height: '1px',
		backgroundColor: 'elevation.surface',
		borderTopColor: 'color.border',
		borderTopStyle: 'solid',
		borderTopWidth: 'border.width',
	},
});

export const BulkConfigureFooter = ({ onClose, isSaveDisabled }: BulkConfigureFooterProps) => {
	return (
		<Box paddingBlock="space.200" paddingInline="space.300" xcss={footerBaseStyles}>
			<Inline space="space.100" alignInline="end">
				<Button appearance="subtle" testId="configure-cancel-button" onClick={() => onClose()}>
					<FormattedMessage {...messages.bulkConfigureFooterCancel} />
				</Button>
				<Button
					type="submit"
					form="configureForm"
					appearance="primary"
					testId="configure-save-button"
					isDisabled={isSaveDisabled}
				>
					<FormattedMessage {...messages.bulkConfigureFooterSave} />
				</Button>
			</Inline>
		</Box>
	);
};
