import { LoadableLazy } from '@confluence/loadable';

// DO NOT change the loadable as it will break SSR on view page
export const AIFloatingContextMenuLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AIFloatingContextMenu" */ './AIFloatingContextMenu'
			)
		).AIFloatingContextMenu,
});

export { AIFloatingContextMenu } from './AIFloatingContextMenu';
