import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { Stack, Text, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';

import { ErrorDisplay } from '@confluence/error-boundary';

import { ErrorSvg } from '../assets/ErrorSvg';

export type ErrorPanelProps = {
	error: ApolloError;
	onRetryClick?: () => void;
};

const errorPanelStyles = xcss({
	alignItems: 'center',
	gap: 'space.200',
	paddingTop: 'space.500',
});

const retryButtonStyles = xcss({
	marginTop: 'space.negative.050',
});

const i18n = defineMessages({
	commentsPanelErrorMessage: {
		id: 'comments-panel.error.panel.message',
		defaultMessage: 'Something went wrong.',
		description: 'Error Message that shows when comments loading in the panel fails',
	},
	commentsPanelErrorRetryButtonLabel: {
		id: 'comments-panel.error.panel.retry.button.label',
		defaultMessage: 'Retry now',
		description: 'Retry button label for error panel',
	},
});

export const ErrorPanel = (props: ErrorPanelProps) => {
	const { onRetryClick, error } = props;
	const { formatMessage } = useIntl();

	return (
		<ErrorDisplay error={error}>
			<Stack xcss={errorPanelStyles} testId="comments-panel-error">
				<ErrorSvg />
				<Text align="center" size="medium" color="color.text.subtle">
					{formatMessage(i18n.commentsPanelErrorMessage)}
				</Text>
				{onRetryClick && (
					<Stack xcss={retryButtonStyles}>
						<Button appearance="subtle" onClick={onRetryClick} testId="comments-panel-error-retry">
							{formatMessage(i18n.commentsPanelErrorRetryButtonLabel)}
						</Button>
					</Stack>
				)}
			</Stack>
		</ErrorDisplay>
	);
};
