import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { B50, B75, B100 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PillOverlayWrapper = styled.div({
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWrapper = styled.div({
	display: 'block',
	flex: 1,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PillContainer = styled.div<{
	isTop?: boolean;
	offset?: number;
}>({
	flex: 1,
	position: 'fixed',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ isTop, offset }) => (isTop ? `${(offset || 0) + 16}px` : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	bottom: ({ isTop }) => (!isTop ? `${token('space.400', '32px')}` : undefined),
	zIndex: 100,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ArrowContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: 3,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NewCommentsPill = styled.button({
	outline: 'none',
	boxSizing: 'border-box',
	padding: `${token('space.075', '6px')} ${token('space.150', '12px')}`,
	backgroundColor: token('color.background.selected', B50),
	display: 'inline-flex',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderRadius: '16px',
	font: token('font.body'),
	height: '32px',
	color: token('color.text.selected'),
	cursor: 'pointer',
	marginTop: 0,
	marginBottom: token('space.100', '8px'),
	marginRight: token('space.050', '4px'),
	userSelect: 'none',
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	verticalAlign: 'top',
	boxShadow: `${token('elevation.shadow.overlay')}`,

	'&:active': {
		backgroundColor: token('color.background.selected.pressed', B100),
	},
	'&:hover': {
		backgroundColor: token('color.background.selected.hovered', B75),
	},
});
