import { useContext } from 'react';

import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useIsXflowUser } from '@confluence/onboarding-helpers/entry-points/hooks/useIsXflowUser';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';
import { SPAViewContext } from '@confluence/spa-view-context';
interface UseSimplifyInitialNavProps {
	isPersonalSpace: boolean;
	spaceCreatedAtDate?: Date;
}

export const useSimplifyInitialNav = ({
	isPersonalSpace,
	spaceCreatedAtDate,
}: UseSimplifyInitialNavProps) => {
	const { cohort, delayInMinutes } = getExperimentData();
	const isInExperimentControl = cohort !== 'treatment';

	const { isSiteAdmin } = useContext(SPAViewContext);

	const skipQueries =
		!isPersonalSpace ||
		!isSiteAdmin ||
		isInExperimentControl ||
		isSpaceOlderThanDelay(delayInMinutes, spaceCreatedAtDate);

	const { isXflowUser, isLoading: isXflowUserLoading } = useIsXflowUser({ skip: skipQueries });

	const { data: onboardingStateData, loading: isFoundingAdminLoading } = useGetOnboardingState(
		[ONBOARDING_EXPERIENCES.IS_FOUNDING_ADMIN],
		skipQueries,
	);

	if (skipQueries) {
		return { showSpaceItems: true };
	}

	const { isFoundingAdmin } = deserializeState(onboardingStateData);

	return {
		showSpaceItems:
			(!isXflowUserLoading && isXflowUser) || (!isFoundingAdminLoading && !isFoundingAdmin),
	};
};

type Cohort = 'control' | 'treatment';
type ExperimentData = {
	cohort: Cohort;
	delayInMinutes: number;
};

const getExperimentData = (): ExperimentData => {
	// eslint-disable-next-line confluence-feature-gating/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('cfind-2776-simplify-initial-conf-nav'); // Experiment exposure is fired in the backend
	const cohort = expConfig.get('cohort', 'control');
	const minutesPerHour = 60;
	const hoursPerDay = 24;
	const defaultDelayInMinutes = minutesPerHour * hoursPerDay;
	const delayInMinutes = expConfig.get('delayInMinutes', defaultDelayInMinutes.toString());
	return { cohort: cohort as Cohort, delayInMinutes: parseInt(delayInMinutes) };
};

const isSpaceOlderThanDelay = (delayInMinutes: number, createdAtDate?: Date): boolean => {
	if (!createdAtDate) {
		return false;
	}
	const createdAtTimestamp = createdAtDate.getTime();
	const millisecondsSinceCreation = Date.now() - createdAtTimestamp;
	const secondsPerMinute = 60;
	const millisecondsPerSecond = 1000;
	const millisecondsInDelayTime = delayInMinutes * secondsPerMinute * millisecondsPerSecond;
	return millisecondsSinceCreation > millisecondsInDelayTime;
};
