import { useUnreadInlineComments } from '@confluence/unread-comments';

import { scrollCommentsPanel } from '../helper/commentsPanelHelper';

import { CurrentView, useCommentsPanel } from './useCommentsPanel';

const commentsPanelListContainerId = 'comments-panel-list-container';
const commentThreadContainerId = (annotationId: string) =>
	`comment-thread-${annotationId}-container`;

export const useCommentsPanelScroll = () => {
	const [{ currentView }, { setCurrentView }] = useCommentsPanel();
	const [{ unreadCommentsListState }] = useUnreadInlineComments();

	const scrollToAnnotationId = (annotationId: string) => {
		const unreadCommentAnnotationIds =
			unreadCommentsListState.map((unreadComment) => unreadComment.inlineMarkerRef) || [];
		let viewUpdated = false;

		// We need to move the user to Open view only if
		// they are on Unread view or Resolved view and clicked annotation does not belongs to UNREAD
		// otherwise moved to Unread view only if annotation belongs to UNREAD and current view is not Open i.e Resolved

		if (
			(currentView === CurrentView.UNREAD || currentView === CurrentView.RESOLVED) &&
			!unreadCommentAnnotationIds.includes(annotationId)
		) {
			setCurrentView(CurrentView.OPEN);
			viewUpdated = true;
		} else if (
			currentView === CurrentView.RESOLVED &&
			unreadCommentAnnotationIds.includes(annotationId)
		) {
			setCurrentView(CurrentView.UNREAD);
			viewUpdated = true;
		}

		// This is a short term fix to ensure the data is loaded before scrolling.
		// This will be replaced with checking the loading states of each view after the data laoding refactor is complete.
		setTimeout(
			() => {
				scrollCommentsPanel({
					containerId: commentsPanelListContainerId,
					commentMarkerRef: commentThreadContainerId(annotationId),
				});
			},
			viewUpdated ? 1500 : 0,
		);
	};

	return {
		scrollToAnnotationId,
	};
};
