/* eslint-disable @atlaskit/ui-styling-standard/no-styled */
/* eslint-disable @atlaskit/ui-styling-standard/no-exported-styles */
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

export const RESPONSIVE_CONTENT_WIDTH_THRESHOLD = 1129;

export const IconWrapper = styled.div({
	display: 'flex',
	marginLeft: token('space.negative.050'),
});

export const CreateContentButtonWrapper = styled.div<{ isNav4Enabled: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Added dynamic style since there is already some here
	marginLeft: ({ isNav4Enabled }) => (isNav4Enabled ? undefined : token('space.150', '12px')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button, button:hover, button:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		[`@media (max-width: ${RESPONSIVE_CONTENT_WIDTH_THRESHOLD}px)`]: {
			width: '32px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'& > span:first-of-type': {
				width: '24px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Added dynamic style since there is already some here
				marginLeft: ({ isNav4Enabled }) =>
					isNav4Enabled ? token('space.100', '8px') : token('space.150', '12px'),
				marginRight: '0',
			},
		},
	},
});
