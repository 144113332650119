import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import type { WatchQueryFetchPolicy } from 'apollo-client';

import { markErrorAsHandled } from '@confluence/graphql';
import { usePageContentId } from '@confluence/page-context';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { NoNetworkError } from '@confluence/network';

import { PageInfoQuery } from './PageInfoQuery.graphql';
import type {
	PageInfoQuery as PageInfoQueryData,
	PageInfoQueryVariables,
	PageInfoQuery_content_nodes_operations as PageInfoOperations,
} from './__types__/PageInfoQuery';

export const EMPTY_RESULT = {
	id: '',
	type: '',
	space: {
		id: '',
	},
	operations: [] as PageInfoOperations[],
	version: {
		number: -1,
	},
};

export const usePageInfo = ({
	fetchPolicy = 'cache-and-network',
	onCompleted = () => {},
	onError = () => {},
}: {
	fetchPolicy?: WatchQueryFetchPolicy;
	onCompleted?: (data: PageInfoQueryData) => void;
	onError?: (error: Error) => void;
} = {}) => {
	const [contentId] = usePageContentId();

	const { data, loading, error, refetch } = useQuery<PageInfoQueryData, PageInfoQueryVariables>(
		// It's only defined in this module, this is a bad lint rule
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		PageInfoQuery,
		{
			variables: { pageId: contentId },
			fetchPolicy,
			onCompleted,
		},
	);

	return useMemo(() => {
		if (loading || error || !data) {
			if (error) {
				// If we error out, just mark it as handled and swallow it, we will try again on the next render
				if (isUnauthorizedError(error) || error instanceof NoNetworkError) {
					markErrorAsHandled(error);
				}
				onError(error);
			}

			return { pageInfo: null, loading, error, refetch: null };
		}

		const pageInfoNode = data.content?.nodes ? data.content?.nodes[0] : null;

		if (!pageInfoNode) {
			return { pageInfo: { ...EMPTY_RESULT }, loading: false, error: undefined, refetch };
		}

		return { pageInfo: { ...pageInfoNode }, loading: false, error: undefined, refetch };
	}, [data, loading, error, onError, refetch]);
};
