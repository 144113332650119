import { useCallback, useState } from 'react';

import type { CreateBulkIssuesBody, CreateBulkIssuesResponse } from '../__types__/apiUtils';
import {
	ErrorStates,
	useBulkCreateContext,
	useSetError,
} from '../providers/BulkCreateContextProvider';

import { createBulkIssues } from './apiUtils';
import { useBulkCreateLoggingEvents } from './useBulkCreateLoggingEvents';
import { updateIssuesWithRowIndex } from './tableUtils';

export const CREATE_BULK_ISSUES = 'create-bulk-issues';

export const useCreateBulkIssues = () => {
	const [{ currentJiraSite }] = useBulkCreateContext();

	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState<CreateBulkIssuesResponse | null>(null);
	const { logRequestFailed } = useBulkCreateLoggingEvents();
	const setError = useSetError();

	const createBulkIssuesHandler = useCallback(
		async ({
			issueBody,
			onComplete,
			onFailure,
			rowIndices,
		}: {
			issueBody: CreateBulkIssuesBody;
			onComplete: (response: CreateBulkIssuesResponse) => void;
			onFailure: (error: Error | unknown) => void;
			rowIndices?: number[];
		}) => {
			if (!currentJiraSite || !currentJiraSite.cloudId) {
				return;
			}
			setLoading(true);
			setResponse(null);
			try {
				const result = await createBulkIssues({ cloudId: currentJiraSite.cloudId, issueBody });
				if (!result) {
					onFailure('Empty response');
					// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
					logRequestFailed(
						{
							requestName: CREATE_BULK_ISSUES,
							errorMessage: 'Failed to create issue: Empty from response call to bulk create',
						},
						true,
					);
					void setError(ErrorStates.BULK_CREATION);
				} else {
					if (rowIndices && rowIndices.length > 0) {
						result.issues = updateIssuesWithRowIndex(result, rowIndices);
					}
					setResponse(result);
					onComplete(result);
				}
			} catch (err) {
				onFailure(err);
				// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
				logRequestFailed(
					{
						requestName: CREATE_BULK_ISSUES,
						errorMessage: `Failed to create issue ${(err as Error).message}`,
					},
					true,
				);
				void setError(ErrorStates.BULK_CREATION);
			} finally {
				setLoading(false);
			}
		},
		[currentJiraSite, logRequestFailed, setError],
	);

	return { createBulkIssuesHandler, loading, response };
};
