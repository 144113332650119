import React, { type FC } from 'react';
import { defineMessages, FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Inline, Pressable, xcss } from '@atlaskit/primitives';
import type { GlyphProps } from '@atlaskit/icon';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import LockIcon from '@atlaskit/icon/core/lock-locked';
import UnlockIcon from '@atlaskit/icon/core/lock-unlocked';
import type { NewCoreIconProps } from '@atlaskit/icon/base-new';

import { fg } from '@confluence/feature-gating';

import { WorldGreenDotIcon } from '../svg/WorldGreenDotIcon-svg';

export const i18n = defineMessages({
	share: {
		id: 'share-and-restrict-dialog.controlled-share-button.share',
		defaultMessage: 'Share',
		description:
			'Button text for the Share and Restrict button, which opens the Share and Restrict dialog',
	},
});

const baseButtonStyles = xcss({
	borderRadius: token('border.radius'),
	font: 'font.body',
	color: 'color.text.subtle',
	fontWeight: 'font.weight.medium',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.150',
	paddingBlock: 'space.050',
});

const buttonStyles = xcss({
	border: `1px solid ${token('color.border')}`,
	background: 'none',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
	':active': {
		background: token('color.background.neutral.subtle.pressed'),
	},
	':focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		outline: `2px solid ${token('color.border.focused')} !important`,
	},
});

const buttonDisabledStyles = xcss({
	background: token('color.background.disabled'),
	color: 'color.text.disabled',
});

export type ControlledShareButtonProps = {
	isBordered: boolean;
	icon: ControlledShareButtonIcon;
	onClick: () => void;
	tooltipMessage: MessageDescriptor;
	isDisabled: boolean;
};

export type ControlledShareButtonIcon = 'open' | 'restricted' | 'public' | 'error';

const ICON_PROP_TO_ICON_COMPONENT: {
	[key in ControlledShareButtonIcon]:
		| typeof UnlockIcon
		| typeof LockIcon
		| typeof WorldGreenDotIcon
		| typeof ErrorIcon;
} = {
	open: UnlockIcon,
	restricted: LockIcon,
	public: WorldGreenDotIcon,
	error: ErrorIcon,
};

const makeIconProps = (icon: ControlledShareButtonIcon): Partial<GlyphProps | NewCoreIconProps> => {
	switch (icon) {
		case 'error':
			return { color: token('color.icon.danger') };
		case 'restricted':
			return { color: token('color.icon.danger') };
		case 'public':
			return { secondaryColor: token('color.icon.accent.green') };
		default:
			return { color: token('color.icon.subtle') };
	}
};

export const ControlledShareButton: FC<ControlledShareButtonProps> = ({
	isBordered,
	icon,
	onClick,
	tooltipMessage,
	isDisabled,
}) => {
	const { formatMessage } = useIntl();
	const Icon = ICON_PROP_TO_ICON_COMPONENT[icon];
	const iconProps: GlyphProps = {
		label: '',
		...makeIconProps(icon),
	};

	return (
		<Tooltip content={formatMessage(tooltipMessage)} position="bottom">
			{(tooltipProps) => (
				<>
					{isBordered && !fg('confluence_frontend_share_restrict_and_copy_button') ? (
						<Pressable
							{...tooltipProps}
							isDisabled={isDisabled}
							onClick={onClick}
							aria-label={`${formatMessage(i18n.share)}, ${formatMessage(tooltipMessage)}`}
							xcss={[baseButtonStyles, isDisabled ? buttonDisabledStyles : buttonStyles]}
						>
							<Inline alignBlock="center" alignInline="center" space="space.075">
								<Icon {...iconProps} />
								<FormattedMessage {...i18n.share} />
							</Inline>
						</Pressable>
					) : (
						<Button
							{...tooltipProps}
							appearance="subtle"
							iconBefore={(parentProps) => <Icon {...parentProps} {...iconProps} />}
							onClick={onClick}
							aria-label={`${formatMessage(i18n.share)}, ${formatMessage(tooltipMessage)}`}
							isDisabled={isDisabled}
						>
							<FormattedMessage {...i18n.share} />
						</Button>
					)}
				</>
			)}
		</Tooltip>
	);
};
