import React, { useState } from 'react';

import { useIntl } from 'react-intl-next';

// eslint-disable-next-line @atlaskit/design-system/no-banned-imports
import { useId } from '@atlaskit/ds-lib/use-id';
import {
	getInputSkeletonFocus,
	getInputSkeletonQuery,
	getSessionItem,
	resetInputSkeletonState,
	SEARCH_QUERY_KEY,
	setInputSkeletonIsFocus,
	setInputSkeletonQuery,
	setInputSkeletonSelection,
	setSessionItem,
} from '@atlassian/search-dialog';

import { Textfield } from '../../../common/ui/quick-find/textfield';
import { skeletonPlaceholders } from '../../../controllers/quick-find/input-placeholder/messages';

export type QuickFindSkeletonProps = {
	onEnter?: (e: React.KeyboardEvent) => void;
	isNav4Enabled?: boolean;
};

if (!window.inputSkeletonState) {
	resetInputSkeletonState();
}

export const QuickFindSkeleton = ({ onEnter, isNav4Enabled }: QuickFindSkeletonProps) => {
	const dialogId = useId();
	const { formatMessage } = useIntl();

	// Use the stored state as initial values for expansion and query
	const [isExpanded, setIsExpanded] = useState(getInputSkeletonFocus);
	const [value, setValue] = useState(
		() => getSessionItem(SEARCH_QUERY_KEY) ?? getInputSkeletonQuery(),
	);

	const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsExpanded(true);
		setInputSkeletonIsFocus(true);
		const target = e.currentTarget;
		setInputSkeletonSelection(
			target.selectionStart || 0,
			target.selectionEnd || 0,
			target.selectionDirection || 'none',
		);
	};

	const onBlur = () => {
		setIsExpanded(false);
		setInputSkeletonIsFocus(false);
		setInputSkeletonSelection(0, 0, 'none');
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		const target = e.currentTarget;
		if (e.key === 'Escape') {
			target.blur();
			onBlur();
		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.currentTarget.value;
		setValue(newValue);
		setInputSkeletonQuery(newValue);
		const target = e.currentTarget;
		setInputSkeletonSelection(
			target.selectionStart || 0,
			target.selectionEnd || 0,
			target.selectionDirection || 'none',
		);
		setSessionItem(SEARCH_QUERY_KEY, newValue);
	};

	const onSelect = (e: React.UIEvent<HTMLInputElement>) => {
		const target = e.currentTarget;
		setInputSkeletonSelection(
			target.selectionStart || 0,
			target.selectionEnd || 0,
			target.selectionDirection || 'none',
		);
	};

	return (
		<Textfield
			value={value}
			dialogId={dialogId}
			quickFindOpen={isExpanded}
			onEnter={onEnter}
			onFocus={onFocus}
			onBlur={onBlur}
			onSelect={onSelect}
			onKeyDown={onKeyDown}
			onChange={onChange}
			isNav4Enabled={isNav4Enabled}
			placeholder={formatMessage(
				isNav4Enabled
					? skeletonPlaceholders.inputPlaceholderDefault
					: skeletonPlaceholders.inputPlaceholderNav3,
			)}
		/>
	);
};
