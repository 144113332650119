import React from 'react';

import { fg } from '@confluence/feature-gating';

import { ShareAndRestrictButtonPlaceholder as ShareAndRestrictButtonPlaceholderV1 } from './v1/shareAndRestrictButton/ShareAndRestrictButtonPlaceholder';
import { ShareAndRestrictButtonPlaceholder as ShareAndRestrictButtonPlaceholderV2 } from './v2/shareAndRestrictButton/ShareAndRestrictButtonPlaceholder';
import { ShareRestrictAndCopyButtonPlaceholder } from './v2/shareRestrictAndCopyButton/ShareRestrictAndCopyButtonPlaceholder';

export const PlaceholderButtonVersionDecider = () => {
	if (fg('confluence_frontend_unified_restrict_and_share_v2')) {
		if (fg('confluence_frontend_share_restrict_and_copy_button')) {
			return <ShareRestrictAndCopyButtonPlaceholder />;
		}

		return <ShareAndRestrictButtonPlaceholderV2 />;
	}

	return <ShareAndRestrictButtonPlaceholderV1 />;
};
