import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Image from '@atlaskit/image';

import lightModeInlineComment from '../images/lightModeInlineComment.gif';
import darkModeInlineComment from '../images/darkModeInlineComment.gif';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	width: '241px',
	height: '214px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& h1': {
		fontWeight: token('font.weight.medium'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '20px',
		marginBottom: `${token('space.150', '12px')}`,
	},
});

export const inlineCommentGifContent = () => (
	<ContentContainer data-cy="inline-comment-gif">
		<FormattedMessage
			id="inline-comments-common.heading"
			defaultMessage="Be the first to add an inline comment"
			description="Heading Section for Inline Comments discoverability Dialog"
			tagName="h1"
		/>
		<Image
			src={`${lightModeInlineComment}?${new Date().getTime()}`}
			srcDark={`${darkModeInlineComment}?${new Date().getTime()}`}
			alt=""
		/>
		<FormattedMessage
			id="inline-comments-common.message"
			defaultMessage="Share your feedback or ask a question by highlighting text and selecting {text}."
			description="Paragraph Section for Inline Comments discoverability Dialog"
			tagName="p"
			values={{
				text: <b>Comment</b>,
			}}
		/>
	</ContentContainer>
);
