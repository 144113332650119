import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';

type UseIntersectionVisibilityParams = {
	containerId: string;
	targetId: string;
	setIsSpaceHeaderSticky: Dispatch<SetStateAction<boolean>>;
};

export const useIntersectionVisibility = ({
	containerId,
	targetId,
	setIsSpaceHeaderSticky,
}: UseIntersectionVisibilityParams) => {
	const isInitializedRef = useRef(false);
	const observerRef = useRef<IntersectionObserver | null>(null);

	useEffect(() => {
		if (isInitializedRef.current) return;

		const container = document.getElementById(containerId);
		const target = document.getElementById(targetId);

		if (!target || !container) {
			return;
		}

		isInitializedRef.current = true;

		if (!process.env.REACT_SSR) {
			/**
			 * IntersectionObserver doesn't work for SSR pages.
			 * Escaped using process.env.REACT_SSR above, per rule,
			 * and silenced eslint warning safely below.
			 */
			// eslint-disable-next-line
			observerRef.current = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						setIsSpaceHeaderSticky(!entry.isIntersecting);
					});
				},
				{ root: container },
			);

			observerRef.current.observe(target);

			return () => {
				observerRef.current?.disconnect();
			};
		}
	}, [containerId, setIsSpaceHeaderSticky, targetId]);
};
