import { useEffect } from 'react';

import { ErrorStates, useBulkCreateContext } from '../providers/BulkCreateContextProvider';

export const useManualBulkCreateState = () => {
	const [
		{ currentJiraSite, currentJiraProject, currentDefaultIssueType, manualCreateState, error },
		actions,
	] = useBulkCreateContext();

	useEffect(() => {
		const isLoading = !currentJiraSite || !currentJiraProject || !currentDefaultIssueType;
		if (isLoading) {
			void actions.setManualCreateState('loading');
		}

		if (!isLoading && error === ErrorStates.EMPTY) {
			void actions.setManualCreateState('idle');
		}
	}, [actions, currentDefaultIssueType, currentJiraProject, currentJiraSite, error]);

	return {
		isLoading: manualCreateState === 'loading',
		isError: manualCreateState === 'error',
		isIdle: manualCreateState === 'idle',
	};
};
