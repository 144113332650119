import type { Action } from 'react-sweet-state';
import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';

import type { AutomationPremiumTipSource } from './AutomationPremiumTip';

type PremiumTipStateType = {
	source?: AutomationPremiumTipSource;
	showPrompt?: boolean;
};

const initialState: PremiumTipStateType = {
	source: undefined,
	showPrompt: false,
};

export const actions = {
	openPrompt:
		({ source }: PremiumTipStateType): Action<typeof initialState> =>
		({ setState }) => {
			setState({
				source,
				showPrompt: true,
			});
		},
	closePrompt:
		(): Action<typeof initialState> =>
		({ setState }) => {
			setState({
				showPrompt: false,
			});
		},
};

export const premiumTipStore = createStore<PremiumTipStateType, typeof actions>({
	initialState,
	actions,
	name: 'premiumTipStore',
});

export const usePremiumTipActions = createActionsHook(premiumTipStore);
export const usePremiumTipState = createStateHook(premiumTipStore);
