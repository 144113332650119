import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import { useViewUserProfile } from '@confluence/global-operations';
import { PeopleLink } from '@confluence/people-directory';

import { getAtlassianPeopleProfileUrl } from './helpers';
import { ProfileCardWrapper, type TriggerPosition } from './ProfileCardWrapper';

export type ProfileLinkProps = {
	userId?: string | null;
	fullName: string;
	isAnonymous?: boolean;
	onUserLinkClick?: () => void;
	referralSource?: string;
	className?: string;
	style?: object;
	behavior?: 'card' | 'link';
	position?: TriggerPosition;
	offset?: [number, number];
	ariaLabel?: string;
};

const linkStyles = xcss({
	textDecoration: 'underline',
	display: 'inline-block',
	cursor: 'pointer',
});

const i18n = defineMessages({
	profileLinkName: {
		id: 'profile.link.name',
		defaultMessage: '{fullName} profile',
		description: 'Text for non-anonymous user profile link',
	},
});

export const ProfileLink: FC<ProfileLinkProps> = ({
	userId,
	fullName,
	isAnonymous = false,
	onUserLinkClick,
	referralSource,
	className,
	style,
	behavior = 'link',
	position = 'bottom-start',
	offset,
	ariaLabel,
}) => {
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();
	const intl = useIntl();

	const renderNameComponent = () => {
		if (behavior === 'card') {
			return (
				<ProfileCardWrapper
					isAnonymous={isAnonymous}
					userId={userId}
					position={position}
					offset={offset}
					ariaLabel={ariaLabel}
				>
					<Box
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={style}
						xcss={linkStyles}
					>
						{fullName}
					</Box>
				</ProfileCardWrapper>
			);
		} else {
			return (
				<PeopleLink
					target="_blank"
					onClick={onUserLinkClick}
					href={getAtlassianPeopleProfileUrl(userId, referralSource)}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={{ ...style, textDecoration: 'underline' }}
					data-testid="profile-link"
					aria-label={ariaLabel || intl.formatMessage(i18n.profileLinkName, { fullName })}
				>
					{fullName}
				</PeopleLink>
			);
		}
	};

	return (
		<>
			{isAnonymous || !userId || userId === '' ? (
				<FormattedMessage
					id="profile.anonymous.name"
					defaultMessage="Anonymous"
					description="Text for Anonymous user profile link."
				/>
			) : (
				<>{hasViewUserProfilePermission ? renderNameComponent() : <span>{fullName}</span>}</>
			)}
		</>
	);
};
