import { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import { useRouteDataRef } from '@confluence/route-manager';

import type { CreateIssueResponse } from '../__types__/apiUtils';
import { messages } from '../messages';
import {
	useBulkCreateContext,
	useResetSingleIssuesCreated,
} from '../providers/BulkCreateContextProvider';
import type { InsertStorageFragmentRequestPayload } from '../__types__/InsertStorageFragmentRequestPayload';
import type { CellModification } from '../__types__/InsertStorageColumnTableRequestPayload';
import { useBulkCreateLoggingEvents } from '../utils/useBulkCreateLoggingEvents';

import {
	bulkConstructSmartlinkTags,
	constructSmartlinkTag,
	constructHeading,
	constructJiraIssueUrl,
	insertContentAtSelectionEnd,
} from './issueInsertionUtils';
import { useBulkCreateFlags } from './useBulkCreateFlags';

const JIRA_SINGLE_ISSUE_LINK_INSERTION = 'jiraSingleIssueLinkInsertion';
const JIRA_MULTIPLE_ISSUE_LINK_INSERTION = 'jiraMultipleIssueLinkInsertion';

export const useGetIssueInsertionActions = () => {
	const { formatMessage } = useIntl();
	const [{ currentJiraSite, insertStorageFragmentRequestPayload, singleIssuesCreated }] =
		useBulkCreateContext();
	const { triggerSingleIssueInsertLinkFailureFlag, triggerMultipleIssuesInsertLinkFailureFlag } =
		useBulkCreateFlags();
	const resetSingleIssuesCreated = useResetSingleIssuesCreated();
	const { logRequestFailed } = useBulkCreateLoggingEvents();
	const headingString = formatMessage(messages.bulkCreateIssueInsertionHeading);
	const currentUrl = useRouteDataRef().current?.href;

	const constructXmlModification = useCallback(
		(
			createdIssues: CreateIssueResponse[],
			insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload | undefined,
		) => {
			const smartlinkTags = bulkConstructSmartlinkTags(
				createdIssues.map((issue) => constructJiraIssueUrl(currentJiraSite?.url, issue.key)),
			);
			return insertStorageFragmentRequestPayload?.selectedText !== headingString
				? `${constructHeading(headingString)}<br/>${smartlinkTags}`
				: `<p>${smartlinkTags}</p>`;
		},
		[currentJiraSite?.url, headingString],
	);

	const constructCellModifications = useCallback(
		(createdIssues: CreateIssueResponse[]): CellModification[] | undefined => {
			return createdIssues
				.filter((issue) => issue.rowIndex !== undefined)
				.map((issue) => {
					const xmlInsertion = constructSmartlinkTag(
						constructJiraIssueUrl(currentJiraSite?.url, issue.key),
					);
					return { rowIndex: Number(issue.rowIndex), xmlInsertion };
				});
		},
		[currentJiraSite?.url],
	);

	const constructAnchorLinkToHeading = useCallback(() => {
		const formattedHeading = headingString.split(' ').join('-');
		const [baseUrl, existingHash] = (currentUrl ?? '').split('#');
		return existingHash ? `${baseUrl}#${formattedHeading}` : `${currentUrl}#${formattedHeading}`;
	}, [currentUrl, headingString]);

	const addSingleIssuesToPage = useCallback(() => {
		if (insertStorageFragmentRequestPayload && singleIssuesCreated.length > 0) {
			insertStorageFragmentRequestPayload.xmlModification = constructXmlModification(
				singleIssuesCreated,
				insertStorageFragmentRequestPayload,
			);
			insertContentAtSelectionEnd(insertStorageFragmentRequestPayload)
				.then((data) => {
					// There is a case where the API response is OK, but the response data is false.
					// When this happens, the backend has trouble inserting the ticket to the page and we should show an error toast.
					if (data === false) {
						throw new Error('Failed to insert storage fragment');
					}
				})
				.then(() => {
					void resetSingleIssuesCreated();
				})
				.catch((_error) => {
					if (singleIssuesCreated.length === 1) {
						triggerSingleIssueInsertLinkFailureFlag({
							issueKey: singleIssuesCreated[0].key,
							summary: singleIssuesCreated[0].summary,
						});
						logRequestFailed(
							{
								requestName: JIRA_SINGLE_ISSUE_LINK_INSERTION,
								errorMessage: (_error as Error).message,
							},
							true,
						);
					} else {
						triggerMultipleIssuesInsertLinkFailureFlag(singleIssuesCreated);
						logRequestFailed(
							{
								requestName: JIRA_MULTIPLE_ISSUE_LINK_INSERTION,
								errorMessage: (_error as Error).message,
							},
							true,
						);
					}
				});
		}
	}, [
		constructXmlModification,
		insertStorageFragmentRequestPayload,
		resetSingleIssuesCreated,
		singleIssuesCreated,
		triggerMultipleIssuesInsertLinkFailureFlag,
		triggerSingleIssueInsertLinkFailureFlag,
		logRequestFailed,
	]);

	return {
		constructAnchorLinkToHeading,
		constructXmlModification,
		constructCellModifications,
		addSingleIssuesToPage,
	};
};
