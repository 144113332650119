import { expValEquals } from '@confluence/feature-experiments';

export const useIsFolderContentViewEnabled = (): {
	isFolderContentViewEnabled: boolean;
} => {
	return {
		isFolderContentViewEnabled: expValEquals<'test' | 'control'>(
			'confluence_cloud_folders_content_view_experiment',
			'cohort',
			'test',
		),
	};
};
