import React from 'react';

import Spinner from '@atlaskit/spinner';
import { FadeIn } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';

import { fg } from '@confluence/feature-gating';

const SpinnerContainerStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

export const LoomPageHeaderPlaceholder = () => {
	return (
		<Box xcss={SpinnerContainerStyles}>
			<FadeIn>
				{(props) => (
					<span {...props}>
						<Spinner
							size="small"
							interactionName={
								fg('ufo-manual-experimental-holds-confluence')
									? 'page-header-loom-entry-point-loading-icon'
									: undefined
							}
							testId="page-header-loom-entry-point-loading-icon"
							data-vc="loom-button"
						/>
					</span>
				)}
			</FadeIn>
		</Box>
	);
};
