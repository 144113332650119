import type { FC } from 'react';
import React, { Fragment, useState } from 'react';

import {
	EXTENSION_POINT_BYLINE_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { RequireLegacyWRM } from '@confluence/wrm';
import type { ForgeUIContentBylineItemExtensionType } from '@confluence/forge-ui';
import { ErrorDisplay } from '@confluence/error-boundary';
import { isErrorMarkedAsHandled } from '@confluence/graphql';
import { LoadableAfterPaint } from '@confluence/loadable';

import { LivePagesByLineAppsPlaceholder } from './LivePagesByLineAppsPlaceholder';
import { ByLineAppsComponent } from './ByLineAppsComponent';

type ByLineAppsProps = {
	contentId: string;
	connectItems: object[];
	forgeItems: ReadonlyArray<ForgeUIContentBylineItemExtensionType>;
	forgeError?: Error;
	showLivePagesExtensionPoints?: boolean;
};

export const LivePagesByLineApps: FC<ByLineAppsProps> = ({
	contentId,
	connectItems,
	forgeItems,
	forgeError,
	showLivePagesExtensionPoints,
}) => {
	const [showLoader, setShowLoader] = useState<boolean>(true);
	const handleLoad = () => setShowLoader(false);

	return (
		<Fragment>
			<ExperienceStart name={EXTENSION_POINT_BYLINE_EXPERIENCE} />
			{showLoader && (
				<LivePagesByLineAppsPlaceholder connectItems={connectItems} forgeItems={forgeItems} />
			)}
			<RequireLegacyWRM
				key="byline-app-wrm"
				wrm="wr!com.atlassian.plugins.atlassian-connect-plugin:confluence-atlassian-connect-content-byline-resources-v5"
			>
				{() => {
					if (forgeError) {
						if (!isErrorMarkedAsHandled(forgeError)) {
							return <ErrorDisplay error={forgeError} />;
						}
						// Process handled errors as successful
						return <ExperienceSuccess name={EXTENSION_POINT_BYLINE_EXPERIENCE} />;
					}

					return (
						<Fragment>
							<ByLineAppsComponent
								contentId={contentId}
								forgeItems={forgeItems}
								connectItems={connectItems}
								showLivePagesExtensionPoints={showLivePagesExtensionPoints}
								onLoad={handleLoad}
								showLoader={showLoader}
							/>
							<ExperienceSuccess name={EXTENSION_POINT_BYLINE_EXPERIENCE} />
						</Fragment>
					);
				}}
			</RequireLegacyWRM>
		</Fragment>
	);
};

export const LazyLivePagesByLineApps = LoadableAfterPaint({
	loader: async () => {
		return (
			await import(/* webpackChunkName: "loadable-LivePagesByLineApps" */ './LivePagesByLineApps')
		).LivePagesByLineApps;
	},
	loading: LivePagesByLineAppsPlaceholder,
});
