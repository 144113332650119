import React from 'react';

import { BulkEditIssueView } from './BulkEditIssueView';
import { BulkConfigureView } from './BulkConfigureView';
import { BulkCreateFlagsContainer } from './BulkCreateFlagsContainer';
import { BulkCreateDefaultView } from './BulkCreateDefaultView';
import { useGetIssueTypeFields } from './utils/useGetIssueTypeFields';
import { useSetOptionsColumns } from './utils/useSetOptionsColumns';
import { useInitMappings } from './utils/useInitMappings';
import { useMapTableToIssues } from './utils/useMapTableToIssues';

export const BulkNavigationContainer = ({ onClose }: { onClose: () => void }) => {
	useGetIssueTypeFields();
	useSetOptionsColumns();
	useInitMappings();
	useMapTableToIssues();

	return (
		<>
			<BulkCreateDefaultView onClose={onClose} />
			<BulkConfigureView />
			<BulkEditIssueView />
			<BulkCreateFlagsContainer />
		</>
	);
};
