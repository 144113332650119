export const isMac = () => window.navigator && window.navigator.platform?.indexOf('Mac') > -1;

export const GLYPHS = {
	command: '\u2318', // ⌘
	shift: '\u21E7', // ⇧
};

const keysStrings: { [key: string]: string | undefined } = {
	command: GLYPHS.command,
	enter: 'Enter',
	shiftMac: GLYPHS.shift,
	shift: 'Shift',
	ctrl: 'Ctrl',
	option: 'Option',
	alt: 'Alt',
};

const osMap: { [key: string]: { mac: string; other?: string } | undefined } = {
	mod: {
		mac: 'command',
		other: 'ctrl',
	},
	shift: {
		mac: 'shiftMac',
	},
	option: {
		mac: 'option',
		other: 'alt',
	},
};

const getGlue = () => (isMac() ? ' ' : '+');

export const getShortcutString = (keysStr: string, stringsGlue = getGlue()) => {
	const os = isMac() ? 'mac' : 'other';
	let stringsCount = 0;
	const shortcutPieces = keysStr.split('+');
	return shortcutPieces
		.map((key) => {
			key = osMap[key]?.[os] ?? key;

			if (!(key in GLYPHS)) {
				stringsCount += 1;
			}
			return keysStrings[key] ?? (key.length === 1 ? key.toUpperCase() : key);
		})
		.join(stringsCount > 1 || shortcutPieces.length > 2 ? stringsGlue : '');
};
