import React, { memo, useEffect, useMemo, useState, useRef, type FC } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import debounce from 'lodash/debounce';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import ClockIcon from '@atlaskit/icon/core/clock';
import { Flex, xcss } from '@atlaskit/primitives';
import { usePluginStateEffect } from '@atlaskit/editor-common/use-plugin-state-effect';
import Tooltip from '@atlaskit/tooltip';
import { N500 } from '@atlaskit/theme/colors';

import { ErrorDisplay, isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';

import type { FullPageEditorPresetAPI } from './byLineTypes';
import { ByLineLastUpdatedQuery } from './ByLineLastUpdatedQuery.graphql';
import type {
	ByLineLastUpdatedQuery as ByLineLastUpdatedQueryType,
	ByLineLastUpdatedQueryVariables,
	ByLineLastUpdatedQuery_content_nodes_version as ByLineLastUpdatedVersion,
} from './__types__/ByLineLastUpdatedQuery';

export type LivePagesByLineLastUpdatedProps = {
	contentId: string;
	editorApi?: FullPageEditorPresetAPI | null;
};

const livePageLastUpdatedStyle = xcss({
	marginTop: 'space.025',
	marginLeft: 'space.100',
	marginRight: 'space.150',
});

const loadingStyle = xcss({
	width: '85px',
	backgroundColor: 'color.skeleton.subtle',
	height: '24px',
	borderRadius: '20px',
});

const i18n = defineMessages({
	recentFriendlyWhen: {
		id: 'content-header.by-line.live-pages.last.updated.friendly.just.now',
		defaultMessage: 'Just now',
		description:
			'Last update description for changes made seconds ago in the editor byline for live pages. PLEASE ENSURE this matches the string with id `date.friendly.short.just.now` in Confluence Monolith.',
	},
	tooltipWhen: {
		id: 'content-header.by-line.live-pages.last.updated.tooltip',
		defaultMessage: 'Last updated: {day} at {time}',
		description:
			'Tooltip content when user hovers over the page last updated time, where {day} will be in localized medium formatting (for example, "Oct 1, 2024") and {time} will be localized short formatting (hh:mm PM/AM), see https://cldr.unicode.org/translation/date-time/date-time-patterns for details.',
	},
	lastUpdateIconLabel: {
		id: 'content-header.by-line.live-pages.last.updated.icon.label',
		defaultMessage: 'Last updated',
		description: 'Aria label for the clock icon next to the last updated time in the byline.',
	},
});

export const LivePagesByLineLastUpdated: FC<LivePagesByLineLastUpdatedProps> = memo(
	({ contentId, editorApi }) => {
		const { data, error, loading, refetch } = useQuery<
			ByLineLastUpdatedQueryType,
			ByLineLastUpdatedQueryVariables
		>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			ByLineLastUpdatedQuery,
			{
				variables: {
					contentId,
				},
				notifyOnNetworkStatusChange: true, // sets loading to true when refetching, necessary for tooltip
				fetchPolicy: window.__SSR_RENDERED__ ? 'cache-first' : 'cache-and-network', // query is preloaded on SSR, but not for SPA
			},
		);

		const latestVersion = data?.content?.nodes?.[0]?.version;

		// We need version in a state variable separate from query data, in order to set it on debouncedSetRecentVersion
		const [version, setVersion] = useState<ByLineLastUpdatedVersion>({
			friendlyWhen: latestVersion?.friendlyWhen || null,
			when: latestVersion?.when || null,
		});
		const previousRemoteChangeTimeRef = useRef<number | null | undefined>(undefined);
		const previousLocalChangeTimeRef = useRef<number | null | undefined>(undefined);

		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { locale } = useSessionData();
		const intl = useIntl();

		// Medium dateStyle does not support 'at' combiner, so we're using separate date/time formatters
		const dayFormatter = Intl.DateTimeFormat(locale, { dateStyle: 'medium' });
		const timeFormatter = Intl.DateTimeFormat(locale, { timeStyle: 'short' });

		useEffect(() => {
			// Only show the latest version, which could be from the query or from debouncedSetRecentVersion
			if (
				latestVersion &&
				JSON.stringify(latestVersion) !== JSON.stringify(version) &&
				latestVersion.when &&
				latestVersion.when >= (version?.when ?? '0')
			) {
				setVersion(latestVersion);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to trigger this on data change
		}, [latestVersion]);

		// Fetch that is immediately invoked before the debounced wait period
		// Used on visibility change (when user switches to this tab) and mouseenter
		const debouncedLeadingFetch = useMemo(
			() =>
				debounce(refetch, 60000, {
					leading: true,
					trailing: false,
					maxWait: 60000,
				}),
			[refetch],
		);

		useEffect(() => {
			// Fetch last updated time when tab is focused
			const handleVisibilityChange = async () => {
				if (document.visibilityState === 'visible') {
					void debouncedLeadingFetch(); // Get latest value when user switches back to this tab
				}
			};

			window.addEventListener('visibilitychange', handleVisibilityChange);
			return () => {
				window.removeEventListener('visibilitychange', handleVisibilityChange);
			};
		}, [debouncedLeadingFetch]);

		// After a page edit, we know the last change was very recent, so we directly set the version and skip
		// fetching over the network. We still debounce this to avoid rapid calls and prevent perfromance lag
		// when a user starts typing on very large pages
		const debouncedSetRecentVersion = useMemo(
			() =>
				debounce(() => {
					setVersion({
						friendlyWhen: intl.formatMessage(i18n.recentFriendlyWhen),
						when: new Date().toISOString(),
					});
				}, 3000),
			[setVersion, intl],
		);

		// Update time stamp to "Just now" on page edit
		usePluginStateEffect(editorApi, ['collabEdit'], ({ collabEditState }) => {
			const lastRemoteChangeTime = collabEditState?.initialised?.lastRemoteOrganicBodyChangeAt;
			const lastLocalChangeTime = collabEditState?.initialised?.lastLocalOrganicBodyChangeAt;

			const hasRemoteChanged =
				lastRemoteChangeTime && lastRemoteChangeTime !== previousRemoteChangeTimeRef.current;
			const hasLocalChanged =
				lastLocalChangeTime && lastLocalChangeTime !== previousLocalChangeTimeRef.current;

			if (hasRemoteChanged || hasLocalChanged) {
				previousRemoteChangeTimeRef.current = lastRemoteChangeTime;
				previousLocalChangeTimeRef.current = lastLocalChangeTime;
				debouncedSetRecentVersion();
			}
		});

		// Cancel any pending timers on unmount
		useEffect(() => {
			return () => {
				debouncedLeadingFetch.cancel();
				debouncedSetRecentVersion.cancel();
			};
		}, [debouncedLeadingFetch, debouncedSetRecentVersion]);

		const tooltipContent = useMemo(() => {
			if (loading) {
				return null;
			} else if (version?.when) {
				// When time since last update is > 48 hours ago, friendlyWhen will be a date (i.e. Oct 31, 2024)
				// However, user time zone conversion of the friendlyWhen date is not currently working on the BE,
				// which means the localized date in the tooltip can differ from the UTC date in the byline.
				// To fix this discrepancy, for now we'll not show the tooltip for dates > 48 hours
				const date = new Date(version?.when);
				const twoDaysInMillis = 48 * 60 * 60 * 1000;
				if (Date.now() - date.getTime() > twoDaysInMillis) {
					return null;
				} else {
					return (
						<FormattedMessage
							{...i18n.tooltipWhen}
							values={{
								day: dayFormatter.format(date),
								time: timeFormatter.format(date),
							}}
						/>
					);
				}
			}
		}, [loading, version?.when, dayFormatter, timeFormatter]);

		if (error) {
			if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
				return null;
			} else {
				// TODO: unknown errors should have a nicer UI within `<ErrorDisplay>`, but we don't have such UI for now
				// so returning empty `<ErrorDisplay>` here.
				return <ErrorDisplay error={error} />;
			}
		}

		const handleTooltipShow = () => {
			void debouncedLeadingFetch();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'LivePagesByLineLastUpdated',
					action: 'displayed',
					actionSubject: 'tooltip',
					actionSubjectId: 'bylineLastUpdate',
					attributes: {
						contentId,
						isLivePage: true,
					},
				},
			}).fire();
		};

		return (
			<Tooltip content={tooltipContent} onShow={handleTooltipShow}>
				<Flex
					alignItems="center"
					gap="space.075"
					xcss={livePageLastUpdatedStyle}
					testId="live-page-last-updated"
				>
					<ClockIcon
						label={intl.formatMessage(i18n.lastUpdateIconLabel)}
						LEGACY_size="small"
						color={token('color.text.subtle', N500)}
						testId="last-updated-icon"
					/>
					{/* TODO: add link to page version comparison */}
					{loading && !version.friendlyWhen ? (
						<Flex xcss={loadingStyle} alignItems="center" justifyContent="center">
							<span />
						</Flex>
					) : (
						version?.friendlyWhen
					)}
				</Flex>
			</Tooltip>
		);
	},
);
