/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { css, jsx } from '@emotion/react';

type RainbowLoadingSidebarProps = {
	children: React.ReactNode;
	isShown: boolean;
};

const defaultStyles = css({
	position: 'absolute',
	width: '100%',
	backgroundColor: 'elevation.surface',
	height: '100%',
	top: '0',
	right: '0',
	left: '0',
	bottom: '0',
	display: 'flex',
	flexDirection: 'column',
});

const gradientBar = `
	--angle: 0deg;
	border-left: 2px solid;
	border-image: conic-gradient(from var(--angle), #0065FF, #0469FF, #BF63F3, #FFA900, #0065FF) 1;

	animation: 1s rotate linear infinite;
`;

const addAngle = `
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
`;
const animateBorder = `
@keyframes rotate {
	to {
		--angle: 360deg;
	}
}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-exported-styles
const rainbowBorder = css(gradientBar, addAngle, animateBorder);

export const RainbowLoadingSidebar = ({ children, isShown }: RainbowLoadingSidebarProps) => {
	return (
		<div data-testId="rainbow-loading-sidebar" css={[defaultStyles, isShown && rainbowBorder]}>
			{children}
		</div>
	);
};
