import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import LinkIcon from '@atlaskit/icon/core/migration/link';
import Button from '@atlaskit/button/standard-button';

const i18n = defineMessages({
	copyLinkLabel: {
		id: 'share.copylink.icon.label',
		defaultMessage: 'Copy link',
		description: 'Label and tooltip for the button to copy content URL',
	},
});

export const CopyLinkButtonPlaceholder = () => {
	const intl = useIntl();
	return (
		<Button
			testId="copy-link-button-placeholder"
			data-test-id="copy-link-button-placeholder"
			appearance="subtle"
			iconBefore={<LinkIcon label={intl.formatMessage(i18n.copyLinkLabel)} />}
			isDisabled
		/>
	);
};
