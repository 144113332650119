import { DEFAULT_CONFIG } from '../constants';
import { RestClient } from '../rest-client';

import { type MutabilityContraints, transformUserManageConfig } from './utils';

const defaultConfig = {
	serviceUrl: `${DEFAULT_CONFIG.stargateRoot}/users`,
};

export class IdentityClient extends RestClient {
	constructor(config = {}) {
		super({ ...defaultConfig, ...config });
	}

	async getUserManageConfig(userId: string) {
		return this.getResourceCached<MutabilityContraints>(`/${userId}/manage`).then((resp) => {
			return transformUserManageConfig(resp);
		});
	}

	/**
	 * @deprecated
	 * User fetchUserAvatarInfo instead
	 */
	async getMyAvatarUploadedStatus(userId: string) {
		return this.fetchUserAvatarInfo(userId);
	}

	async deleteAvatar(userId: string) {
		return this.deleteResource(`/${userId}/manage/avatar`);
	}

	async fetchUserAvatarInfo(userId: string) {
		return this.getResource<{ uploaded: boolean; url: string }>(`/${userId}/manage/avatar`);
	}
}

export default new IdentityClient();
