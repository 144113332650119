// Disable no-re-export rule for entry point files
/* eslint-disable @atlaskit/editor/no-re-export */

import { type EditorView } from '@atlaskit/editor-prosemirror/view';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../config-items/config-items';
import type { AIPlugin } from '../editor-plugin-ai';
import { EditorPluginAI, NextEditorPluginAI } from '../editor-plugin-ai';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import { createEditorPluginAIProvider } from '../provider/provider';
import type { AIGlobalOptIn, SelectionToolbarDropdownConfig } from '../types';

import { advancedPromptWithOptions } from './config-items/advanced-prompt/advanced-prompt';
import { brainstorm, brainstormWithOptions } from './config-items/brainstorm/brainstorm';
import {
	changeToneToCasual,
	changeToneToCasualWithOptions,
	changeToneToEducational,
	changeToneToEducationalWithOptions,
	changeToneToEmpathetic,
	changeToneToEmpatheticWithOptions,
	changeToneToNeutral,
	changeToneToNeutralWithOptions,
	changeToneToProfessional,
	changeToneToProfessionalWithOptions,
} from './config-items/change-tone/change-tone';
import { messages as changeToneMessages } from './config-items/change-tone/messages';
import { convertToBulletListWithOptions } from './config-items/convert-to-bullet-list/convert-to-bullet-list';
import { convertToTableWithOptions } from './config-items/convert-to-table/convert-to-table';
import {
	fixSpellingAndGrammarWithOptions,
	improveWritingWithOptions,
	improveWriting,
	fixSpellingAndGrammar,
} from './config-items/enhance/enhance';
import { messages as enhanceMessages } from './config-items/enhance/messages';
import { getExperimentalPromptPlaceholderConfigItemWithOptions } from './config-items/experimental-prompt-placeholder/experimental-prompt-placeholder';
import { findActionItemsWithOptions } from './config-items/find-action-items/find-action-items';
import { freeGenerate } from './config-items/free-generate/free-generate';
import { makeLongerWithOptions } from './config-items/make-longer/make-longer';
import { makeShorter, makeShorterWithOptions } from './config-items/make-shorter/make-shorter';
import { messages as shortenMessages } from './config-items/make-shorter/messages';
import { rephraseWithOptions } from './config-items/rephrase/rephrase';
import { suggestTitleWithOptions } from './config-items/suggest-title/suggest-title';
import { messages as summarizeMessages } from './config-items/summarize-writing/messages';
import {
	summarizeWriting,
	summarizeWritingWithOptions,
} from './config-items/summarize-writing/summarize-writing';
import {
	translationConfigItemWithOptions,
	translationConfigItemsMap,
} from './config-items/translate/translate';
import messages from './messages';
import { languageMessages } from './translations/messages';
import { getSortedSupportedTranslationLanguages } from './translations/utils';
import type { BaseCreateProviderProps } from './types';
import { _getFetchCustomHeaders, deriveGenerativeAIApiUrl, deriveProactiveAIConfig } from './utils';

export const UNSTABLE_configItems = {
	brainstormConfigItem: brainstorm,
};

/**
 * IMPORTANT: This API is likely to change in future versions when we look
 * to provide more configurability to consumers.
 * In future you may need to refactor to use a new API.
 *
 * ```ts
 * import { UNSTABLE_configItems, UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem } from '@atlaskit/editor-plugin-ai/prebuilt/confluence';
 * // ...
 * UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem({
 *   editorView,
 *   configItem: UNSTABLE_configItems.brainstormConfigItem,
 * })
 * ```
 */
export const UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem = (props: {
	editorView: EditorView;
	configItem: EditorPluginAIConfigItem;
}) => {
	const { editorView, configItem } = props;
	const openAIModalCommand = createOpenAIModalCommand({
		state: editorView.state,
		configItem,
		lastTriggeredFrom: 'triggerConfigItem',
		aiGlobalOptIn: {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});
	openAIModalCommand(editorView.state, editorView.dispatch);
};

// Confluence was relying on the deriveGenerativeAIApiUrl function in
// https://stash.atlassian.com/projects/confcloud/repos/confluence-frontend/commits/ad5bd5e4989a173d62fa3b966224a59dc2b296fc
//
// Making this optional and will be removed in the future once it's explicitly set in confluence.
type CreateEditorPluginAIProviderProps = Omit<BaseCreateProviderProps, 'generativeAIApiUrl'> & {
	/**
	 * Note -- optional use is deprecated, and will be removed in the future
	 */
	generativeAIApiUrl?: BaseCreateProviderProps['generativeAIApiUrl'];
};

// Page comment editor
// note -- shape is to maintain consistency with createPageEditorPluginAIProvider
export function createPageCommentEditorPluginAIProvider(props: BaseCreateProviderProps) {
	/**
	 * Not abstracting these defaults up, in order to be explicit about possible
	 * different behaviours between page comment and page editors.
	 */
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = props.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	function getConfigItems(): EditorPluginAIConfigItemWithOptions[] {
		const list: EditorPluginAIConfigItemWithOptions[] = [
			changeToneToProfessionalWithOptions,
			summarizeWritingWithOptions,
			improveWritingWithOptions,
			fixSpellingAndGrammarWithOptions,
			makeShorterWithOptions,
			makeLongerWithOptions,
			rephraseWithOptions,
			convertToBulletListWithOptions,
			convertToTableWithOptions,
			suggestTitleWithOptions,
			brainstormWithOptions,
			changeToneToEmpatheticWithOptions,
			changeToneToCasualWithOptions,
			changeToneToNeutralWithOptions,
			changeToneToEducationalWithOptions,
			...translationConfigItemWithOptions,
		];

		const experimentalPromptPlaceholderData =
			getExperimentalPromptPlaceholderConfigItemWithOptions();
		if (experimentalPromptPlaceholderData) {
			const { listOrder, configItemWithOptions } = experimentalPromptPlaceholderData;
			list.splice(listOrder, 0, configItemWithOptions);
		}

		if (editorExperiment('platform_editor_ai_advanced_prompts', true)) {
			list.unshift(advancedPromptWithOptions);
		}

		return list;
	}

	return createEditorPluginAIProvider({
		baseGenerate: freeGenerate,
		configItemWithOptions: getConfigItems(),
		disableAISelectionToolbar: true,
		generativeAIApiUrl: props.generativeAIApiUrl || deriveGenerativeAIApiUrl(),
		allowProactiveAIFeatures: false,
		proactiveAIConfig: deriveProactiveAIConfig({ enabled: false }),
		product: 'CONFLUENCE',
		getFetchCustomHeaders: props.getFetchCustomHeaders || _getFetchCustomHeaders,
		onExperienceEvent,
		handleFeedbackSubmission: props.handleFeedbackSubmission,
		AIButtonWrapper: props.AIButtonWrapper,
		proactiveAIToolbarButtonWrapper: props.proactiveAIToolbarButtonWrapper,
		PromptEditor: props.PromptEditor,
		providers: props.providers,
		auditLogSettings: props.auditLogSettings,
	});
}

/**
 * Intended for use with Confluence page comment editors
 */
export const createPageCommentEditorPluginAI = ({
	editorPluginAIProvider,
	aiGlobalOptIn,
}: {
	editorPluginAIProvider: ReturnType<typeof createEditorPluginAIProvider>;
	/**
	 * While you don't have a aiGlobalOptIn setup, you can pass in a dummy
	 * object with the status set to 'enabled' and a no-op triggerOptInFlow
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageCommentEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: 'enabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Before setting up the opt-in nag, you may want to refactor to use the
	 * disabled state, rather than the dynamically added plugin strategy.
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageCommentEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: aiOptInStatus ? 'enabled' : 'disabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Note: This is intended to be made mandatory once this version lands on confluence
	 */
	aiGlobalOptIn?: AIGlobalOptIn;
}) => {
	const nextEditorPluginAI = new EditorPluginAI({
		editorPluginAIProvider,
		aiGlobalOptIn: aiGlobalOptIn || {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});

	return nextEditorPluginAI;
};

// Page editor
/**
 * This is written here as a "wrapper" in order to retain control of
 * the other values passed into the provider, e.g. configItems,
 * etc. - due to the high level of uncertainty, we'll keep this in place until
 * past phase 3.
 *
 * (design ethos: having control/flexibility in the API)
 *
 */
export const createPageEditorPluginAIProvider = (props: CreateEditorPluginAIProviderProps) => {
	/**
	 * Not abstracting these defaults up, in order to be explicit about possible
	 * different behaviours between page comment and page editors.
	 */
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = props.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	function getConfigItems(): EditorPluginAIConfigItemWithOptions[] {
		const list: EditorPluginAIConfigItemWithOptions[] = [
			changeToneToProfessionalWithOptions,
			summarizeWritingWithOptions,
			improveWritingWithOptions,
			fixSpellingAndGrammarWithOptions,
			makeShorterWithOptions,
			makeLongerWithOptions,
			rephraseWithOptions,
			convertToBulletListWithOptions,
			convertToTableWithOptions,
			findActionItemsWithOptions,
			suggestTitleWithOptions,
			brainstormWithOptions,
			changeToneToEmpatheticWithOptions,
			changeToneToCasualWithOptions,
			changeToneToNeutralWithOptions,
			changeToneToEducationalWithOptions,
			...translationConfigItemWithOptions,
		];

		if (editorExperiment('platform_editor_ai_advanced_prompts', true)) {
			list.unshift(advancedPromptWithOptions);
		}

		const experimentalPromptPlaceholderData =
			getExperimentalPromptPlaceholderConfigItemWithOptions();
		if (experimentalPromptPlaceholderData) {
			const { listOrder, configItemWithOptions } = experimentalPromptPlaceholderData;
			list.splice(listOrder, 0, configItemWithOptions);
		}

		return list;
	}

	return createEditorPluginAIProvider({
		baseGenerate: freeGenerate,
		configItemWithOptions: getConfigItems(),
		getSelectionToolbarDropdowns() {
			const selectionToolbarOptions: SelectionToolbarDropdownConfig[] = [
				{
					id: 'change-tone',
					label: messages.confluenceChangeToneSelectionToolbarDropdownMenuTitle,
					options: [
						{
							label: changeToneMessages.professionalToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToProfessional,
						},
						{
							label: changeToneMessages.empatheticToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToEmpathetic,
						},
						{
							label: changeToneMessages.casualToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToCasual,
						},
						{
							label: changeToneMessages.neutralToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToNeutral,
						},
						{
							label: changeToneMessages.educationalToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToEducational,
						},
					],
				},
				{
					id: 'rewrite',
					label: messages.confluenceRewriteSelectionToolbarDropdownMenuTitle,
					options: [
						{
							label: enhanceMessages.improveWritingSelectionToolbarDropdownItemTitle,
							configItem: improveWriting,
						},
						{
							label: enhanceMessages.fixSpellingGrammarSelectionToolbarDropdownItemTitle,
							configItem: fixSpellingAndGrammar,
						},
						{
							label: summarizeMessages.selectionToolbarDropdownItemTitle,
							configItem: summarizeWriting,
						},
						{
							label: shortenMessages.selectionToolbarDropdownItemTitle,
							configItem: makeShorter,
						},
					],
				},
			];

			const translateOptions = getSortedSupportedTranslationLanguages().map((language) => {
				const label = languageMessages[language];
				return {
					label,
					configItem: translationConfigItemsMap[language],
				};
			});

			selectionToolbarOptions.push({
				id: 'translate',
				label: messages.confluenceTranslateSelectionToolbarDropdownMenuTitle,
				options: translateOptions,
			});
			return selectionToolbarOptions;
		},
		disableAISelectionToolbar: false,
		generativeAIApiUrl: props.generativeAIApiUrl || deriveGenerativeAIApiUrl(),
		allowProactiveAIFeatures: props.allowProactiveAIFeatures,
		proactiveAIConfig: {
			...deriveProactiveAIConfig(),
			...props.proactiveAIConfig,
		},
		product: 'CONFLUENCE',
		isFullPageExperimentsEnabled: true,
		getFetchCustomHeaders: props.getFetchCustomHeaders || _getFetchCustomHeaders,
		getChannelVariables: props.getChannelVariables,
		onDocChangeByAgent: props.onDocChangeByAgent,
		onAIProviderChanged: props.onAIProviderChanged,
		onExperienceEvent,
		handleFeedbackSubmission: props.handleFeedbackSubmission,
		AIButtonWrapper: props.AIButtonWrapper,
		proactiveAIToolbarButtonWrapper: props.proactiveAIToolbarButtonWrapper,
		PromptEditor: props.PromptEditor,
		disableInterrogation: props.disableInterrogation ?? false,
		aiUsageDisclaimer: props.aiUsageDisclaimer,
		isRovoEnabled: props.isRovoEnabled ?? true,
		providers: props.providers,
		auditLogSettings: props.auditLogSettings,
	});
};

/**
 * Intended for use with Confluence page editors (when using the new editor)
 *
 */
export const PageNextEditorPlugin: AIPlugin = ({
	config: { editorPluginAIProvider, aiGlobalOptIn, __livePage },
	api,
}): ReturnType<typeof NextEditorPluginAI> => {
	const nextEditorPluginAI = NextEditorPluginAI({
		config: {
			editorPluginAIProvider,
			aiGlobalOptIn: aiGlobalOptIn || {
				status: 'enabled',
				triggerOptInFlow: () => {},
			},
			__livePage,
		},
		api,
	});
	return nextEditorPluginAI;
};

/**
 * Intended for use with Confluence Page Comment editors (when using the new editor)
 *
 */
export const PageCommentNextEditorPlugin: AIPlugin = PageNextEditorPlugin;

/**
 * Intended for use with Confluence page editors
 *
 */
export const createPageEditorPluginAI = ({
	editorPluginAIProvider,
	aiGlobalOptIn,
}: {
	editorPluginAIProvider: ReturnType<typeof createEditorPluginAIProvider>;
	/**
	 * While you don't have a aiGlobalOptIn setup, you can pass in a dummy
	 * object with the status set to 'enabled' and a no-op triggerOptInFlow
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: 'enabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Before setting up the opt-in nag, you may want to refactor to use the
	 * disabled state, rather than the dynamically added plugin strategy.
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: aiOptInStatus ? 'enabled' : 'disabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Note: This is intended to be made mandatory once this version lands on confluence
	 */
	aiGlobalOptIn?: AIGlobalOptIn;
}) => {
	const editorPluginAI = new EditorPluginAI({
		editorPluginAIProvider,
		aiGlobalOptIn: aiGlobalOptIn || {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});

	return editorPluginAI;
};
