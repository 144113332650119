import type {
	NodeType,
	Schema,
	Node as PMNode,
	Mark,
	MarkType,
} from '@atlaskit/editor-prosemirror/model';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import { uuid } from '@atlaskit/adf-schema';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type {
	ConnectionLink,
	LinkNode,
	LocalId,
	NewLinkElement,
	NodeNamingHandler,
	NormalizedConnection,
} from './types';
import type { ReferentialityContext } from './referentiality-context';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { safeInsert } from '@atlaskit/editor-prosemirror/utils';
import {
	CyclicReferenceError,
	LocalIdCollisionError,
	SourceReferenceError,
	TargetReferenceError,
	UnsupportedTypeError,
} from './errors';

interface FoundNode {
	node: PMNode;
	pos: number;
	fragmentMark: Mark | null;
}

export const getNodeTypesSupported = (schema: Schema): NodeType[] => {
	const { table, extension, bodiedExtension, inlineExtension } = schema.nodes;
	return [table, extension, bodiedExtension, inlineExtension];
};

export const getNodesSupportingFragmentMark = (schema: Schema): Map<NodeType, string> => {
	const { table, extension, bodiedExtension, inlineExtension } = schema.nodes;
	return new Map([
		[table, 'Table'],
		[extension, 'Extension'],
		[bodiedExtension, 'Extension'],
		[inlineExtension, 'Extension'],
	]);
};

export const getNodesSupportingNameCallback = (schema: Schema): Set<NodeType> => {
	const { extension, bodiedExtension, inlineExtension } = schema.nodes;
	return new Set([extension, bodiedExtension, inlineExtension].filter(Boolean));
};

export const createConnectionLink = (node: PMNode, id: string, name?: string): ConnectionLink => ({
	linkNode: {
		localId: id,
		name: name ?? id,
		node: {
			type: node.type.name,
			attrs: {
				...node.attrs,
			},
		},
	},
	sources: [],
	targets: [],
});

export const decodeName = (
	name: string,
): { defaultName: string; defaultNameNumber: number } | null => {
	const splitName = name.split(' '); // ['Awesome', 'list', '2.0', '5']
	if (splitName.length < 2) {
		return null;
	}

	const lastNumberInName = Number(splitName[splitName.length - 1]);
	if (typeof lastNumberInName !== 'number') {
		return null;
	}

	const nameWithoutLastNumber = splitName
		.slice(0, splitName.length - 1) // ['Awesome', 'list', '2.0']
		.join(' '); // 'Awesome list 2.0'

	return {
		defaultName: nameWithoutLastNumber,
		defaultNameNumber: lastNumberInName,
	};
};

export const generateMarkNameWithNumber = (
	{ type, attrs }: { type: NodeType; attrs?: ADFEntity['attrs'] },
	schema: Schema,
	fragmentMarkNameLastNumbers: Record<string, number>,
	nodeNameCallback?: NodeNamingHandler | null,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
) => {
	const nodesSupportingFragmentMark = getNodesSupportingFragmentMark(schema);
	const nodesSupportingNameCallback = getNodesSupportingNameCallback(schema);

	let markName = '';
	if (nodesSupportingNameCallback.has(type)) {
		if (nodeNameCallback) {
			markName = nodeNameCallback({ node: { type: type.name, attrs } });
		}
	}

	if (!markName) {
		markName = nodesSupportingFragmentMark.get(type) ?? 'Element';
	}

	const markNameNumber = (fragmentMarkNameLastNumbers?.[markName] ?? 0) + 1;
	return {
		markNameWithNumber: `${markName} ${markNameNumber}`,
		markName,
		markNameNumber,
	};
};

export const createLinkNodeFromConnection = ({
	normalizedId,
	name,
	node,
}: NormalizedConnection): LinkNode => createLinkNode(normalizedId, name, node);

export const createLinkNode = (localId: string, name: string, node: PMNode): LinkNode => {
	const jsonNode = new JSONTransformer().encodeNode(node);
	return {
		localId,
		name,
		node: {
			type: jsonNode.type,
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			attrs: jsonNode.attrs as Record<string, any>,
		},
	};
};

export const isCyclicRef = (
	context: ReferentialityContext,
	target: NormalizedConnection,
	source: NormalizedConnection,
): boolean => {
	if (target.normalizedId === source.normalizedId) {
		return true;
	}
	// Try and get a consumer from the source, this is because we don't care about the initial link between target -> source
	// we only care if somewhere down the chain after the source; is there a link back to this target.
	return !!source.dataConsumer?.attrs.sources.some((src: string) => {
		const nextSrc = context.getById(src);
		return !!nextSrc && isCyclicRef(context, target, nextSrc);
	});
};

export const hasNodeWithLocalId = (state: EditorState, localId: LocalId) => {
	let found = false;

	const { doc, schema } = state;
	const { fragment } = schema.marks;
	const nodesSupportingFragmentMark = getNodesSupportingFragmentMark(schema);

	// TODO: Update scanning method to abort the scan immediately once a node has been found.
	doc.descendants((node) => {
		if (found) {
			return false;
		}

		if (!nodesSupportingFragmentMark.has(node.type)) {
			return true;
		}

		if (node.attrs?.localId === localId) {
			found = true;
			return false;
		}

		const fragmentMark = fragment.isInSet(node.marks);

		// If node cannot be referenced by any means then abort.
		if (!fragmentMark) {
			return true;
		}

		if (fragmentMark?.attrs?.localId === localId) {
			found = true;
		}

		return !found;
	});

	return found;
};

export const findNodeByLocalId = (localId: LocalId, state: EditorState): FoundNode | null => {
	const { doc, schema } = state;
	const fragment = schema.marks.fragment as MarkType;

	const nodesSupportingFragmentMark = getNodesSupportingFragmentMark(schema);

	let foundNode: FoundNode | null = null;

	doc.descendants((node, pos) => {
		if (foundNode) {
			return false;
		}

		if (!nodesSupportingFragmentMark.has(node.type)) {
			return true;
		}

		const existingFragmentMark = fragment.isInSet(node.marks) ?? null;
		if (existingFragmentMark && existingFragmentMark.attrs.localId === localId) {
			foundNode = { node, pos, fragmentMark: existingFragmentMark };
			return false;
		}

		if (node.attrs.localId === localId) {
			foundNode = { node, pos, fragmentMark: existingFragmentMark };
			return false;
		}

		return true;
	});

	return foundNode;
};

export const createMarks = (
	schema: Schema,
	localId: LocalId,
	name: string,
	sources?: LocalId[] | Set<LocalId>,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): Mark[] => {
	const { fragment, dataConsumer } = schema.marks;
	return [
		fragment.create({
			localId,
			name,
		}),
		sources &&
			dataConsumer.create({
				sources: Array.from(sources) ?? [],
			}),
	].filter(Boolean) as Mark[];
};

export const createNode = (
	schema: Schema,
	type: NodeType,
	attrs: ADFEntity['attrs'],
	marks: Mark[],
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): PMNode => {
	const { table } = schema.nodes;

	const createNodeContent = type === table ? createTableContent : createExtensionContent;

	return type.createChecked(
		{ ...attrs, localId: uuid.generate() },
		createNodeContent(schema, type),
		marks,
	);
};

export const createTableContent = (schema: Schema, type: NodeType) => {
	const { tableCell, tableHeader, tableRow } = schema.nodes;

	const cells: PMNode[] = [];
	const headerCells: PMNode[] = [];

	for (let i = 0; i < 3; i++) {
		const cell = tableCell.createAndFill();
		if (cell) {
			cells.push(cell);
		}

		const headerCell = tableHeader.createAndFill();
		if (headerCell) {
			headerCells.push(headerCell);
		}
	}

	const rows = [];
	for (let i = 0; i < 3; i++) {
		rows.push(tableRow.createChecked(null, i === 0 ? headerCells : cells));
	}
	return rows;
};

export const createExtensionContent = (schema: Schema, type: NodeType): Fragment | PMNode[] => {
	const { bodiedExtension, paragraph } = schema.nodes;
	if (type === bodiedExtension) {
		return [paragraph.createChecked()];
	}

	return Fragment.empty;
};

export const insertNodeAfter = (
	localId: LocalId,
	node: PMNode,
	state: EditorState,
	tr?: Transaction,
	options?: { selectNode?: boolean },
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): Transaction => {
	tr = tr ?? state.tr;
	const nodePos = findNodeByLocalId(localId, state);
	const insertPosition = nodePos ? nodePos.pos + nodePos.node.nodeSize : undefined;
	tr = safeInsert(node, insertPosition)(tr);
	return tr;
};

// normalizes ids and enforces uniqueness
export const normalizeIds = (context: ReferentialityContext, ids?: LocalId[]): Set<LocalId> => {
	return (
		ids?.reduce((idSet: Set<LocalId>, id: LocalId) => {
			const ns = context.getById(id)?.normalizedId;
			// filters out undefined, null or empty items
			return !!ns ? idSet.add(ns) : idSet;
		}, new Set()) ?? new Set()
	);
};

export const updateSources =
	(schema: Schema, target: NormalizedConnection, sources: Set<LocalId>) => (tr: Transaction) =>
		updateNodeSources(schema, target.node, target.pos, sources, target.dataConsumer)(tr);

export const updateNodeSources =
	(
		schema: Schema,
		node: PMNode,
		pos: number,
		sources?: Set<LocalId>,
		consumer?: Mark,
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/max-params
	) =>
	(tr: Transaction) => {
		const { dataConsumer } = schema.marks;

		const currentSources: string[] = (consumer ?? dataConsumer.isInSet(node.marks))?.attrs.sources;

		// Quick Check: No point in updating node markup if nothing is changing.
		if (!sources?.size && !currentSources?.length) {
			return tr;
		}

		// This attempts to check deep equality. If the sizes are not the same or a source is different to what's being set; then
		// we want to perform the markup change, otherwise we should avoid unnecessary transactions.
		if (
			sources?.size === currentSources?.length &&
			currentSources.every((src: LocalId) => sources.has(src))
		) {
			return tr;
		}

		const marks = (dataConsumer?.removeFromSet(node.marks) ?? node.marks).concat();

		if (!!sources?.size) {
			marks.push(
				dataConsumer.create({
					sources: Array.from(sources),
				}),
			);
		}

		// If the old target sources is now 0 then the data consumer will be removed from the marks. Otherwise a new
		// consumer with updated sources will be injected.
		tr.setNodeMarkup(pos, undefined, node.attrs, marks);

		return tr;
	};

export const updateFragmentName =
	(schema: Schema, target: NormalizedConnection, name: string) => (tr: Transaction) => {
		const { fragment } = schema.marks;

		// Quick Check: No point in updating node markup if nothing is changing.
		if (name === target.fragmentMark?.attrs.name) {
			return tr;
		}

		const marks = (
			target.fragmentMark?.removeFromSet(target.node.marks) ?? target.node.marks
		).concat();

		marks.push(
			fragment.create({
				localId: target.fragmentMark?.attrs.localId ?? uuid.generate(),
				name,
			}),
		);

		return tr.setNodeMarkup(target.pos, undefined, target.node.attrs, marks);
	};

export const createLinkElementNode = (
	context: ReferentialityContext,
	element: NewLinkElement,
	nodeNameCallback?: NodeNamingHandler | null,
	sources?: LocalId[] | Set<LocalId>,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): { linkNode: LinkNode; node: PMNode } => {
	const nodeType: NodeType = context.schema.nodes[element.type];

	if (!getNodesSupportingFragmentMark(context.schema).has(nodeType)) {
		throw new UnsupportedTypeError(element.type);
	}

	const newNodeUUID = uuid.generate();

	if (context.hasId(newNodeUUID)) {
		// XXX: Assuming UUID is true this condition should never occur.
		// This is just a safety catch; to protect against lightning strikes.
		throw new LocalIdCollisionError(newNodeUUID);
	}

	let attrs;
	if (element.type !== 'table') {
		attrs = element.attrs;
	}

	const { markNameWithNumber } = generateMarkNameWithNumber(
		{ type: nodeType, attrs },
		context.schema,
		context.getMaxNumberUsedInFragmentMarkNames(),
		nodeNameCallback,
	);

	if (element.type === 'table') {
		attrs = { width: 760 };
	}

	const node = createNode(
		context.schema,
		nodeType,
		attrs,
		createMarks(context.schema, newNodeUUID, markNameWithNumber, sources),
	);

	const { inlineExtension, paragraph } = context.schema.nodes;
	// The finalNode is a post-processed node which is eventually added to the document. This may not be the actual
	// element node this was told to create as some nodes may need to be wrapped by others to be safely added  to the document.
	const finalNode = nodeType === inlineExtension ? paragraph.create(undefined, [node]) : node;

	return {
		linkNode: createLinkNode(newNodeUUID, markNameWithNumber, node),
		node: finalNode,
	};
};

export const connectNodes = (
	context: ReferentialityContext,
	targetLocalId: LocalId,
	sourceLocalId: LocalId,
	tr: Transaction,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): { target: LinkNode; source: LinkNode; tr: Transaction } => {
	const target = context.getById(targetLocalId);

	if (!target) {
		throw new TargetReferenceError(targetLocalId);
	}

	const source = context.getById(sourceLocalId);

	if (!source) {
		throw new SourceReferenceError(sourceLocalId);
	}

	if (target.normalizedId === source.normalizedId) {
		throw new CyclicReferenceError(targetLocalId, sourceLocalId);
	}

	// If the current target dataConsumer already has the source linked.
	const hasDataConsumerSource = !!target.dataConsumer?.attrs.sources.some((src: LocalId) =>
		source.ids.has(src),
	);

	if (hasDataConsumerSource) {
		// If the connection has already been made, avoid an unnecessary transaction
		return {
			target: createLinkNodeFromConnection(target),
			source: createLinkNodeFromConnection(source),
			tr,
		};
	} else {
		if (isCyclicRef(context, target, source)) {
			throw new CyclicReferenceError(targetLocalId, sourceLocalId);
		}

		const newSources = normalizeIds(context, target.dataConsumer?.attrs.sources);

		// Add the source to the target.
		newSources.add(source.normalizedId);

		tr = updateSources(context.schema, target, newSources)(tr);
	}

	return {
		target: createLinkNodeFromConnection(target),
		source: createLinkNodeFromConnection(source),
		tr,
	};
};

export const disconnectNodes = (
	context: ReferentialityContext,
	targetLocalId: LocalId,
	sourceLocalId: LocalId,
	tr: Transaction,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): Transaction => {
	const source = context.getById(sourceLocalId);

	if (!source) {
		throw new SourceReferenceError(sourceLocalId);
	}

	const target = context.getById(targetLocalId);

	if (!target) {
		throw new TargetReferenceError(targetLocalId);
	}

	const targetSources = normalizeIds(context, target.dataConsumer?.attrs.sources);

	// Remove the source from the target (if any) and update the sources if the source was deleted.
	if (targetSources.delete(source.normalizedId)) {
		return updateSources(context.schema, target, targetSources)(tr);
	}

	return tr;
};

/**
 * This is very similar to connectNodes with one key difference; This will force the sources to contain, only 1 source.
 * If that source is already linked, nothing will happen, otherwise all sources will be cleared and only the supplied source
 * will be linked.
 * @param context
 * @param targetLocalId
 * @param sourceLocalId
 * @param tr
 * @returns
 */
export const resetNodes = (
	context: ReferentialityContext,
	targetLocalId: LocalId,
	sourceLocalId: LocalId,
	tr: Transaction,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): { target: LinkNode; source: LinkNode; tr: Transaction } => {
	const target = context.getById(targetLocalId);

	if (!target) {
		throw new TargetReferenceError(targetLocalId);
	}

	const source = context.getById(sourceLocalId);

	if (!source) {
		throw new SourceReferenceError(sourceLocalId);
	}

	if (target.normalizedId === source.normalizedId) {
		throw new CyclicReferenceError(targetLocalId, sourceLocalId);
	}

	// If the current target dataConsumer already has the source linked.
	const hasDataConsumerSource = !!target.dataConsumer?.attrs.sources.every((src: LocalId) =>
		source.ids.has(src),
	);

	if (hasDataConsumerSource) {
		// If the connection has already been made, avoid an unnecessary transaction
		return {
			target: createLinkNodeFromConnection(target),
			source: createLinkNodeFromConnection(source),
			tr,
		};
	} else {
		if (isCyclicRef(context, target, source)) {
			throw new CyclicReferenceError(targetLocalId, sourceLocalId);
		}

		tr = updateSources(context.schema, target, new Set([source.normalizedId]))(tr);
	}

	return {
		target: createLinkNodeFromConnection(target),
		source: createLinkNodeFromConnection(source),
		tr,
	};
};

/**
 * This will disconnect the source from all targets which consume it.
 * @param context
 * @param localId
 * @param tr
 * @returns
 */
export const disconnectSource = (
	context: ReferentialityContext,
	localId: LocalId,
	tr: Transaction,
): Transaction => {
	const source = context.getById(localId);

	if (!source) {
		throw new SourceReferenceError(localId);
	}

	source.targets.forEach((target) => {
		tr = disconnectNodes(context, target, localId, tr);
	});

	return tr;
};

/**
 * This will update all targets which are consuming the old source, and point them at the new source.
 * @param context
 * @param oldSourceLocalId
 * @param newSourceLocalId
 * @param tr
 * @returns
 */
export const updateSource = (
	context: ReferentialityContext,
	oldSourceLocalId: LocalId,
	newSourceLocalId: LocalId,
	tr: Transaction,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): { source: LinkNode; tr: Transaction } => {
	// This needs to disconnect the old source from all targets. Then update each target with the new source
	const oldSource = context.getById(oldSourceLocalId);

	if (!oldSource) {
		throw new SourceReferenceError(oldSourceLocalId);
	}

	const newSource = context.getById(newSourceLocalId);

	if (!newSource) {
		throw new SourceReferenceError(newSourceLocalId);
	}

	// If the old/new source are referring to the same connection then we should avoid unnecessary transactions.
	if (oldSource === newSource) {
		return {
			source: createLinkNodeFromConnection(newSource),
			tr,
		};
	}

	// Before we do any disconnecting or updating we must ensure a couple things first;
	// 1. The old source targets exist
	// 2. Moving the old source target to the new source doesn't cause a cyclic ref.
	for (const targetId of oldSource.targets) {
		const target = context.getById(targetId);

		if (!target) {
			throw new TargetReferenceError(targetId);
		}

		if (isCyclicRef(context, target, newSource)) {
			throw new CyclicReferenceError(targetId, newSourceLocalId);
		}

		const targetSources = normalizeIds(context, target.dataConsumer?.attrs.sources);

		const deleted = targetSources.delete(oldSource.normalizedId);
		// If the target source doesn't have the new source then it will be added.
		const added = !targetSources.has(newSource.normalizedId);
		targetSources.add(newSource.normalizedId);

		if (deleted || added) {
			tr = updateSources(context.schema, target, targetSources)(tr);
		}
	}

	return {
		source: createLinkNodeFromConnection(newSource),
		tr,
	};
};

export const insertTarget = (
	context: ReferentialityContext,
	sourceLocalId: LocalId,
	target: NewLinkElement,
	tr: Transaction,
	nodeNameCallback?: NodeNamingHandler | null,
	oldTargetLocalId?: LocalId,
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/max-params
): { linkNode: LinkNode; tr: Transaction } => {
	const nodeType: NodeType = context.schema.nodes[target.type];

	if (!getNodesSupportingFragmentMark(context.schema).has(nodeType)) {
		throw new UnsupportedTypeError(target.type);
	}

	if (!context.hasId(sourceLocalId)) {
		throw new SourceReferenceError(sourceLocalId);
	}

	const { linkNode, node } = createLinkElementNode(context, target, nodeNameCallback, [
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		context.getById(sourceLocalId)!.normalizedId,
	]);

	if (!!oldTargetLocalId) {
		// If an old target id has been supplied then this insert action is
		// replacing and old connection, so we need to disconnect the
		// old connection, then insert the new node.
		tr = disconnectNodes(context, oldTargetLocalId, sourceLocalId, tr);
	}

	tr = insertNodeAfter(sourceLocalId, node, context.state, tr);
	tr = tr.setMeta(
		'referentialityTableInserted',
		node.type.name === context.schema.nodes.table.name,
	);

	return {
		linkNode,
		tr,
	};
};
