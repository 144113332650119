import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { CommentData } from '@confluence/comments-data';
import type { FlagsStateContainer } from '@confluence/flags';
import { CommentBatchList } from '@confluence/comment/entry-points/CommentBatchList';

import { getCommentThreadsToBatch } from '../helper/commentThreadHelper';

import { CommentThread } from './CommentThread';

type CommentPanelListProps = {
	commentThreads: CommentData[];
	supportedTopLevelActions: CommentAction[];
	flags?: FlagsStateContainer;
};

const listStyles = xcss({
	overflowY: 'auto',
	overflowX: 'hidden',
});

// this component holds a list of comments
export const CommentsPanelList = ({
	commentThreads,
	supportedTopLevelActions,
	flags,
}: CommentPanelListProps) => {
	const pageMode = useGetPageMode();
	const eventEmitter =
		pageMode === PageMode.EDIT || pageMode === PageMode.LIVE
			? getEditorAnnotationEventEmitter()
			: getRendererAnnotationEventEmitter();

	const actionsToShowUsers =
		pageMode === PageMode.EDIT
			? supportedTopLevelActions.filter((action) => action !== 'delete')
			: supportedTopLevelActions;

	// Props we want the batch renderer to pass through that aren't comment specific
	const passThroughProps = {
		supportedTopLevelActions: actionsToShowUsers,
		eventEmitter,
		pageMode,
		flags,
	};

	return (
		<Stack xcss={listStyles} testId="comments-panel-list-container">
			<CommentBatchList
				commentsList={commentThreads}
				passThroughProps={passThroughProps}
				Component={CommentThread}
				getElementsToBatch={getCommentThreadsToBatch}
			/>
		</Stack>
	);
};
