import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

type AiResultActionType = 'createAll' | 'createSingleIssue';

export const BULK_ISSUE_CREATE_SOURCE = 'confluenceBulkIssueCreate';
export const AI_BULK_ISSUE_CREATE_FEATURE_NAME = 'aiIssueCreateConfluenceBulk';

const buildAttributes = (baseAttributes: Record<string, any>, isAi: boolean) => {
	if (isAi) {
		return { ...baseAttributes, aiFeatureName: AI_BULK_ISSUE_CREATE_FEATURE_NAME };
	}
	return baseAttributes;
};

const getErrorMessage = (error: Error | unknown) => {
	if (error instanceof Error) return error.message;
	return String(error);
};

export const useBulkCreateLoggingEvents = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		trackAIResultError,
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultAction,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();

	const logMultipleIssueCreateSuccess = useCallback(
		(numIssues: number, isAi = false) => {
			const attributes = buildAttributes({ numIssues }, isAi);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'created',
					actionSubject: 'issue',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logSingleIssueCreateSuccess = useCallback(
		(isAi = false) => {
			logMultipleIssueCreateSuccess(1, isAi);
		},
		[logMultipleIssueCreateSuccess],
	);

	const logSingleIssueCreateFailure = useCallback(
		(error: Error | unknown, isAi = false) => {
			const attributes = buildAttributes({ error: getErrorMessage(error) }, isAi);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'error',
					actionSubject: 'globalIssueCreate',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logCancelAiPanel = useCallback(() => {
		const attributes = buildAttributes({}, true);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: BULK_ISSUE_CREATE_SOURCE,
				action: 'cancelled',
				actionSubject: 'aiPanel',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const logAiResultViewed = useCallback(
		(issuesGenerated: number) => {
			const attributes = buildAttributes({ issuesGenerated }, true);
			trackAIResultView({ attributes });
		},
		[trackAIResultView],
	);

	const logAiResultActioned = useCallback(
		(aiResultAction: AiResultActionType) => {
			const attributes = buildAttributes({}, true);
			trackAIResultAction(aiResultAction, { attributes });
		},
		[trackAIResultAction],
	);

	const logRequestFailed = useCallback(
		(
			{ requestName, errorMessage }: { requestName: string; errorMessage: string },
			isAi = false,
		) => {
			const attributes = buildAttributes({ errorMessage }, isAi);
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'failed',
					actionSubject: requestName,
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logCreateAllClick = useCallback(() => {
		logAiResultActioned('createAll');
	}, [logAiResultActioned]);

	const logCreateSingleIssueClick = useCallback(() => {
		logAiResultActioned('createSingleIssue');
	}, [logAiResultActioned]);

	const logAiResultRemoved = useCallback(() => {
		const attributes = buildAttributes({}, true);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: BULK_ISSUE_CREATE_SOURCE,
				action: 'removed',
				actionSubject: 'aiResult',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const logAiIssueCreationFailed = useCallback(
		(numIssues: number, error: Error | unknown = '') => {
			const attributes = buildAttributes(
				{
					errorMessage: getErrorMessage(error),
					numIssues,
				},
				true,
			);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'failed',
					actionSubject: 'aiIssueCreation',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logAiInteractionDismissed = useCallback(() => {
		const attributes = buildAttributes({}, true);
		trackAIInteractionDismiss({ attributes });
	}, [trackAIInteractionDismiss]);

	const logAiNoResultsGenerated = useCallback(() => {
		const attributes = buildAttributes({}, true);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: BULK_ISSUE_CREATE_SOURCE,
				action: 'generated',
				actionSubject: 'aiNoResults',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const logAiInteractionInitiated = useCallback(() => {
		const attributes = buildAttributes({}, true);
		trackAIInteractionInit({ attributes });
	}, [trackAIInteractionInit]);

	const logAiStreamingBackendFailed = useCallback(
		(error: Error | unknown = '') => {
			const attributes = buildAttributes({}, true);
			trackAIResultError({ aiErrorMessage: getErrorMessage(error) }, { attributes });
		},
		[trackAIResultError],
	);

	return {
		logSingleIssueCreateSuccess,
		logMultipleIssueCreateSuccess,
		logSingleIssueCreateFailure,
		logCancelAiPanel,
		logCreateAllClick,
		logCreateSingleIssueClick,
		logAiInteractionInitiated,
		logAiResultViewed,
		logAiResultRemoved,
		logAiIssueCreationFailed,
		logAiInteractionDismissed,
		logAiNoResultsGenerated,
		logRequestFailed,
		logAiStreamingBackendFailed,
	};
};
