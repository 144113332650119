import type { PublicLinkPageProviderData } from '@confluence/public-links';
import type { ConfluenceEdition } from '@confluence/session-data';

import type { ContentType } from '../../shareAndRestrictButton/ShareAndRestrictButton';
import type { QueriesState } from '../Controller';
import type { GeneralAccessLevel, Principal } from '../../controlled/commonTypes';

import { getContentRestrictions } from './getContentRestrictions';
import { getCurrentUserPrincipal } from './getCurrentUserPrincipal';
import type {
	ShareAndRestrictDialogQueryV1_content_nodes_ancestors,
	ShareAndRestrictDialogQueryV1_content,
	ShareAndRestrictDialogQueryV1 as ShareAndRestrictDialogQueryType,
} from './__types__/ShareAndRestrictDialogQueryV1';

/**
 * Translates all main query data into an internal format. Returns `null` if
 * the query resolved with too many nulls to be able to display the dialog in
 * any form.
 */
export function dataToQueriesState(
	data: ShareAndRestrictDialogQueryType,
	publicLinkPageData: PublicLinkPageProviderData,
	spaceKey: string,
	contentType: ContentType,
	edition: ConfluenceEdition,
): QueriesState | null {
	const currentUser = getCurrentUserPrincipal(data.user);
	if (!currentUser) return null;

	const contentData = getContentData(data.content);
	if (!contentData) return null;

	return {
		...contentData,
		spaceKey,
		currentUser,
		publicLinkPageData,
		userCanEditRestrictions: canSetRestrictions(
			data,
			contentData.contentRestrictions.generalAccessLevel,
			contentType,
			currentUser,
		),
		edition,
	};
}

/**
 * Determines if the current user can set restrictions on the content.
 */
export function canSetRestrictions(
	data: ShareAndRestrictDialogQueryType | undefined,
	generalAccessLevel: GeneralAccessLevel,
	contentType: ContentType,
	currentUser: Principal,
) {
	const contentNode = data?.content?.nodes?.[0];
	const operationsData = contentNode?.operations || [];
	const updateRestrictionsList =
		contentNode?.restrictions?.update?.restrictions?.userWithRestrictions?.nodes || [];
	const isSpaceAdmin = data?.space?.currentUser?.isAdmin || false;

	// Check if the current content type has a restrict_content operation
	const canRestrictContent = operationsData.some(
		(operation) =>
			operation?.operation === 'restrict_content' && operation?.targetType === contentType,
	);

	if (generalAccessLevel === 'open-can-edit') return canRestrictContent;

	// Check if the current user is allowed to update restrictions
	const userHasEditRestrictions = updateRestrictionsList.some(
		(user) => user?.accountId === currentUser.id,
	);

	return (canRestrictContent && userHasEditRestrictions) || isSpaceAdmin;
}

/**  Extracts all the QueriesState data that can be obtained from the `content` node */
function getContentData(
	content: ShareAndRestrictDialogQueryV1_content | null,
): Pick<
	QueriesState,
	'contentRestrictions' | 'hasInheritedRestrictions' | 'parentPageLink'
> | null {
	if (!content) return null;

	const { nodes } = content;
	if (!nodes) return null;

	const [node0] = nodes;
	if (!node0) return null;

	const { restrictions, hasInheritedRestrictions, ancestors } = node0;
	if (!restrictions) return null;

	const contentRestrictions = getContentRestrictions(restrictions);
	if (!contentRestrictions) return null;

	const parentPageLink = hasInheritedRestrictions
		? getClosestAncestorWithViewRestrictions(ancestors)
		: null;

	return {
		contentRestrictions,
		hasInheritedRestrictions,
		parentPageLink,
	};
}

export function getClosestAncestorWithViewRestrictions(
	ancestors: (ShareAndRestrictDialogQueryV1_content_nodes_ancestors | null)[] | null,
): string | null | undefined {
	const closestBoundingPage: {
		id: string;
		link?: string | null;
	} = { id: '', link: null };
	// Last ancestor is closest bounding
	if (ancestors)
		for (let i = ancestors.length - 1; i >= 0; i--) {
			if (ancestors[i]?.hasViewRestrictions) {
				closestBoundingPage.id = ancestors[i]?.id || '';
				closestBoundingPage.link = ancestors[i]?.links?.webui;
				break;
			}
		}

	return closestBoundingPage.link;
}
