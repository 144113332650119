import { LoadableAfterPaint } from '@confluence/loadable';

export const UnreadCommentCount = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-UnreadCommentCount" */ './UnreadCommentCount'))
			.UnreadCommentCount,
});

export const UnreadTooltipText = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-UnreadTooltipText" */ './UnreadTooltipText'))
			.UnreadTooltipText,
});

export {
	useUnreadInlineComments,
	useUnreadCommentsIsEnabled,
} from './hooks/useUnreadInlineComments';

export { useUnreadCommentsForInlineComment } from './hooks/useUnreadCommentsForInlineComment';

export {
	handleAddedInlineCommentForUnread,
	handleObliteratedInlineCommentForUnread,
} from './utils/unreadCommentsPubSubUtils';

export type {
	UnreadPubSubParams,
	MarkerRefToCommentIdMap,
	UnreadInlineComment,
} from './types/unreadCommentsTypes';

export { isValidUnreadComment } from './utils/unreadCommentCountUtils';
