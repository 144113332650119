import type { Action, BoundActions } from 'react-sweet-state';
import { createHook, createStore, createContainer } from 'react-sweet-state';

import type { DocNode } from '@atlaskit/adf-schema';
import { doc, p } from '@atlaskit/adf-utils/builders';

import type { AiIssueCreateState, ContentType } from '@atlassian/ai-issue-create';

import type { InsertStorageFragmentRequestPayload } from '../__types__/InsertStorageFragmentRequestPayload';
import type { InsertStorageColumnTableRequestPayload } from '../__types__/InsertStorageColumnTableRequestPayload';

export type IssueCreateSidePanelContextType = {
	contentId?: string;
	contentType?: ContentType;
	summary?: string;
	descriptionAdf?: DocNode;
	highlightedText?: DocNode;
	selectedTable?: HTMLTableElement;
	insertStorageFragmentRequestPayload?: InsertStorageFragmentRequestPayload;
	insertStorageColumnTableRequestPayload?: InsertStorageColumnTableRequestPayload;
	aiState: AiIssueCreateState;
	isCreatedWithAi: boolean;
	firstAttempt: boolean;
	totalRetries: number;
};

type AiAnalyticsContextType = {
	aiResponseLength: number;
	pageContextLength: number;
	highlightedTextLength: number;
};

type State = {
	contentId: string;
	contentType: ContentType;
	summary: string;
	descriptionAdf: DocNode;
	highlightedText: DocNode;
	selectedTable: HTMLTableElement | undefined;
	insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload | undefined;
	insertStorageColumnTableRequestPayload: InsertStorageColumnTableRequestPayload | undefined;
	aiState: AiIssueCreateState;
	isCreatedWithAi: boolean;
	firstAttempt: boolean;
	totalRetries: number;
	aiAnalyticsContext: AiAnalyticsContextType;
};

export type IssueCreateSidePanelActions = BoundActions<State, typeof issueCreateSidePanelActions>;

const initialIssueCreateSidePanelState: State = {
	contentId: '',
	contentType: 'page',
	summary: '',
	descriptionAdf: doc(p('')),
	highlightedText: doc(p('')),
	selectedTable: undefined,
	insertStorageFragmentRequestPayload: undefined,
	insertStorageColumnTableRequestPayload: undefined,
	aiState: 'idle',
	isCreatedWithAi: false,
	firstAttempt: true,
	totalRetries: 0,
	aiAnalyticsContext: { aiResponseLength: 0, pageContextLength: 0, highlightedTextLength: 0 },
};

const issueCreateSidePanelActions = {
	setContentId:
		(contentId: string): Action<State> =>
		({ setState }) => {
			setState({ contentId });
		},
	setContentType:
		(contentType: State['contentType']): Action<State> =>
		({ setState }) => {
			setState({ contentType });
		},
	setSummary:
		(summary: string): Action<State> =>
		({ setState }) => {
			setState({ summary });
		},
	setDescriptionAdf:
		(descriptionAdf: DocNode): Action<State> =>
		({ setState }) => {
			setState({ descriptionAdf });
		},
	setHighlightedText:
		(highlightedText: State['highlightedText']): Action<State> =>
		({ setState }) => {
			setState({ highlightedText });
		},
	setSelectedTable:
		(selectedTable: State['selectedTable']): Action<State> =>
		({ setState }) => {
			setState({ selectedTable });
		},
	setAiState:
		(aiState: State['aiState']): Action<State> =>
		({ setState }) => {
			setState({ aiState });
		},
	setIsCreatedWithAi:
		(isCreatedWithAi: State['isCreatedWithAi']): Action<State> =>
		({ setState }) => {
			setState({ isCreatedWithAi });
		},
	setInsertStorageFragmentRequestPayload:
		(insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload): Action<State> =>
		({ setState }) => {
			setState({ insertStorageFragmentRequestPayload });
		},
	setInsertStorageColumnTableRequestPayload:
		(
			insertStorageColumnTableRequestPayload: InsertStorageColumnTableRequestPayload,
		): Action<State> =>
		({ setState }) => {
			setState({ insertStorageColumnTableRequestPayload });
		},
	setAiAnalyticsContext:
		(key: string, value: number): Action<State> =>
		({ setState, getState }) => {
			setState({
				aiAnalyticsContext: {
					...getState()['aiAnalyticsContext'],
					[key]: value,
				},
			});
		},
	setFirstAttempt:
		(firstAttempt: State['firstAttempt']): Action<State> =>
		({ setState }) => {
			setState({ firstAttempt });
		},
	setTotalRetries:
		(): Action<State> =>
		({ setState, getState }) => {
			const currentTotalRetries = getState().totalRetries;
			setState({ totalRetries: currentTotalRetries + 1 });
		},
};

const Store = createStore({
	initialState: {
		...initialIssueCreateSidePanelState,
	},
	actions: issueCreateSidePanelActions,
	name: 'issueCreateSidePanelContext',
});

export const IssueCreateSidePanelContainer = createContainer(Store);

export const useIssueCreateSidePanelContext = createHook(Store);
