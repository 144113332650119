import { fg } from '@confluence/feature-gating';

/**
 * Returns if the current tenant has the ability to use Live Pages.
 * This takes into account multiple releases that contribute to the feature's availability.
 * @returns { boolean } Whether the Live Pages feature is enabled
 */
export const isLivePagesFeatureEnabled = (): boolean => {
	// This is allowed as this is the abstraction layer for other packages to use
	// fg('confluence_live_pages') checks if live pages are enabled
	// fg('confluence_live_pages_ab_test_opted_in') checks if live pages AB test opted in
	// fg('confluence_live_pages_open_beta') checks if in live pages beta is enabled
	return (
		fg('confluence_live_pages') ||
		fg('confluence_live_pages_ab_test_opted_in') ||
		fg('confluence_live_pages_open_beta')
	);
};
