import type { FC } from 'react';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ErrorDisplay } from '@confluence/error-boundary';
import { LoadableLazy, LoadablePaint } from '@confluence/loadable';

import { GrantAccessDialogLoaderQuery } from './GrantAccessDialogLoaderQuery.graphql';
import type { GrantAccessLoaderProps } from './types';
import type {
	GrantAccessDialogLoaderQuery as GrantAccessDialogLoaderQueryType,
	GrantAccessDialogLoaderQueryVariables,
} from './__types__/GrantAccessDialogLoaderQuery';

export const GrantAccessDialogPaint = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GrantAccessDialog" */ './GrantAccessDialog'))
			.GrantAccessDialog,
});

export const GrantAccessDialogLoader: FC<GrantAccessLoaderProps> = ({
	contentId,
	accountId,
	shouldSSRGrantAccessRestrictions,
	buttonPlaceholder,
	...props
}) => {
	const GrantAccessDialog = LoadableLazy({
		loader: async () =>
			(await import(/* webpackChunkName: "loadable-GrantAccessDialog" */ './GrantAccessDialog'))
				.GrantAccessDialog,
		loading: () => buttonPlaceholder,
	});

	const shouldPreloadGrantAccess = shouldSSRGrantAccessRestrictions && window.__SSR_RENDERED__;

	const { data, error } = useQuery<
		GrantAccessDialogLoaderQueryType,
		GrantAccessDialogLoaderQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GrantAccessDialogLoaderQuery,
		{
			variables: { contentId: contentId || '', accountId },
			skip: !contentId || !accountId,
			fetchPolicy: shouldPreloadGrantAccess ? 'cache-first' : 'cache-and-network',
		},
	);

	let profilePhoto = '';
	const photos = data?.user?.photos;
	if (photos?.length) {
		const primary = photos.find((photo) => photo?.isPrimary);
		const foundPhoto = primary ? primary.value : photos[0]?.value;
		profilePhoto = foundPhoto || '';
	}

	const Component = shouldSSRGrantAccessRestrictions ? GrantAccessDialogPaint : GrantAccessDialog;

	if (error) {
		return <ErrorDisplay error={error} />;
	} else if (!contentId || !data?.user) {
		// If user is not recognized or we have no target contentId, render just the children.
		// User is identified via URL params and it would be easy to pass invalid data.
		// Note: must render the children, as this is how restrictions button is displayed
		return <>{props.children}</>;
	}
	return (
		<Component
			contentId={contentId}
			accountId={accountId}
			contentTitle={data?.content?.nodes?.[0]?.title || ''}
			displayName={data?.user?.displayName || ''}
			profilePhoto={profilePhoto}
			{...props}
		/>
	);
};
