import type { DocNode } from '@atlaskit/adf-schema';

import { htmlToADF } from '@atlassian/html-adf-transformer';
import type { ContentType } from '@atlassian/ai-issue-create';

import type { IssueCreateSidePanelActions } from '@confluence/issue-create-side-panel';
import {
	BULK_ISSUE_CREATE_SIDE_PANEL_ID,
	BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH,
	ISSUE_CREATE_SIDE_PANEL_ID,
	ISSUE_CREATE_SIDE_PANEL_WIDTH,
	constructInsertStorageFragmentRequestPayload,
	constructInsertStorageColumnTableRequestPayload,
	getClosestTableFromSelection,
	getValidRange,
} from '@confluence/issue-create-side-panel';
import type { RightSidebarContextType } from '@confluence/page-layout-context';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

const getHTMLString = (activeSelection: Selection | null | undefined): string => {
	if (!activeSelection) {
		return '';
	}

	const docFragment = activeSelection.getRangeAt(0).cloneContents();
	const tempDiv = document.createElement('div');
	tempDiv.appendChild(docFragment);

	return tempDiv.innerHTML;
};

export const SUMMARY_MAX_LENGTH = 254;

export const splitText = (text: string, maxLength: number): [string, string] => {
	const horizontalEllipsis = '…';
	if (text.length <= maxLength) {
		return [text, ''];
	}
	// Calculate the length of the first part without including the ellipsis
	const firstPartLength = maxLength - horizontalEllipsis.length;
	// Split the text into two parts
	const firstPart = text.substring(0, firstPartLength);
	const firstPartWithHorizontalEllipis = firstPart + horizontalEllipsis;
	return [firstPartWithHorizontalEllipis, text];
};

export const getSummaryAndDescription = (
	activeSelection: Selection | null | undefined,
): [string, DocNode] => {
	const selectionString = activeSelection?.toString() || '';
	const htmlString = getHTMLString(activeSelection);

	const [summary] = splitText(selectionString, SUMMARY_MAX_LENGTH);
	/*
	 The regex matches one or more occurrences of any line break (\r, \n) or tab (\t)
	 and replaces them all with a single space. (We only consider whitespace characters because
	 these are the only ones that ultimately stick when parsing the summary.)
  */
	const summaryWithoutFormatting = summary.replace(/[\r\n\t]+/g, ' ');

	// Convert the full HTML content to ADF for the description
	const descriptionAdf = htmlToADF(htmlString);

	return [summaryWithoutFormatting, descriptionAdf];
};

export interface CreateSingleJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	contentType?: ContentType;
	container: HTMLElement;
	lastModified?: string | null;
	isCreatedWithAi?: boolean;
}

export interface CreateSingleAiJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	contentType?: ContentType;
	container: HTMLElement;
	fireAiAnalytics: () => void;
	lastModified?: string | null;
	pressTracing?: (timeStamp?: number) => void;
}

export interface CreateBulkJiraIssuesHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	pressTracing?: (timeStamp?: number) => void;
	activeSelection?: Selection | null;
	contentId: string;
	container: HTMLElement;
	lastModified?: string | null;
}

const createSingleJiraIssueHandleCommon = ({
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	lastModified,
}: Omit<CreateSingleJiraIssueHandleParams, 'rightSidebarContext'>) => {
	const highlightedText = htmlToADF(getHTMLString(activeSelection));

	const insertStorageFragmentRequestPayload = constructInsertStorageFragmentRequestPayload(
		contentId,
		convertSelectionToLegacyFormat,
		getValidRange(activeSelection?.getRangeAt(0)),
		container,
		lastModified,
	);

	const [summaryWithoutFormatting, descriptionAdf] = getSummaryAndDescription(activeSelection);

	void actions.setSummary(summaryWithoutFormatting);
	void actions.setDescriptionAdf(descriptionAdf);
	void actions.setContentId(contentId);
	contentType && void actions.setContentType(contentType);
	void actions.setHighlightedText(highlightedText);
	void actions.setInsertStorageFragmentRequestPayload(insertStorageFragmentRequestPayload);
};

export const createSingleJiraIssueHandle = ({
	rightSidebarContext,
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	lastModified,
}: CreateSingleJiraIssueHandleParams) => {
	createSingleJiraIssueHandleCommon({
		actions,
		activeSelection,
		contentId,
		contentType,
		container,
		lastModified,
	});
	void actions.setIsCreatedWithAi(false);

	rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
};

export const createSingleAiJiraIssueHandle = ({
	rightSidebarContext,
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	fireAiAnalytics,
	lastModified,
	pressTracing,
}: CreateSingleAiJiraIssueHandleParams) => {
	fireAiAnalytics();
	createSingleJiraIssueHandleCommon({
		actions,
		activeSelection,
		contentId,
		contentType,
		container,
		lastModified,
	});
	void actions.setIsCreatedWithAi(true);

	rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	pressTracing?.();
};

export const createMultipleJiraIssuesHandleModernized = ({
	actions,
	rightSidebarContext,
	pressTracing,
	activeSelection,
	contentId,
	container,
	lastModified,
}: CreateBulkJiraIssuesHandleParams) => {
	const selectedTable = getClosestTableFromSelection(activeSelection);
	if (!selectedTable) {
		// We have a check to ensure that the activeSelection is in a table, so this should never happen.
		return;
	}
	actions.setSelectedTable(selectedTable);
	const insertStorageColumnTableRequestPayload = constructInsertStorageColumnTableRequestPayload(
		contentId,
		convertSelectionToLegacyFormat,
		getValidRange(activeSelection?.getRangeAt(0)),
		container,
		lastModified,
	);
	void actions.setContentId(contentId);
	void actions.setInsertStorageColumnTableRequestPayload(insertStorageColumnTableRequestPayload);
	void actions.setIsCreatedWithAi(false);
	rightSidebarContext.open(BULK_ISSUE_CREATE_SIDE_PANEL_ID, BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH);
	pressTracing?.();
};
