import React, { memo, useCallback, useMemo, useState, type FC, type ComponentType } from 'react';

import { usePageContentId } from '@confluence/page-context';

import { CommentBatch } from './CommentBatch';

export const BATCH_SIZE = 8;

// TODO: Convert any to generics
export type SharedCommentBatchProps = {
	passThroughProps: any;
	Component: ComponentType<any>;
	getElementsToBatch: (
		batchedList: any[],
		passThroughProps: any,
		Component: ComponentType<any>,
	) => React.JSX.Element[];
};

type CommentBatchListProps = SharedCommentBatchProps & {
	commentsList: any[];
};

export const CommentBatchList: FC<CommentBatchListProps> = memo(
	({ commentsList, passThroughProps, Component, getElementsToBatch }) => {
		const [contentId] = usePageContentId();

		const batchCount = Math.ceil(commentsList.length / BATCH_SIZE);
		const batches = useMemo(() => new Array(batchCount).fill(0), [batchCount]);
		const [batch, setBatch] = useState(0);

		const onBatchRendered = useCallback((batchIndex) => {
			setBatch(batchIndex + 1);
		}, []);

		return (
			<>
				{batches.map((_, index) => (
					<CommentBatch
						key={`${contentId}-batch-${index}`}
						commentsList={commentsList}
						canRenderBatch={index <= batch}
						batchIndex={index}
						passThroughProps={passThroughProps}
						Component={Component}
						getElementsToBatch={getElementsToBatch}
						onBatchRendered={onBatchRendered}
					/>
				))}
			</>
		);
	},
);
