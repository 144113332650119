import { defineMessages } from 'react-intl-next';

export const skeletonPlaceholders = defineMessages({
	inputPlaceholderDefault: {
		id: 'input_placeholder_default',
		defaultMessage: 'Search for anything',
		description: 'Placeholder text in primary search input',
	},
	inputPlaceholderNav3: {
		id: 'input_placeholder_nav3',
		defaultMessage: 'Search',
		description: 'Placeholder text in primary search input for Nav3',
	},
});

export const inputPlaceholderMessages = defineMessages({
	inputPlaceholderWithAtlassianIntelligence: {
		id: 'input_placeholder_with_atlassian_intelligence',
		defaultMessage: 'Search or ask anything',
		description: 'Placeholder text in primary search input with Atlassian Intelligence',
	},
	inputPlaceholderWithRovo: {
		id: 'input_placeholder_with_rovo',
		defaultMessage: 'Search or ask Rovo',
		description: 'Placeholder text in primary search input with Rovo',
	},
	searchAcrossProducts: {
		id: 'input_placeholder_ghost_text_search_across',
		defaultMessage: 'Search across {products}, and more',
		description:
			'Placeholder text in the search input for searching across connected products - each product is separated by commas',
	},
	submitExpenseQuestion: {
		id: 'input_placeholder_ghost_text_submit_expense',
		defaultMessage: 'Ask a question like "How do I submit an expense?"',
		description:
			'Placeholder text in the search input to prompt an AI repsonse about submitting an expense',
	},
	kpiAcronymSearch: {
		id: 'input_placeholder_ghost_text_kpi_acronym',
		defaultMessage: 'Search any acronym, like "KPI"',
		description: 'Placeholder text in the search input to prompt an AI repsonse about an acronym',
	},
	mentionTeammatesSearch: {
		id: 'input_placeholder_ghost_text_mention_teammates',
		defaultMessage: '@mention a teammate to see their work',
		description:
			'Placeholder text in the search input to filter search results based on a mentioned teammate using the @ keyword',
	},
	searchTeammateName: {
		id: 'input_placeholder_ghost_text_search_teammate',
		defaultMessage: 'Search the name of a teammate',
		description: 'Placeholder text in the search input to search for teammates',
	},
});
