import type { QueryOptions } from '@apollo/react-hooks';
import { useQuery } from '@apollo/react-hooks';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import { WebItemLocationQuery } from './WebItemLocationQuery.graphql';
import type {
	WebItemLocationQuery as QueryData,
	WebItemLocationQueryVariables as QueryVars,
	WebItemLocationQuery_webItemSections as WebItemSection,
	WebItemLocationQuery_webItemSections_items as WebItemRaw,
} from './__types__/WebItemLocationQuery';
import { replaceAtlTokenPlaceholder } from './replaceAtlTokenPlaceholder';

export type WebItem = Omit<WebItemRaw, 'params'> & {
	parentSection: {
		label: string | null;
	};
	params: Record<string, string>;
	webSection?: string;
};

export type UseWebItemLocationOptions = {
	location: string | string[];
	contentId?: string | null;
	spaceKey?: string | null;
	version?: number | null;
	fetchPolicy?: QueryOptions['fetchPolicy'];
	allowedWebItems?: string[];
	notAllowedWebItems?: string[];
	skipQuery?: boolean;
};

export const useWebItemLocation = ({
	location,
	contentId = null,
	spaceKey = null,
	version = null,
	fetchPolicy = 'cache-and-network',
	allowedWebItems,
	notAllowedWebItems,
	skipQuery,
}: UseWebItemLocationOptions) => {
	const { data, loading, error } = useQuery<QueryData, QueryVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		WebItemLocationQuery,
		{
			fetchPolicy,
			variables: {
				// GraphQL doesn't cache single location strings properly when passed to locations
				// unless we're using multiple locations we should be using the location variable
				location: Array.isArray(location) ? null : location,
				locations: Array.isArray(location) ? location : [],
				contentId,
				spaceKey,
				version,
			},
			skip: skipQuery,
		},
	);

	if (!data) {
		return {
			loading,
			error,
			webItems: [],
			webSections: [],
		};
	}

	// replace any instances of {atlTokenPlaceholderWillBeReplacedInFrontend}
	// with the atlToken in all section items
	const webItems = getWebItems(replaceAtlTokenPlaceholder(data).webItemSections, {
		allowedWebItems,
		notAllowedWebItems,
	});

	const webSections = uniq(webItems.map((webItem) => webItem.section));

	webItems.sort(sortWebItems);

	return {
		// Since the default fetchPolicy is cache-and-network
		// loading field will be true, false, true (background refetch), false
		// Query is refreshing when data not empty but loading is true
		// Padding loading false to avoid reverting back to the empty state or loading skeleton.
		// This aligns with confluence/next/packages/web-item-location/src/WebItemLocation.tsx
		loading: false,
		error,
		webItems,
		webSections,
	};
};

const getWebItems = (
	webItemSections: (WebItemSection | null)[],
	webItemFilterOptions: WebItemFilterOptions,
): WebItem[] => {
	const webItems = webItemSections.flatMap((section) => {
		return section!.items
			.filter(filterWebItem(webItemFilterOptions))
			.map(transformWebItem(section!));
	});

	return uniqBy(webItems, 'completeKey');
};

type WebItemFilterOptions = {
	allowedWebItems?: string[];
	notAllowedWebItems?: string[];
};

const filterWebItem =
	({ allowedWebItems, notAllowedWebItems }: WebItemFilterOptions) =>
	(item: WebItemRaw | null): item is WebItemRaw => {
		if (item === null || item.completeKey === null) {
			return false;
		}

		const isAllowed = !allowedWebItems || allowedWebItems.includes(item.completeKey);
		const isDisallowed = notAllowedWebItems?.includes(item.completeKey);

		return isAllowed && !isDisallowed;
	};

const transformWebItem =
	(section: WebItemSection) =>
	(rawItem: WebItemRaw): WebItem => ({
		...rawItem,
		params: getParamsObject(rawItem),
		webSection: getWebSection(rawItem),
		parentSection: {
			label: section.label || section.styleClass,
		},
	});

const sortWebItems = (webItemA: WebItem, webItemB: WebItem) => {
	if (!webItemA.weight || !webItemB.weight || !webItemA.label || !webItemB.label) {
		return 0;
	}

	if (webItemA.weight - webItemB.weight === 0) {
		return webItemA.label.localeCompare(webItemB.label);
	} else {
		return webItemA.weight - webItemB.weight;
	}
};

const getWebSection = (item: WebItemRaw) => {
	if (item.section && item.section.indexOf('/') !== -1) {
		const parts = item.section.split('/');
		return parts[1];
	}
};

const getParamsObject = (item: WebItemRaw) => {
	const paramObj: any = {};
	item.params?.forEach((param) => {
		if (param?.key) {
			paramObj[param.key] = param.value;
		}
	});
	return paramObj;
};
