import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import AddIcon from '@atlaskit/icon/core/add';
import Button from '@atlaskit/button/new';
import { Box, Stack, Text } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

import { fg } from '@confluence/feature-gating';

import { messages } from './messages';
import { ColumnToIssueFieldMapping } from './ColumnToIssueFieldMapping';
import type { FieldMetadata } from './__types__/apiUtils';
import type { UnsavedColumnToFieldMapping } from './providers/BulkConfigureContextProvider';
import {
	useIsConfigureLoading,
	useMappingsActions,
	useMappingsState,
} from './providers/BulkConfigureContextProvider';

const mapFieldsToOptions = (fields: FieldMetadata[]) => {
	return fields.map((field) => ({
		label: field.name,
		value: field,
	}));
};

type MappingValidationError = {
	message: string;
};

export const ColumnToIssueFieldMappingView = forwardRef<{ validateMappings: () => void }, {}>(
	(_, ref) => {
		const { formatMessage } = useIntl();
		const { isLoading: isConfigureLoading } = useIsConfigureLoading();
		const {
			optionsColumns,
			optionsIssueTypeFields,
			unsavedColumnToFieldMappings,
			loadingIssueTypeFields,
		} = useMappingsState();
		const { setUnsavedColumnToFieldMappings, addUnsavedColumnToFieldMapping } =
			useMappingsActions();
		const tableColumnOptions = optionsColumns
			? optionsColumns.map((column) => ({ label: column.header, value: column }))
			: [];
		const issueFieldOptions = optionsIssueTypeFields
			? mapFieldsToOptions(optionsIssueTypeFields)
			: [];
		const isLoading = isConfigureLoading || loadingIssueTypeFields;

		const handleMappingChange = useCallback(
			(index: number, newMapping: UnsavedColumnToFieldMapping) => {
				if (unsavedColumnToFieldMappings) {
					const updatedMappings = unsavedColumnToFieldMappings.map((mapping, idx) =>
						idx === index ? newMapping : mapping,
					);
					setUnsavedColumnToFieldMappings(updatedMappings);
				}
			},
			[unsavedColumnToFieldMappings, setUnsavedColumnToFieldMappings],
		);

		const addMapping = useCallback(() => {
			if (isLoading) {
				return;
			}
			const newMapping: UnsavedColumnToFieldMapping = {
				column: undefined,
				field: undefined,
				errors: {},
				isDefaultMapping: false,
			};
			addUnsavedColumnToFieldMapping(newMapping);
		}, [isLoading, addUnsavedColumnToFieldMapping]);

		// TODO: Please update the messages and ensure they are internationalized once the content design has been finalized.
		const validateMappings = (): boolean => {
			if (!unsavedColumnToFieldMappings) {
				return false;
			}
			let isValid = true;
			const mappedField = new Set<string>();
			const updatedMappings = unsavedColumnToFieldMappings.map((mapping) => {
				const { column, field } = mapping;
				const errors: { column?: MappingValidationError; field?: MappingValidationError } = {};
				if (!column) {
					errors.column = {
						message: formatMessage(messages.bulkConfigureMapTableToIssueErrorColumnNotSelected),
					};
					isValid = false;
				}
				if (!field) {
					errors.field = {
						message: formatMessage(messages.bulkConfigureMapTableToIssueErrorFieldNotSelected),
					};
					isValid = false;
				} else {
					const fieldId = field.fieldId;
					if (mappedField.has(fieldId)) {
						errors.field = {
							message: formatMessage(messages.bulkConfigureMapTableToIssueErrorFieldAlreadyMapped),
						};
						isValid = false;
					} else {
						mappedField.add(fieldId);
					}
				}
				return {
					...mapping,
					errors,
				};
			});
			setUnsavedColumnToFieldMappings(updatedMappings);
			return isValid;
		};

		useImperativeHandle(ref, () => ({
			validateMappings,
		}));

		return (
			<Stack space="space.100">
				<Heading size="small">
					<FormattedMessage {...messages.bulkConfigureMapTableToIssueTitle} />
				</Heading>
				<Text color="color.text.subtlest">
					<FormattedMessage {...messages.bulkConfigureMapTableToIssueDescription} />
				</Text>
				<Box padding="space.100">
					<Stack space="space.100">
						<Stack space="space.300">
							{unsavedColumnToFieldMappings?.map((mapping, index) => (
								<ColumnToIssueFieldMapping
									key={index}
									index={index}
									mapping={mapping}
									tableColumnOptions={tableColumnOptions}
									issueFieldOptions={issueFieldOptions}
									onMappingChange={handleMappingChange}
									isLoading={loadingIssueTypeFields || isLoading}
									isDisabled={loadingIssueTypeFields || isLoading}
								/>
							))}
						</Stack>
						{fg('confluence_bulk_issue_create_additional_mappings') && (
							<Button
								appearance="subtle"
								iconBefore={(iconProps) => <AddIcon {...iconProps} />}
								onClick={addMapping}
								isDisabled={loadingIssueTypeFields || isLoading}
							>
								<FormattedMessage {...messages.bulkConfigureMapTableToIssueAddMapping} />
							</Button>
						)}
					</Stack>
				</Box>
			</Stack>
		);
	},
);
