/**
 * @deprecated Use space tokens instead. If a space token is not available, such as sizes above 80px, just hardcode your value e.g. 240px
 */
export const gridSize = 8; // import { gridSize } from "@atlaskit/theme/constants";
export const borderRadius = 3; // import { borderRadius } from "@atlaskit/theme/constants";

// TODO(brizvash): move it to separate package or check is atlaskit will become compatible with compiled
export const layersLayer = 400; // layers.layer()
export const layersDialog = 300; // layers.dialog()
export const layersNavigation = 200; // layers.navigation()
