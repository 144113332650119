/* eslint-disable @atlaskit/design-system/no-legacy-icons */
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import type { ContentType } from '@atlassian/ai-issue-create';

import { useRightSidebarContext } from '@confluence/page-layout-context';
import {
	useIssueCreateSidePanelContext,
	useIsAIIssueCreationEnabled,
} from '@confluence/issue-create-side-panel';
import { useIsSidePanelOpen } from '@confluence/side-panel-state-container';
import { fg } from '@confluence/feature-gating';

import { createMultipleJiraIssuesHandle } from './builtInButtonHandlers';
import {
	createMultipleJiraIssuesHandleModernized,
	createSingleAiJiraIssueHandle,
	createSingleJiraIssueHandle,
} from './createJiraIssueHandlers';
import { GreyAtlassianIntelligenceIcon } from './GreyAtlassianIntelligenceIcon';
import type { CreateIssueRecordedProps } from './HighlightActions';

type CreateJiraIssueActionsProps = {
	activeSelection?: Selection | null;
	completeKey: string;
	container: any;
	contentId: string;
	contentType?: ContentType;
	lastModified?: string | null;
	showCreateMultipleIssuesButton: boolean;
	setRecordedClick: React.Dispatch<React.SetStateAction<CreateIssueRecordedProps | undefined>>;
	setIsWarningDialogOpen: React.Dispatch<React.SetStateAction<Boolean>>;
};

const createWithAiDropdownItemStyle = xcss({
	display: 'flex',
	alignItems: 'center',
});

const aiIconStyle = xcss({
	paddingRight: 'space.100',
	lineHeight: '8px',
});

const betaLozengeStyle = xcss({
	paddingLeft: 'space.100',
});

const iconWrapperStyle = xcss({
	paddingTop: 'space.025',
});

const IssueCreationType = {
	SINGLE_ISSUE: 'SINGLE_ISSUE',
	MULTIPLE_ISSUE: 'MULTIPLE_ISSUE',
	AI_ISSUE: 'AI_ISSUE',
} as const;

type IssueCreationType = (typeof IssueCreationType)[keyof typeof IssueCreationType];

// Use this helper function when we have the modernized bulk create ready.
const isMultiRowTableSelection = (selection?: Selection | null): boolean => {
	if (!selection || selection.rangeCount === 0) {
		return false;
	}
	const range = selection.getRangeAt(0);
	const startContainer = range.startContainer;
	const endContainer = range.endContainer;
	// Helper function to find the parent <tr> element of a given node
	const findParentTableRow = (node: Node): HTMLTableRowElement | null => {
		while (node) {
			if ((node as HTMLElement).tagName === 'TR') {
				return node as HTMLTableRowElement;
			}
			node = node.parentNode as Node;
		}
		return null;
	};
	// Find the closest <tr> ancestor for both start and end of the selection
	const startRow = findParentTableRow(startContainer);
	const endRow = findParentTableRow(endContainer);
	if (!startRow || !endRow) {
		return false;
	}
	// If the start and end are not in the same row, it's a multi-row selection
	return startRow !== endRow;
};

const isTableSelection = (selection?: Selection | null): boolean => {
	if (!selection || selection.rangeCount === 0) {
		return false;
	}
	const range = selection.getRangeAt(0);
	const startContainer = range.startContainer;
	const endContainer = range.endContainer;
	// Helper function to find the parent <table> element of a given node
	const findParentTable = (node: Node): HTMLTableElement | null => {
		let currentNode: Node | null = node;
		while (currentNode) {
			if (
				currentNode.nodeType === Node.ELEMENT_NODE &&
				(currentNode as HTMLElement).tagName === 'TABLE'
			) {
				return currentNode as HTMLTableElement;
			}
			currentNode = currentNode.parentNode;
		}
		return null;
	};
	// Check if both the start and end container are within a table
	const startInTable = findParentTable(startContainer) !== null;
	const endInTable = findParentTable(endContainer) !== null;
	return startInTable && endInTable;
};

const getIssueCreationType = ({
	isAIIssueCreationEnabled,
}: {
	isAIIssueCreationEnabled: boolean;
}) => {
	if (isAIIssueCreationEnabled) {
		return IssueCreationType.AI_ISSUE;
	} else {
		return IssueCreationType.SINGLE_ISSUE;
	}
};

const ConsolidatedCreateJiraIssueButton = ({
	completeKey,
	handleCreateIssueItemClicked,
	isAIIssueCreationEnabled,
	isSelectionMultipleRows,
}: {
	completeKey: string;
	handleCreateIssueItemClicked: (issueCreationType: IssueCreationType) => () => void;
	isAIIssueCreationEnabled: boolean;
	isSelectionMultipleRows: boolean;
}) => {
	return (
		<Button
			id="create-jira-issue-button"
			appearance="subtle"
			iconBefore={(iconProps) => <EditorAddIcon {...iconProps} size="medium" label="" />}
			data-key={completeKey}
			testId="consolidated-create-jira-issue-button"
			onClick={handleCreateIssueItemClicked(
				isSelectionMultipleRows
					? 'MULTIPLE_ISSUE'
					: getIssueCreationType({
							isAIIssueCreationEnabled,
						}),
			)}
			spacing="compact"
		>
			<FormattedMessage
				id="highlight-actions.create-jira-issue"
				defaultMessage="Jira issue"
				description="Create a new Jira issue"
			/>
		</Button>
	);
};

const IssueCreateDropdownMenu = ({
	handleCreateIssueItemClicked,
	showCreateMultipleIssuesButton,
	isAIIssueCreationEnabled,
	isConsolidatedButtonFgEnabled,
	fireMenuToggledAnalytics,
}: {
	handleCreateIssueItemClicked: (issueCreationType: IssueCreationType) => () => void;
	showCreateMultipleIssuesButton: boolean;
	isAIIssueCreationEnabled: boolean;
	isConsolidatedButtonFgEnabled: boolean;
	fireMenuToggledAnalytics: () => void;
}) => {
	return (
		<DropdownMenu
			testId="create-jira-issue-dropdown"
			shouldRenderToParent
			trigger={({ triggerRef, ...props }) => (
				<Button
					{...props}
					id="create-jira-issue-trigger"
					appearance="subtle"
					/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */
					iconBefore={(iconProps) => <EditorAddIcon {...iconProps} size="medium" label="" />}
					iconAfter={(iconProps) => (
						<Box xcss={iconWrapperStyle}>
							{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
							<ChevronDownIcon {...iconProps} size="medium" label="Drop down" />
						</Box>
					)}
					ref={triggerRef}
					spacing="compact"
				>
					<FormattedMessage
						id="highlight-actions.create-jira-issue"
						defaultMessage="Jira issue"
						description="Create a new Jira issue"
					/>
				</Button>
			)}
			onOpenChange={fireMenuToggledAnalytics}
		>
			<DropdownItemGroup>
				<DropdownItem
					onClick={handleCreateIssueItemClicked(
						isConsolidatedButtonFgEnabled
							? getIssueCreationType({ isAIIssueCreationEnabled })
							: IssueCreationType.SINGLE_ISSUE,
					)}
				>
					<FormattedMessage
						id="highlight-actions.single-issue"
						defaultMessage="Create single issue"
						description="Create a single Jira issue"
					/>
				</DropdownItem>
				{showCreateMultipleIssuesButton && (
					<DropdownItem onClick={handleCreateIssueItemClicked(IssueCreationType.MULTIPLE_ISSUE)}>
						<FormattedMessage
							id="highlight-actions.multiple-issues"
							defaultMessage="Multiple issues"
							description="Create multiple Jira issues from a table"
						/>
					</DropdownItem>
				)}
				{isAIIssueCreationEnabled && !isConsolidatedButtonFgEnabled && (
					<DropdownItem onClick={handleCreateIssueItemClicked(IssueCreationType.AI_ISSUE)}>
						<Box xcss={createWithAiDropdownItemStyle}>
							<Box xcss={aiIconStyle}>
								<GreyAtlassianIntelligenceIcon size="large" />
							</Box>
							<FormattedMessage
								id="highlight-actions.use-atlassian-intelligence.description"
								defaultMessage="Create with Atlassian Intelligence"
								description="Create a single Jira issue using Atlassian Intelligence"
							/>
							<Box xcss={betaLozengeStyle}>
								<Lozenge
									style={{
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
										color: 'color.icon',
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
										backgroundColor: 'color.icon.disabled',
									}}
								>
									<FormattedMessage
										id="highlight-actions.use-atlassian-intelligence.beta-lozenge"
										defaultMessage="beta"
										description="Beta lozenge letting the user know that ai features are in beta state"
									/>
								</Lozenge>
							</Box>
						</Box>
					</DropdownItem>
				)}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export const CreateJiraIssueActions = ({
	activeSelection,
	completeKey,
	container,
	contentId,
	contentType,
	lastModified,
	showCreateMultipleIssuesButton,
	setRecordedClick,
	setIsWarningDialogOpen,
}: CreateJiraIssueActionsProps) => {
	const { trackAIInteractionInit } = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const rightSidebarContext = useRightSidebarContext();
	const pressTracing = usePressTracing('highlight-actions.ai-issue-create.dropdown-item-click');
	const [, actions] = useIssueCreateSidePanelContext();
	const isSidePanelOpen = useIsSidePanelOpen();
	const isAIIssueCreationEnabled = useIsAIIssueCreationEnabled();

	const fireCreateIssueButtonClickedAnalytics = useCallback(
		(issueCreationType: IssueCreationType) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'confluenceHighlightActionsMenu',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'jiraIssueLink',
					type: 'ui',
					attributes: {
						issueCreationType,
						isDropdownItem: showCreateMultipleIssuesButton || isAIIssueCreationEnabled,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, showCreateMultipleIssuesButton, isAIIssueCreationEnabled],
	);

	const fireMenuToggledAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'confluenceHighlightActionsMenu',
				action: 'toggled',
				actionSubject: 'menu',
				actionSubjectId: 'createJiraIssue',
				type: 'ui',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const handleCreateIssueItemClicked = useCallback(
		(issueCreationType: IssueCreationType) => () => {
			if (!isSidePanelOpen) {
				switch (issueCreationType) {
					case IssueCreationType.SINGLE_ISSUE:
						fireCreateIssueButtonClickedAnalytics(IssueCreationType.SINGLE_ISSUE);
						createSingleJiraIssueHandle({
							rightSidebarContext,
							actions,
							activeSelection,
							contentId,
							contentType,
							container,
							lastModified,
						});
						break;
					case IssueCreationType.MULTIPLE_ISSUE:
						fireCreateIssueButtonClickedAnalytics(IssueCreationType.MULTIPLE_ISSUE);
						fg('confluence_bulk_issue_creation')
							? createMultipleJiraIssuesHandleModernized({
									rightSidebarContext,
									pressTracing,
									activeSelection,
									actions,
									contentId,
									container,
									lastModified,
								})
							: createMultipleJiraIssuesHandle(container, contentId, lastModified);
						break;
					case IssueCreationType.AI_ISSUE:
						fireCreateIssueButtonClickedAnalytics(IssueCreationType.AI_ISSUE);
						createSingleAiJiraIssueHandle({
							rightSidebarContext,
							actions,
							activeSelection,
							contentId,
							contentType,
							container,
							fireAiAnalytics: trackAIInteractionInit,
							lastModified,
						});
						pressTracing();
						break;
				}
			} else if (fg('modernize-issue-creation-show-warning-dialog')) {
				// ai specific data
				const aiData = {
					contentType,
					fireAiAnalytics: trackAIInteractionInit,
				};
				// record temp data
				const tempData = {
					rightSidebarContext,
					actions,
					activeSelection,
					contentId,
					container,
					lastModified,
					...(issueCreationType === IssueCreationType.AI_ISSUE ? aiData : {}),
				};

				setRecordedClick(tempData);
				setIsWarningDialogOpen(true);
			}
		},
		[
			isSidePanelOpen,
			fireCreateIssueButtonClickedAnalytics,
			rightSidebarContext,
			actions,
			activeSelection,
			contentId,
			contentType,
			container,
			lastModified,
			pressTracing,
			trackAIInteractionInit,
			setRecordedClick,
			setIsWarningDialogOpen,
		],
	);

	const isSelectionMultipleRows = isMultiRowTableSelection(activeSelection);

	if (fg('confluence_single_issue_create_ai_toggle')) {
		// if FG is enabled AI toggle in side panel will set local storage value
		// that determines if AI is used, making dropdown unnecessary
		return isTableSelection(activeSelection) && !isSelectionMultipleRows ? (
			<IssueCreateDropdownMenu
				handleCreateIssueItemClicked={handleCreateIssueItemClicked}
				showCreateMultipleIssuesButton={showCreateMultipleIssuesButton}
				// We explicitly disabled AI issue creation button because we want the user to use the AI toggle in the side panel.
				isAIIssueCreationEnabled={isAIIssueCreationEnabled}
				isConsolidatedButtonFgEnabled={fg('confluence_single_issue_create_ai_toggle')}
				fireMenuToggledAnalytics={fireMenuToggledAnalytics}
			/>
		) : (
			<ConsolidatedCreateJiraIssueButton
				completeKey={completeKey}
				handleCreateIssueItemClicked={handleCreateIssueItemClicked}
				isAIIssueCreationEnabled={isAIIssueCreationEnabled}
				isSelectionMultipleRows={isSelectionMultipleRows}
			/>
		);
	}

	const shouldShowDropdown =
		!isSelectionMultipleRows && (showCreateMultipleIssuesButton || isAIIssueCreationEnabled);

	return shouldShowDropdown ? (
		<IssueCreateDropdownMenu
			handleCreateIssueItemClicked={handleCreateIssueItemClicked}
			showCreateMultipleIssuesButton={showCreateMultipleIssuesButton}
			isAIIssueCreationEnabled={isAIIssueCreationEnabled}
			isConsolidatedButtonFgEnabled={fg('confluence_single_issue_create_ai_toggle')}
			fireMenuToggledAnalytics={fireMenuToggledAnalytics}
		/>
	) : (
		<Button
			id="create-jira-issue-button"
			appearance="subtle"
			iconBefore={(iconProps) => <EditorAddIcon {...iconProps} size="medium" label="" />}
			data-key={completeKey}
			onClick={
				isSelectionMultipleRows
					? handleCreateIssueItemClicked(IssueCreationType.MULTIPLE_ISSUE)
					: handleCreateIssueItemClicked(IssueCreationType.SINGLE_ISSUE)
			}
			spacing="compact"
		>
			<FormattedMessage
				id="highlight-actions.create-jira-issue"
				defaultMessage="Jira issue"
				description="Create a new Jira issue"
			/>
		</Button>
	);
};
