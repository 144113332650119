import { styled } from '@compiled/react';
import type { ComponentProps, FC, RefObject } from 'react';
import React, { createRef } from 'react';
import type { ApolloError } from 'apollo-client';

import { useQuery } from '@atlassian/ufo-apollo-log/use-query';

import type { ContentUnifiedQueryType } from '@confluence/content-unified-query';
import { PageContentRenderer } from '@confluence/content-renderer';
import { DeepLinkHandler } from '@confluence/deep-link-handler';
import { HighlightActions } from '@confluence/highlight-actions';
import { ScrollToHashLinkHandler } from '@confluence/scroll';

import { InlineActionsLoader } from './InlineActionsLoader/InlineActionsLoader';
import { useViewPageParams } from './useViewPageParams';
import type {
	PageStatusQuery as PageStatusQueryType,
	PageStatusQueryVariables,
} from './graphql/__types__/PageStatusQuery';
import { PageStatusQuery } from './graphql/PageStatusQuery.graphql';

const SPA = !process.env.REACT_SSR;

type PassThroughProps =
	| 'eventHandlerOverrides'
	| 'spaceKey'
	| 'error'
	| 'appearance'
	| 'hasInlineComments'
	| 'isSpaceOverview'
	| 'customPerfData';

type ViewPageContentRendererProps = Pick<
	ComponentProps<typeof PageContentRenderer>,
	PassThroughProps
> & {
	contentId: string;
	data?: ContentUnifiedQueryType;
	lastModifiedDate?: string;
	isArchived?: boolean;
	contentStatusError?: ApolloError;
	isEmbeddedPage?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FabricWidthContainer = styled.div({
	width: '100%',
	margin: '0 auto',
});

const ViewPageContentRenderer: FC<ViewPageContentRendererProps> = ({
	contentId,
	data,
	isArchived,
	isEmbeddedPage,
	...passThroughProps
}) => {
	const { versionOverride, embeddedContentRender, queryHash } = useViewPageParams();

	const refOfNodeToWatchForChange: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

	return (
		<div
			id="content"
			data-inline-comments-target="true"
			data-testid="page-content-only"
			// The "page view" classes are included for compatibility with Connect add-ons, see navigator-context.js in the Confluence Backend repo
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="highlighter-context page view"
		>
			<FabricWidthContainer ref={refOfNodeToWatchForChange}>
				{SPA && refOfNodeToWatchForChange && queryHash ? (
					<DeepLinkHandler
						scrollTargetId={queryHash}
						refOfNodeToWatchForChange={refOfNodeToWatchForChange}
					/>
				) : null}

				{SPA && <ScrollToHashLinkHandler anchorContainer={refOfNodeToWatchForChange} />}

				<PageContentRenderer
					{...{
						contentId,
						versionOverride,
						embeddedContentRender,
						queryHash,
						data,
						isArchived,
						isEmbeddedPage,
					}}
					{...passThroughProps}
				/>
			</FabricWidthContainer>
		</div>
	);
};

export type ViewPageContentProps = ViewPageContentRendererProps & {
	hasInlineActions?: boolean;
};

export const ViewPageContent: FC<ViewPageContentProps> = ({
	contentId,
	hasInlineComments,
	hasInlineActions = true,
	data,
	lastModifiedDate,
	...passThroughProps
}) => {
	const { data: contentStatusData, error: contentStatusError } = useQuery<
		PageStatusQueryType,
		PageStatusQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageStatusQuery,
		{
			variables: {
				contentId,
			},
		},
	);

	const isArchived =
		contentStatusData && String(contentStatusData?.content?.nodes?.[0]?.status) === 'archived';

	const isFabric = Boolean(
		data?.content?.nodes?.[0]?.body?.dynamic?.representation === 'atlas_doc_format',
	);

	const viewPageContentRenderer = (
		<>
			<ViewPageContentRenderer
				contentId={contentId}
				data={data}
				lastModifiedDate={lastModifiedDate}
				hasInlineComments={hasInlineComments}
				isArchived={isArchived}
				contentStatusError={contentStatusError}
				{...passThroughProps}
			/>
			{SPA && hasInlineActions && <InlineActionsLoader />}
		</>
	);

	// HighlightActions is only for TinyMCE
	if (isFabric) {
		return viewPageContentRenderer;
	}

	return (
		<HighlightActions
			contentId={contentId}
			hasInlineComments={hasInlineComments}
			lastModifiedDate={lastModifiedDate}
			isArchived={isArchived}
			contentStatusError={contentStatusError}
			isFabric={isFabric}
		>
			{viewPageContentRenderer}
		</HighlightActions>
	);
};
