import { doc, p } from '@atlaskit/adf-utils/builders';

import type { CreateBulkIssuesResponse } from '../__types__/apiUtils';
import type { IssueObject } from '../providers/BulkCreateContextProvider';
import type { ColumnToFieldMapping } from '../providers/BulkConfigureContextProvider';

export const getClosestTableFromSelection = (activeSelection: Selection | undefined | null) => {
	if (activeSelection && activeSelection.rangeCount > 0) {
		// Get the first range from the selection
		const range = activeSelection.getRangeAt(0);
		// Start from the common ancestor container of the range
		let node: Node | null = range.commonAncestorContainer;
		// If the node is a text node, move up to its parent element
		if (node?.nodeType === Node.TEXT_NODE) {
			node = node.parentElement;
		}
		// Traverse up the DOM tree to find the closest <table> element
		while (node && node.nodeType === Node.ELEMENT_NODE) {
			if ((node as HTMLElement).tagName === 'TABLE') {
				return node as HTMLTableElement;
			}
			node = node.parentElement;
		}
	}
	return null;
};

export const mapTableToIssues = (mappings: ColumnToFieldMapping[], table: HTMLTableElement) => {
	let issueIndex = 0;
	const rows = table.rows;
	const issues: IssueObject[] = [];

	const isFirstRowHeader = rows.length > 0 && rows[0].cells[0].tagName.toLowerCase() === 'th';
	// Starting from the second row as we will be using the first row as the header even if it is not a HTML header row
	for (let i = 1; i < rows.length; i++) {
		const issue: IssueObject = {
			id: '',
			summary: '',
			description: undefined,
			issueType: {
				iconUrl: '',
				name: '',
				id: '',
			},
			// Note: the issue insertion API does not count the HTML header row as the first row so we want to shift the index by 1 if the first row is a HTML header row
			rowIndex: i - (isFirstRowHeader ? 1 : 0),
		};
		let emptySummary = true;
		let emptyDescription = true;
		for (const mapping of mappings) {
			const textContent = mapping.column
				? rows[i]?.cells[mapping.column.index]?.textContent?.trim()
				: '';
			if (mapping.field) {
				if (mapping.field.fieldId === 'summary') {
					issue.summary = textContent || 'Untitled';
					emptySummary = !textContent;
				} else if (mapping.field.fieldId === 'description') {
					//The description expects ADF content
					issue.description = doc(p(textContent ?? ''));
					emptyDescription = !textContent;
				} else {
					if (!issue.additionalFields) {
						issue.additionalFields = [];
					}
					issue.additionalFields.push({ field: mapping.field, value: textContent ?? '' });
				}
			}
		}
		if (!emptySummary || !emptyDescription) {
			issue.id = String(issueIndex++);
			issues.push(issue as IssueObject);
		}
	}
	return issues;
};

export const updateIssuesWithRowIndex = (
	result: CreateBulkIssuesResponse,
	rowIndices: number[],
) => {
	const failedElementNumbers = result.errors.map((error) => error.failedElementNumber);
	const updatedRowIndices = rowIndices.filter(
		(_, elementNumber) => !failedElementNumbers.includes(elementNumber),
	);
	return result.issues.map((issue, index) => {
		return {
			...issue,
			rowIndex: updatedRowIndices[index],
		};
	});
};
