import type { ApolloError } from 'apollo-client';
import type { FC } from 'react';
import React, { memo, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import {
	SPACE_OVERVIEW_EXPERIENCE,
	VIEW_PAGE_EXPERIENCE,
	VIEW_ALL_MACRO_EXPERIENCE,
	FRONT_COVER_LOAD_EXPERIENCE,
	ExperienceTimeout,
	routeNameToContentTypeMap,
	// eslint-disable-next-line no-restricted-imports
	getExperienceTracker,
	stopExperienceOnError,
} from '@confluence/experience-tracker';
import { usePageState } from '@confluence/page-context';
import { ContentContainer } from '@confluence/content-container';
import { ContentUnifiedQuery, ContentBlogUnifiedQuery } from '@confluence/content-unified-query';
import type {
	ContentUnifiedQueryType,
	ContentBlogUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
} from '@confluence/content-unified-query';
import { PagePrerequisites } from '@confluence/content-prerequisites';
import { useDocumentUpdateStatusDispatch } from '@confluence/annotation-provider-store';
import { VIEW_PAGE, SPACE_OVERVIEW, COMPANY_HUB } from '@confluence/named-routes';
import { MacroExperienceStart, RENDERER } from '@confluence/macro-tracker';
import { ContentStateContainer } from '@confluence/content-state/entry-points/useContentState';
import {
	ReloadType,
	useQuickReloadSubscriber,
} from '@confluence/quick-reload/entry-points/subscription';
import { useSessionData } from '@confluence/session-data';
import { PageSegmentLoadStart, PageSegmentLoadEnd } from '@confluence/browser-metrics';

import type { BaseViewPageProps } from './BaseViewPageProps';
import { VIEW_PAGE_CONTAINER_METRIC } from './perf.config';

const pageMap = {
	SPACE_OVERVIEW: {
		route: SPACE_OVERVIEW,
		errorAttribution: 'next-space-overview',
		name: SPACE_OVERVIEW_EXPERIENCE,
		isContentView: false,
	},
	COMPANY_HUB: {
		route: COMPANY_HUB,
		errorAttribution: 'next-company-hub',
		name: FRONT_COVER_LOAD_EXPERIENCE,
		isContentView: false,
	},
	page: {
		route: VIEW_PAGE,
		errorAttribution: 'next-view-page',
		name: VIEW_PAGE_EXPERIENCE,
		isContentView: true,
	},
};

type ContentQueryVariablesTimestamp = ContentUnifiedQueryVariablesType & {
	timestamp?: number;
};

interface ViewPageContainerProps extends BaseViewPageProps {
	children?: (props: {
		isThemed: boolean;
		contentQueryData: ContentUnifiedQueryType | ContentBlogUnifiedQueryType | undefined;
		contentQueryError: ApolloError | undefined;
	}) => React.ReactNode | null;
	stickyHeader?: React.ReactNode;
	contentScreenStyles?: React.CSSProperties;
	isContainerContentView?: boolean;
	versionOverride?: number | null;
}

export const ViewPageContainer: FC<ViewPageContainerProps> = memo(
	({
		contentId,
		spaceKey,
		children,
		stickyHeader,
		contentScreenStyles,
		isContainerContentView = true,
		versionOverride = null,
	}) => {
		const { setPublishedDocumentVersion } = useDocumentUpdateStatusDispatch();
		const [{ routeName = '', contentType }] = usePageState();
		const { edition } = useSessionData();
		const isSSR = Boolean(process.env.REACT_SSR || window.__SSR_RENDERED__);

		const extractDataAndSetPublishedVersion = useCallback(
			({ refetchContentQueryResult }) => {
				const { data } = refetchContentQueryResult;
				if (data) {
					const versionNumber = data.content?.nodes?.[0]?.version?.number;
					if (versionNumber !== undefined && versionNumber !== null) {
						setPublishedDocumentVersion(versionNumber);
					}
				}
			},
			[setPublishedDocumentVersion],
		);

		let chooseContentUnifiedQuery = ContentUnifiedQuery;
		if (contentType === 'blogpost') {
			chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
		}

		const contentQueryResult = useQuery<ContentUnifiedQueryType, ContentQueryVariablesTimestamp>(
			chooseContentUnifiedQuery,
			{
				fetchPolicy: 'cache-first',
				context: { single: true },
				skip: !contentId || !spaceKey,
				variables: {
					contentId,
					spaceKey,
					isSSR,
					versionOverride,
					useNewContentTopper: true,
				},
			},
		);

		const { route, errorAttribution, name, isContentView } = pageMap[routeName] ?? pageMap['page'];

		const { refetch } = contentQueryResult;
		const reload = useCallback(
			async () => {
				getExperienceTracker().start({
					name,
					timeout: ExperienceTimeout.PAGE_LOAD,
					attributes: {
						contentType: routeNameToContentTypeMap[routeName],
						contentId,
						isSpaceOverviewNextRendered: true,
						edition,
					},
				});

				try {
					const refetchContentQueryResult = await refetch({
						contentId,
						timestamp: Date.now(),
					});
					extractDataAndSetPublishedVersion({ refetchContentQueryResult });
				} catch (error) {
					stopExperienceOnError(name, error);
					throw error;
				}

				getExperienceTracker().succeed({ name });
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[refetch, contentId],
		);

		useQuickReloadSubscriber({
			name: 'content',
			types: [ReloadType.content, ReloadType.comment],
			reload,
		});

		return (
			<>
				<PageSegmentLoadStart key={contentId} metric={VIEW_PAGE_CONTAINER_METRIC} />
				<MacroExperienceStart
					contentId={contentId}
					mode={RENDERER}
					name={VIEW_ALL_MACRO_EXPERIENCE}
					timeout={ExperienceTimeout.MACRO_LOAD}
				/>
				<PagePrerequisites
					route={route}
					contentQueryResult={contentQueryResult}
					versionOverride={versionOverride}
				>
					<ContentStateContainer scope={contentId}>
						<ContentContainer
							fetchTheme
							contentId={contentId}
							spaceKey={spaceKey}
							key="content-screen"
							errorAttribution={errorAttribution}
							isScreenContentView={isContentView}
							isContainerContentView={isContainerContentView}
							contentScreenStyles={contentScreenStyles}
							stickyHeader={stickyHeader}
							versionOverride={versionOverride}
						>
							{({ isThemed }) => (
								<>
									{children &&
										children({
											isThemed,
											contentQueryData: contentQueryResult.data,
											contentQueryError: contentQueryResult.error,
										})}
									<PageSegmentLoadEnd key={contentId} metric={VIEW_PAGE_CONTAINER_METRIC} />
								</>
							)}
						</ContentContainer>
					</ContentStateContainer>
				</PagePrerequisites>
			</>
		);
	},
);
