import type { ReactNode } from 'react';
import React, { Fragment, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Inline, Grid, Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';
import { ErrorDisplay } from '@confluence/error-boundary';

import { useShowConvertPageToFolder } from './useShowConvertPageToFolder';

const i18n = defineMessages({
	convertToFolderBanner: {
		id: 'folder.convert-page-to-folder.banner.message',
		defaultMessage:
			'Simplify your space by converting parent items to folders. <link>Show me how</link>',
		description:
			'Message on the folder conversion banner that appears on a page. It informs the user that they can convert pages to folders. At the end is a link that when clicked, opens the more actions menu which contains the "Convert to folder" button.',
	},
	bannerClose: {
		id: 'folder.convert-page-to-folder.banner.close',
		defaultMessage: 'Close',
		description:
			'Label for the x icon in the folder conversion banner. Clicking this icon closes the banner',
	},
});

export const BANNER_HEIGHT = 32;

const bannerStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	borderBottom: `1px solid ${token('color.background.discovery.hovered')}`,
	backgroundColor: 'color.background.discovery',
	height: `${BANNER_HEIGHT}px`,
	whiteSpace: 'nowrap',
});

const bannerMessageStyles = xcss({
	gridArea: 'message',
});

const closeButtonStyles = xcss({
	display: 'flex',
	justifySelf: 'end',
	gridArea: 'close',
});

type ConvertPageToFolderBannerProps = {
	contentId: string;
	spaceKey: string;
	onActionButtonClick: () => void;
};

export const ConvertPageToFolderBanner = ({
	contentId,
	spaceKey,
	onActionButtonClick,
}: ConvertPageToFolderBannerProps) => {
	const { formatMessage } = useIntl();

	const { showBanner, hasLoaded, handleOnClickClose, error } = useShowConvertPageToFolder({
		contentId,
		spaceKey,
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (hasLoaded && showBanner) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'rendered',
					actionSubject: 'banner',
					actionSubjectId: 'convertPageToFolderBanner',
					source: 'viewPageScreen',
				},
			}).fire();
		}
	}, [createAnalyticsEvent, hasLoaded, showBanner]);

	return (
		<Fragment>
			{hasLoaded && showBanner && (
				<Grid
					templateColumns="1fr auto 1fr"
					templateAreas={['empty message close']}
					xcss={bannerStyles}
				>
					<Inline
						space="space.100"
						alignInline="center"
						alignBlock="center"
						xcss={bannerMessageStyles}
					>
						<ContentTreeIcon type="folder" label="" spacing="spacious" />
						<FormattedMessage
							{...i18n.convertToFolderBanner}
							values={{
								link: (chunks: ReactNode[]) => (
									<Button appearance="link" spacing="none" onClick={onActionButtonClick}>
										{chunks}
									</Button>
								),
							}}
						/>
					</Inline>
					<Box xcss={closeButtonStyles}>
						<IconButton
							appearance="subtle"
							spacing="compact"
							icon={EditorCloseIcon}
							label={formatMessage(i18n.bannerClose)}
							onClick={handleOnClickClose}
						/>
					</Box>
				</Grid>
			)}
			{error && <ErrorDisplay error={error} />}
		</Fragment>
	);
};
