import { useCallback } from 'react';

import type { AvailableSite } from '@atlassian/gic-anywhere';
import { useGetFirstAvailableSiteDomain } from '@atlassian/gic-anywhere';

import { useSessionData } from '@confluence/session-data';

import {
	ErrorStates,
	useBulkCreateContext,
	useSetError,
} from '../providers/BulkCreateContextProvider';
import { useAvailableSitesActions } from '../providers/BulkConfigureContextProvider';

import { getPersistedOverrideCloudId } from './localStorageHelpers';
import { useBulkCreateLoggingEvents } from './useBulkCreateLoggingEvents';

export const useGetAvailableSites = () => {
	const { setSelectedAvailableSite, setOptionsAvailableSites } = useAvailableSitesActions();
	const { cloudId: sessionCloudId } = useSessionData();

	const persistedOverrideCloudId = getPersistedOverrideCloudId();

	const overrideCloudIdForSitePicker = persistedOverrideCloudId || sessionCloudId;
	const { logRequestFailed } = useBulkCreateLoggingEvents();
	const setError = useSetError();
	const [, actions] = useBulkCreateContext();

	useGetFirstAvailableSiteDomain({
		preferredCloudId: overrideCloudIdForSitePicker,
		onComplete: useCallback(
			({ availableSites }: { availableSites: AvailableSite[] }) => {
				const firstSite = availableSites[0];
				const preferredSite = availableSites.find(
					(site: AvailableSite) => site.cloudId === overrideCloudIdForSitePicker,
				);
				void setSelectedAvailableSite(preferredSite || firstSite);
				void setOptionsAvailableSites(availableSites);
			},
			[setSelectedAvailableSite, setOptionsAvailableSites, overrideCloudIdForSitePicker],
		),
		onError: useCallback(
			(error: Error) => {
				// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
				logRequestFailed({ requestName: 'fetch-jira-site', errorMessage: error.message }, true);
				void setError(ErrorStates.SITE_RETRIEVAL);
				void actions.setManualCreateState('error');
			},
			[logRequestFailed, setError, actions],
		),
	});
};
