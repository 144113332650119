/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop */
import React, { useCallback, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { useIsReducedMotion } from '@atlaskit/motion';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { P300, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { xcss, Box, Anchor, Text } from '@atlaskit/primitives';
import { useChoreographerAssets } from '@confluence/choreographer-services';

const i18n = defineMessages({
	body: {
		id: 'folder-onboarding.convert-to-folder.spotlight.body',
		defaultMessage:
			'Select <strong>Convert to folder</strong> to turn this parent page into a simple container. Read more about the <a>difference between parent pages and folders</a>.',
		description: 'Body text inside the spotlight highlighting the new Convert to Folder',
	},
	dismissButton: {
		id: 'folder-onboarding.convert-to-folder.spotlight.dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Text on the button to click to indicate you are done viewing the spotlight',
	},
});

// Backup outline in case we disable the spotlight pulse for a11y reasons
const targetContainerStyle = xcss({
	borderRadius: '4px',
	outline: `2px solid ${token('color.border.discovery', P300)}`,
});

export interface ConvertToFolderSpotlightProps {
	children?: React.ReactNode;
	onClose?: () => void;
}

export const ConvertToFolderSpotlight = ({ children, onClose }: ConvertToFolderSpotlightProps) => {
	const isReducedMotion = useIsReducedMotion();
	const intl = useIntl();
	const spotlightTargetRef = useRef<HTMLDivElement | null>(null);

	const isClosed = useRef<boolean>(false);
	const handleSpotlightClose = useCallback(() => {
		if (!isClosed.current) {
			isClosed.current = true;
			onClose?.();
		}
	}, [onClose]);

	// Call `onClose()` when this component unmounts if it wasn't already closed in order to catch when the spotlight
	// disappears if users click outside of it. This is important because we need the parent to know when to call `stop()`
	// for the EP message so we don't block other onboarding from appearing for 5 min (the default expiration time)
	useEffect(() => {
		return () => {
			handleSpotlightClose();
		};
	}, [handleSpotlightClose]);

	const { Spotlight } = useChoreographerAssets();

	return (
		<SpotlightManager onBlanketClicked={handleSpotlightClose}>
			<SpotlightTarget name="convert-to-folder-spotlight">
				<Box xcss={targetContainerStyle} ref={spotlightTargetRef}>
					{children}
				</Box>
			</SpotlightTarget>
			<SpotlightTransition>
				<Spotlight
					messageType="engagement"
					messageId="cc-convert-to-folder-onboarding"
					target="convert-to-folder-spotlight"
					targetBgColor={token('color.text.inverse')}
					pulse={!isReducedMotion}
					targetRadius={4}
					dialogPlacement="left bottom"
					dialogWidth={275}
					actions={[
						{
							onClick: () => handleSpotlightClose(),
							text: intl.formatMessage(i18n.dismissButton),
						},
					]}
				>
					<FormattedMessage
						{...i18n.body}
						values={{
							strong: (chunks: React.ReactNode[]) => <Text weight="bold">{chunks}</Text>,
							a: (chunks: React.ReactNode[]) => (
								<Anchor
									href="https://support.atlassian.com/confluence-cloud/docs/use-folders-to-organize-your-work/"
									target="_blank"
									style={{
										color: token('color.text.inverse', N0),
										textDecoration: 'underline',
										outline: 'none',
									}}
								>
									{chunks}
								</Anchor>
							),
						}}
					/>
				</Spotlight>
			</SpotlightTransition>
		</SpotlightManager>
	);
};
