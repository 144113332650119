import type { FC } from 'react';
import React, { memo } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { buildCreationLink } from '@confluence/create-blank-fabric-page';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { START_TOUCH } from '@confluence/navdex';
import { BLOG_SHORTCUT } from '@confluence/shortcuts';
import { CREATE_PAGETREE_BLOG_BUTTON_CLICK } from '@confluence/load-edit-page/entry-points/constants';
import { CreateContentButtonPreloader } from '@confluence/edit-button/entry-points/preloaders';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import { CreateButtonBase } from './CreateButtonBase';
import { canCreateBlog } from './helpers';

const i18n = defineMessages({
	label: {
		id: 'contextual-create.create-blog.label',
		description: 'Label for button that creates a blog in the current space',
		defaultMessage: 'Create a blog',
	},
});

export type Props = {
	spaceKey: string;
};

const CreateBlogButtonComponent: FC<Props> = memo(({ spaceKey }) => {
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireCreationAnalytics = ({ spaceId }) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'contextualCreateButton',
				actionSubjectId: fg('blog_tree_under_content_tree') ? 'blogTree' : 'blogLink',
				source: 'containerNavigation',
				containerType: 'space',
				containerId: spaceId,
				attributes: {
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	};

	const createBlogHref = buildCreationLink({
		contentType: 'blog',
		source: 'spaceLevelBlogContextualCreate',
		spaceKey,
	});

	return (
		<CreateContentButtonPreloader editSource={CREATE_PAGETREE_BLOG_BUTTON_CLICK}>
			<CreateButtonBase
				canCreateContent={canCreateBlog}
				onCreateContent={fireCreationAnalytics}
				href={createBlogHref}
				labelI18n={i18n.label}
				tooltipShortcut={
					isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')
						? undefined
						: BLOG_SHORTCUT
				}
				spaceKey={spaceKey}
				tooltipPosition="top"
				pagesSideNavButtonStyleOverride
			/>
		</CreateContentButtonPreloader>
	);
});

export const CreateBlogButton: FC<Props> = withTransparentErrorBoundary({
	attribution: Attribution.TAILORED_EXPERIENCES,
})(CreateBlogButtonComponent);
