import type { Action, BoundActions } from 'react-sweet-state';
import {
	createActionsHook,
	createHook,
	createStore,
	createContainer,
	createStateHook,
} from 'react-sweet-state';
import { useCallback } from 'react';

import type { DocNode } from '@atlaskit/adf-schema';

import type { AiIssueCreateState } from '@atlassian/ai-issue-create';
import type { AvailableSite } from '@atlassian/gic-anywhere';

import type { InsertStorageFragmentRequestPayload } from '../__types__/InsertStorageFragmentRequestPayload';
import type {
	CreateIssueResponse,
	FieldMetadata,
	JiraProject,
	SupportedValueType,
} from '../__types__/apiUtils';
import type { InsertStorageColumnTableRequestPayload } from '../__types__/InsertStorageColumnTableRequestPayload';
import { transformStargateUrl } from '../utils/configureUtils';
import { messages } from '../messages';

import {
	useAiBulkNavigationGotoPage,
	type AiBulkNavigationPageType,
} from './AiBulkNavigationContextProvider';

export const AiStates = {
	ABORT: 'abort',
	LOADING: 'loading',
	ERROR: 'error',
	IDLE: 'idle',
} as const;

export type ManualCreateStates = 'loading' | 'error' | 'idle';

export const ErrorStates = {
	BULK_CREATION: 'bulk-issue-creation-failed',
	ISSUE_GENERATION: 'ai-issue-generation-failed',
	SITE_RETRIEVAL: 'jira-site-retreival-failed',
	PROJECT_RETRIEVAL: 'jira-project-retreival-failed',
	ISSUE_TYPE_RETRIEVAL: 'issue-type-retreival-failed',
	EMPTY: '',
} as const;

export type ErrorState = (typeof ErrorStates)[keyof typeof ErrorStates];
type NonEmptyErrorState = Exclude<ErrorState, typeof ErrorStates.EMPTY>;
export const ErrorMessageMap: Record<
	NonEmptyErrorState,
	{
		id: string;
		defaultMessage: string;
		description: string;
	}
> = {
	[ErrorStates.BULK_CREATION]: messages.bulkCreateAiErrorDescription,
	[ErrorStates.ISSUE_GENERATION]: messages.issueGenerationErrorDescription,
	[ErrorStates.SITE_RETRIEVAL]: messages.connectJiraProjectErrorDescription,
	[ErrorStates.PROJECT_RETRIEVAL]: messages.connectJiraProjectErrorDescription,
	[ErrorStates.ISSUE_TYPE_RETRIEVAL]: messages.connectJiraProjectErrorDescription,
};

export interface IssueType {
	iconUrl: string;
	name: string;
	id: string;
	description?: string;
	self?: string;
	subtask?: boolean;
}

export interface IssueObject {
	id: string;
	summary: string;
	description?: DocNode;
	issueType: IssueType;
	additionalFields?: AdditionalIssueField[];
	rowIndex?: number;
}

export interface AdditionalIssueField {
	field: FieldMetadata;
	value: SupportedValueType;
}

type IssueTypeFields = {
	requiredFields: FieldMetadata[];
	optionalFields: FieldMetadata[];
};

// Still not fully known what the exact object shape that will be sent to us is
export type BulkIssuesListType = IssueObject[];

export type BulkCreateContextType = {
	bulkIssuesList: BulkIssuesListType;
	contentLastModifiedTimestamp?: string;
	insertStorageFragmentRequestPayload?: InsertStorageFragmentRequestPayload;
	insertStorageColumnTableRequestPayload?: InsertStorageColumnTableRequestPayload;
	aiState: AiIssueCreateState;
	isCreatedWithAi: boolean;
	currentJiraSite: AvailableSite;
	currentJiraProject: JiraProject;
	currentIssueId: string;
	issueTypeHasRequiredFields: boolean;
	singleIssuesCreated: CreateIssueResponse[];
	error: ErrorState;
	issueTypeFields: IssueTypeFields;
	manualCreateState: ManualCreateStates;
};

type State = {
	bulkIssuesList: BulkIssuesListType;
	currentIssueId: string;
	contentLastModifiedTimestamp: string | null | undefined;
	insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload | undefined;
	insertStorageColumnTableRequestPayload: InsertStorageColumnTableRequestPayload | undefined;
	aiState: AiIssueCreateState;
	isCreatedWithAi: boolean;
	currentJiraSite: AvailableSite | undefined;
	currentJiraProject: JiraProject | undefined;
	currentDefaultIssueType: IssueType | undefined;
	issueTypeHasRequiredFields: boolean;
	singleIssuesCreated: CreateIssueResponse[];
	error: ErrorState;
	issueTypeFields: IssueTypeFields;
	manualCreateState: ManualCreateStates;
};

export type IssueCreateSidePanelActions = BoundActions<State, typeof bulkCreateActions>;

const initialBulkCreateState: State = {
	bulkIssuesList: [],
	currentIssueId: '',
	contentLastModifiedTimestamp: undefined,
	insertStorageFragmentRequestPayload: undefined,
	insertStorageColumnTableRequestPayload: undefined,
	aiState: AiStates.IDLE,
	isCreatedWithAi: false,
	currentJiraSite: undefined,
	currentJiraProject: undefined,
	currentDefaultIssueType: undefined,
	issueTypeHasRequiredFields: false,
	singleIssuesCreated: [],
	error: '',
	issueTypeFields: {
		requiredFields: [],
		optionalFields: [],
	},
	manualCreateState: 'idle',
};

const bulkCreateActions = {
	setCurrentIssueId:
		(currentIssueId: State['currentIssueId']): Action<State> =>
		({ setState }) => {
			setState({ currentIssueId });
		},
	setBulkIssuesList:
		(bulkIssuesList: State['bulkIssuesList']): Action<State> =>
		({ setState }) => {
			setState({ bulkIssuesList });
		},
	setIssueTypeFields:
		(issueTypeFields: State['issueTypeFields']): Action<State> =>
		({ setState }) => {
			setState({ issueTypeFields });
		},
	setAiState:
		(aiState: State['aiState']): Action<State> =>
		({ setState, getState }) => {
			const { error } = getState();
			if (error === ErrorStates.ISSUE_GENERATION && aiState == AiStates.LOADING) {
				setState({ aiState, error: ErrorStates.EMPTY });
			} else if (aiState == AiStates.ERROR) {
				setState({ aiState, error: ErrorStates.ISSUE_GENERATION });
			} else {
				setState({ aiState });
			}
		},
	setIsCreatedWithAi:
		(isCreatedWithAi: State['isCreatedWithAi']): Action<State> =>
		({ setState }) => {
			setState({ isCreatedWithAi });
		},
	setContentLastModifiedTimestamp:
		(contentLastModifiedTimestamp: State['contentLastModifiedTimestamp']): Action<State> =>
		({ setState }) => {
			setState({ contentLastModifiedTimestamp });
		},
	setInsertStorageFragmentRequestPayload:
		(insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload): Action<State> =>
		({ setState }) => {
			setState({ insertStorageFragmentRequestPayload });
		},
	setInsertStorageColumnTableRequestPayload:
		(
			insertStorageColumnTableRequestPayload: InsertStorageColumnTableRequestPayload,
		): Action<State> =>
		({ setState }) => {
			setState({ insertStorageColumnTableRequestPayload });
		},
	removeBulkIssue:
		(issueId: string): Action<State> =>
		({ setState, getState }) => {
			const { bulkIssuesList } = getState();
			setState({ bulkIssuesList: bulkIssuesList.filter((issue) => issue.id !== issueId) });
		},
	removeBulkIssueList:
		(issueIds: string[]): Action<State> =>
		({ setState, getState }) => {
			const { bulkIssuesList } = getState();
			setState({
				bulkIssuesList: bulkIssuesList.filter((issue) => !issueIds.includes(issue.id)),
			});
		},
	setSingleIssuesCreated:
		(singleIssue: CreateIssueResponse): Action<State> =>
		({ setState, getState }) => {
			const { singleIssuesCreated } = getState();
			setState({
				singleIssuesCreated: [...singleIssuesCreated, singleIssue],
			});
		},
	resetSingleIssuesCreated:
		(): Action<State> =>
		({ setState }) => {
			setState({
				singleIssuesCreated: initialBulkCreateState.singleIssuesCreated,
			});
		},
	setCurrentJiraSite:
		(currentJiraSite: State['currentJiraSite']): Action<State> =>
		({ setState }) => {
			setState({ currentJiraSite });
		},
	setCurrentJiraProject:
		(currentJiraProject: State['currentJiraProject']): Action<State> =>
		({ setState }) => {
			setState({ currentJiraProject });
		},
	setNextIssueToEdit:
		(goToPage: (page: AiBulkNavigationPageType) => void): Action<State> =>
		({ setState, getState }) => {
			const { bulkIssuesList, currentIssueId } = getState();
			const currentIssueListIndex = bulkIssuesList.findIndex(
				(issue) => issue.id === currentIssueId,
			);
			if (currentIssueListIndex >= bulkIssuesList.length - 1) {
				setState({
					bulkIssuesList: bulkIssuesList.filter((issue) => issue.id !== currentIssueId),
				});
				void goToPage('default');
				return;
			}
			setState({
				currentIssueId: bulkIssuesList[currentIssueListIndex + 1].id,
				bulkIssuesList: bulkIssuesList.filter((issue) => issue.id !== currentIssueId),
			});
		},
	setCurrentDefaultIssueType:
		(currentDefaultIssueType: State['currentDefaultIssueType']): Action<State> =>
		({ setState }) => {
			setState({ currentDefaultIssueType });
		},
	setIssueTypeHasRequiredFields:
		(issueTypeHasRequiredFields: State['issueTypeHasRequiredFields']): Action<State> =>
		({ setState }) => {
			setState({ issueTypeHasRequiredFields });
		},
	setError:
		(error: State['error']): Action<State> =>
		({ setState }) => {
			setState({ error });
		},
	setManualCreateState:
		(manualCreateState: State['manualCreateState']): Action<State> =>
		({ setState }) => {
			setState({ manualCreateState });
		},
};

const Store = createStore({
	initialState: {
		...initialBulkCreateState,
	},
	actions: bulkCreateActions,
	name: 'bulkCreateContext',
});

export const BulkCreateContainer = createContainer(Store);

export const useBulkCreateContext = createHook(Store);

const useActions = createActionsHook(Store);

export const useSetIssueTypeFields = () => {
	const { setIssueTypeFields } = useActions();
	return setIssueTypeFields;
};

export const useSetIsCreatedWithAi = () => {
	const { setIsCreatedWithAi } = useActions();
	return setIsCreatedWithAi;
};

export const useSetCurrentIssueId = () => {
	const { setCurrentIssueId } = useActions();
	return setCurrentIssueId;
};

export const useSetNextIssueToEdit = () => {
	const { setNextIssueToEdit } = useActions();
	const goToPage = useAiBulkNavigationGotoPage();
	return useCallback(() => void setNextIssueToEdit(goToPage), [goToPage, setNextIssueToEdit]);
};

export const useSetIssueTypeHasRequiredFields = () => {
	const { setIssueTypeHasRequiredFields } = useActions();
	return setIssueTypeHasRequiredFields;
};

export const useSetSingleIssuesCreated = () => {
	const { setSingleIssuesCreated } = useActions();
	return setSingleIssuesCreated;
};

export const useResetSingleIssuesCreated = () => {
	const { resetSingleIssuesCreated } = useActions();
	return resetSingleIssuesCreated;
};

export const useGetContentLastModifiedTimestamp = createStateHook(Store, {
	selector: (state: State): string | null | undefined => state.contentLastModifiedTimestamp,
});

export const useSetContentLastModifiedTimestamp = () => {
	const { setContentLastModifiedTimestamp } = useActions();
	return setContentLastModifiedTimestamp;
};

export const useSetInsertStorageFragmentRequestPayload = () => {
	const { setInsertStorageFragmentRequestPayload } = useActions();
	return setInsertStorageFragmentRequestPayload;
};

export const useGetIssueType = createStateHook(Store, {
	selector: (state: State): IssueType => ({
		iconUrl: transformStargateUrl(state.currentDefaultIssueType?.iconUrl || ''),
		name: state.currentDefaultIssueType?.name || '',
		id: state.currentDefaultIssueType?.id || '',
	}),
});

export const useSetError = () => {
	const { setError } = useActions();
	return setError;
};
