import React, { type ReactNode } from 'react';

import ModalCore, { type ModalDialogProps } from '@atlaskit/modal-dialog';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';

import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalTitle } from './ModalTitle';

export interface ModalProps extends ModalDialogProps {
	/**
	 * The normal expectation is that callers provide a string title. The modal will handle default
	 * styling as a heading. But this is typed as a `ReactNode` to allow callers to customise the
	 * title. The modal will still apply its own styling to it, so depending on the particular set of
	 * customisations, your mileage may vary.
	 * See ../../examples/trial-awareness-with-custom-title-example.tsx for an example customisation.
	 */
	title: ReactNode;
	logo?: ReactNode;
	subtitle?: ReactNode;
	children: ReactNode;
	/**
	 * Wrap the actions in a Fragment so that spacing, wrapping, etc are handled by the modal.
	 */
	actions?: ReactNode;
	/**
	 * @deprecated use sideContent and sideLocation instead.
	 */
	right?: ReactNode;
	sideContent?: ReactNode;
	/**
	 * Defaults to the right side
	 **/
	sideLocation?: 'left' | 'right';
}

const container = xcss({
	overflowY: 'auto',
	height: '100%',
	[media.above.sm]: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	},
});

const stack = xcss({
	height: '100%',
	padding: 'space.600',
	overflowY: 'auto',
});

const sideContainer = xcss({
	display: 'none',
	[media.above.sm]: {
		backgroundColor: 'color.background.accent.gray.subtlest',
		display: 'flex',
		width: '100%',
		overflowY: 'auto',
		borderTopRightRadius: 'border.radius',
		borderBottomRightRadius: 'border.radius',
	},
});

export const Modal = ({
	logo,
	title,
	subtitle,
	children,
	actions,
	right,
	sideContent,
	sideLocation = 'right',
	...modalProps
}: ModalProps) => {
	const hasSideContent = !!right || !!sideContent;

	const rightContent = right ?? (sideLocation === 'right' ? sideContent : undefined);
	const leftContent = !right && sideLocation === 'left' ? sideContent : undefined;

	return (
		<ModalCore width={hasSideContent ? 'x-large' : undefined} autoFocus={false} {...modalProps}>
			<Box xcss={hasSideContent && container}>
				{!!leftContent && <Box xcss={sideContainer}>{leftContent}</Box>}

				<Stack space="space.400" xcss={stack}>
					{logo}
					<Stack space="space.200">
						<ModalTitle>{title}</ModalTitle>
						{subtitle ? (
							<Text color="color.text.subtle" size="medium">
								{subtitle}
							</Text>
						) : null}
					</Stack>
					<ModalBody>{children}</ModalBody>
					<ModalFooter>{actions}</ModalFooter>
				</Stack>

				{!!rightContent && <Box xcss={sideContainer}>{rightContent}</Box>}
			</Box>
		</ModalCore>
	);
};
