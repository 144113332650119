import React from 'react';
import { useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N40 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputContainer = styled.input({
	height: '30px',
	padding: token('space.050', '4px'),
	border: `1px solid ${token('color.border', N40)}`,
	borderRadius: '3px',
	boxSizing: 'border-box',
	font: token('font.body'),
	width: '100%',
});

export const LazyCommentEditorPlaceholder = () => {
	const intl = useIntl();
	return (
		<div>
			<InputContainer
				disabled
				placeholder={intl.formatMessage({
					id: 'comment.reply.placeholder',
					defaultMessage: 'Reply',
					description: 'Reply placeholder',
				})}
			/>
		</div>
	);
};
