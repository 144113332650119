import React, { useEffect, useState } from 'react';

import UFOSegment from '@atlaskit/react-ufo/segment';

import {
	NativeCollabLoader,
	NativeCollabPresenceStateContainer,
	fetchCollabPermissionToken,
} from '@confluence/native-collab';
import type { NativeCollabInjectedProps } from '@confluence/native-collab';
import {
	FullPageEditorLoader,
	TreeRootForServerPlaceholderWrapper,
} from '@confluence/full-page-editor';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import { Redirection } from '@confluence/route-manager';
import { VIEW_PAGE } from '@confluence/named-routes';
import { isLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/isLivePagesFeatureEnabled';

export type EditPageLoaderProps = {
	contentId: string;
	spaceKey: string;
	draftShareId: string | undefined;
	contentType: string;
	ClassicEditorContextProviderLoader?: React.ComponentType<any>;
};

const EditPageLoaderInternal = ({
	contentId,
	spaceKey,
	draftShareId,
	contentType,
	ClassicEditorContextProviderLoader,
}: EditPageLoaderProps) => {
	const [prevContentId, setPrevContentId] = useState(contentId);
	const isLivePagesEnabled = isLivePagesFeatureEnabled();

	// This useEffect allows us to unmount and remount the ClassicEditorContextProvider,
	// NativeCollabLoader, and FullPageEditor when switching from one edit page to another edit page.
	// This is necessary to reset the editor properly for edit -> edit transitions.
	useEffect(() => {
		setPrevContentId(contentId);
	}, [contentId]);

	if (contentId !== prevContentId) {
		// isLoading is set to true by a state change, which does not happen immediately after content id changes
		// To make sure loading screen shows up immediately after content id changes, compare new content id with the content id in ref
		// This is smilar to how componentWillUpdate works in a classic react component
		return <FullPageLoadingScreen />;
	}

	const EditPageWrapper =
		ClassicEditorContextProviderLoader ??
		(({ children }) => <div data-testid="edit-page-default-wrapper">{children}</div>);
	return (
		// @ts-ignore - Property 'isWithinEditor' is missing in type '{ children: Element; }' but required in type '{ children: any; isWithinEditor: any; }'
		// This error was introduced after upgrading to TypeScript 5
		<EditPageWrapper>
			<NativeCollabPresenceStateContainer scope={contentId}>
				<NativeCollabLoader
					contentId={contentId}
					spaceKey={spaceKey}
					draftShareId={draftShareId}
					contentType={contentType}
					permissionTokenRefresh={fetchCollabPermissionToken(contentId, draftShareId)}
				>
					{(propsFromNativeCollab: NativeCollabInjectedProps) =>
						propsFromNativeCollab.subType === 'live' &&
						!propsFromNativeCollab.isOverviewPage &&
						isLivePagesEnabled ? (
							<Redirection name={VIEW_PAGE.name} params={{ contentId, spaceKey }} />
						) : (
							// This TreeRootForServerPlaceholderWrapper placed here to ensure the proper lookup of the placeholders for
							// SSRed loadables. Due to different component trees between server (EditPageLoader) and client (EditPageLoaderServer)
							// the placeholder ids hierarchy is different. This wrapper terminates the lookup for placeholder ids on the common parent,
							// so it matches on both server and client.
							<TreeRootForServerPlaceholderWrapper>
								<FullPageEditorLoader
									{...propsFromNativeCollab}
									extensionContextProvider={{
										ClassicEditorContextProvider: ClassicEditorContextProviderLoader,
									}}
								/>
							</TreeRootForServerPlaceholderWrapper>
						)
					}
				</NativeCollabLoader>
			</NativeCollabPresenceStateContainer>
		</EditPageWrapper>
	);
};

export const EditPageLoader: typeof EditPageLoaderInternal = (props) => {
	return (
		<UFOSegment name="edit-page-loader">
			<EditPageLoaderInternal {...props} />
		</UFOSegment>
	);
};
