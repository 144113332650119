import type { FC, RefObject } from 'react';
import React, {
	Component,
	useState,
	useContext,
	useEffect,
	useCallback,
	memo,
	Fragment,
	useRef,
} from 'react';
import { FormattedMessage } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';
import { mergeRefs } from 'use-callback-ref';

import ButtonItem from '@atlaskit/menu/button-item';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import type { ContentProps, TriggerProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import { useOverflowStatus, PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { ExperienceTrackerContext, ExperienceTimeout } from '@confluence/experience-tracker';
import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	useKeyboardShortcutsState,
} from '@confluence/shortcuts';
import { AppNavigationContext } from '@confluence/app-navigation-context';

import { PrimaryItemWrapper } from '../presentationalComponents';

type CloseCurrentPopup = (reason?: string) => void;
const noop = () => {};

export let closeCurrentPopup: CloseCurrentPopup = noop;
export const setCloseCurrentPopup = (closeCallback) => {
	closeCurrentPopup = closeCallback || noop;
};

const stopPopupPropagation = (event) => event.stopPropagation();

export interface ClosePopup {
	closePopup?: (reason?: string | Event | React.MouseEvent | React.KeyboardEvent) => void;
}

type DivTriggerProps = {
	ref:
		| string
		| ((instance: HTMLDivElement | null) => void)
		| RefObject<HTMLDivElement>
		| null
		| undefined;
	'aria-controls'?: string;
	'aria-expanded': boolean;
	'aria-haspopup': boolean;
};

/**
 * Temporary type until all the deprecations get fixed.
 * After the fixes, the `ContentComponent` prop needs to be integrated into the `PrimaryDropdownItemProps` type.
 */
type ContentComponentWithDeprecation =
	| {
			/**
			 * @deprecated Use `ContentComponent` prop instead, which better signifies that this is intended to be a stable reference to a component.
			 */
			content: FC<ClosePopup>;
	  }
	| {
			ContentComponent: FC<ClosePopup>;
	  };

type PrimaryDropdownItemProps = {
	triggerId?: string;
	testId?: string;
	i18n: MessageDescriptor;
	onOpen?: () => void;
	onClose?: () => void;
	onClick?: (e: MouseEvent) => void;
	onHover?: () => void;
	forceOpen?: boolean;
	experienceName: string;
	collapsedTestId?: string;
	keyboardShortcut?: string;
	onShortcutPressed?: (isCollapsed: boolean) => void;
	isAppNavigationShortcut?: boolean;
	isHighlighted?: boolean;
	pendoId?: string;
} & ContentComponentWithDeprecation;

function getContentComponent(props: ContentComponentWithDeprecation): FC<ClosePopup> {
	return 'ContentComponent' in props ? props.ContentComponent : props.content;
}

type PopupProps = {
	triggerId?: string;
	content: FC<ContentProps & ClosePopup>;
	i18n: MessageDescriptor;
	testId: string;
	isOpen: boolean;
	isHighlighted: boolean;
	closePopup: (reason?: string | Event | React.MouseEvent | React.KeyboardEvent) => void;
	onTriggerClick: () => void;
	onHover: () => void;
	keyboardShortcut?: string;
	pendoId?: string;
};

// PrimaryPopup is rendered in the top navigation (not in the "More" menu)
const PrimaryPopup: FC<PopupProps> = ({
	triggerId,
	content,
	i18n,
	testId,
	isOpen,
	isHighlighted,
	closePopup,
	onTriggerClick,
	onHover,
	keyboardShortcut,
	pendoId,
}) => {
	const [areKeyboardShortcutsEnabled] = useKeyboardShortcutsState();
	const { primaryDropdownItemRef } = useContext(AppNavigationContext);
	const Trigger: FC<TriggerProps> = (triggerProps) => {
		const button = (
			// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
			<span data-testid={`${testId}-dropdown`} data-pendo-id={pendoId}>
				<FormattedMessage {...i18n} />
			</span>
		);

		return (
			<PrimaryDropdownButton
				{...triggerProps}
				id={triggerId}
				onClick={onTriggerClick}
				onMouseEnter={onHover}
				isHighlighted={isHighlighted}
				isSelected={isOpen}
				ref={
					primaryDropdownItemRef
						? mergeRefs([triggerProps.ref, primaryDropdownItemRef])
						: triggerProps.ref
				}
			>
				{!!keyboardShortcut ? (
					<Tooltip
						content={
							areKeyboardShortcutsEnabled && <ShortcutVisualizer shortcut={keyboardShortcut} />
						}
						tag="span"
						hideTooltipOnClick
					>
						{button}
					</Tooltip>
				) : (
					button
				)}
			</PrimaryDropdownButton>
		);
	};

	return (
		<Popup
			isOpen={isOpen}
			onClose={closePopup}
			trigger={Trigger}
			placement="bottom-start"
			content={content}
			shouldRenderToParent
		/>
	);
};

// CollapsedPopup is rendered in the "More" menu of top navigation
class CollapsedPopup extends Component<PopupProps> {
	constructor(props) {
		super(props);
		this.renderContent = this.renderContent.bind(this);
		this.renderTrigger = this.renderTrigger.bind(this);
	}

	componentWillUnmount() {
		this.props.closePopup('more menu closed');
	}

	renderContent(contentProps) {
		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<div onClick={stopPopupPropagation}>
				<this.props.content {...contentProps} />
			</div>
		);
	}

	renderTrigger(triggerProps) {
		const { i18n, testId, isOpen, onTriggerClick, onHover, pendoId } = this.props;

		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div {...(triggerProps as DivTriggerProps)} onMouseEnter={onHover}>
				<PrimaryItemWrapper>
					<ButtonItem
						data-pendo-id={pendoId}
						data-testid={testId}
						onClick={onTriggerClick}
						isSelected={isOpen}
						iconAfter={<ChevronRightIcon label="dropdown-chevron" />}
					>
						<FormattedMessage {...i18n} />
					</ButtonItem>
				</PrimaryItemWrapper>
			</div>
		);
	}

	render() {
		const { isOpen, closePopup } = this.props;

		return (
			<Popup
				offset={[12, -11]}
				isOpen={isOpen}
				onClose={closePopup}
				content={this.renderContent}
				trigger={this.renderTrigger}
				placement="right-start"
			/>
		);
	}
}

export const PrimaryDropdownItem: FC<PrimaryDropdownItemProps> = memo(
	({
		triggerId,
		testId: primaryTestId,
		collapsedTestId,
		i18n,
		onOpen,
		onClose,
		onClick,
		onHover,
		experienceName,
		keyboardShortcut,
		onShortcutPressed,
		isHighlighted,
		forceOpen,
		isAppNavigationShortcut,
		pendoId,
		...contentComponentProps
	}) => {
		const [isOpen, setIsOpen] = useState(false || forceOpen);
		const { isVisible } = useOverflowStatus();
		const { transitionId } = useContext(RoutesContext);
		const experienceTracker = useContext(ExperienceTrackerContext);

		const Content: FC<ClosePopup> = getContentComponent(contentComponentProps);

		let PopupComponent, testId;
		if (isVisible) {
			PopupComponent = PrimaryPopup;
			testId = primaryTestId;
		} else {
			PopupComponent = CollapsedPopup;
			testId = collapsedTestId;
		}

		const abortExperienceTracker = useCallback(
			(reason) => {
				experienceTracker.abort({
					name: experienceName,
					reason: `Primary dropdown menu: ${
						typeof reason === 'string' ? reason : 'closed by user'
					}`,
					attributes: {
						navVersion: '3',
					},
				});
			},
			[experienceName, experienceTracker],
		);

		const closePopup = useCallback(
			(reason?: string | Event | React.MouseEvent | React.KeyboardEvent) => {
				if (isOpen) {
					abortExperienceTracker(reason);
					setCloseCurrentPopup(undefined);
					setIsOpen(false);
					onClose && onClose();
				}
			},
			[isOpen, onClose, abortExperienceTracker],
		);

		const openPopup = useCallback(() => {
			if (!isOpen) {
				experienceTracker.start({
					name: experienceName,
					timeout: ExperienceTimeout.NAVIGATION_LOAD,
					attributes: {
						navVersion: '3',
					},
				});
				closeCurrentPopup(`${testId} menu opened`);
				setIsOpen(true);
				onOpen && onOpen();
			}
		}, [isOpen, experienceName, onOpen, testId, experienceTracker]);

		const onTriggerClick = useCallback(
			(e: any) => {
				if (isOpen) {
					closePopup('trigger clicked');
				} else {
					openPopup();
				}
				onClick && onClick(e);
			},
			[openPopup, closePopup, isOpen, onClick],
		);

		const shortcutListener = () => {
			onShortcutPressed && onShortcutPressed(!isVisible);
			if (isVisible) {
				openPopup();
			}
		};

		useEffect(() => {
			if (isOpen) {
				setCloseCurrentPopup(closePopup);
			}
		}, [isOpen, closePopup]);

		const transitionIdRef = useRef<number | null>(null);
		useEffect(() => {
			if (transitionIdRef.current !== null && transitionIdRef.current !== transitionId) {
				closePopup('route changed');
			}
			transitionIdRef.current = transitionId;
		}, [transitionId, closePopup]);

		useEffect(() => {
			return () => {
				abortExperienceTracker('route changed');
			};
		}, [abortExperienceTracker]);

		const content = () => <Content closePopup={closePopup} />;

		return (
			<Fragment>
				<PopupComponent
					triggerId={triggerId}
					content={content}
					testId={testId}
					i18n={i18n}
					keyboardShortcut={keyboardShortcut}
					isOpen={isOpen}
					isHighlighted={isHighlighted}
					closePopup={closePopup}
					onTriggerClick={onTriggerClick}
					onHover={onHover}
					pendoId={pendoId}
				/>
				{keyboardShortcut && (
					<GeneralShortcutListener
						accelerator={keyboardShortcut}
						listener={shortcutListener}
						isAppNavigationShortcut={isAppNavigationShortcut || false}
					/>
				)}
			</Fragment>
		);
	},
);
