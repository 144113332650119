import type { ComponentType } from 'react';
import React from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import PageIcon from '@atlaskit/icon/core/page';
import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import DatabaseIcon from '@atlaskit/icon/core/database';
import FolderIcon from '@atlaskit/icon/core/folder-open';
import PlusSquareIcon from '@atlaskit/icon/core/plus-square';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { isValidEmojiOrFavicon } from '@confluence/emoji-title/entry-points/isValidEmojiOrFavicon';
import type { NewCoreIconProps } from '@atlaskit/icon';

type ContentIconProps = {
	type: string;
	emoji?: string;
	color?: NewCoreIconProps['color'];
};

export const ContentIcon = ({ type, emoji, color }: ContentIconProps) => {
	const intl = useIntl();

	// embed favicon/default content type icon will also be handled by the emoji component
	if (isValidEmojiOrFavicon(emoji, type)) {
		return (
			<LazyEmojiComponentLoader
				emoji={emoji || ''}
				contentType={type}
				context="contentMenu"
				height={16}
				wrapper={({ children }) => <Box xcss={emojiWrapperStyles}>{children}</Box>}
			/>
		);
	}

	const definition = iconDefinitions[type];

	if (!definition) {
		return null;
	}

	const { icon: Icon, label } = definition;

	return <Icon label={intl.formatMessage(label)} color={color} />;
};

const emojiWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	maxWidth: '16px',
	width: '16px',
	height: '16px',
	font: 'font.body.large',
	overflow: 'hidden',
});

const i18n = defineMessages({
	page: {
		id: 'icons.content-icon.label.page',
		defaultMessage: 'Page',
		description: "Label for the 'page' content type icon in the content menu",
	},
	blogpost: {
		id: 'icons.content-icon.label.blogpost',
		defaultMessage: 'Blog',
		description: "Label for the 'blog' content type icon in the content menu",
	},
	whiteboard: {
		id: 'icons.content-icon.label.whiteboard',
		defaultMessage: 'Whiteboard',
		description: "Label for the 'whiteboard' content type icon in the content menu",
	},
	database: {
		id: 'icons.content-icon.label.database',
		defaultMessage: 'Database',
		description: "Label for the 'database' content type icon in the content menu",
	},
	folder: {
		id: 'icons.content-icon.label.folder',
		defaultMessage: 'Folder',
		description: "Label for the 'folder' content type icon in the content menu",
	},
	space: {
		id: 'icons.content-icon.label.space',
		defaultMessage: 'Space',
		description: "Label for the 'space' icon in the content menu",
	},
});

const iconDefinitions: Record<
	string,
	{
		icon: ComponentType<NewCoreIconProps>;
		label: MessageDescriptor;
	}
> = {
	page: {
		icon: PageIcon,
		label: i18n.page,
	},
	blogpost: {
		icon: QuotationMarkIcon,
		label: i18n.blogpost,
	},
	whiteboard: {
		icon: WhiteboardIcon,
		label: i18n.whiteboard,
	},
	database: {
		icon: DatabaseIcon,
		label: i18n.database,
	},
	folder: {
		icon: FolderIcon,
		label: i18n.folder,
	},
	space: {
		icon: PlusSquareIcon,
		label: i18n.space,
	},
};
