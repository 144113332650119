import type { ReactElement, ReactChild } from 'react';
import type { MessageDescriptor } from 'react-intl-next';

import type { Appearance } from '@atlaskit/button/types';

export type LinkProps = {
	actionSubjectId: string;
	appearance?: Appearance;
	ariaLabel?: MessageDescriptor;
	href?: string;
	icon?: ReactChild;
	messageDescriptor: MessageDescriptor;
	onClick?(): void;
	target?: string;
};

export type ChangeEditionSectionMessageButtonsWrapperProps = {
	canShowUpgradeButton?: boolean;
	canSeeStandardUpsellButton?: boolean;
	children(_: {
		showUpgradeTryNowButton: boolean;
		showLearnMore: boolean;
		learnMoreIsPrimaryCTA: boolean;
	}): ReactElement | null;
	isSiteAdmin: boolean;
	secondaryLink?: LinkProps;
};

export const ChangeEditionSectionMessageButtonsWrapper = ({
	canShowUpgradeButton,
	canSeeStandardUpsellButton,
	children,
	isSiteAdmin,
	secondaryLink,
}: ChangeEditionSectionMessageButtonsWrapperProps) => {
	let showUpgradeTryNowButton: boolean = false;
	let showLearnMore: boolean = true;
	let learnMoreIsPrimaryCTA: boolean = true;

	if (canShowUpgradeButton) {
		if (isSiteAdmin || canSeeStandardUpsellButton) {
			showUpgradeTryNowButton = true;
		}

		if (showUpgradeTryNowButton) {
			// NOTE: Don't show 'Learn More' CTA if it will appear in between the
			// Upgrade Edition button and a secondary feature link (like
			// "Remove this page's restrictions" button). In those cases, "Learn
			// More" CTA will be displayed at the end of the general text content,
			// outside of this component.
			// Additionally, for Standard upsell, don't show "Learn More" CTA when
			// there isn't a secondary feature link. This will prevent the "Learn
			// More" CTA from opening the Upflow SPA.
			showLearnMore = !secondaryLink && !canSeeStandardUpsellButton;
			learnMoreIsPrimaryCTA = false;
		}
	}

	return children({
		showUpgradeTryNowButton,
		showLearnMore,
		learnMoreIsPrimaryCTA,
	});
};
