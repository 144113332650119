import React from 'react';
import gql from 'graphql-tag';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import type { ADNode } from '@atlaskit/editor-common/validator';

import { getApolloClient } from '@confluence/graphql';
import type { FlagsStateContainer } from '@confluence/flags';

import { convertPageToLiveEditMutation } from './ConvertToLivePageMutation.graphql';
import { i18n as i18nErrors, getI18nStringForError, processError } from './convertToLivePageErrors';

export type RetrieveEditorTitleAndContent = () => Promise<{
	title?: string | null | undefined;
	adf: ADNode;
	syncRev?: string;
	ncsStepVersion?: string;
}>;

const i18n = defineMessages({
	titleConflictFlagTitle: {
		id: 'live-pages-features.converttolivepage.draft-conversion-title-conflict.title',
		defaultMessage: 'Unable to convert draft to live page',
		description:
			'error title shown to user when their page has the same title as another page, preventing them from converting it',
	},
	genericErrorFlagTitle: {
		id: 'live-pages-features.converttolivepage.generic-error.title',
		defaultMessage: 'An error occurred',
		description:
			'error title shown to user when an unknown error occurs, preventing them from converting it',
	},
});

export const convertDraftToLivePage = async ({
	contentId,
	onSuccess,
	flags,
	retrieveEditorTitleAndContent,
}: {
	contentId: string;
	onSuccess: () => void;
	flags: FlagsStateContainer;
	retrieveEditorTitleAndContent: RetrieveEditorTitleAndContent;
}) => {
	try {
		const { data: mutationData } = await getApolloClient().mutate({
			mutation: convertPageToLiveEditMutation,
			variables: { contentId },
			update: (cache, { data }) => {
				if (data?.convertPageToLiveEditAction?.success) {
					cache.writeFragment({
						id: `Content:${contentId}`,
						fragment: gql`
							fragment SubTypeUpdate on Content {
								subType
							}
						`,
						data: { __typename: 'Content', subType: 'live' },
					});
				}
			},
		});
		const result = mutationData?.convertPageToLiveEditAction;

		if (result?.success) {
			onSuccess();
			return;
		}

		result.errors.forEach(async (error: ApolloError) => {
			processError(error, contentId);

			const flagTitle =
				error.message === 'Title already exists'
					? i18n.titleConflictFlagTitle
					: i18n.genericErrorFlagTitle;
			const flagDescription = getI18nStringForError(error.message);

			const flagDescriptionValues: Record<string, string> = {};
			if (error.message === 'Title already exists') {
				const { title: pageTitle } = await retrieveEditorTitleAndContent();
				flagDescriptionValues.pageTitle = pageTitle || '';
			}

			void flags.showErrorFlag({
				title: <FormattedMessage {...flagTitle} />,
				description: <FormattedMessage {...flagDescription} values={flagDescriptionValues} />,
				isAutoDismiss: false,
			});
		});
	} catch (error) {
		processError(error, contentId);
		void flags.showErrorFlag({
			title: <FormattedMessage {...i18n.genericErrorFlagTitle} />,
			description: <FormattedMessage {...i18nErrors.unknownError} />,
			isAutoDismiss: false,
		});
	}
};

export const i18n_test = i18n;
