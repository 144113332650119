import { getForgeUIExtensionsAsync } from '../../../../provider/getForgeUIExtensionsAsync';
import { type ViewContext } from '../../../iframe/types';

type GetForgeUIExtensionsAsync = typeof getForgeUIExtensionsAsync;

export type TargetURLUtils = {
	getContext: () => Promise<ViewContext>;
	getAppForgeUIExtensions: (
		param: Omit<
			Parameters<GetForgeUIExtensionsAsync>[0],
			'client' | 'contextIds' | 'moduleType' | 'extensionsFilter'
		>,
	) => ReturnType<GetForgeUIExtensionsAsync>;
};

export const getExtensionByModuleKey = async (
	getAppForgeUIExtensions: TargetURLUtils['getAppForgeUIExtensions'],
	moduleKey: string,
) => {
	const { extensions } = await getAppForgeUIExtensions({
		expandAppOwner: false,
	});
	const extension = extensions?.find(({ key }) => key === moduleKey);

	return extension?.type ? extension : null;
};
