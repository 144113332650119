import { LoadableLazy, LoadableAfterPaint } from '@confluence/loadable';

export const LivePagesInProductPromptGlobalBanner = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-live-pages-in-product-prompt-banner" */
				'./in-product-prompt/LivePagesInProductPromptGlobalBannerContainer'
			)
		).LivePagesInProductPromptGlobalBannerContainer,
});

export { ConvertToLivePageDialog } from './convert-to-live-page/LazyConvertToLivePageDialog';

export const ViewPageDistinctionBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ViewPageDistinctionBanner" */ './ViewPageDistinctionBanner'
			)
		).ViewPageDistinctionBanner,
});

export const EditorDistinctionBanner = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorDistinctionBanner" */ './EditorDistinctionBanner'
			)
		).EditorDistinctionBanner,
});

export { useViewPageDistinctionBannerStore } from './ViewPageDistinctionBannerStore';
export { BANNER_HEIGHT as VIEW_PAGE_DISTINCTION_BANNER_HEIGHT } from './ViewPageDistinctionBanner';
