import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { getApolloClient } from '@confluence/graphql';
import {
	CHANGE_PAGE_OWNER_EXPERIENCE,
	CONTENT_OWNERSHIP_DIALOG_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { usePageSpaceKey, usePageContentId, usePageState } from '@confluence/page-context';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useChoreographerAssets } from '@confluence/choreographer-services';

import { i18n } from './i18n';
import { FlexContainer, ModalLabel } from './ContentOwnershipStyles';
import { EXPECTED_ERROR_MESSAGES } from './contentOwnershipConstants';
import { UpdateOwnerMutation } from './queries/UpdateOwnerMutation.graphql';
import { useContentOwnershipContext } from './ContentOwnershipContext';
import { ContentOwnershipDialogUserPicker } from './ContentOwnershipDialogUserPicker';
import { ContentOwnershipPermissionWarning } from './ContentOwnershipPermissionWarning';
import { checkIsUserRestricted, useExperienceTrackerContext } from './content-ownership-helpers';
import type { Owner, Restrictions, SelectedUser } from './types';
import { ChangeOwnerConfirmationModal } from './ChangeOwnerConfirmationModal';
import { useIsSpaceOrSiteAdmin } from './useIsSpaceOrSiteAdmin';
import { ContentOwnerHistoryQuery } from './queries/ContentOwnerHistoryQuery.graphql';
import type {
	ContentOwnerHistoryQuery as ContentOwnerHistoryQueryType,
	ContentOwnerHistoryQueryVariables,
} from './queries/__types__/ContentOwnerHistoryQuery';

const currentOwnerContainer = xcss({
	marginBottom: 'space.100',
});

const photoAndNameContainer = xcss({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	marginBottom: 'space.200',
});

const nameContainer = xcss({
	marginLeft: 'space.075',
});

const ContentOwnershipDialogComponent = ({ flags }: WithFlagsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isLivePage = useIsCurrentPageLive();

	const intl = useIntl();
	const experienceTracker = useExperienceTrackerContext();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const [{ contentType }] = usePageState();
	const { Modal } = useChoreographerAssets();

	const [selectedUser, setSelectedUser] = useState<SelectedUser | undefined>(undefined);
	const [currentOwner, setCurrentOwner] = useState<Owner | undefined>(undefined);
	const [isRequestInFlight, setIsRequestInFlight] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [restrictions, setRestrictions] = useState<Restrictions>({
		isUserRestricted: false,
		isSpaceRestricted: false,
		isParentPageRestricted: false,
		isEditPageRestricted: false,
	});
	const { closeModal } = useContentOwnershipContext();
	const isAdmin = useIsSpaceOrSiteAdmin();
	const { data } = useQuery<ContentOwnerHistoryQueryType, ContentOwnerHistoryQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentOwnerHistoryQuery,
		{
			variables: {
				contentId: contentId!,
			},
			skip: !contentId,
		},
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		let ownerId: string | undefined;
		const ownerData = data?.singleContent?.history?.ownedBy;

		if (ownerData && 'accountId' in ownerData) {
			ownerId = ownerData.accountId ?? undefined;
		}

		const owner = {
			fullName: ownerData?.displayName ?? undefined,
			avatarUrl: ownerData?.profilePicture?.path ?? undefined,
			id: ownerId,
		};
		setCurrentOwner(owner);
	}, [data]);

	useEffect(() => {
		const { isUserRestricted, isSpaceRestricted, isParentPageRestricted } = restrictions;

		if (isUserRestricted || isSpaceRestricted || isParentPageRestricted) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'changeErrorPermissionWarning',
					actionSubject: 'content.owner',
					source: 'changeOwnerModal',
					attributes: {
						...restrictions,
						contentId,
						isAdmin,
						contentType,
					},
				},
			}).fire();
		}
	}, [restrictions, createAnalyticsEvent, contentId, isAdmin, contentType]);

	const resetRestrictions = () => {
		setRestrictions({
			isUserRestricted: false,
			isSpaceRestricted: false,
			isParentPageRestricted: false,
			isEditPageRestricted: false,
		});
	};

	const handleShowConfirmModal = useCallback(() => {
		setShowConfirmModal(true);
	}, []);

	const handleHideConfirmModal = useCallback(() => {
		setShowConfirmModal(false);
	}, []);

	// TODO: The user picker itself doesn't seem to get cleared
	const handleClearSelectedUser = useCallback(() => {
		setSelectedUser(undefined);
		resetRestrictions();
	}, []);

	const handleSelectUser = useCallback(
		async (user: any) => {
			setIsRequestInFlight(true);
			setSelectedUser(user);
			const newRestrictions = await checkIsUserRestricted(contentId, user.id);
			if (newRestrictions) {
				setRestrictions(newRestrictions);
			} else {
				resetRestrictions();
			}
			setIsRequestInFlight(false);
		},
		[contentId],
	);

	const tryUpdateOwner = useCallback(async () => {
		try {
			experienceTracker.start({
				name: CHANGE_PAGE_OWNER_EXPERIENCE,
			});
			setIsRequestInFlight(true);
			const newOwnerId = selectedUser!.id;

			try {
				const mutationResult = await getApolloClient().mutate({
					mutation: UpdateOwnerMutation,
					variables: {
						contentId,
						ownerId: newOwnerId,
					},
				});
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'changeSuccess',
						actionSubject: 'content.owner',
						source: 'changeOwnerModal',
						attributes: {
							contentId,
							isAdmin,
							isLivePage,
							contentType,
						},
					},
				}).fire();
				experienceTracker.succeed({
					name: CHANGE_PAGE_OWNER_EXPERIENCE,
				});
				closeModal();
				void flags.showSuccessFlag({
					id: 'content-ownership-dialog-update-owner-success',
					title: intl.formatMessage(i18n.successFlagMessage, {
						user: mutationResult.data.updateOwner.content.history.ownedBy.displayName,
					}),
					isAutoDismiss: true,
				});
			} catch (error) {
				// if the attempt to update owner fails due to
				// new owner lacking page view / edit permissions
				if (error.message.includes(EXPECTED_ERROR_MESSAGES.USER_MISSING_PAGE_PERMISSION)) {
					experienceTracker.abort({
						name: CHANGE_PAGE_OWNER_EXPERIENCE,
						reason: 'New owner does not have view/edit permission on the page',
					});
					setRestrictions({ ...restrictions, isUserRestricted: true });
				} else {
					experienceTracker.fail({
						name: CHANGE_PAGE_OWNER_EXPERIENCE,
						error,
					});
				}
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'changeError',
						actionSubject: 'content.owner',
						source: 'changeOwnerModal',
						attributes: {
							...error,
							contentId,
							isAdmin,
							isLivePage,
							contentType,
						},
					},
				}).fire();
				void flags.showErrorFlag({
					id: 'content-ownership-dialog-update-owner-failed',
					title: intl.formatMessage(i18n.errorFlagMessage),
					isAutoDismiss: true,
				});
			}
		} finally {
			setIsRequestInFlight(false);
		}
	}, [
		selectedUser,
		contentId,
		closeModal,
		restrictions,
		createAnalyticsEvent,
		isAdmin,
		experienceTracker,
		isLivePage,
		flags,
		intl,
		contentType,
	]);

	const handleConfirmChange = useCallback(async () => {
		setShowConfirmModal(false);
		await tryUpdateOwner();
	}, [tryUpdateOwner]);

	const onChangeButtonHandler = useMemo(() => {
		return isAdmin ? tryUpdateOwner : handleShowConfirmModal;
	}, [isAdmin, tryUpdateOwner, handleShowConfirmModal]);

	if (!spaceKey || !contentId) {
		return null;
	}

	if (!currentOwner) {
		return null;
	}

	return (
		<>
			<Modal
				testId="content-owner-modal"
				onClose={closeModal}
				messageType="transactional"
				messageId="content-ownership-modal"
			>
				<ModalHeader>
					<ModalTitle>{intl.formatMessage(i18n.modalTitleEnabledForAllContent)}</ModalTitle>
					<IconButton
						onClick={closeModal}
						icon={CrossIcon}
						label={intl.formatMessage(i18n.closeModal)}
						appearance="subtle"
					/>
				</ModalHeader>
				<ModalBody>
					<Box xcss={currentOwnerContainer}>
						<ModalLabel>{intl.formatMessage(i18n.modalCurrentOwner)}</ModalLabel>
						<Box xcss={photoAndNameContainer}>
							<Avatar src={currentOwner?.avatarUrl} name={currentOwner?.fullName} size="small" />
							<Box xcss={nameContainer} as="span">
								{currentOwner?.fullName}
							</Box>
						</Box>
					</Box>
					<ModalLabel>{intl.formatMessage(i18n.modalBodyEnabledForAllContent)}</ModalLabel>
					<ContentOwnershipDialogUserPicker
						selectedUser={selectedUser}
						onSelectUser={handleSelectUser}
						onClearSelectedUser={handleClearSelectedUser}
						spaceKey={spaceKey}
						contentId={contentId}
						currentOwner={currentOwner}
					/>
					{restrictions.isUserRestricted && (
						<ContentOwnershipPermissionWarning
							isParentPageRestriction={restrictions.isParentPageRestricted}
							isEditPageRestricted={restrictions.isEditPageRestricted}
							contentId={contentId}
							isSpaceRestricted={restrictions.isSpaceRestricted}
							selectedUser={selectedUser}
							resetRestrictions={resetRestrictions}
						/>
					)}
				</ModalBody>
				<ModalFooter>
					<FlexContainer>
						{/* <HiddenInput autoFocus /> */}
						<Button appearance="subtle" onClick={closeModal}>
							{intl.formatMessage(i18n.modalCancel)}
						</Button>
						<Button
							testId="content-owner-modal-update-owner-button"
							appearance="primary"
							onClick={onChangeButtonHandler}
							isDisabled={!selectedUser || restrictions.isUserRestricted || isRequestInFlight}
						>
							{intl.formatMessage(i18n.modalChangeOwner)}
						</Button>
					</FlexContainer>
				</ModalFooter>
			</Modal>
			{showConfirmModal && (
				<ChangeOwnerConfirmationModal
					onCancel={handleHideConfirmModal}
					onConfirm={handleConfirmChange}
					currentOwner={currentOwner}
					newOwner={{
						fullName: selectedUser!.name,
						avatarUrl: selectedUser!.avatarUrl,
						id: selectedUser!.id,
					}}
				/>
			)}
			<ExperienceSuccess
				name={CONTENT_OWNERSHIP_DIALOG_EXPERIENCE}
				attributes={{
					contentType,
					isLivePage,
				}}
			/>
		</>
	);
};

export const ContentOwnershipDialog = withFlags(({ flags }: WithFlagsProps) => {
	const { isModalOpen } = useContentOwnershipContext();

	if (!isModalOpen) {
		return null;
	}

	return <ContentOwnershipDialogComponent flags={flags} />;
});
