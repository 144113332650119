const UNSAFE_CHARS_REGEXP = /[<>\/\u2028\u2029"]/g;
const ESCAPED_CHARS: { [key: string]: string } = {
	'<': '\\u003C',
	'>': '\\u003E',
	'/': '\\u002F',
	'\u2028': '\\u2028',
	'\u2029': '\\u2029',
	'"': '\\u0022',
};

export function sanitizeDataForSSR(data: string | object) {
	const serialized = typeof data === 'string' ? data : JSON.stringify(data);

	// We cannot use serialize-javascript directly because it imports randombytes which relies on
	// a real browser or nodejs Here we are running scripts in JSDOM which makes randombytes to
	// think it is running in a real browser. It resulted "Error: Secure random number generation
	// is not supported by this browser. Use Chrome, Firefox or Internet Explorer 11":
	// https://github.com/yahoo/serialize-javascript/blob/main/index.js#L210
	return serialized.replace(UNSAFE_CHARS_REGEXP, (char) => ESCAPED_CHARS[char]);
}
