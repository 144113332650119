import React from 'react';
import type { FC } from 'react';
import { useIntl, type MessageDescriptor } from 'react-intl-next';

import { Text, Stack, xcss } from '@atlaskit/primitives';

import { EmptyUnreadComponent } from './EmptyUnreadComponent';

const emptyContainerStyles = xcss({
	alignItems: 'center',
});

type EmptyCommentsProps = {
	message?: MessageDescriptor;
	isUnreadView?: boolean;
};

export const EmptyComments: FC<EmptyCommentsProps> = ({ message, children, isUnreadView }) => {
	const { formatMessage } = useIntl();

	if (isUnreadView) {
		return <EmptyUnreadComponent />;
	}

	return (
		<Stack xcss={emptyContainerStyles} testId="empty-comments-panel-view">
			{message ? <Text color="color.text.subtle">{formatMessage(message)}</Text> : children}
		</Stack>
	);
};
