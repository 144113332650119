import { useCallback } from 'react';

import {
	useGetOnboardingState,
	deserializeState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';

const SPACE_GETTING_STARTED_TOUR_PREFIX = 'space-getting-started-tour';

export const SPACE_GETTING_STARTED_TOUR_EXPERIENCES = {
	IS_DISMISSED: `${SPACE_GETTING_STARTED_TOUR_PREFIX}:tourIsDismissed`,
};

interface UseSpaceGettingStartedTourOnboardingStateProps {
	skip?: boolean;
}

export const useSpaceGettingStartedTourOnboardingState = ({
	skip = false,
}: UseSpaceGettingStartedTourOnboardingStateProps = {}) => {
	const { setOnboardingState } = useSetOnboardingState();

	const setIsDismissed = useCallback(
		(isDismissed: boolean) => {
			setOnboardingState({
				key: SPACE_GETTING_STARTED_TOUR_EXPERIENCES.IS_DISMISSED,
				value: isDismissed ? 'true' : 'false',
			});
		},
		[setOnboardingState],
	);

	const {
		data,
		loading: getOnboardingStateLoading,
		error: getOnboardingStateError,
	} = useGetOnboardingState(Object.values(SPACE_GETTING_STARTED_TOUR_EXPERIENCES), skip);

	const { tourIsDismissed } = deserializeState(data);

	return {
		isDismissed:
			skip || getOnboardingStateLoading || getOnboardingStateError
				? true
				: tourIsDismissed === true,
		loading: getOnboardingStateLoading,
		error: getOnboardingStateError,
		setIsDismissed,
	};
};
