import { isLivePagesFeatureEnabled } from './isLivePagesFeatureEnabled';
import { useIsCurrentPageLiveRaw } from './LivePagesStore';

/**
 * Checks that current page is a live page and that live pages feature flag is enabled
 * @returns boolean
 */

/**
 * Returns if the current or most recently viewed page is of subtype live.
 * To check if live pages as a feature is enabled, use `isLivePagesFeatureEnabled`.
 * @returns { boolean } if the current or most recently viewed page is of subtype live
 */
export const useIsCurrentPageLive = (): boolean => {
	const isLivePagesEnabled = isLivePagesFeatureEnabled();
	const [isLive] = useIsCurrentPageLiveRaw();
	return isLivePagesEnabled && isLive;
};
