import { EmojiResource } from '@atlaskit/emoji/resource';

import { getEmojiProvider as fabricGetEmojiProvider } from '@confluence/fabric-providers';

import { EMOJI_TITLE_PUBLISHED_FIELD } from './useEmojiStatusFieldName';
import type {
	getEmojiTitleProperty_content_nodes_emojiTitlePublished_nodes,
	getEmojiTitleProperty as getEmojiTitlePropertyType,
} from './emoji-title/__types__/getEmojiTitleProperty';
import type { EmojiStatusFieldName } from './__types__/EmojiStatusFieldName';

type EmojiTitleProperty<TContentNode> = {
	content: {
		nodes: (TContentNode | null)[] | null;
	} | null;
	singleContent?: TContentNode | null;
	publicLinkInformation?: TContentNode | null;
};

type EmojiTitlePublishedPropertyNode<
	TEmojiNode = getEmojiTitleProperty_content_nodes_emojiTitlePublished_nodes,
> = {
	id: string | null;
	emojiTitlePublished: {
		nodes: (TEmojiNode | null)[] | null;
	} | null;
};

export const getVersionNumber = (
	data: getEmojiTitlePropertyType,
	emojiFieldName: EmojiStatusFieldName,
) => data?.content?.nodes?.[0]?.[emojiFieldName]?.nodes?.[0]?.version?.number || 0;

export const getEmojiId = (data: getEmojiTitlePropertyType, emojiFieldName: EmojiStatusFieldName) =>
	(data?.content?.nodes?.[0]?.[emojiFieldName]?.nodes?.[0]?.value || '').replace(/['"]+/g, '');

export const sanitizeEmojiValue = (emojiValue: string | null | undefined) =>
	(emojiValue || '').replace(/['"]+/g, '');

export type EmojiProperty = {
	propertyId: string;
	value: string;
	versionNumber: number;
};

type EmojiPropertyType = getEmojiTitleProperty_content_nodes_emojiTitlePublished_nodes;

const transformProperty = (property: EmojiPropertyType | undefined): EmojiProperty | undefined => {
	if (property) {
		return {
			propertyId: property?.id ?? '',
			value: (property?.value || '').replace(/['"]+/g, '').replace(/null/, ''),
			versionNumber: property?.version?.number || 0,
		};
	}
};

type ObjectWithEmojiProperties = {
	properties?: { nodes?: EmojiPropertyType[] } | null;
} | null;

const findPropertyByKey = (object: ObjectWithEmojiProperties, key: string) =>
	transformProperty(object?.properties?.nodes?.find((node) => node.key === key));

export const getEmojiPublishedProperty = (object: ObjectWithEmojiProperties) =>
	findPropertyByKey(object, 'emoji-title-published');

export const getEmojiDraftProperty = (object: ObjectWithEmojiProperties) =>
	findPropertyByKey(object, 'emoji-title-draft');

export const getEmojiFromPublishedPageProperties = (page: ObjectWithEmojiProperties) => {
	const values = page?.properties?.nodes?.filter((node) => node.key === 'emoji-title-published');
	return (values?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');
};

export const getEmojiFromDraftPageProperties = (page: ObjectWithEmojiProperties) => {
	const values = page?.properties?.nodes?.filter((node) => node.key === 'emoji-title-draft');
	return (values?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');
};

export const getPublishedEmojiId = (
	data:
		| EmojiTitleProperty<
				EmojiTitlePublishedPropertyNode<
					Pick<getEmojiTitleProperty_content_nodes_emojiTitlePublished_nodes, 'value'>
				>
		  >
		| undefined
		| null,
) =>
	(
		(data?.publicLinkInformation || data?.content?.nodes?.[0] || data?.singleContent)
			?.emojiTitlePublished?.nodes?.[0]?.value || ''
	).replace(/['"]+/g, '');

export const getSavedPublishedEmoji = (data: EmojiTitleProperty<EmojiTitlePublishedPropertyNode>) =>
	data?.content?.nodes?.[0]?.emojiTitlePublished?.nodes?.[0];

type EmojiNodesContent = {
	emoji?: {
		nodes?: Array<{
			value?: string | null;
			key?: string | null;
		} | null> | null;
	} | null;
};
type EmojiDraftNodesContent = {
	emojiDraft?: {
		nodes?: Array<{
			value: string;
			key: string;
		}>;
	};
};

export const getEmojiValueFromContent = (content: EmojiNodesContent) =>
	(content?.emoji?.nodes?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');

export const getEmojiDraftValueFromContent = (content: EmojiDraftNodesContent) =>
	(content?.emojiDraft?.nodes?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');

export const getEmojiDraftFromProperties = (content: EmojiNodesContent) => {
	const values = content?.emoji?.nodes?.filter((node) => node?.key === 'emoji-title-draft');

	return (values?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');
};

export const getEmojiPublishedFromProperties = (content: EmojiNodesContent) => {
	const values = content?.emoji?.nodes?.filter((node) => node?.key === 'emoji-title-published');

	return (values?.[0]?.value || '').replace(/['"]+/g, '').replace(/null/, '');
};

export const getHasEmoji = (
	data: getEmojiTitlePropertyType,
	emojiFieldName: EmojiStatusFieldName,
) => {
	const nodes = (data?.publicLinkInformation || data?.content?.nodes?.[0] || data?.singleContent)?.[
		emojiFieldName
	]?.nodes?.[0];

	return !!nodes?.value && nodes?.value !== '""' && nodes?.value !== '"null"';
};

export const getHasEmojiPublished = (data: getEmojiTitlePropertyType) =>
	getHasEmoji(data, EMOJI_TITLE_PUBLISHED_FIELD);

export const getEmojiProvider = async (cloudId: string, userId: string) => {
	return await fabricGetEmojiProvider(EmojiResource, {
		cloudId,
		userId,
	});
};
