import React, { useEffect, useMemo } from 'react';
import { defineMessages } from 'react-intl-next';

import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useCommentsData, useActiveInlineCommentsQuery } from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';

import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';
import { EmptyComments } from '../components/EmptyComments';
import { CommentsPanelList } from '../components/CommentsPanelList';
import { getUnreadCommentThreads } from '../helper/commentsPanelHelper';
import { useCommentsPanel, CurrentView } from '../hooks/useCommentsPanel';

type UnreadViewProps = {
	showDeleteOption: boolean;
};

const i18n = defineMessages({
	noUnreadCommentsText: {
		id: 'comments-panel.unread.view.empty.text',
		defaultMessage: 'There are no unread comments.',
		description: 'Text to display in Unread View when there are no unread comments for the page.',
	},
});

export const UnreadView = ({ showDeleteOption }: UnreadViewProps) => {
	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const [{ orderedActiveAnnotationIdList, inlineCommentsDataMap, removedCommentIdsMap }] =
		useCommentsData();
	const [{ initialDataLoadedForViewMap }, { setInitialDataLoadedForView }] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[CurrentView.UNREAD];

	const [contentId] = usePageContentId();

	const unreadAnnotationsToLoad = useMemo(() => {
		const unreadCommentsMarkerRefs = new Set([
			...unreadCommentsListState.map((unreadComment) => unreadComment.inlineMarkerRef),
		]);
		// Filter and map to get the annotation IDs that need to be loaded
		return orderedActiveAnnotationIdList
			.filter(
				(annotation) =>
					!annotation.isLoaded && unreadCommentsMarkerRefs.has(annotation.annotationId),
			)
			.map((annotation) => annotation.annotationId);
	}, [unreadCommentsListState, orderedActiveAnnotationIdList]);

	const { loading, error, refetch } = useActiveInlineCommentsQuery({
		pageId: contentId || '',
		markerRefList: unreadAnnotationsToLoad,
		skip: unreadAnnotationsToLoad.length === 0,
		orderedActiveAnnotationIdList,
	});

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: CurrentView.UNREAD });
		}
	}, [loading, setInitialDataLoadedForView]);

	// TODO needs improvement: getting the unread comment threads here when the unread view is opened means that
	// the user might be seeing unread comments that they previously read suddenly disappear
	// since we're marking comments as read only when the user changes views
	const commentThreads = useMemo(() => {
		if (
			(!isInitialCommentDataLoaded && loading) ||
			orderedActiveAnnotationIdList.length === 0 ||
			unreadCommentsListState.length === 0
		) {
			return [];
		}

		return getUnreadCommentThreads({
			numUnreadComments: unreadCommentsListState.length,
			inlineCommentsDataMap,
			orderedActiveAnnotationIdList,
			removedCommentIdsMap,
		});
	}, [
		loading,
		isInitialCommentDataLoaded,
		inlineCommentsDataMap,
		orderedActiveAnnotationIdList,
		removedCommentIdsMap,
		unreadCommentsListState.length,
	]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <Loading />;
		}

		if (error) {
			return (
				<ErrorPanel
					error={error}
					onRetryClick={async () => {
						await refetch();
					}}
				/>
			);
		}
	}

	if (commentThreads.length === 0) {
		return <EmptyComments message={i18n.noUnreadCommentsText} isUnreadView />;
	}

	return (
		<CommentsPanelList
			commentThreads={commentThreads}
			supportedTopLevelActions={
				showDeleteOption ? ['edit', 'resolve', 'delete'] : ['edit', 'resolve']
			}
		/>
	);
};
