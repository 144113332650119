import React, { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss, Text, Inline } from '@atlaskit/primitives';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import { token } from '@atlaskit/tokens';

import type { ReplyData } from '@confluence/comments-data';

import { ViewReplyOptions } from '../helper/commentThreadHelper';

const replyOpenerStyles = xcss({
	cursor: 'pointer',
	userSelect: 'none',
	display: 'flex',
	alignItems: 'center',
});

const unreadIndicatorStyle = xcss({
	display: 'flex',
	alignItems: 'center',
});

const defaultDisplayCount = 3;

const i18n = defineMessages({
	commentsPanelHideRepliesText: {
		id: 'comments-panel.comment-thread.hide.replies',
		defaultMessage: 'Hide replies',
		description: 'Text for hiding replies',
	},
	commentsPanelShowMoreRepliesText: {
		id: 'comments-panel.comment-thread.show.more.replies',
		defaultMessage: `Show {numOfMoreRepliesToDisplay, plural, one {# more reply} other {# more replies}}`,
		description: 'Text for showing more replies',
	},
});

export const RepliesOpener = ({
	replies,
	setRepliesToDisplay,
	unreadReplies,
	setReplyView,
	currentReplyView,
}: {
	replies: ReplyData[];
	setRepliesToDisplay: React.Dispatch<React.SetStateAction<ReplyData[]>>;
	unreadReplies: ReplyData[];
	setReplyView: (option: ViewReplyOptions) => void;
	currentReplyView: ViewReplyOptions;
}) => {
	const { formatMessage } = useIntl();

	const numReplies = replies.length;
	const numOfMoreRepliesToDisplay = useRef(numReplies);

	const handleReplyViewClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		// if the current view is unread or it's hidden, then show all
		if (
			(replies.length > defaultDisplayCount && currentReplyView === ViewReplyOptions.DEFAULT) ||
			currentReplyView === ViewReplyOptions.HIDDEN
		) {
			setReplyView(ViewReplyOptions.ALL);
			setRepliesToDisplay(replies);
		}

		// if it's already showing all, then hide
		if (currentReplyView === ViewReplyOptions.ALL) {
			setReplyView(ViewReplyOptions.HIDDEN);
			setRepliesToDisplay([]);
		}
	};

	useEffect(() => {
		// if there are unread replies, then we want to show up to 3
		if (unreadReplies.length > 0) {
			const unreadRepliesToShow = unreadReplies.slice(
				-Math.min(unreadReplies.length, defaultDisplayCount),
			);
			setRepliesToDisplay(unreadRepliesToShow);

			// the number of more replies to display depends on the number of unread replies to show, but only if
			// there are more replies than the threshold
			if (numReplies > defaultDisplayCount) {
				numOfMoreRepliesToDisplay.current = replies.length - unreadRepliesToShow.length;
			}

			if (unreadReplies.length > defaultDisplayCount || replies.length > unreadReplies.length) {
				setReplyView(ViewReplyOptions.DEFAULT);
			} else {
				setReplyView(ViewReplyOptions.ALL);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to set the display view here on first open
	}, []);

	// This useEffect makes new comment replies or edited changes show up immediately in the comments panel when saved
	useEffect(() => {
		if (currentReplyView === ViewReplyOptions.ALL) {
			setRepliesToDisplay(replies);
		} else {
			if (unreadReplies.length > 0) {
				const unreadRepliesToDisplay = unreadReplies.slice(
					-Math.min(unreadReplies.length, defaultDisplayCount),
				);
				setRepliesToDisplay(unreadRepliesToDisplay);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numReplies, replies]);

	const showMoreReplies =
		currentReplyView === ViewReplyOptions.HIDDEN ||
		(currentReplyView === ViewReplyOptions.DEFAULT && replies.length > defaultDisplayCount);
	const hideReplies = currentReplyView === ViewReplyOptions.ALL;

	return (
		<Box
			testId="comments-panel-reply-opener-container"
			xcss={replyOpenerStyles}
			onClick={handleReplyViewClick}
		>
			{showMoreReplies && (
				<Inline space="space.050" alignInline="center" alignBlock="center">
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
					<ChevronDownIcon spacing="compact" label="show-more-replies-button" />
					<Text color="color.text" size="medium">
						{formatMessage(i18n.commentsPanelShowMoreRepliesText, {
							numOfMoreRepliesToDisplay: numOfMoreRepliesToDisplay.current,
						})}
					</Text>
					{unreadReplies.length > defaultDisplayCount && (
						<Box
							xcss={unreadIndicatorStyle}
							testId="comments-panel-unread-comment-replies-indicator"
						>
							{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
							<PresenceActiveIcon
								size="small"
								primaryColor={token('color.icon.brand', '#0052CC')}
								label=""
							/>
						</Box>
					)}
				</Inline>
			)}

			{hideReplies && (
				<Inline space="space.050" alignInline="center" alignBlock="center">
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
					<ChevronUpIcon spacing="compact" label="hide-replies-button" />
					<Text color="color.text" size="medium">
						{formatMessage(i18n.commentsPanelHideRepliesText)}
					</Text>
				</Inline>
			)}
		</Box>
	);
};
