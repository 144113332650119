import React, { type FC } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

import { gridSize } from '@atlaskit/theme/constants';

import type { PanelName } from './PanelName';

export const CONTENT_RIGHT_PADDING = 40;
// We will always reset the panel to the default size when we close it
export const MIN_PANEL_WIDTH = 400;
export const defaultPanelWidth = Math.max(MIN_PANEL_WIDTH, gridSize() * 46) + CONTENT_RIGHT_PADDING;

export type ObjectSidebarControlState = 'shown' | 'hidden';
export type ObjectSidebarBehavior = 'push' | 'cover';
export type ObjectSidebarPanelCloseOptions = {
	canClosePanel?: () => boolean; // Returns true if panel can be closed
	onPanelClose?: () => void;
};
export type ObjectSidebarPanel = {
	id: PanelName;
	headerComponentElements: {
		HeaderIcon?: FC | (() => React.ReactElement<any, any> | null);
		headerLabel: MessageDescriptor;
		HeaderAfterElement?: FC | (() => React.ReactElement<any, any> | null);
	};
	BodyComponent: FC | (() => React.ReactElement<any, any> | null);
	FooterComponent?: FC | (() => React.ReactElement<any, any> | null);
	closeOptions?: ObjectSidebarPanelCloseOptions;
};

export type ObjectSidebarState = {
	isObjectSidebarShown: boolean;
	behavior: ObjectSidebarBehavior;
	panelWidth: number;
	panel?: ObjectSidebarPanel;
	sidebarControlState: ObjectSidebarControlState;
};

export const initialState: ObjectSidebarState = {
	isObjectSidebarShown: false,
	behavior: 'push',
	panelWidth: defaultPanelWidth,
	panel: undefined,
	sidebarControlState: 'shown',
};

export const actions = {
	showObjectSidebar:
		(panel: ObjectSidebarPanel, behavior?: ObjectSidebarBehavior, panelWidth?: number) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const {
				isObjectSidebarShown,
				panel: currentPanel,
				behavior: currentBehavior,
				panelWidth: currentPanelWidth,
			} = getState();

			// Check if panel is open
			if (isObjectSidebarShown) {
				// If it's the same panel do nothing
				if (currentPanel?.id === panel.id) {
					return;
				}

				// Otherwise change it
				setState({
					panel,
					behavior: behavior ?? currentBehavior,
					panelWidth: panelWidth ?? currentPanelWidth,
				});
			}

			// Set the panel to be open
			setState({
				isObjectSidebarShown: true,
				panel,
				behavior: behavior ?? currentBehavior,
				panelWidth: panelWidth ?? currentPanelWidth,
				sidebarControlState: 'hidden',
			});

			// Close the global panel
			window.dispatchEvent(new Event('closeGlobalSidebar'));
		},
	hideObjectSidebar:
		() =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel } = getState();

			// If we're not showing a panel, don't do anything
			if (!isObjectSidebarShown || !panel) {
				return;
			}

			// If the user has provided closeOptions check them
			if (panel.closeOptions) {
				const { canClosePanel, onPanelClose } = panel.closeOptions;

				// If the panel closing check returns false, do nothing
				if (canClosePanel && !canClosePanel()) {
					return;
				}

				// Call the onClose callback
				onPanelClose && onPanelClose();
			}

			// Close the panel
			setState({
				isObjectSidebarShown: false,
				panel: undefined,
				panelWidth: defaultPanelWidth,
				sidebarControlState: 'shown',
			});
		},
	setPanelWidth:
		(newPanelWidth: number) =>
		({ setState }: StoreActionApi<ObjectSidebarState>) => {
			setState({ panelWidth: Math.max(newPanelWidth, MIN_PANEL_WIDTH) + CONTENT_RIGHT_PADDING });
		},
	changePanelBehavior:
		(newBehavior: ObjectSidebarBehavior) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { panelWidth } = getState();

			// When we set the new state, if it's set to cover, we no longer have a
			// component that will size the panel accordingly so we just use the default
			setState({
				behavior: newBehavior,
				panelWidth: newBehavior === 'cover' ? defaultPanelWidth : panelWidth,
			});
		},
	// TODO: Do we need to specifically change Header, Body, and Footer components individually?
	changePanel:
		(newPanel: ObjectSidebarPanel, newBehavior?: ObjectSidebarBehavior, newPanelWidth?: number) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel, behavior, panelWidth } = getState();

			// Only change panels if the panel is open
			if (isObjectSidebarShown && panel) {
				// If it's the same panel do nothing
				if (panel.id === newPanel.id) {
					return;
				}

				// If the user has provided closeOptions check them
				if (panel.closeOptions) {
					const { canClosePanel, onPanelClose } = panel.closeOptions;

					// If the panel closing check returns false, do nothing
					if (canClosePanel && !canClosePanel()) {
						return;
					}

					// Call the onClose callback
					onPanelClose && onPanelClose();
				}

				setState({
					panel: newPanel,
					behavior: newBehavior ?? behavior,
					panelWidth: newPanelWidth ?? panelWidth,
				});
			}

			// Otherwise do nothing
		},
};

export type ObjectSidebarActions = typeof actions;

export const ObjectSidebarStore = createStore({
	initialState,
	actions,
	name: 'ObjectSidebarStore',
});

export const useObjectSidebar = createHook(ObjectSidebarStore);
export const useObjectSidebarActions = createActionsHook(ObjectSidebarStore);
export const useObjectSidebarState = createStateHook(ObjectSidebarStore);
