import { useEffect, useState } from 'react';

import { di } from 'react-magnetic-di';

import { type AvailableSite, getAvailableJiraSites } from '../../common/utils';

import { type GetFirstAvailableSiteDomainProps } from './types';

export const useGetFirstAvailableSiteDomain = ({
	preferredCloudId,
	onComplete,
	onError,
}: GetFirstAvailableSiteDomainProps) => {
	di(getAvailableJiraSites);
	const [domain, setDomain] = useState<string | undefined>(undefined);
	const [availableSites, setAvailableSites] = useState<AvailableSite[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		const fetchAvailableJiraSites = async () => {
			setLoading(true);
			try {
				const { sites } = await getAvailableJiraSites();
				setAvailableSites(sites ?? []);
				const computedDomain =
					(sites.find((site) => site.cloudId === preferredCloudId) ?? sites[0])?.url ?? '';
				setDomain(computedDomain);
				onComplete?.({ domain: computedDomain, availableSites: sites });
			} catch (e: Error | unknown) {
				onError && onError(e as Error);
				setError((e as Error).message);
			} finally {
				setLoading(false);
			}
		};
		void fetchAvailableJiraSites();
	}, [preferredCloudId, onComplete, onError]);

	return { domain, availableSites, loading, error };
};
