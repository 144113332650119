import type { FC } from 'react';
import React, { useCallback, Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Tooltip from '@atlaskit/tooltip/Tooltip';
import { ButtonItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ShortcutEvent } from '@confluence/shortcuts';

import { SpaceWatchMutation, SpaceUnwatchMutation } from '../ActionButtonMutations.graphql';
import { FilledIconButton, UnfilledIconButton } from '../PresentationalComponents';
import type { AnalyticsOptions, StarMutationArgs } from '../SpaceStar/SpaceStar';
import { WatchIcon } from '../WatchIcon';

type SpaceWatchButtonProps = {
	spaceId: string;
	isWatched: boolean;
	onWatch?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	onUnwatch?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	analytics?: AnalyticsOptions;
	inMenu?: boolean;
	legacy?: boolean;
};

export const SpaceWatchButton: FC<SpaceWatchButtonProps> = ({
	spaceId,
	isWatched,
	onWatch,
	onUnwatch,
	analytics,
	inMenu = false,
	legacy,
}) => {
	const intl = useIntl();

	const [watchSpace] = useMutation<StarMutationArgs>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceWatchMutation,
		{
			optimisticResponse: {
				__typename: 'Mutation',
				watchSpace: {
					__typename: 'WatchSpacePayload',
					space: {
						__typename: 'Space',
						id: spaceId,
						currentUser: {
							__typename: 'SpaceUserMetadata',
							isWatched: true,
						},
					},
				},
			},
		},
	);

	const [unWatchSpace] = useMutation<StarMutationArgs>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceUnwatchMutation,
		{
			optimisticResponse: {
				__typename: 'Mutation',
				unwatchSpace: {
					__typename: 'WatchSpacePayload',
					space: {
						__typename: 'Space',
						id: spaceId,
						currentUser: {
							__typename: 'SpaceUserMetadata',
							isWatched: false,
						},
					},
				},
			},
		},
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(e: any) => {
			if (isWatched) {
				onUnwatch && onUnwatch(e);
			} else {
				onWatch && onWatch(e);
			}

			const mutation = isWatched ? unWatchSpace : watchSpace;
			void mutation({
				variables: { spaceId },
			});
			if (analytics) {
				const { source, containerId, attributes } = analytics;
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: isWatched ? 'unwatched' : 'watched',
						actionSubject: 'space',
						source,
						attributes,
						...(containerId && { containerId }),
					},
				}).fire();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[spaceId, isWatched, analytics, watchSpace, unWatchSpace, createAnalyticsEvent],
	);

	const watchTooltip = isWatched ? i18n.Unwatch : i18n.Watch;

	const watchIconButton = isWatched ? (
		<FilledIconButton
			onClick={handleClick}
			data-testid="actions-watched"
			aria-label={intl.formatMessage(i18n.Watch)}
			aria-pressed
		>
			<WatchIcon isWatched legacy={legacy} />
		</FilledIconButton>
	) : (
		<UnfilledIconButton
			onClick={handleClick}
			data-testid="actions-unwatched"
			aria-label={intl.formatMessage(i18n.Watch)}
			aria-pressed={false}
		>
			<WatchIcon isWatched={false} legacy={legacy} />
		</UnfilledIconButton>
	);

	return (
		<Fragment>
			{inMenu ? (
				<ButtonItem
					testId="watch-menu-item"
					iconBefore={<WatchIcon isWatched={isWatched} legacy={legacy} />}
					onClick={handleClick}
				>
					<FormattedMessage {...watchTooltip} />
				</ButtonItem>
			) : (
				<Tooltip content={<FormattedMessage {...watchTooltip} />} hideTooltipOnMouseDown>
					{watchIconButton}
				</Tooltip>
			)}
		</Fragment>
	);
};

const i18n = defineMessages({
	Watch: {
		id: 'action-buttons.space.watch',
		defaultMessage: 'Watch space',
		description: 'Tooltip message to Watch the given space.',
	},
	Unwatch: {
		id: 'action-buttons.space.unwatch',
		defaultMessage: 'Unwatch space',
		description: 'Tooltip message to remove Watch status from the given space.',
	},
});
