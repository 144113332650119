import React, { useEffect, useState, type FC } from 'react';

import { useUnreadInlineComments } from '@confluence/unread-comments';

import { CommentsPanel } from './CommentsPanel';
import { Loading } from './components/Loading';
import { CurrentView, useCommentsPanel } from './hooks/useCommentsPanel';

type CommentsPanelWrapperProps = {
	openWithView?: CurrentView;
};

export const CommentsPanelWrapper: FC<CommentsPanelWrapperProps> = ({ openWithView }) => {
	const [showLoader, setShowLoader] = useState(!openWithView);
	const [{ unreadCommentsListState: unreadCommentList, isUnreadQueryLoading }] =
		useUnreadInlineComments();

	const [{ currentView }, { setCurrentView }] = useCommentsPanel();

	// As long as we don't have a "default" view to open the panel to show then we should check
	// if the unread call is still in flight to figure out which view to show if there are unread
	useEffect(() => {
		if (!openWithView) {
			setShowLoader(isUnreadQueryLoading);
		}
	}, [isUnreadQueryLoading, openWithView]);

	let viewToShow = CurrentView.OPEN;

	if (openWithView) {
		viewToShow = openWithView;
	} else if (unreadCommentList.length === 0) {
		// If no unreads then move to Open View
		viewToShow = CurrentView.OPEN;
	} else if (unreadCommentList.length > 0 && (!currentView || currentView === CurrentView.UNREAD)) {
		// If there is Unread and current view is undefined or Unread then move it to Unread or retain Unread view
		// otherwise any incoming Unread through pubsub will auto move the user to the Unread Pill while
		// they are on the Open/Resolved pill and going through it.
		// Unread should only get defaulted if there are any unreads on opening the comments panel
		viewToShow = CurrentView.UNREAD;
	}

	useEffect(() => {
		setCurrentView(viewToShow);
	}, [viewToShow, setCurrentView]);

	return showLoader ? <Loading /> : <CommentsPanel currentView={viewToShow} />;
};
