/** @jsx jsx */
/* eslint-disable @atlaskit/design-system/no-legacy-icons */
import type { PropsWithChildren } from 'react';
import { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl-next';
import { css, jsx } from '@compiled/react';

import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { token } from '@atlaskit/tokens';
import AkButton from '@atlaskit/button/custom-theme-button';
import type { OnOpenChangeArgs } from '@atlaskit/dropdown-menu';
import DropdownMenu from '@atlaskit/dropdown-menu';

import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';

const NOOP = () => {};

const i18n = defineMessages({
	triggerLabel: {
		id: 'content-tools.more.icon.label',
		defaultMessage: 'More actions',
		description: 'A label for the button that opens a menu providing additional actions for a page',
	},
});

const dropdownMenuCollaboratorStyle = css({
	marginLeft: '0px',
	minWidth: '24px',
});

const dropdownMenuNonCollaboratorStyle = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
	minWidth: '24px',
});

const ContentToolsButtonComponent = ({
	isDisabled,
	isOpen = false,
	onOpenChange = NOOP,
	onPositioned = NOOP,
	children,
	isLoading,
	preloadCopyItem,
	preloadMoveItem,
	isExternalCollaborator = false,
}: ContentToolsButtonProps) => {
	const intl = useIntl();

	const [showSpinner, setSpinner] = useState(false);
	const [drodownMenuMaxHeight, setDropdownMenuMaxHeight] = useState('auto');
	const dropdownMenuContainerRef = useRef<HTMLDivElement>(null);

	useSubmitSSRScriptErrors('page-more-action-button');

	const isMoreActionButtonClickedInSSR = () =>
		Boolean(
			window.__SSR_EVENTS_CAPTURE__?.moreActionButton &&
				(window.__SSR_RENDERED__ || process.env.REACT_SSR),
		);

	const handleOnOpenChange: typeof onOpenChange = (obj) => {
		if (window.__SSR_RENDERED__ && isLoading) {
			if (!window.__SSR_EVENTS_CAPTURE__) {
				window.__SSR_EVENTS_CAPTURE__ = {};
			}

			window.__SSR_EVENTS_CAPTURE__.moreActionButton = true;

			setSpinner(true);
		}

		onOpenChange(obj);

		if (obj.isOpen) {
			const distanceFromTop = dropdownMenuContainerRef.current?.getBoundingClientRect().bottom ?? 0;
			const availableHeight = window.innerHeight - distanceFromTop - 16;

			setDropdownMenuMaxHeight(`${availableHeight}px`);
		}
	};

	useEffect(() => {
		const handleButton = async () => {
			if (isMoreActionButtonClickedInSSR()) {
				if (!isLoading) {
					await preloadCopyItem?.();
					await preloadMoveItem?.();
					handleOnOpenChange({ isOpen: true });
					setSpinner(false);
					delete window.__SSR_EVENTS_CAPTURE__?.moreActionButton;
				} else {
					//if clicked in SSR, gotta show spinner even when SPA takes over while loading
					setSpinner(true);
				}
			}
		};
		void handleButton();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	if (isMoreActionButtonClickedInSSR() && showSpinner) {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		const SSR_ACTION_LOADING_SPINNER_STYLE = { marginLeft: '10px' } as React.CSSProperties;
		return (
			<SSRActionLoadingSpinner
				spinnerId="more-action-loading-spinner"
				actionType="moreActionButton"
				showSpinner
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				styleOverride={SSR_ACTION_LOADING_SPINNER_STYLE}
			/>
		);
	}

	return (
		<div
			data-id="content-tools-dropdown-menu-container"
			data-test-id="content-tools-dropdown-menu-container"
			css={[
				dropdownMenuNonCollaboratorStyle,
				isExternalCollaborator && dropdownMenuCollaboratorStyle,
			]}
			ref={dropdownMenuContainerRef}
		>
			<DropdownMenu
				testId="content-tools-dropdown-menu"
				appearance="tall"
				trigger={({ triggerRef, ...props }) => (
					<AkButton
						{...props}
						data-id="page-more-action-button"
						data-test-id="page-more-action-button"
						id="tools-menu-trigger"
						iconBefore={
							<ShowMoreHorizontalIcon
								label={intl.formatMessage(i18n.triggerLabel)}
								spacing="spacious"
								color={token('color.icon.subtle')}
							/>
						}
						ref={triggerRef}
						appearance="subtle"
						isDisabled={isDisabled}
					/>
				)}
				placement="bottom-end"
				isOpen={isOpen}
				onOpenChange={handleOnOpenChange}
				shouldFlip={false}
				// @ts-ignore `DropdownMenu` removed support for `onPositioned` in v11.
				onPositioned={onPositioned}
				shouldRenderToParent
			>
				<div style={{ maxHeight: drodownMenuMaxHeight }}>{children || null}</div>
			</DropdownMenu>
		</div>
	);
};

ContentToolsButtonComponent.propTypes = {
	children: PropTypes.node,
	isDisabled: PropTypes.bool,
	isExternalCollaborator: PropTypes.bool,
	isLoading: PropTypes.bool,
	isOpen: PropTypes.bool,
	onOpenChange: PropTypes.func,
	onPositioned: PropTypes.func,
	preloadCopyItem: PropTypes.func,
	preloadMoveItem: PropTypes.func,
};

export type ContentToolsButtonProps = PropsWithChildren<{
	isDisabled?: boolean;
	isExternalCollaborator?: boolean;
	isLoading?: boolean;
	isOpen?: boolean;
	onOpenChange?: (args: Pick<OnOpenChangeArgs, 'isOpen'>) => void;
	/**
	 * @deprecated `DropdownMenu` removed support for `onPositioned` in v11.
	 */
	onPositioned?: () => void;
	preloadCopyItem?: (() => void) | (() => Promise<void>);
	preloadMoveItem?: (() => void) | (() => Promise<void>);
}>;

export const ContentToolsButton = memo(ContentToolsButtonComponent);
