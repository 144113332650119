import { useLayoutEffect, useRef, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ATL_GENERAL } from '@confluence/web-item-location';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { usePageState } from '@confluence/page-context';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { useRouteDataRef } from '@confluence/route-manager';
import { isPageOrBlogPost } from '@confluence/content-types-utils';

import { useWebPanelLocation } from './useWebPanelLocation';
import type {
	SpaceThemeQuery as SpaceThemeQueryType,
	SpaceThemeQueryVariables as SpaceThemeQueryVariablesType,
} from './__types__/SpaceThemeQuery';
import { SpaceThemeQuery } from './SpaceThemeQuery.graphql';

export const useATLGeneralWebPanelObserver = (isViewPage: boolean) => {
	const [{ contentId, spaceKey, contentType }] = usePageState();
	const { panels } = useWebPanelLocation({
		location: ATL_GENERAL,
		fetchPolicy: 'cache-first',
		spaceKey,
		contentId,
	});
	const fixedATLGeneralWebPanelRef = useRef(null);
	const isLivePage = useIsCurrentPageLive();
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const routeDataRef = useRouteDataRef();

	// We are expanding atl.general panel to full width when the side nav is not present. This is the case for
	// company hub and when url has mode=global, such as on custom homepage
	// TODO: For Nav 4, we want to replace these checks so that we simply check for existence of the side nav (DISCO-2142)
	const isFullWidth =
		isCompanyHubSpaceKey(spaceKey) || routeDataRef.current?.queryParams?.mode === 'global';

	const shouldShowATLGeneralWebPanel = panels.length > 0;

	// Determines if panels should not display a UI element and only allow for the support of scripts and analytics
	const shouldHidePanels = !isPageOrBlogPost(contentType) && contentType;

	// If the other conditions for a fixed web panel are true, check whether the space is themed
	// The web panel should not be fixed for themed spaces since they do not have a fixed page header
	const canFixATLGeneralWebPanel = shouldShowATLGeneralWebPanel && isViewPage && !isLivePage;

	const { data } = useQuery<SpaceThemeQueryType, SpaceThemeQueryVariablesType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceThemeQuery,
		{
			skip: !canFixATLGeneralWebPanel,
			variables: { spaceKey },
		},
	);

	const isATLGeneralWebPanelFixed = canFixATLGeneralWebPanel && data?.space?.theme === null;

	// ResizeObserver is needed because the height of web panel items can change at any time
	const resizeObserver = useMemo(() => {
		if (!process.env.REACT_SSR) {
			return new ResizeObserver(([entry]) => {
				if (entry) {
					document.documentElement.style.setProperty(
						'--atl-general-webpanel-height',
						`${entry.target.clientHeight}px`,
					);
					document.dispatchEvent(new CustomEvent('atlGeneralWebPanelHeightUpdated'));
				}
			});
		}
	}, []);

	useLayoutEffect(() => {
		const fixedATLGeneralWebPanel = fixedATLGeneralWebPanelRef.current;

		if (isATLGeneralWebPanelFixed) {
			fixedATLGeneralWebPanel && resizeObserver?.observe(fixedATLGeneralWebPanel);
		}

		return () => {
			if (isATLGeneralWebPanelFixed) {
				fixedATLGeneralWebPanel && resizeObserver?.unobserve(fixedATLGeneralWebPanel);
			}
		};
	}, [shouldShowATLGeneralWebPanel, isATLGeneralWebPanelFixed, resizeObserver]);

	return {
		shouldShowATLGeneralWebPanel,
		isATLGeneralWebPanelFixed,
		fixedATLGeneralWebPanelRef,
		isCompanyHub,
		isFullWidth,
		shouldHidePanels,
	};
};
