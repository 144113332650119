import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { ONBOARDING_TUTORIAL_LABEL } from '../../constants/onboarding-state/onboarding-editor-constants';

import type {
	OnboardingLabelsQuery as OnboardingLabelsQueryType,
	OnboardingLabelsQueryVariables as OnboardingLabelsQueryVariablesType,
} from './__types__/LabelsQuery';
import { LabelsQuery } from './LabelsQuery.graphql';

type useCheckIfOnboardingEditorTemplateProps = {
	contentId: string;
	skip?: boolean;
};

type useCheckIfOnboardingEditorTemplatePropsReturn = {
	isTemplate: boolean;
	isOnboardingTemplate: boolean;
	labelsData: OnboardingLabelsQueryType | undefined;
	labelsLoading: boolean;
	labelsError: ApolloError | undefined;
};

export const useCheckIfOnboardingEditorTemplate = ({
	contentId,
	skip,
}: useCheckIfOnboardingEditorTemplateProps): useCheckIfOnboardingEditorTemplatePropsReturn => {
	const {
		data: labelsData,
		loading: labelsLoading,
		error: labelsError,
	} = useQuery<OnboardingLabelsQueryType, OnboardingLabelsQueryVariablesType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LabelsQuery,
		{
			variables: {
				contentId,
				status: ['current', 'draft'],
			},
			skip,
		},
	);

	const labels = labelsData?.content?.nodes?.[0]?.labels?.nodes || [];
	const sourceTemplateEntityId =
		labelsData?.content?.nodes?.[0]?.metadata?.sourceTemplateEntityId || undefined;

	// If the current editor is NOT the onboarding editor tutorial, show the draft banner.
	const isOnboardingTemplate = Boolean(
		labels.find((label) => label?.label === ONBOARDING_TUTORIAL_LABEL),
	);

	if (labelsError && isUnauthorizedError(labelsError)) {
		markErrorAsHandled(labelsError);
	}

	return {
		isTemplate: !!sourceTemplateEntityId,
		isOnboardingTemplate,
		labelsData,
		labelsLoading,
		labelsError,
	};
};
