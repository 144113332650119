import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Toggle from '@atlaskit/toggle';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { messages } from './messages';
import {
	getIsCreateIssueWithAiEnabledByUser,
	setCreateIssueWithAiEnabledByUser,
} from './utils/localStorageHelpers';

const aiToggleContainerStyle = xcss({
	paddingInline: 'space.250',
	paddingBlockStart: 'space.250',
});
const toggleWrapperStyle = xcss({ flexShrink: 0 });

export const IssueCreateWithAiToggle = ({ onChange }: { onChange: (enabled: boolean) => any }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const id = 'issue-create-with-ai-toggle';
	const [isChecked, setIsChecked] = useState(getIsCreateIssueWithAiEnabledByUser());

	useEffect(() => {
		setCreateIssueWithAiEnabledByUser(isChecked);
	});

	const onToggleChange = useCallback(async () => {
		const newIsChecked = !isChecked;
		setIsChecked(newIsChecked);
		setCreateIssueWithAiEnabledByUser(newIsChecked);
		// Wait for the toggle to finish animating before continuing
		await new Promise((resolve) => setTimeout(resolve, 250));
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'issueCreateSidePanel',
				action: 'toggled',
				actionSubject: 'toggle',
				actionSubjectId: 'issueCreateAiToggle',
				type: 'ui',
				attributes: {
					newState: newIsChecked ? 'enabled' : 'disabled',
				},
			},
		}).fire();
		onChange(newIsChecked);
	}, [isChecked, onChange, createAnalyticsEvent]);

	return (
		<Inline
			xcss={aiToggleContainerStyle}
			alignBlock="start"
			testId="issue-create-side-panel.with-ai"
		>
			<Box xcss={toggleWrapperStyle}>
				<Toggle
					id={id}
					testId="issue-create-side-panel.with-ai.toggle"
					isChecked={isChecked}
					onChange={onToggleChange}
				/>
			</Box>
			<label htmlFor={id}>
				<FormattedMessage {...messages.sidePanelIssueCreateAiToggleLabel} />
			</label>
		</Inline>
	);
};
