import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Link from '@atlaskit/link';
import { Box, xcss, Text, Inline } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { confluenceLocalStorageInstance, keys } from '@confluence/storage-manager';
import { EDIT_PAGE_V2, EDIT_BLOG_V2 } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager';

import { fabricMigrationPreviewMigratePageMutation } from './FabricMigrationPreviewMigration.experimentalgraphql.js';
import { getStatusFromResponse } from './util.js';

const i18n = defineMessages({
	migrationModalTitle: {
		id: 'fabric-page-optin-migration.migration-modal.title',
		defaultMessage: 'Get the latest editing experience',
		description: 'Title for migration modal',
	},
	migrationModalDescription: {
		id: 'fabric-page-optin-migration.migration-modal.description',
		defaultMessage: 'Select {text} to get the latest editor experience.',
		description: 'Description for migration modal',
	},
	migrationModalUpdateButton: {
		id: 'fabric-page-optin-migration.migration-modal.update-button',
		defaultMessage: 'Update page',
		description: 'Update button for migration modal',
	},
	migrationModalCancelButton: {
		id: 'fabric-page-optin-migration.migration-modal.cancel-button',
		defaultMessage: 'Not now',
		description: 'Cancel button for migration modal',
	},
	migrationModalUnsupported: {
		id: 'fabric-page-optin-migration.migration-modal.unsupported',
		defaultMessage: 'This page cannot be converted',
		description: 'Error for converting page',
	},
});

const popupContainerStyle = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	fontSize: `${token('font.body.small', '16px')}`,
});

const topMarginStyle = xcss({
	marginTop: 'space.200',
});

const widthStyle = xcss({
	width: '297px',
});

export const EditPopupEligibleMigration = ({
	onClickEdit,
	contentId,
	href,
	contentHash,
	contentType,
	spaceKey,
}) => {
	const [popupMessage, setPopupMessage] = React.useState({
		...i18n.migrationModalDescription,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPage = contentType === 'page';
	const redirectUrl = () =>
		isPage
			? EDIT_PAGE_V2.toUrl({
					contentType: 'pages',
					spaceKey,
					contentId,
				})
			: EDIT_BLOG_V2.toUrl({
					contentType: 'blog',
					spaceKey,
					contentId,
				});
	const redirectRoute = !contentId ? '' : redirectUrl();

	const { push } = useRouteActions();

	const { isLoggedIn } = useSessionData();

	// Attempt to migrate page to new editor
	const attemptToMigrate = async ({ migratePages }) => {
		if (!contentId) {
			return;
		}
		const response = await migratePages({
			variables: {
				contentId,
				contentHash,
			},
		});

		const status = getStatusFromResponse(response);

		if (status === '200' || status === '204') {
			// perform redirect
			push(redirectRoute);
			confluenceLocalStorageInstance.setItem(keys.PAGE_JUST_CONVERTED, contentId);
		} else {
			setPopupMessage(i18n.migrationModalUnsupported);
		}
	};

	if (!isLoggedIn) {
		return null;
	}

	return (
		<Box xcss={popupContainerStyle}>
			<Mutation mutation={fabricMigrationPreviewMigratePageMutation}>
				{(
					migratePages: (options: {
						variables: { contentId: string; contentHash: string };
					}) => Promise<any>,
				) => (
					<Box xcss={widthStyle}>
						<FormattedMessage tagName="h3" {...i18n.migrationModalTitle} />
						<Box xcss={topMarginStyle} role="status" aria-live="polite" aria-atomic="true">
							<FormattedMessage
								{...popupMessage}
								tagName="p"
								values={{
									text: (
										<b>
											<FormattedMessage {...i18n.migrationModalUpdateButton} />
										</b>
									),
								}}
							/>
						</Box>
						<Box xcss={topMarginStyle}>
							<Inline space="space.050">
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									href=""
									onClick={async (e) => {
										e.preventDefault();
										createAnalyticsEvent({
											type: 'sendUIEvent',
											data: {
												action: 'clicked',
												actionSubject: 'button',
												actionSubjectId: 'eligibleMigrationModalConvertButton',
												source: 'editPopupEligibleMigration',
											},
										}).fire();
										await attemptToMigrate({ migratePages });
									}}
								>
									<FormattedMessage {...i18n.migrationModalUpdateButton} />
								</Link>
								<Text>&nbsp;&middot;&nbsp;</Text>
								<Link
									href={href}
									onClick={(e) => {
										onClickEdit(e);
									}}
								>
									<FormattedMessage {...i18n.migrationModalCancelButton} />
								</Link>
							</Inline>
						</Box>
					</Box>
				)}
			</Mutation>
		</Box>
	);
};

EditPopupEligibleMigration.propTypes = {
	onClickEdit: PropTypes.func.isRequired,
	contentId: PropTypes.string.isRequired,
	href: PropTypes.string,
	contentHash: PropTypes.string,
	contentType: PropTypes.string,
	spaceKey: PropTypes.string,
};
