import type { FC } from 'react';
import React, { useContext, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { defineMessages, injectIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import BitbucketBranchesIcon from '@atlaskit/icon/core/migration/branch--bitbucket-branches';
import { ConfluenceIcon, ConfluenceLogo } from '@atlaskit/logo';
import { ProductHome, CustomProductHome } from '@atlaskit/atlassian-navigation';
import { Box } from '@atlaskit/primitives';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useRouteDataRef, useRouteActions } from '@confluence/route-manager';
import { GenericErrorBoundary, Attribution } from '@confluence/error-boundary';
import { SSRMouseEventWrapper, SSR_NAV_HOME_BUTTON_METRIC } from '@confluence/browser-metrics';
import { START_TOUCH } from '@confluence/navdex';
import { WIKI } from '@confluence/named-routes';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { LoadableAfterPaint } from '@confluence/loadable';
import { getOverrides, removeAllOverrides } from '@confluence/session-data';

import type { AppNavigationUnifiedQuery as QueryData } from './__types__/AppNavigationUnifiedQuery';
import { HomeLogo } from './presentationalComponents';
import { AppNavigationUnifiedQuery } from './AppNavigationUnifiedQuery.graphql';

const href = WIKI.toUrl();

const i18n = defineMessages({
	productLinkLabel: {
		id: 'app-navigation.product-link.label',
		defaultMessage: 'Confluence Home',
		description: 'Text alternative for the Confluence link in the top navigation panel',
	},
});

const BranchDeployLogoLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-BranchDeployLogo" */ '../BranchDeployLogo'))
			.BranchDeployLogo,
	loading: () => <BitbucketBranchesIcon label="Loading..." />,
});

const FeatureFlagLogoLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-FeatureFlagLogo" */ '../FeatureFlagLogo'))
			.FeatureFlagLogo,
});

const ConfluenceProductHomeBase = injectIntl(({ intl }) => {
	const routeDataRef = useRouteDataRef();
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const { data, loading, error } = useQuery<QueryData>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AppNavigationUnifiedQuery,
	);

	const fireAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'productLogo',
				attributes: {
					selectedItemPageContext: routeDataRef.current?.routeName,
					navigationLayer: 'global',
					navVersion: '3',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, routeDataRef]);

	const onClickWithMeta = (e) => {
		e.preventDefault();

		fireAnalytics();
		resetStickySearchRef?.current?.();

		if (e.metaKey) {
			window.open(href, '_blank', 'noreferrer');
		} else {
			push(href);
		}
	};

	if (loading) {
		return null;
	}
	if (error) {
		throw error;
	}

	const showSiteTitle = data?.siteConfiguration.showSiteTitle;
	const siteTitle = showSiteTitle ? data?.siteConfiguration.siteTitle : undefined;

	if (data?.siteConfiguration.customSiteLogo) {
		const siteLogo = data?.siteConfiguration.siteLogoUrl;
		return (
			<CustomProductHome
				iconAlt="Custom icon"
				iconUrl={siteLogo}
				logoAlt="Custom logo"
				logoUrl={siteLogo}
				href={href}
				onClick={onClickWithMeta}
				aria-label={siteTitle}
				siteTitle={siteTitle}
				data-testid="app-navigation-custom-wordmark"
			/>
		);
	}
	return (
		<ProductHome
			icon={ConfluenceIcon}
			logo={ConfluenceLogo}
			siteTitle={siteTitle}
			href={href}
			aria-label={intl.formatMessage(i18n.productLinkLabel)}
			onClick={onClickWithMeta}
			data-testid="app-navigation-wordmark"
		/>
	);
});

export const ConfluenceProductHome: FC = memo(() => {
	const { isBranchDeploy } = useContext(AppNavigationContext);
	const featureFlagOverrides = getOverrides();
	const featureGateOverrides = FeatureGates.getOverrides();
	const hasOverrides =
		Object.keys(featureFlagOverrides).length > 0 ||
		Object.keys(featureGateOverrides.gates).length > 0 ||
		Object.keys(featureGateOverrides.configs).length > 0;

	return (
		<HomeLogo data-testid="app-navigation-home-logo">
			<GenericErrorBoundary
				attribution={Attribution.DISCO}
				renderOnError={() => (
					<ProductHome
						icon={ConfluenceIcon}
						logo={ConfluenceLogo}
						data-testid="app-navigation-wordmark-error"
					/>
				)}
			>
				{isBranchDeploy && (
					<Box paddingInlineEnd="space.050">
						<BranchDeployLogoLoader />
					</Box>
				)}
				{hasOverrides && (
					<FeatureFlagLogoLoader
						featureFlagOverrides={featureFlagOverrides}
						featureGateOverrides={featureGateOverrides}
						onReset={() => {
							removeAllOverrides();
							FeatureGates.clearAllOverrides();
							window.location.reload();
						}}
					/>
				)}
				<SSRMouseEventWrapper metricName={SSR_NAV_HOME_BUTTON_METRIC}>
					<ConfluenceProductHomeBase />
				</SSRMouseEventWrapper>
			</GenericErrorBoundary>
		</HomeLogo>
	);
});
