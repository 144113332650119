import type {
	AIUnifiedCommonAttributes,
	AIUnifiedEventPayload,
} from '@atlaskit/editor-common/analytics';
import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	EVENT_TYPE,
} from '@atlaskit/editor-common/analytics';

function createAIUnifiedCommonAttributes(
	interactionID: string | undefined,
	experienceName?: string,
	isProactive?: boolean,
): AIUnifiedCommonAttributes {
	return {
		singleInstrumentationID: interactionID ?? '',
		aiInteractionID: interactionID ?? '',
		aiFeatureName: 'Editor AI',
		aiExperienceName: experienceName ?? 'unknown',
		isAIFeature: 1,
		proactiveAIGenerated: !!isProactive ? 1 : 0,
		userGeneratedAI: !isProactive ? 1 : 0,
	};
}

/**
 * Utility types to extract only the non-common attributes
 * * OmitCommonAttributes: Removes AIUnifiedCommonAttributes from the unified attributes
 *   * We also distribute the Omit across the union
 * * ExcludeEmpty: Editor Common AEP Utilities also extends attributes with & {}
 *   * {} allows any properties to be added
 *   * We are also sometimes left with {} after using Omit
 *   * This util drops any {} types from the union
 */

type ExcludeEmpty<T> = T extends unknown ? { [K in keyof T]: Pick<T, K> }[keyof T] : never;
type OmitCommonAttributes<T> = T extends AIUnifiedCommonAttributes
	? ExcludeEmpty<Omit<T, keyof AIUnifiedCommonAttributes>>
	: never;

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/max-params
export function createUnifiedAnalyticsPayload(
	action: AIUnifiedEventPayload['action'],
	interactionID: string | undefined,
	experienceName?: string,
	isProactive?: boolean,
	attributes?: OmitCommonAttributes<AIUnifiedEventPayload['attributes']>,
): AIUnifiedEventPayload {
	const unifiedAnalyticsAttributes = createAIUnifiedCommonAttributes(
		interactionID,
		experienceName,
		isProactive,
	);

	switch (action) {
		case ACTION.ACTIONED: {
			return {
				action,
				actionSubject: ACTION_SUBJECT.AI_RESULT,
				actionSubjectId: ACTION_SUBJECT_ID.EDITOR_PLUGIN_AI,
				eventType: EVENT_TYPE.TRACK,
				attributes: {
					aiResultAction: 'unknown',
					...unifiedAnalyticsAttributes,
					...attributes,
				},
			};
		}
		case ACTION.VIEWED:
		case ACTION.ERROR: {
			return {
				action,
				actionSubject: ACTION_SUBJECT.AI_RESULT,
				actionSubjectId: ACTION_SUBJECT_ID.EDITOR_PLUGIN_AI,
				eventType: EVENT_TYPE.TRACK,
				attributes: {
					...unifiedAnalyticsAttributes,
					...attributes,
				},
			};
		}
		case ACTION.INITIATED:
		case ACTION.DISMISSED: {
			return {
				action,
				actionSubject: ACTION_SUBJECT.AI_INTERACTION,
				actionSubjectId: ACTION_SUBJECT_ID.EDITOR_PLUGIN_AI,
				eventType: EVENT_TYPE.TRACK,
				attributes: {
					...unifiedAnalyticsAttributes,
					...attributes,
				},
			};
		}
		case ACTION.SUBMITTED: {
			return {
				action,
				actionSubject: ACTION_SUBJECT.AI_FEEDBACK,
				actionSubjectId: ACTION_SUBJECT_ID.EDITOR_PLUGIN_AI,
				eventType: EVENT_TYPE.TRACK,
				attributes: {
					// If feedback result is not explicitly set then we should assume negative, since people are more likely to
					// report negative experiences than they are positive ones.
					aiFeedbackResult: 'down',
					...unifiedAnalyticsAttributes,
					...attributes,
				},
			};
		}
	}
}
