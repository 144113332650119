import React, { useEffect } from 'react';

import { SpotlightTransition } from '@atlaskit/onboarding';

import { LoadableLazy } from '@confluence/loadable';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { useIsSpaceGettingStartedTourEligible } from './useIsSpaceGettingStartedTourEligible';
import { useSpaceGettingStartedTourOnboardingState } from './useSpaceGettingStartedTourOnboardingState';

const SpaceGettingStartedTourComponentLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceGettingStartedTourComponent" */ './SpaceGettingStartedTourComponent'
			)
		).SpaceGettingStartedTourComponent,
});

export const SpaceGettingStartedTour = () => {
	const [, fireSpaceGettingStartedTourXflowExperiment] = UNSAFE_noExposureExp(
		'cc_getting_started_tour_xflow',
	);
	const [, fireSpaceGettingStartedTourExperiment] = UNSAFE_noExposureExp('cc_getting_started_tour');
	const {
		isXflowEligibleForSpaceGettingStartedTour,
		isLandAndOtherEligibleForSpaceGettingStartedTour,
		isSpaceGettingStartedTourExperimentCohort,
	} = useIsSpaceGettingStartedTourEligible();

	const { isDismissed } = useSpaceGettingStartedTourOnboardingState({
		skip: !isSpaceGettingStartedTourExperimentCohort,
	});

	useEffect(() => {
		if (isXflowEligibleForSpaceGettingStartedTour) {
			fireSpaceGettingStartedTourXflowExperiment();
		}
		if (isLandAndOtherEligibleForSpaceGettingStartedTour) {
			fireSpaceGettingStartedTourExperiment();
		}
	}, [
		isXflowEligibleForSpaceGettingStartedTour,
		isLandAndOtherEligibleForSpaceGettingStartedTour,
		fireSpaceGettingStartedTourXflowExperiment,
		fireSpaceGettingStartedTourExperiment,
	]);

	return (
		<SpotlightTransition>
			{isSpaceGettingStartedTourExperimentCohort && !isDismissed ? (
				<SpaceGettingStartedTourComponentLoadable />
			) : null}
		</SpotlightTransition>
	);
};
