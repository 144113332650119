import { LoadableAfterPaint } from '@confluence/loadable';

export { ObjectSidebarPushPlaceholder } from './ObjectSidebarPushPlaceholder';
export const EditorObjectSidebarPanel = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorObjectSidebarPanel" */ './EditorObjectSidebarPanel'
			)
		).EditorObjectSidebarPanel,
});

export const ObjectSidebarPanel = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ObjectSidebarPanel" */ './ObjectSidebarPanelWrapper'
			)
		).ObjectSidebarPanelWrapper,
});

export const ObjectSidebarControl = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ObjectSidebarControl" */ './ObjectSidebarControl'))
			.ObjectSidebarControl,
});
