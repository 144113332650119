import React, { Fragment } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner';
import { N20 } from '@atlaskit/theme/colors';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';

import { InlineHeaderButton, NavigationContainer } from './styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	display: 'flex',
	padding: `${token('space.200', '16px')}`,
	flex: 1,
	flexDirection: 'column',
});

type CommentSkeletonRowProps = {
	flex?: number;
	useColumnDirection?: boolean;
	marginTop?: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentSkeletonRow = styled.div<CommentSkeletonRowProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  flex: ${(props: CommentSkeletonRowProps) => (props.flex ? props.flex : '1')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  flex-direction: ${(props: CommentSkeletonRowProps) =>
		props.useColumnDirection ? 'column' : 'row'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  margin-top: ${(props: CommentSkeletonRowProps) => props.marginTop}px;
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentAuthorSkeleton = styled.div({
	height: 32,
	width: 32,
	borderRadius: 16,
	backgroundColor: `${token('color.skeleton', N20)}`,
});

type CommentSkeletonLineProps = {
	width: number;
	height?: number;
	marginBottom?: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentSkeletonLine = styled.div<CommentSkeletonLineProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  background-color: ${token('color.skeleton', N20)};
  border-radius: 3px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  margin-bottom: ${(props: CommentSkeletonLineProps) =>
		typeof props.marginBottom === 'number' ? props.marginBottom : '9'}px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  height: ${(props: CommentSkeletonLineProps) => (props.height ? props.height : '8')}px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  width: ${(props: CommentSkeletonLineProps) => props.width}px;
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: `${token('space.100', '8px')}`,
});

type SidebarHeaderProps = {
	shouldShowNavigation?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarHeader = styled.div<SidebarHeaderProps>({
	position: 'relative',
	display: 'flex',
	padding: `10px ${token('space.150', '12px')} 0`,
	justifyContent: 'space-between',
	height: 25,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CloseButton = styled(InlineHeaderButton)({
	height: 24,
	width: 24,
	margin: '0 0 0 5px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavContainer = styled(NavigationContainer)({
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommentContainer = styled.div({
	marginTop: 0,
	marginBottom: 0,
	position: 'relative',
	backgroundColor: `${token('elevation.surface', '#fff')}`,
});
/*Shared component for loading skeleton*/

export const LoadingSkeletonChildren = () => {
	return (
		<Fragment>
			<SidebarHeader>
				<NavContainer>
					<InlineHeaderButton
						data-cy="inline-nav-previous"
						iconBefore={
							<ChevronUpIcon
								label=""
								color={token('color.icon.disabled', N20)}
								LEGACY_size="medium"
								spacing="spacious"
							/>
						}
					/>
					<InlineHeaderButton
						data-cy="inline-nav-previous"
						iconBefore={
							<ChevronDownIcon
								label=""
								color={token('color.icon.disabled', N20)}
								LEGACY_size="medium"
								spacing="spacious"
							/>
						}
					/>
					<CommentSkeletonLine width={30} height={8} marginBottom={0} />
				</NavContainer>
				<NavContainer>
					<MoreIcon spacing="spacious" label="" color={token('color.icon.disabled', N20)} />
					<CloseButton iconBefore={<CrossIcon label="" LEGACY_size="small" />} />
				</NavContainer>
			</SidebarHeader>
			<CommentContainer>
				<SkeletonContainer>
					<CommentSkeletonRow flex={1}>
						<CommentSkeletonRow flex={1}>
							<CommentAuthorSkeleton />
						</CommentSkeletonRow>
						<CommentSkeletonRow flex={5} marginTop={5} useColumnDirection>
							<CommentSkeletonLine width={81} />
							<CommentSkeletonLine width={53} height={6} />
						</CommentSkeletonRow>
					</CommentSkeletonRow>
				</SkeletonContainer>
			</CommentContainer>
			<SpinnerContainer>
				<Spinner size="medium" />
			</SpinnerContainer>
		</Fragment>
	);
};
