import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import type { SizeType } from '@atlaskit/avatar/types';
import { N200, N800 } from '@atlaskit/theme/colors';

import { ProfileAvatar } from '@confluence/profile';
import { ExternalCollaboratorUserLozenge } from '@confluence/external-collab-ui';
import { TimeAgo } from '@confluence/time-ago';
import type { SitePermissionType } from '@confluence/inline-comments-queries';
import { SitePermissionTypeValues } from '@confluence/inline-comments-queries';
import type { CommentMode } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';

type CommentAuthorProps = {
	commentMode: CommentMode;
	userId: string;
	avatarUrl: string;
	displayName: string;
	date: string;
	permissionType?: SitePermissionType;
	size?: SizeType;
};

type CommentModeProps = {
	commentMode: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateContainer = styled.div({
	display: 'flex',
	flexFlow: 'row nowrap',
	alignItems: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		font: token('font.body.small'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.subtle', N200)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		margin: `${token('space.0', '0px')} !important`,
		backgroundColor: 'transparent',
		border: 'none',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> a': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.subtle', N200)} !important`,

			'&:visited': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.subtle', N200)} !important`,
			},
			'&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.subtle', N200)} !important`,
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AuthorContainer = styled.div<CommentModeProps>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: `${(props: CommentModeProps) =>
		props.commentMode !== 'reply' ? token('space.negative.025', '-2px') : 'inherit'}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: `${(props: CommentModeProps) =>
		props.commentMode === 'view' ? token('space.100', '8px') : undefined}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> a': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text', N800)} !important`,

		'&:visited': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text', N800)} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			textDecoration: 'none !important',
		},
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text', N800)} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			textDecoration: 'none !important',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div<CommentModeProps>({
	display: 'flex',
	paddingRight: `${token('space.100', '8px')}`,
	lineHeight: 1.143,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props: CommentModeProps) =>
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
		props.commentMode === 'view' ? '39px' : undefined}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfileLinkContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	margin: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		fontWeight: `${token('font.weight.medium')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text', N800)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		lineHeight: '16px !important',
		'&:visited': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text', N800)} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			textDecoration: 'none !important',
		},
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text', N800)} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			textDecoration: 'none !important',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span.comment-timestamp': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		font: token('font.body.small'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.subtle', N200)} !important`,
		margin: 0,
		backgroundColor: 'transparent',
		border: 'none',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> a': {
			color: `${token('color.text.subtle', N200)}`,

			'&:visited': {
				color: `${token('color.text.subtle', N200)}`,
			},
			'&:hover': {
				color: `${token('color.text.subtle', N200)}`,
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	paddingLeft: `${token('space.100', '8px')}`,
	verticalAlign: 'top',
});

export const CommentAuthor: FC<CommentAuthorProps> = ({
	commentMode,
	userId,
	date,
	displayName,
	avatarUrl,
	permissionType,
	size,
}) => {
	const isDateShown = commentMode === 'view' && date;

	return (
		<AuthorContainer commentMode={commentMode}>
			<AvatarWrapper commentMode={commentMode}>
				<ProfileAvatar
					userId={userId}
					src={avatarUrl}
					size={size}
					trigger="click"
					name={displayName}
				/>
			</AvatarWrapper>
			<Container>
				<ProfileLinkContainer>
					<span>{displayName}</span>
					{permissionType === SitePermissionTypeValues.EXTERNAL ? (
						<LozengeWrapper>
							<ExternalCollaboratorUserLozenge />
						</LozengeWrapper>
					) : null}
				</ProfileLinkContainer>
				{isDateShown && (
					<DateContainer>
						<span>
							<TimeAgo date={date!} />
						</span>
					</DateContainer>
				)}
			</Container>
		</AuthorContainer>
	);
};
