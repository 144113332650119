import React, { useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { GicAnywhere } from '@atlassian/gic-anywhere';

import { useSessionData } from '@confluence/session-data';

import type { GicPanelBodyProps } from './__types__/GicPanelBody';
import { getPersistedOverrideCloudId } from './utils/localStorageHelpers';

const noop = () => {};

export const GicPanelBody = ({
	onClose,
	onSuccess,
	onFailure,
	analyticsSource,
	summary,
	descriptionAdf,
	isCreatedWithAi,
	issueTypeId,
	projectId,
	configuration,
	cloudId,
}: GicPanelBodyProps) => {
	const { cloudId: sessionCloudId } = useSessionData();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const persistedOverrideCloudId = getPersistedOverrideCloudId();

	const overrideCloudIdForSitePicker =
		persistedOverrideCloudId !== sessionCloudId ? persistedOverrideCloudId : undefined;

	const currentCloudId = overrideCloudIdForSitePicker || cloudId;

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'linkCreateScreen',
				attributes: {
					isManuallyCreated: !isCreatedWithAi,
					isCreatedWithAi,
				},
				source: analyticsSource,
			},
		}).fire();
	}, [isCreatedWithAi, createAnalyticsEvent, analyticsSource]);

	return (
		<GicAnywhere
			cloudId={currentCloudId}
			consumer="confluence-highlight-issue-create"
			onInit={noop}
			onSuccess={onSuccess}
			onClose={onClose}
			onFailure={onFailure}
			summary={summary}
			descriptionAdf={descriptionAdf}
			overrideCloudId={overrideCloudIdForSitePicker}
			issueTypeId={issueTypeId}
			projectId={projectId}
			isPayloadReady
			configuration={configuration}
		/>
	);
};
