import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Pressable, xcss } from '@atlaskit/primitives';
import { IconTile } from '@atlaskit/icon';
import VisuallyHidden from '@atlaskit/visually-hidden';
import Tooltip from '@atlaskit/tooltip';
import VideoPauseOverlayIcon from '@atlaskit/icon/core/video-pause-overlay';
import VideoPlayOverlayIcon from '@atlaskit/icon/core/video-play-overlay';

const i18n = defineMessages({
	pauseButtonLabel: {
		id: 'audio.play-pause-button.pause-label',
		defaultMessage: 'Pause',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will pause the actively playing audio',
	},
	resumeButtonLabel: {
		id: 'audio.play-pause-button.resume-label',
		defaultMessage: 'Resume',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will resume the actively paused audio',
	},
});

export type PlayPauseButtonProps = {
	isPlaying: boolean;
	onPause: () => void;
	onResume: () => Promise<void>;
};

// These styles "extend" the IconTile to give the appearance of more padding around the icon
const buttonStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.brand.bold',
	borderRadius: 'border.radius.circle',
	width: '2.75rem',
	height: '2.75rem',
});

export const PlayPauseButton: FC<PlayPauseButtonProps> = ({ isPlaying, onPause, onResume }) => {
	const intl = useIntl();
	const pressableRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (pressableRef.current) {
			pressableRef.current.focus();
		}
	}, []);

	return (
		<Tooltip
			content={
				isPlaying
					? intl.formatMessage(i18n.pauseButtonLabel)
					: intl.formatMessage(i18n.resumeButtonLabel)
			}
		>
			<Pressable
				xcss={buttonStyles}
				onClick={isPlaying ? onPause : onResume}
				componentName="PlayPauseButton"
				ref={pressableRef}
			>
				{isPlaying ? (
					<IconTile
						icon={VideoPauseOverlayIcon}
						appearance="blueBold"
						shape="circle"
						size="32"
						label={intl.formatMessage(i18n.pauseButtonLabel)}
						testId="miniplayer-pause"
					/>
				) : (
					<IconTile
						icon={VideoPlayOverlayIcon}
						appearance="blueBold"
						shape="circle"
						size="32"
						label={intl.formatMessage(i18n.resumeButtonLabel)}
						testId="miniplayer-resume"
					/>
				)}
				<VisuallyHidden>
					{isPlaying
						? intl.formatMessage(i18n.pauseButtonLabel)
						: intl.formatMessage(i18n.resumeButtonLabel)}
				</VisuallyHidden>
			</Pressable>
		</Tooltip>
	);
};
