import gql from 'graphql-tag';

/*
 * getFeedUserConfig
 *   queried to provide better stats at a small performance cost (~10ms)
 *   if performance optimization becomes more important than experimentation, it can be removed.
 *
 * content excerpt = 350
 *   when excerpt is the full length, it will overflow the 2 line max (with current size and font styles) and get ellipsis via CSS.
 *   if it doesnt overflow, it is the full content and doesnt need ellipsis.
 */
export const FeedContentQuery = gql`
	query FeedContentQuery($first: Int, $after: String, $sortBy: String) {
		feed(first: $first, after: $after, sortBy: $sortBy)
			@connection(key: "homeFollowingFeed", filter: ["sortBy"]) {
			nodes {
				recentActionsCount
				summaryLineUpdate {
					datetime
					type
					groupSize
					... on FeedEventCreate {
						user {
							...FeedContentUserFragment
						}
					}
					... on FeedEventEdit {
						version
						user {
							...FeedContentUserFragment
						}
					}
					... on FeedEventEditLive {
						version
						user {
							...FeedContentUserFragment
						}
					}
					... on FeedEventPublishLive {
						version
						user {
							...FeedContentUserFragment
						}
					}
					... on FeedEventComment {
						user {
							...FeedContentUserFragment
						}
						comment {
							id
							links {
								webui
							}
							location {
								type
								... on InlineComment {
									inlineText
								}
							}
							body(representation: ATLAS_DOC_FORMAT) {
								value
							}
						}
					}
				}
				source
				content {
					title
					id
					type
					subType
					emoji: properties(key: "emoji-title-published") {
						nodes {
							id
							value
						}
					}
					excerpt(length: 350)
					links {
						webui
					}
					history {
						createdBy {
							...FeedContentUserFragment
						}
						ownedBy {
							type
							displayName
							profilePicture {
								path
							}
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
						createdDate
					}
					space {
						alias
						id
						key
						name
					}
					metadata {
						lastModifiedDate
						comments {
							commentsCount
						}
						currentuser {
							favourited {
								isFavourite
							}
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}

		getFeedUserConfig {
			spaces {
				id
			}
			users {
				... on KnownUser {
					accountId
				}
				... on User {
					accountId
				}
				... on UnknownUser {
					accountId
				}
			}
			servingRecommendations
		}
	}

	fragment FeedContentUserFragment on Person {
		type
		displayName
		profilePicture {
			path
		}
		... on KnownUser {
			accountId
		}
		... on User {
			accountId
		}
		... on UnknownUser {
			accountId
		}
	}
`;
