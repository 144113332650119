import { useEffect } from 'react';

import { useMappingsState } from '../providers/BulkConfigureContextProvider';
import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';
import { useIssueCreateSidePanelContext } from '../providers/IssueCreateSidePanelContextProvider';

import { mapTableToIssues } from './tableUtils';

export const useMapTableToIssues = () => {
	const { columnToFieldMappings } = useMappingsState();
	const [{ selectedTable }] = useIssueCreateSidePanelContext();
	const [, { setBulkIssuesList }] = useBulkCreateContext();
	useEffect(() => {
		if (columnToFieldMappings && columnToFieldMappings.length > 0 && selectedTable) {
			const mappedIssues = mapTableToIssues(columnToFieldMappings, selectedTable);
			void setBulkIssuesList(mappedIssues);
		}
	}, [columnToFieldMappings, selectedTable, setBulkIssuesList]);
};
