import type { FC } from 'react';
import React, { useMemo } from 'react';

import StarFilledIcon from '@atlaskit/icon/core/migration/star-starred--star-filled';
import { token, useThemeObserver } from '@atlaskit/tokens';
import { Y300 } from '@atlaskit/theme/colors';

type Size = 'small' | 'medium';

type IconSpacing = 'none' | 'spacious';

type StarIconProps = {
	label: string;
	size?: Size;
	testId?: string;
	spacing?: IconSpacing;
};

export const StarSelectedIcon: FC<StarIconProps> = ({ label, size, testId, spacing }) => {
	const theme = useThemeObserver();
	const primaryColor = useMemo(() => {
		return theme.colorMode === 'dark'
			? token('color.icon.accent.yellow', Y300)
			: token('color.icon.accent.orange', '#E56910');
	}, [theme.colorMode]);

	return (
		<StarFilledIcon
			label={label}
			color={primaryColor}
			{...(size && { size })}
			{...(testId && { testId })}
			spacing={spacing}
		/>
	);
};
