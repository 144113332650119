import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { i18n } from '../controlled/ControlledShareButton';

const iconSkeletonStyle = xcss({
	width: '16px',
	height: '16px',
	borderRadius: '2px',
	margin: 'space.050',
	background: token('color.background.accent.gray.subtlest.hovered'),
});

const SkeletonGlyph = () => <Box testId="icon-skeleton" xcss={iconSkeletonStyle} />;

export const ShareAndRestrictButtonPlaceholder: FC = () => (
	<Button
		appearance="default"
		iconBefore={() => <SkeletonGlyph />}
		onClick={() => {}}
		isDisabled
		testId="share-and-restrict-button-placeholder"
	>
		<FormattedMessage {...i18n.share} />
	</Button>
);
