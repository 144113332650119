import { useIsTransition } from '@confluence/route-manager';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';

declare global {
	interface Window {
		__SSR_IS_LIVE_PAGE__?: boolean;
	}
}

// Hook implementing the "shortcut" logic for determining the page type.
// On SSR or on post-SSR client hydration we know for certain if the page is live or not, so we don't need to query anything else.
export const useIsLivePageWithSSR = () => {
	const isTransition = useIsTransition();

	if (process.env.REACT_SSR) {
		return {
			isLive: getSSRRenderInputs().isLivePage,
			isTypeKnown: true,
		};
	}

	if (isTransition) {
		return {
			isLive: undefined,
			isTypeKnown: false,
		};
	}

	return {
		isLive: window.__SSR_IS_LIVE_PAGE__,
		isTypeKnown: window.__SSR_IS_LIVE_PAGE__ !== undefined,
	};
};
