import React, { useEffect } from 'react';

import { AIEventsInstrumentationProvider } from '@atlassian/ai-analytics';

import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { AiBulkNavigationContainer } from './AiBulkNavigationContainer';
import { BulkIssueCreateProvidersWrapper } from './providers/BulkIssueCreateProvidersWrapper';
import { useHasRequiredFields } from './utils/useHasRequiredFields';
import { IssueCreateWarningDialog } from './IssueCreateWarningDialog';
import { useGetWarningDialogActions } from './utils/useGetWarningDialogActions';
import { useGetInitialData } from './utils/useGetInitialData';
import {
	AI_BULK_ISSUE_CREATE_FEATURE_NAME,
	useBulkCreateLoggingEvents,
} from './utils/useBulkCreateLoggingEvents';
import { BulkNavigationContainer } from './BulkNavigationContainer';

export const bulkCreateSidePanelTestId = 'bulk-issue-create-side-panel';
export const BULK_ISSUE_CREATE_SIDE_PANEL_ID = 'bulk-issue-create-side-panel';
export const BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH = 320;

export const BulkCreateSidePanelComponent = ({ onClose }: { onClose: () => void }) => {
	useGetInitialData();
	const [state] = useBulkCreateContext();
	const { isCreatedWithAi } = state;
	useHasRequiredFields();

	const { isWarningDialogOpen, handleConfirm, handleCancel } = useGetWarningDialogActions(onClose);

	const { logAiInteractionInitiated } = useBulkCreateLoggingEvents();

	useEffect(() => {
		logAiInteractionInitiated();
	}, [logAiInteractionInitiated]);

	return (
		<>
			{isCreatedWithAi ? (
				<AiBulkNavigationContainer onClose={onClose} />
			) : (
				<BulkNavigationContainer onClose={onClose} />
			)}
			{isWarningDialogOpen && (
				<IssueCreateWarningDialog onCancel={handleCancel} onConfirm={handleConfirm} />
			)}
		</>
	);
};

export const BulkCreateSidePanel = ({ onClose }: { onClose: () => void }) => {
	return (
		<BulkIssueCreateProvidersWrapper>
			<AIEventsInstrumentationProvider
				config={{
					// Required configruation
					product: 'confluence',
					subproduct: 'jira',
					aiFeatureName: AI_BULK_ISSUE_CREATE_FEATURE_NAME,
					proactiveGeneratedAI: 0,
					userGeneratedAI: 1,
					// Optional configuration
					source: 'confluenceHighlightIssueCreate',
					skipAISessionValidation: true,
				}}
			>
				<BulkCreateSidePanelComponent onClose={onClose} />
			</AIEventsInstrumentationProvider>
		</BulkIssueCreateProvidersWrapper>
	);
};
