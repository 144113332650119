import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import SettingsIcon from '@atlaskit/icon/core/settings';
import Tooltip from '@atlaskit/tooltip';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';
import {
	ADMIN_CENTER_SPACE,
	SPACE_SETTINGS,
	SPACE_SETTINGS_DETAILS,
} from '@confluence/named-routes';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useSpaceKey } from '@confluence/space-utils';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

const i18n = defineMessages({
	spaceSettingsLinkTooltip: {
		id: 'side-navigation.space-navigation.space-settings-link.tooltip',
		defaultMessage: 'View settings for this space',
		description: 'Tooltip message on hover of space settings space link',
	},
	spaceSettingsLinkTitle: {
		id: 'side-navigation.space-navigation.space-settings-link.title',
		defaultMessage: 'Space settings',
		description: 'Space Settings Menu Item',
	},
});

export const useSpaceSettingsLinkUtils = (props: { spaceKey?: string }) => {
	const { edition } = useSessionData();
	const spaceKey = useSpaceKey(props.spaceKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const spaceDetailsRoute = fg('confluence-fe-space-settings-space-details')
		? SPACE_SETTINGS_DETAILS.toUrl({ spaceKey })
		: SPACE_SETTINGS.toUrl({ spaceKey });

	return {
		getHref: ({ isSpaceAdmin }: { isSpaceAdmin: boolean }) =>
			isSpaceAdmin && edition === ConfluenceEdition.PREMIUM
				? ADMIN_CENTER_SPACE.toUrl({ spaceKey })
				: spaceDetailsRoute,
		fireClickedEvent: ({ analyticsSource }: { analyticsSource: string }) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'spaceSettingsLink',
					source: analyticsSource,
				},
			}).fire();
		},
		isExperimentTest: expValEquals('confluence_nav_4_space_settings_entry_point', 'cohort', 'test'),
	};
};

export type SpaceSettingsLinkProps = {
	isSpaceAdmin: boolean;
	analyticsSource: string;
};

export const SpaceSettingsLink = ({ isSpaceAdmin, analyticsSource }: SpaceSettingsLinkProps) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { getHref, fireClickedEvent, isExperimentTest } = useSpaceSettingsLinkUtils({});
	if (!isExperimentTest) {
		return null;
	}

	return (
		<Tooltip position="top" content={<FormattedMessage {...i18n.spaceSettingsLinkTooltip} />}>
			<MenuLinkItem
				href={getHref({ isSpaceAdmin })}
				onClick={() => fireClickedEvent({ analyticsSource })}
				elemBefore={<SettingsIcon label="" color="currentColor" />}
				data-vc="space-settings-navigation"
				{...ssrPlaceholderIdProp}
			>
				<FormattedMessage {...i18n.spaceSettingsLinkTitle} />
			</MenuLinkItem>
		</Tooltip>
	);
};
