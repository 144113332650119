/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';

import { ActiveInlineCommentsQuery } from '@confluence/inline-comments-queries';
import type {
	ActiveInlineCommentsQueryType,
	ActiveInlineCommentsQueryVariables,
	GraphQLContentStatus,
} from '@confluence/inline-comments-queries';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useEditorAnnotations } from '@confluence/inline-comments-hooks';
import { useSessionData } from '@confluence/session-data';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';

import { useCommentsData, type AnnotationStatus } from '../useCommentsData';
import { updateCommentsDataState } from '../helper/commentsDataHelper';

export const COMMENT_BATCH_SIZE = 1000; // setting to 1000 for now until we implement infinite scroll for the comments panel

// gets comments using paginated query
export const useActiveInlineCommentsQuery = ({
	pageId,
	markerRefList,
	orderedActiveAnnotationIdList,
	skip = false,
	batchSize,
}: {
	pageId: string;
	markerRefList: string[];
	orderedActiveAnnotationIdList: AnnotationStatus[];
	skip?: boolean;
	batchSize?: number;
}) => {
	// The query loading was not sticking around long enough or triggering at the right times
	// so I created a loading state that will set after the query is successful/fails
	const [loading, setLoading] = useState(true);
	const [{ inlineCommentsDataMap }, { addNewCommentThreads, setInlineCommentsDataMap }] =
		useCommentsData();

	const [{ readCommentsListState }] = useUnreadInlineComments();

	const [{ annotations }] = useEditorAnnotations();
	const pageMode = useGetPageMode();
	const { userId: currentUserId } = useSessionData();

	const [startOffset, setStartOffset] = useState(0);
	const [endOffset, setEndOffset] = useState(COMMENT_BATCH_SIZE);

	// Determine the batch size to use
	const numParentCommentsToFetch = batchSize || COMMENT_BATCH_SIZE;

	// Determine if the query should be skipped
	const skipQuery = !pageId || markerRefList.length === 0 || skip;

	// Determine the subset of markers to fetch based on offsets
	const currentMarkerRefList = useMemo(() => {
		return markerRefList.slice(startOffset, endOffset);
	}, [markerRefList, startOffset, endOffset]);

	const annotationsInEditorDoc = useMemo(() => {
		return new Set(annotations);
	}, [annotations]);

	const hasAlreadyFetchedInitialData = Object.keys(inlineCommentsDataMap).length > 0;

	// Execute the query
	const {
		data,
		loading: queryLoading,
		error,
		refetch,
	} = useQuery<ActiveInlineCommentsQueryType, ActiveInlineCommentsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ActiveInlineCommentsQuery,
		{
			variables: {
				pageId,
				inlineMarkerRefList: currentMarkerRefList,
				contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
			},
			// Skip is not respected after the initial call: https://github.com/apollographql/apollo-client/issues/6190#issuecomment-649933628
			fetchPolicy: skipQuery ? 'cache-only' : 'cache-first', // We want to utilize the cache as much as possible, but keep the list up to date if it changes
			skip: skipQuery,
			onCompleted: (data: ActiveInlineCommentsQueryType) => {
				updateCommentsDataState({
					data,
					readCommentsListState,
					updateData: hasAlreadyFetchedInitialData
						? addNewCommentThreads
						: setInlineCommentsDataMap,
					annotationsInEditorDoc,
					pageMode,
					currentUserId: currentUserId ?? '',
					isOpen: true,
				});
				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
		},
	);

	useEffect(() => {
		if (queryLoading) {
			setLoading(true);
		}
	}, [queryLoading]);

	// Function to fetch more annotations before the current annotation
	const fetchMoreAnnotationsBefore = useCallback(async () => {
		if (!loading) {
			setStartOffset((prevOffset) => Math.max(0, prevOffset - numParentCommentsToFetch));
			await refetch();
		}
	}, [refetch, numParentCommentsToFetch, loading]);

	// Function to fetch more annotations after the current annotation
	const fetchMoreAnnotationsAfter = useCallback(async () => {
		if (!loading) {
			setEndOffset((prevOffset) =>
				Math.min(orderedActiveAnnotationIdList.length, prevOffset + numParentCommentsToFetch),
			);
			await refetch();
		}
	}, [refetch, numParentCommentsToFetch, orderedActiveAnnotationIdList.length, loading]);

	return {
		loading: !skipQuery ? loading : false, // If we skip the query make sure loading is false
		data,
		error,
		fetchMoreAnnotationsBefore,
		fetchMoreAnnotationsAfter,
		refetch,
	};
};
