import React, { type ReactNode, useCallback, useRef } from 'react';

import AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import { AnalyticsListener } from '@atlaskit/analytics-next';
import {
	ANALYTICS_BRIDGE_CHANNEL,
	extractAWCDataFromEvent,
	OPERATIONAL_EVENT_TYPE,
	SCREEN_EVENT_TYPE,
	TRACK_EVENT_TYPE,
	UI_EVENT_TYPE,
} from '@atlassian/analytics-bridge';

import { ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED } from './constants';

const analyticsClientInfo = {
	env: process.env.NODE_ENV !== 'production' ? 'staging' : 'prod',
	product: 'jira',
};

export type AvailableSite = {
	avatarUrl: string;
	cloudId: string;
	displayName: string;
	isVertigo: boolean;
	products: AvailableSitesProductType[];
	url: string;
};

export type AvailableSitesResponse = {
	sites: AvailableSite[];
};
type AvailableSitesProductType =
	| 'jira-core.ondemand'
	| 'jira-incident-manager.ondemand'
	| 'jira-product-discovery'
	| 'jira-servicedesk.ondemand'
	| 'jira-software.ondemand';

const LIGHT_MODE = 'light' as const;
const DARK_MODE = 'dark' as const;
const AUTO = 'auto' as const;
export type ThemeMode = typeof DARK_MODE | typeof LIGHT_MODE | typeof AUTO | undefined;

// Server side cannot handle a url over 2048 characters
export const MAX_CHARACTERS_PER_URL = 2048;

export type GicConfigurationProps = {
	/**
	 * Keeps only the required fields in the GIC embed
	 */
	displayOnlyRequiredFields?: boolean;
	/**
	 * removes project selector
	 */
	disableProjectDropdown?: boolean;
	/**
	 * removes site selector
	 * @default true
	 */
	displaySitePicker?: boolean;
};

// Adapted from function of the same name from `@atlassian/link-create-jira`
// This gets the Issue Create embed URL for the given domain name and params
export const getIframeSrc = ({
	colorMode = AUTO,
	domainName = '',
	overrideCloudId,
	params,
	onFailure,
	displaySitePicker,
	disableProjectDropdown,
	displayOnlyRequiredFields,
}: {
	colorMode?: ThemeMode;
	domainName?: string;
	params?: URLSearchParams | Record<string, string>;
	overrideCloudId?: string;
	onFailure?: (error: unknown) => void;
} & GicConfigurationProps): string => {
	const iframeParams = new URLSearchParams(params);
	iframeParams.append('postMessageInit', '1');

	if (displaySitePicker === false) {
		iframeParams.append('displaySitePicker', 'false');
	}
	if (disableProjectDropdown) {
		iframeParams.append('disableProjectDropdown', 'true');
	}
	if (displayOnlyRequiredFields) {
		iframeParams.append('displayOnlyRequiredFields', 'true');
	}
	if (colorMode) {
		iframeParams.append('themeMode', colorMode);
	}
	if (overrideCloudId) {
		iframeParams.append('overrideCloudId', overrideCloudId);
	}
	/**
	 * We want to use the postMessageInit param to instruct the Jira embed iframe
	 * to wait before mounting Issue Create.
	 * It will send a message back to us when it's ready to receive the init message
	 */
	const srcUrl = `${domainName}/jira/issues/create/embed?${iframeParams}`;

	if (srcUrl.length > MAX_CHARACTERS_PER_URL) {
		// Will need to handle this case properly in the future, only logging a warning for now
		if (process.env.NODE_ENV !== 'production') {
			/* eslint-disable-next-line no-console */
			console.warn(ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED);
		}
		onFailure?.(new Error(ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED));
	}

	return srcUrl;
};

export const JIRA_PRODUCTS: AvailableSitesProductType[] = [
	'jira-software.ondemand',
	'jira-core.ondemand',
	'jira-servicedesk.ondemand',
	'jira-product-discovery',
	// 'jira-incident-manager.ondemand',
];

export async function getAvailableJiraSites(): Promise<AvailableSitesResponse> {
	const requestConfig = {
		method: 'POST',
		credentials: 'include' as RequestCredentials,
		headers: {
			Accept: 'application/json',
			'Cache-Control': 'no-cache',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			products: JIRA_PRODUCTS,
		}),
	};

	const response = await fetch('/gateway/api/available-sites', requestConfig);
	if (response.ok) {
		return response.json();
	}
	throw response;
}

export const AnalyticsProvider = (props: { children: ReactNode }) => {
	const analyticsClient = useRef<AnalyticsWebClient>(
		new AnalyticsWebClient(analyticsClientInfo, {}),
	);

	const sendEvent = useCallback((event: { type: string; payload: Record<string, any> }) => {
		const { type, payload } = event;
		switch (type) {
			case UI_EVENT_TYPE:
				// @ts-expect-error - Argument of type 'Record<string, any>' is not assignable to parameter of type 'UITrackAndOperationalEventPayload'
				analyticsClient.current.sendUIEvent(payload);
				break;
			case TRACK_EVENT_TYPE:
				// @ts-expect-error - Argument of type 'Record<string, any>' is not assignable to parameter of type 'UITrackAndOperationalEventPayload'
				analyticsClient.current.sendTrackEvent(payload);
				break;
			case OPERATIONAL_EVENT_TYPE:
				// @ts-expect-error - Argument of type 'Record<string, any>' is not assignable to parameter of type 'UITrackAndOperationalEventPayload'
				analyticsClient.current.sendOperationalEvent(payload);
				break;
			case SCREEN_EVENT_TYPE:
				analyticsClient.current.sendScreenEvent(payload.name, null, payload.attributes);
				break;
			default:
				break;
		}
	}, []);

	return (
		<AnalyticsListener
			channel={ANALYTICS_BRIDGE_CHANNEL}
			onEvent={(event) => sendEvent(extractAWCDataFromEvent(event))}
		>
			{props.children}
		</AnalyticsListener>
	);
};
