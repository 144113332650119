import { useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import type { MutationUpdaterFn } from 'apollo-client';

import { getLogger } from '@confluence/logger';
import { useSessionData } from '@confluence/session-data';

import { FeatureDiscoveryQuery } from './FeatureDiscoveryQuery.graphql';
import { MarkFeatureDiscoveredMutation } from './MarkFeatureDiscoveredMutation.graphql';
import type {
	FeatureDiscoveryQuery as FeatureDiscoveryQueryType,
	FeatureDiscoveryQuery_featureDiscovery as FeatureDiscovery,
} from './__types__/FeatureDiscoveryQuery';
import type {
	MarkFeatureDiscoveredMutation as MarkFeatureDiscoveredMutationType,
	MarkFeatureDiscoveredMutationVariables,
} from './__types__/MarkFeatureDiscoveredMutation';

const logger = getLogger('useFeatureDiscovery');
export const PLUGIN_KEY = 'com.atlassian.confluence.plugins.confluence-frontend-support';

const updateCache: MutationUpdaterFn<MarkFeatureDiscoveredMutationType> = (cache, { data }) => {
	const newMarkedFeature = data?.markFeatureDiscovered;
	const cachedFeatureData = cache.readQuery<FeatureDiscoveryQueryType>({
		query: FeatureDiscoveryQuery,
	});
	if (newMarkedFeature && cachedFeatureData?.featureDiscovery) {
		cache.writeQuery<FeatureDiscoveryQueryType>({
			query: FeatureDiscoveryQuery,
			data: {
				featureDiscovery: cachedFeatureData.featureDiscovery.concat(newMarkedFeature),
			},
		});
	}
};

const getLocalOverrideValue = (featureKey: string): boolean => {
	try {
		if (sessionStorage.getItem(`featureDiscoveryOverride-${featureKey}`)) {
			return true;
		}
	} catch (e) {
		logger.error(e);
	}
	return false;
};

const noop = () => {};

/**
 * @deprecated Please use `useOnboardingState` instead.
 */
export type UseFeatureDiscoveryResponse = [boolean, () => void, Error | undefined];

/**
 * @deprecated Please use `useOnboardingState` instead.
 */
export const useFeatureDiscovery = (
	featureKey: string,
	skip?: boolean,
): UseFeatureDiscoveryResponse => {
	const { isLoggedIn } = useSessionData();
	const skipFeatureDiscoveryQuery = !isLoggedIn || skip;

	const {
		data,
		loading: queryLoading,
		error: queryError,
	} = useQuery<FeatureDiscoveryQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FeatureDiscoveryQuery,
		{
			skip: skipFeatureDiscoveryQuery,
		},
	);
	const [markFeatureDiscovered, { loading: mutationLoading, error: mutationError }] = useMutation<
		MarkFeatureDiscoveredMutationType,
		MarkFeatureDiscoveredMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		MarkFeatureDiscoveredMutation,
		{
			variables: {
				featureKey,
				pluginKey: PLUGIN_KEY,
			},
			update: updateCache,
		},
	);

	const loading = queryLoading || mutationLoading;
	const error = queryError || mutationError;

	const showFeature =
		!loading &&
		!error &&
		!data?.featureDiscovery?.some(
			(feature: FeatureDiscovery | null) =>
				feature?.pluginKey === PLUGIN_KEY && feature?.featureKey === featureKey,
		);

	const handleStop = useCallback(() => {
		if (showFeature) {
			// error is handled in the useMutation result
			markFeatureDiscovered().catch(() => {});
		}
	}, [showFeature, markFeatureDiscovered]);

	if (skipFeatureDiscoveryQuery) {
		return [false, noop, undefined];
	}

	// this is for dev/testing, since we cant easily remove data from the featureDiscovery array
	const forceShowFeature = showFeature || getLocalOverrideValue(featureKey);

	return [forceShowFeature, handleStop, error];
};
