import React from 'react';
import { styled } from '@compiled/react';
import { fg } from '@confluence/feature-gating';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/custom-theme-button';
import LegacyStarUnstarredIcon from '@atlaskit/icon/core/migration/star-unstarred--star';
import UnstarredIcon from '@atlaskit/icon/core/star-unstarred';

import { SSRMouseEventWrapper, SSR_FAVORITE_BUTTON_METRIC } from '@confluence/browser-metrics';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025', '2px'),
		paddingRight: token('space.025', '2px'),
	},
});

export const FavouriteButtonPlaceholder = () => (
	<SSRMouseEventWrapper metricName={SSR_FAVORITE_BUTTON_METRIC} isPlaceholder>
		<StyledButton
			data-test-id="page-favourite-button-placeholder"
			appearance="subtle"
			iconBefore={
				fg('confluence_frontend_object_header') ? (
					<UnstarredIcon label="" color="currentColor" LEGACY_size="medium" spacing="spacious" />
				) : (
					<LegacyStarUnstarredIcon label="StarIcon" spacing="spacious" />
				)
			}
			isDisabled
		/>
	</SSRMouseEventWrapper>
);
