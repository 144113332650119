/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef, type ReactNode, type Ref } from 'react';

import { jsx } from '@compiled/react';

import type { StrictXCSSProp } from '@atlaskit/css';

type ListItemProps = {
	children: ReactNode;
	xcss?: StrictXCSSProp<'display' | 'scrollMarginInline', never>;
};

function _ListItem({ children, xcss }: ListItemProps, ref: Ref<HTMLDivElement>) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
		<div role="listitem" ref={ref} className={xcss}>
			{children}
		</div>
	);
}

/**
 * __List item__
 *
 * An element with the role of `listitem`.
 */
export const ListItem = forwardRef<HTMLDivElement, ListItemProps>(_ListItem);
