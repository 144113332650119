import {
	JiraExtension,
	type ForgeUIExtensionType,
	type JiraExtensionType,
} from '@atlassian/forge-ui-types';
import { createMakeProductURLPath } from '../createMakeProductURLPath';

type FullForgeUIExtensionType = ForgeUIExtensionType & {
	properties: ForgeUIExtensionType['properties'] & {
		route?: string;
	};
};

const makeJiraURLPath = createMakeProductURLPath('/jira');

export const jiraExtensionURLHelpers: Partial<
	Record<JiraExtensionType, (extension: FullForgeUIExtensionType, params?: any) => string | null>
> = {
	[JiraExtension.ADMIN_PAGE]: ({ appId, environmentId }) => {
		if (appId && environmentId) {
			return makeJiraURLPath(`/settings/apps/${appId}/${environmentId}`);
		}
		return null;
	},
	[JiraExtension.GLOBAL_PAGE]: ({ appId, environmentId }) => {
		if (appId && environmentId) {
			return makeJiraURLPath(`/apps/${appId}/${environmentId}`);
		}
		return null;
	},
	[JiraExtension.PERSONAL_SETTINGS_PAGE]: ({ appId, environmentId }) => {
		if (appId && environmentId) {
			return makeJiraURLPath(`/settings/personal/apps/${appId}/${environmentId}`);
		}
		return null;
	},
	[JiraExtension.PROJECT_PAGE]: ({ appId, environmentId }, { projectKey }) => {
		if (appId && environmentId) {
			return makeJiraURLPath(`/software/projects/${projectKey}/apps/${appId}/${environmentId}`);
		}
		return null;
	},
	[JiraExtension.PROJECT_SETTINGS_PAGE]: ({ appId, environmentId }, { projectKey }) => {
		if (appId && environmentId) {
			return makeJiraURLPath(
				`/software/projects/${projectKey}/settings/apps/${appId}/${environmentId}`,
			);
		}
		return null;
	},
};

export const jiraNavigationTargetHandlers = {
	dashboard: async ({ dashboardId }: { dashboardId: string }) => {
		return makeJiraURLPath(`/dashboards/${dashboardId}`);
	},
	issue: async ({ issueKey }: { issueKey: string }) => {
		return `/browse/${issueKey}`;
	},
	// equivalent to Connects projectAdminSummary target
	projectSettingsDetails: async ({ projectKey }: { projectKey: string }) => {
		return makeJiraURLPath(`/software/projects/${projectKey}/settings/details`);
	},
	// equivalent to Connects projectAdminTabPanel target
	projectSettingsPage: async ({
		extensionId,
		environmentId,
		projectKey,
	}: {
		extensionId: string;
		environmentId: string;
		projectKey: string;
	}) => {
		return makeJiraURLPath(
			`/software/projects/${projectKey}/settings/apps/${extensionId}${environmentId}`,
		);
	},
} as const;
