import React from 'react';

export const EmptyUnreadSVG = () => {
	return (
		<svg
			width="127"
			height="126"
			viewBox="0 0 127 126"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M101.3 71.729C101.831 68.7509 102.106 65.594 102.106 62.2401C102.106 58.8862 101.822 55.6423 101.286 52.6274C98.0694 34.6942 85.6068 23.6429 68.1363 20.7975C65.1536 20.3165 62.0288 20.0645 58.7803 20.0645C55.5317 20.0645 52.132 20.3302 49.0347 20.8525C31.5551 23.7711 19.12 34.7996 15.8899 52.6274C15.3446 55.6377 15.0605 58.845 15.0605 62.2401C15.0605 65.6352 15.34 68.7509 15.8715 71.729C19.0742 89.5386 31.523 100.585 49.0301 103.504C52.1275 104.022 55.3851 104.292 58.7757 104.292C62.1662 104.292 65.1536 104.045 68.1317 103.559C85.6297 100.718 98.0968 89.6394 101.295 71.729H101.3Z"
				fill="#DDDEE1"
			/>
			<path
				d="M73.7172 61.9251L107.219 27.0254L119.609 38.4983L70.9452 80.2707L48.0361 59.1623L59.9489 45.4076L73.7126 61.9297L73.7172 61.9251Z"
				fill="#1868DB"
			/>
			<path
				d="M101.29 52.6279C100.337 47.3405 98.5824 42.6396 96.1311 38.5801L73.7214 61.9244L59.9577 45.4024L48.0449 59.157L70.954 80.2655L101.524 54.0254C101.455 53.5535 101.382 53.0861 101.299 52.6234L101.29 52.6279Z"
				fill="#09326C"
			/>
		</svg>
	);
};
