import type { Principal } from '../../controlled/commonTypes';

import {
	type ShareAndRestrictDialogQueryV1_user,
	type ShareAndRestrictDialogQueryV1_user_emails,
	type ShareAndRestrictDialogQueryV1_user_photos,
} from './__types__/ShareAndRestrictDialogQueryV1';

export function getCurrentUserPrincipal(
	user: ShareAndRestrictDialogQueryV1_user | null,
): Principal | null {
	if (!user) return null;

	// Insist on a bare minimum of profile data
	const { id, displayName, photos, emails } = user;
	if (!id) return null;
	if (!displayName) return null;

	return {
		type: 'user',
		id,
		name: displayName,
		avatarUrl: getPrimaryValue(photos),
		email: getPrimaryValue(emails),
		knownErrors: undefined,
		sitePermissionType: 'INTERNAL',
	};
}

function getPrimaryValue(
	maybeList:
		| (ShareAndRestrictDialogQueryV1_user_photos | null)[]
		| (ShareAndRestrictDialogQueryV1_user_emails | null)[]
		| null
		| undefined,
): string {
	let best = '';
	if (maybeList) {
		for (const item of maybeList) {
			if (!item) continue;
			const { value, isPrimary } = item;
			if (!value) continue;
			best = value;
			if (isPrimary) break;
		}
	}
	return best;
}
