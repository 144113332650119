import { useCallback, useEffect, useRef, useState } from 'react';

import isEqual from 'lodash/isEqual';
import type { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

export type ObservableStatefulInput<TInputs extends Readonly<any[]>, TState> = {
	inputs: [...TInputs];
	state: TState;
};

export function useStatefulObservableEffect<TState, TInputs extends Readonly<any[]>>(
	onObservableInit: (
		input$: Observable<ObservableStatefulInput<TInputs, TState>>,
	) => Observable<TState>,
	initialState: TState,
	inputs: [...TInputs],
): [TState, (nextState: ObservableStatefulInput<TInputs, TState>) => void] {
	const [state, setState] = useState(initialState);
	const subject$ = useRef<Subject<ObservableStatefulInput<TInputs, TState>> | null>(null);
	const observable$ = useRef<Observable<TState> | null>(null);
	const prevInputs = useRef(inputs);

	useEffect(() => {
		subject$.current = new Subject<ObservableStatefulInput<TInputs, TState>>();
		observable$.current = onObservableInit(subject$.current);
		const subscription = observable$.current.subscribe({
			next: (nextState) => setState(nextState),
			// eslint-disable-next-line no-console
			error: (error) => console.error(error),
			complete: () => subject$.current?.complete(),
		});
		subject$.current.next({ inputs, state: initialState });
		return () => {
			subscription.unsubscribe();
			subject$.current?.complete();
		};
		// we only want this to run on initial mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isEqual(inputs, prevInputs.current)) {
			subject$.current?.next({ inputs, state });
			prevInputs.current = inputs;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, inputs);

	const callback = useCallback(
		(nextState: ObservableStatefulInput<TInputs, TState>) => {
			subject$.current?.next(nextState);
		},
		// empty dependency array because
		// 1. subject$.current is always the same reference.
		// 2. The function of the callback (to emit a new value to the subject) never changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return [state, callback];
}
