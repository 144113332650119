import React from 'react';

import { LoadableLazy, LoadableAfterPaint } from '@confluence/loadable';

import { QuickSummaryButtonPlaceholder } from './QuickSummaryButtonPlaceholder';

export { i18n } from './i18n';
export { CustomPopupContainer } from './CustomPopupContainer';
export { SmartsThemedButton } from './shared-components/SmartsThemedButton';
export { SummaryPopupScrollableBody } from './shared-components/SummaryPopupScrollableBody';
export { AtlassianIntelligenceIconDark } from './assets/AtlassianIntelligenceIconDark';
export { QuickSummaryIcon } from './assets/QuickSummaryIcon';
export { InfoIcon } from './assets/InfoIcon';
export { QuickSummaryIcon as AIButtonIcon } from './assets/QuickSummaryIcon';
export {
	FeedbackCollectorPropertiesContext,
	FeedbackCollectorPropertiesProvider,
	type FeedbackCollectorProperties,
} from './shared-components/FeedbackCollectorPropertiesProvider';
export {
	CommentsSummaryAnalyticsType,
	CommentSummaryType,
	type SummaryAnalyticsPropertiesType,
} from './shared-components/summarySharedTypes';
export { SmartsFeedbackComponent } from './shared-components/SmartsFeedbackComponent';
export { ContentContributorsQuery } from './ContentContributorQuery.graphql';
export {
	TROUBLE_SHOOTING_DOCS_URL,
	USAGE_LIMITS_DOCS_URL,
	ACCEPTABLE_USE_POLICY_URL,
} from './shared-components/errorMessageURLS';
export {
	HandledErrorType,
	tooMuchContentString,
	notEnoughContentString,
	useSummaryErrorHelper,
} from './shared-components/useSummaryErrorHelper';
export { QuickSummaryButtonPlaceholder } from './QuickSummaryButtonPlaceholder';
export { QuickSummaryButtonWhitePlaceholder } from './QuickSummaryButtonWhitePlaceholder';
export { QuickSummaryUpsell } from './QuickSummaryUpsell';

export type {
	ContentContributorsQuery as ContentContributorsQueryType,
	ContentContributorsQueryVariables,
} from './__types__/ContentContributorsQuery';

export { CreatePageModalContextProvider } from './CreatePageModal/CreatePageModalContextProvider';

export const QuickSummaryPopup = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuickSummaryPopup" */ './QuickSummaryPopup'))
			.QuickSummaryPopup,
	loading: ({ buttonStyles }) => <QuickSummaryButtonPlaceholder buttonStyles={buttonStyles} />,
});

export const QuickSummaryStreamingComponent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-QuickSummaryStreamingComponent" */ './QuickSummaryStreaming/QuickSummaryStreamingComponent'
			)
		).QuickSummaryStreamingComponent,
});

export const AISummaryFooter = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AISummaryFooter" */ '../src/shared-components/AISummaryFooter'
			)
		).AISummaryFooter,
});

export const SmartsSummaryErrorComponent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AISummaryFooter" */ '../src/shared-components/SmartsSummaryErrorComponent'
			)
		).SmartsSummaryErrorComponent,
});
