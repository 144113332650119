import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import type { FlagsStateContainer } from '@confluence/flags';

import { ConvertToLivePageDialog } from './LazyConvertToLivePageDialog';
import type { ADFOrigin } from './ConvertToLivePageDialog';
import { convertDraftToLivePage } from './convertDraftToLivePage';
import type { RetrieveEditorTitleAndContent } from './convertDraftToLivePage';

type CommonProps = {
	contentId: string;
	spaceId: string | undefined;
	analyticsSource: string;
	onSuccessfulConversion?: () => void;
};

type PropsForUnpublishedDraft = {
	isUnpublishedDraft: true;
	flags: FlagsStateContainer;
	retrieveEditorTitleAndContent: RetrieveEditorTitleAndContent;
	saveDraft?: (arg?: any) => Promise<void>;
	// We don't use `adfOrigin` in this case
	adfOrigin?: any;
} & CommonProps;

type PropsForPublishedPage = {
	isUnpublishedDraft: false;
	adfOrigin: ADFOrigin;
	// We don't use `flags`, `retrieveEditorTitleAndContent`, or `saveDraft` in this case
	flags?: any;
	retrieveEditorTitleAndContent?: any;
	saveDraft?: any;
} & CommonProps;
export type UseSwitchToLivePageProps = PropsForUnpublishedDraft | PropsForPublishedPage;

export type UseSwitchToLivePageReturnType = () => Promise<void>;

export const useSwitchToLivePage = (
	props: UseSwitchToLivePageProps,
): UseSwitchToLivePageReturnType => {
	const {
		contentId,
		spaceId,
		isUnpublishedDraft,
		analyticsSource,
		adfOrigin,
		onSuccessfulConversion,
	} = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showModal } = useDialogs();

	const onSuccess = () => {
		createAnalyticsEvent?.({
			type: 'sendUIEvent',
			data: {
				action: 'convertedToLivePage',
				actionSubject: 'page',
				source: analyticsSource,
				objectId: contentId,
				objectType: 'page',
				containerId: spaceId,
				containerType: 'space',
				attributes: {
					isDraft: isUnpublishedDraft,
				},
			},
		}).fire();

		onSuccessfulConversion?.();
	};

	return async () => {
		createAnalyticsEvent?.({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'switchToLiveEdit',
				source: analyticsSource,
				objectId: contentId,
				objectType: 'page',
				containerId: spaceId,
				containerType: 'space',
				attributes: {
					isDraft: isUnpublishedDraft,
				},
			},
		}).fire();

		if (!isUnpublishedDraft) {
			showModal(ConvertToLivePageDialog, {
				contentId,
				onConfirm: onSuccess,
				adfOrigin,
			});
		} else {
			const { flags, retrieveEditorTitleAndContent, saveDraft } = props;
			// Trigger draft sync before converting. If we don't do this and the user recently updated their draft title then quickly converted the draft, draft sync may not have triggered and we may not identify their title conflict.
			await saveDraft?.();
			void convertDraftToLivePage({
				contentId,
				onSuccess,
				flags,
				retrieveEditorTitleAndContent,
			});
		}
	};
};
