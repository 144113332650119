import type { FC } from 'react';
import React from 'react';
import { css } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import { N20A } from '@atlaskit/theme/colors';

import { SSRMouseEventWrapper, SSR_REACTIONS_BUTTON_METRIC } from '@confluence/browser-metrics';
import { usePageContentId } from '@confluence/page-context';

import { ReactionsPlaceholderQuery } from './graphql/ReactionsPlaceholderQuery.graphql';
import type {
	ReactionsPlaceholderQuery as ContentQueryType,
	ReactionsPlaceholderQueryVariables as ContentQueryVariables,
} from './graphql/__types__/ReactionsPlaceholderQuery';

const placeholderContainerStyles = css({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	cursor: 'not-allowed !important',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
	marginLeft: '0px',
});

const reactionPillStyles = css({
	width: 42,
	height: 24,
	background: token('color.skeleton.subtle', N20A),
	borderRadius: 20,
	marginRight: token('space.050', '4px'),
});

const reactionIconStyles = css({
	width: 12,
	height: 12,
	background: token('color.skeleton.subtle', N20A),
	borderRadius: 20,
	marginLeft: token('space.075', '6px'),
});

type ReactionsPlaceholderProps = {
	useAccuratePills?: boolean;
};

const DEFAULT_NUM_PILLS = 2;

export const ReactionsPlaceholder: FC<ReactionsPlaceholderProps> = ({ useAccuratePills }) => {
	const isSSR = window.__SSR_RENDERED__ || process.env.REACT_SSR;
	const [contentId] = usePageContentId();
	const { data, loading } = useQuery<ContentQueryType, ContentQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReactionsPlaceholderQuery,
		{
			variables: { contentId: contentId! },
			// Only run this query if we're in SSR and useAccuratePills is set
			skip: !isSSR || !useAccuratePills || !contentId,
		},
	);

	if (loading) {
		return null;
	}

	const renderPlaceholder = (numPills) => (
		<div css={placeholderContainerStyles} data-testid="reactions-placeholder">
			{Array(numPills)
				.fill('')
				// @ts-ignore
				.map((e, i) => (
					<span css={reactionPillStyles} key={i} data-testid="reactions-pill" />
				))}
			<span css={reactionIconStyles} data-testid="reaction-icon" />
		</div>
	);

	// We only need to show accurate pills in SSR
	if (isSSR) {
		const numDistinctReactions =
			data?.content?.nodes?.[0]?.contentReactionsSummary?.reactionsSummaryForEmoji.length;

		// If we get a count of reactions use it, otherwise fall back to the default
		const numReactionPills =
			numDistinctReactions !== undefined ? numDistinctReactions : DEFAULT_NUM_PILLS;

		return (
			<SSRMouseEventWrapper metricName={SSR_REACTIONS_BUTTON_METRIC} isPlaceholder>
				{renderPlaceholder(numReactionPills)}
			</SSRMouseEventWrapper>
		);
	} else {
		return renderPlaceholder(DEFAULT_NUM_PILLS);
	}
};
