import type { FC, ComponentProps } from 'react';
import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { ByLine, ContentTitle } from '@confluence/content-header';
import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
} from '@confluence/content-topper';

import { useViewPageParams } from './useViewPageParams';

type ByLinePassThroughProps = Pick<
	ComponentProps<typeof ByLine>,
	'hasByLineContributors' | 'hasByLineExtensions'
>;

type Props = Partial<ByLinePassThroughProps> &
	ComponentProps<typeof ContentTitle> & {
		hasCoverPicture?: boolean;
		pageWidthType?: string;
		isEmbeddedPage?: boolean;
		shouldShowBylineReactions?: boolean;
		contentType?: string | null;
		spaceId?: string | null;
		contentSubType?: string | null;
		isUsingPublicLinkInfo?: boolean;
	};

const BylineCenterStyles = xcss({
	justifyContent: 'center',
});

export const ContentTitleWithByLine: FC<Props> = ({
	contentId,
	spaceKey,
	banner,
	hasByLineContributors,
	hasByLineExtensions,
	headingContainer,
	hasCoverPicture,
	pageWidthType,
	isEmbeddedPage,
	shouldShowBylineReactions,
	contentType,
	contentSubType,
	spaceId,
	isUsingPublicLinkInfo,
}: Props) => {
	const { versionOverride, embeddedContentRender } = useViewPageParams();

	const { titleContentProperties } = useTitleContentPropertiesForPublishedPage({
		contentId,
	});

	const isBylineCenterStyles =
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;

	const renderContentTitleWithByLine = () => {
		return (
			<ContentTitle
				banner={banner}
				contentId={contentId}
				spaceKey={spaceKey}
				headingContainer={headingContainer}
				hasCoverPicture={hasCoverPicture}
				pageWidthType={pageWidthType}
				isEmbeddedPage={isEmbeddedPage}
				isUsingPublicLinkInfo={isUsingPublicLinkInfo}
			>
				<Box
					xcss={[isBylineCenterStyles && BylineCenterStyles]}
					testId={
						isBylineCenterStyles ? 'centered-content-title-and-byline' : 'content-title-and-byline'
					}
				>
					<ByLine
						contentId={contentId}
						versionOverride={versionOverride}
						embeddedContentRender={embeddedContentRender}
						hasByLineContributors={hasByLineContributors}
						hasByLineExtensions={hasByLineExtensions}
						isUsingPublicLinkInfo={isUsingPublicLinkInfo}
						shouldShowBylineReactions={shouldShowBylineReactions}
						contentType={contentType}
						contentSubType={contentSubType}
						spaceId={spaceId}
					/>
				</Box>
			</ContentTitle>
		);
	};

	return renderContentTitleWithByLine();
};
