import type { FC } from 'react';
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Spinner from '@atlaskit/spinner/spinner';
import Skeleton from '@atlaskit/skeleton';

import {
	APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { useGuestSpacesMenuUpgradeFlowABExperiment } from '@confluence/external-collab-ui/entry-points/useGuestSpacesMenuUpgradeFlowExperiment';
import { GuestRequestToUpgradeSpacesMenu } from '@confluence/external-collab-ui/entry-points/GuestRequestToUpgradeSpacesMenu';
import type { Space } from '@confluence/space-utils/entry-points/useSpacesData';
import { useSpacesData } from '@confluence/space-utils/entry-points/useSpacesData';

import fileCabinet from './img/fileCabinet.svg';
import { SpacesDropdownSection } from './SpacesDropdownSection';
import { SpacesDropdownError } from './SpacesDropdownError';
import errorSearchLight from './img/errorSearchLight.svg';
import errorSearchDark from './img/errorSearchDark.svg';

type SpacesDropdownContentProps = {
	isNav4Enabled: boolean;
	filterText: string;
};

const bottomPaddingContainerStyles = xcss({
	paddingBottom: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyContainer = styled.div({
	textAlign: 'center',
	marginBottom: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> img': {
		width: '40%',
		display: 'block',
		margin: `${token('space.250', '20px')} auto`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LoadingContainer = styled.div({
	textAlign: 'center',
	width: '100%',
	padding: '30px 0',
});

const noResultsTextStyles = xcss({
	font: 'font.body.small',
	color: 'color.text.subtle',
	textAlign: 'center',
	fontWeight: 'font.weight.regular',
});

const i18n = defineMessages({
	starredHeading: {
		id: 'spaces-dropdown.starred',
		defaultMessage: 'Starred',
		description: 'Heading text for starred spaces',
	},
	currentHeading: {
		id: 'spaces-dropdown.current',
		defaultMessage: 'Current',
		description: 'Heading text for current space',
	},
	recentHeading: {
		id: 'spaces-dropdown.recent',
		defaultMessage: 'Recent',
		description: 'Heading text for recent spaces',
	},
	empty: {
		id: 'spaces-dropdown.empty',
		defaultMessage: 'Once you view a space, or create a space, they appear here.',
		description: 'Displayed when there are no spaces to show',
	},
	noResults: {
		id: 'spaces-dropdown.no-results',
		defaultMessage: 'We couldn’t find what you’re looking for.',
		description:
			'Displayed when there are no results message in the spaces dropdown after filtering by name',
	},
});

const EmptyState: FC = () => (
	<EmptyContainer>
		<img src={fileCabinet} alt="file cabinet icon" />
		<FormattedMessage {...i18n.empty} />
	</EmptyContainer>
);

const Nav3LoadingState: FC = () => (
	<LoadingContainer>
		<Spinner size="large" />
	</LoadingContainer>
);

export const LoadingState: FC = () => (
	<Box paddingBlock="space.300">
		<Stack
			space="space.300"
			alignBlock="center"
			alignInline="center"
			grow="fill"
			testId="content-menu-loading"
		>
			{[...Array(10)].map((_, index) => (
				<Skeleton key={index} width="250px" height="9px" borderRadius="3px" />
			))}
		</Stack>
	</Box>
);

const NoFilteredResultsState: FC = () => (
	<Stack alignInline="center" alignBlock="center" space="space.100" grow="fill">
		<Image src={errorSearchLight} srcDark={errorSearchDark} alt="" width="160px" height="160px" />
		<Stack alignInline="center" space="space.075">
			<Box xcss={noResultsTextStyles}>
				<FormattedMessage {...i18n.noResults} />
			</Box>
		</Stack>
	</Stack>
);

const StarredHeading = <FormattedMessage {...i18n.starredHeading} />;

const RecentHeading = <FormattedMessage {...i18n.recentHeading} />;

const CurrentHeading = <FormattedMessage {...i18n.currentHeading} />;

const getFilteredSpaces = (spaces: Space[], filterText: string) => {
	return spaces.filter(
		(space) => !filterText || space?.name?.toLowerCase().includes(filterText.toLowerCase()),
	);
};

export const SpacesDropdownContent: FC<SpacesDropdownContentProps> = ({
	isNav4Enabled,
	filterText,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isLicensed } = useSessionData();
	const { starredSpaces, recentSpaces, currentSpace, loading, errors } = useSpacesData();
	const { isExternalCollaborator: isGuest } = useIsExternalCollaborator();
	const { shouldRenderExperiment } = useGuestSpacesMenuUpgradeFlowABExperiment({
		meetsEnrollmentCriteria: isGuest,
	});
	const [hideRequestToUpgrade, setHideRequestToUpgrade] = useState<boolean>(false);

	const hideRequestToUpgradeHandler = useCallback(
		() => setHideRequestToUpgrade(true),
		[setHideRequestToUpgrade],
	);

	const filteredStarredSpaces = isNav4Enabled
		? getFilteredSpaces(starredSpaces, filterText)
		: starredSpaces;
	const filteredRecentSpaces = isNav4Enabled
		? getFilteredSpaces(recentSpaces, filterText)
		: recentSpaces;
	const filteredCurrentSpace = isNav4Enabled
		? currentSpace
			? getFilteredSpaces([currentSpace], filterText)[0]
			: undefined
		: currentSpace;

	useEffect(() => {
		if (loading || errors.length > 0) {
			return;
		}

		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'done',
				actionSubject: 'spacesDropdown',
				source: 'confluenceFrontend',
				attributes: {
					starredSpacesCount: isLicensed ? starredSpaces.length : 0,
					navVersion: isNav4Enabled ? '4' : '3',
				},
			},
		}).fire();
	}, [
		loading,
		errors.length,
		isLicensed,
		starredSpaces.length,
		createAnalyticsEvent,
		isNav4Enabled,
	]);

	if (errors.length > 0) {
		return (
			<Fragment>
				{errors.map((error) => (
					<ErrorDisplay key={error.name} error={error} />
				))}
				<SpacesDropdownError error={errors[0]} />
			</Fragment>
		);
	}

	if (loading) {
		return isNav4Enabled ? <LoadingState /> : <Nav3LoadingState />;
	}

	if (starredSpaces.length === 0 && recentSpaces.length === 0) {
		return (
			<Fragment>
				{isGuest && !hideRequestToUpgrade && shouldRenderExperiment && (
					<GuestRequestToUpgradeSpacesMenu
						onPermissionDenied={hideRequestToUpgradeHandler}
						onPermissionError={hideRequestToUpgradeHandler}
					/>
				)}
				<EmptyState />
				<ExperienceSuccess
					name={APP_NAV_SPACES_DROPDOWN_EXPERIENCE}
					attributes={{ navVersion: isNav4Enabled ? '4' : '3' }}
				/>
			</Fragment>
		);
	}

	if (
		filteredStarredSpaces.length === 0 &&
		filteredRecentSpaces.length === 0 &&
		!filteredCurrentSpace
	) {
		return <NoFilteredResultsState />;
	}

	const isCompanyHub = isCompanyHubSpaceKey(currentSpace?.key);
	return (
		<Box xcss={!isNav4Enabled && bottomPaddingContainerStyles}>
			{isGuest && !hideRequestToUpgrade && shouldRenderExperiment && (
				<GuestRequestToUpgradeSpacesMenu
					onPermissionDenied={hideRequestToUpgradeHandler}
					onPermissionError={hideRequestToUpgradeHandler}
				/>
			)}
			{filteredCurrentSpace && !isCompanyHub && (
				<SpacesDropdownSection
					heading={CurrentHeading}
					currentSpace={filteredCurrentSpace}
					listId="current"
					isNav4Enabled={isNav4Enabled}
				/>
			)}
			{filteredStarredSpaces.length > 0 && (
				<SpacesDropdownSection
					heading={isLicensed && StarredHeading}
					spaces={filteredStarredSpaces}
					listId="starred"
					isNav4Enabled={isNav4Enabled}
				/>
			)}
			{filteredRecentSpaces.length > 0 && (
				<SpacesDropdownSection
					heading={RecentHeading}
					spaces={filteredRecentSpaces}
					listId="recent"
					isNav4Enabled={isNav4Enabled}
				/>
			)}
			<ExperienceSuccess name={APP_NAV_SPACES_DROPDOWN_EXPERIENCE} />
		</Box>
	);
};
