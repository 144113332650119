import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { expValEquals, expValEqualsNoExposure } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import { useCanUpgradeForAllUsersInProduct } from '@confluence/experiment-mpucup-free-to-standard-feature-gates';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';

type ConfluenceMpucupStandardFgsExperimentResponse = {
	isEnrolled: boolean;
	showUpsell?: boolean;
	upgradeUrl?: string;
};

export const useConfluenceMpucupStandardFgsExperiment = (
	skip?: boolean,
): ConfluenceMpucupStandardFgsExperimentResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const aaEnabled = Boolean(fg('conf-standard-fgs-for-mpucup-aa-gate'));
	const abEnabled = Boolean(fg('conf-standard-fgs-for-mpucup-ab-gate'));

	const skipHook = skip || !(aaEnabled || abEnabled);
	const { error, canUpgradeAndPay, changeOfferingLink } =
		useCanUpgradeForAllUsersInProduct(skipHook);

	if (error) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'error',
				actionSubject: 'luna-growth-experiment-graphql-error',
				actionSubjectId: `useConfluenceMpucupStandardFgsExperiment${abEnabled ? 'AB' : 'AA'}`,
				attributes: {
					error: error?.message,
					extraInfo: error?.extraInfo,
					graphqlErrors: error?.graphQLErrors,
					networkErrors: error?.networkError,
					growthFeature: 'confluenceMpucupStandardUpsellFgs',
					experimentType: abEnabled ? 'ab' : 'aa',
				},
				source: 'confluenceMpucupStandardUpsellFgs',
			},
		}).fire();

		if (!isErrorMarkedAsHandled(error)) {
			markErrorAsHandled(error);
		}
	}

	if (!skipHook && canUpgradeAndPay) {
		return {
			isEnrolled: abEnabled
				? expValEquals('conf-standard-fgs-for-mpucup-ab', 'cohort', 'control') ||
					expValEqualsNoExposure('conf-standard-fgs-for-mpucup-ab', 'cohort', 'variation')
				: expValEquals('conf-standard-fgs-for-mpucup-aa', 'cohort', 'control') ||
					expValEqualsNoExposure('conf-standard-fgs-for-mpucup-aa', 'cohort', 'variation'),
			upgradeUrl: abEnabled
				? expValEqualsNoExposure('conf-standard-fgs-for-mpucup-ab', 'cohort', 'variation')
					? changeOfferingLink
					: undefined
				: undefined,
		};
	}

	return { isEnrolled: false };
};

export const useConfluenceMpucupStandardFgsExperimentAA = (
	skip?: boolean,
): ConfluenceMpucupStandardFgsExperimentResponse => {
	return useConfluenceMpucupStandardFgsExperiment(skip);
};

export const useConfluenceMpucupStandardFgsExperimentAB = (
	skip?: boolean,
): ConfluenceMpucupStandardFgsExperimentResponse => {
	return useConfluenceMpucupStandardFgsExperiment(skip);
};
